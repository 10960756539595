import { firebaseApp } from "./firebase";
import { getStorage, ref, uploadBytesResumable, listAll, getDownloadURL } from "firebase/storage";
import { createdocument } from "./db";
export const uploadFileToBucket = (uid, callbackFunction, file, fileName) => {
    const storage = getStorage(firebaseApp);
    // upload file to firebase storage
    const storageRef = ref(storage, `${uid}/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
        "state_changed",
        (snapshot) => {
            // upload in progress
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
        },
        (error) => {
            // upload failed
            console.log(error);
        },
        () => {   
             if (callbackFunction != null && callbackFunction != undefined) {

                callbackFunction(fileName); 
            }

            getDownloadURL(storageRef).then((url) => {
                createdocument (uid, file, fileName, url)
            });
            // return out
        }
    );
    

};

export const uploaddocToBucket = (uid, callbackFunction, file, fileName, type) => {
    const storage = getStorage(firebaseApp);
    const metadata = {
        customMetadata: {
          'uid': uid,
          'type': type,
        }
      };
    // upload file to firebase storage
    const storageRef = ref(storage, `spv/product/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
        "state_changed",
        (snapshot) => {
            // upload in progress
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
        },
        (error) => {
            // upload failed
            console.log(error);
        },
        () => {   
             if (callbackFunction != null && callbackFunction != undefined) {

                callbackFunction(fileName); 
            }

            getDownloadURL(storageRef).then((url) => {
                createdocument (uid, file, fileName, url)
            });
            // return out
        }
    );
    

};



// get all files from firebase storage
export const getAllFiles = async (uid) => {
    const storage = getStorage(firebaseApp);
    const storageRef = ref(storage, uid);
    const files = await listAll(storageRef);
    console.log(files);
    return files;
}

// get all files from firebase storage
export const getFileUrl = async (fullPath) => {
    const storage = getStorage(firebaseApp);
    const storageRef = ref(storage, fullPath);
    return await getDownloadURL(storageRef);
}


