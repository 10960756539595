import React, { useEffect, useState } from "react";
import { getAllEOI, getAllProducts, updateEOI,getaProducts,  approveEOI } from '../../util/db'
import Button from "@material-ui/core/Button";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AdminNavbar from "../../components/AdminNavbar";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import { auth as myAuth, requireAuth } from "../../util/auth";
import { ToastContainer, toast } from 'react-toastify';
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { uploaddocToBucket } from '../../util/firebase-storage';
import ApproveEOI1 from './ApproveEOI';
function EOIApprove() {
    const [eoi, setEOI] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState("-1");
    const [selectedUserId, setSelectedUserId] = React.useState(null);
    const [panFile, setPanFile] = useState(null);
    const [approveb, setApproveb] = useState (false);
    const [showNewClientModal, setShowNewClientModal] = useState(false);
    const [rowinfo, setRowinfo] = useState([]);
const [panfVerified, setPANfVerified] = useState(false);
const [pending10, setPending10] = useState(false);
const [product, setProduct] = useState([]);

const populateproduct = async () => {
     
    const user = await getaProducts("dWjFy1y1XVJ3Ue8EYFBx");
    setProduct(user);
 
}
const pancallbackFunction = (fileName) => { 
  setPending10(false);
  setPANfVerified(true);
  toast.success("PAN Uploaded Successfully", {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
}
const ApproveEOI =  (row) =>  {
//    await approveEOI(id);
//     setApproveb(true);
setRowinfo(row);
setShowNewClientModal(true);


  }
  const handleCloseClientModal = (needRefresh) => {
    // open modal
    setShowNewClientModal(false);
    fetchEOI();
    if (needRefresh) {
        fetchProductsForFilter();
    }
}

    const type = [
        {
          value: 'Investment_Deck',
          label: 'Investment_Deck',
        },
        {
          value: 'EOI_Agreement',
          label: 'EOI_Agreement',
        },
        {
          value: 'Insurance',
          label: 'Insurance',
        },
        {
          value: 'Lease_Agreement',
          label: 'Lease_Agreement',
        },
        {
          value: 'Legal_Opinion',
          label: 'Legal_Opinion',
        },
        {
          value: 'Rent_Receipts',
          label: 'Rent_Receipts',
        },
        {
          value: 'Sale_Deed',
          label: 'Sale_Deed',
        },
        {
          value: 'TDS_Documents',
          label: 'TDS_Documents',
        },
        {
          value: 'Sale_Agreement',
          label: 'Sale_Agreement',
        },
        {
          value: 'Property_tax',
          label: 'Property_tax',
        },
      ];
      const [doctype, setDoctype] = React.useState('');
      
      const uploadPan = () => {
        setPending10(true);
        uploaddocToBucket('dWjFy1y1XVJ3Ue8EYFBx', pancallbackFunction, panFile, doctype, doctype);
      }
      const handleosChange = (event) => {
        setDoctype(event.target.value);
      };
      
    useEffect(() => {
        fetchProductsForFilter();
        populateproduct();
    }, []);
    const openTransactionPage = async () => {
        setSelectedUserId(eoi.email);
    }
    useEffect(() => {
        fetchEOI();
        
    }, [selectedProduct]);

    const fetchEOI = async () => {
        // const temp = await getAllEOI(selectedProduct); change from git
        // console.table(temp);
        // setEOI(temp);
        if (selectedProduct === "-1") {
            setEOI([]);
        } else {
            const temp = await getAllEOI(selectedProduct);
            setEOI(temp);
            if(temp.status === "Registered"){
                setApproveb(true);
            }
        }

    }
    const fetchProductsForFilter = async () => {
        const temp = await getAllProducts();
        setProducts(temp);
    }

    return (
        <>
             <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
<ToastContainer />
            <AdminNavbar
                color="default"
                logo="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-black.png?alt=media&token=4df2ee2a-8696-48b5-8a65-98171d8179d9"
                logoInverted="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096"
            />
            {showNewClientModal && <ApproveEOI1 eoi_data={eoi} rowinfos= {rowinfo} product_cost= {product.property_cost} property_name= {product.property_name} handleCloseModal={handleCloseClientModal} />}
            <div style={{ padding: '2rem' }}>
                <h1>EOI</h1>
                <Box sx={{ width: 200 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Product</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedProduct}
                            label="Product"
                            onChange={(event) => setSelectedProduct(event.target.value)}
                        ><MenuItem value={"-1"}>Select</MenuItem>
                            {products.map((product) => {
                                return <MenuItem value={product.id}>{product.property_name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Paper sx={{ width: '100%' }}>

                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Name
                                    </TableCell>
                                    <TableCell>
                                        Email
                                    </TableCell>
                                    <TableCell >
                                        Phone
                                    </TableCell>
                                    <TableCell >
                                        Product
                                    </TableCell>
                                    <TableCell >
                                        Investment Amount
                                    </TableCell>
                                    <TableCell >
                                        Add Documents
                                    </TableCell>
                                    <TableCell >Approve</TableCell>
                                    
                                    <TableCell >
                                        Status
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    eoi.map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.name}>
                                                <TableCell >
                                                    {row.name}
                                                </TableCell>
                                                <TableCell >
                                                    {row.email}
                                                </TableCell>
                                                <TableCell >
                                                    {row.phone}
                                                </TableCell>
                                                <TableCell >
                                                    {row.productName}
                                                </TableCell>
                                                <TableCell >
                                                    {row.investment_amount}
                                                </TableCell>
                                                <TableCell >
                                                <Button variant="contained" color="primary" onClick={() => openTransactionPage(row.email)} disabled={true}>
                                                Add Documents
                                                    </Button>
                                                </TableCell>
                                                <TableCell >
                                                <Button variant="contained" color="primary" disabled={row.status !== "Requested"}   onClick={() => ApproveEOI(row)}>
                                                Approve
                                                    </Button>
                                                </TableCell>
                                                <TableCell >
                                                    {row.status}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {eoi.length == 0 &&
                                    <TableRow hover role="checkbox" tabIndex={-1}>
                                        <TableCell colSpan={5} >
                                            No Pending EOI
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                {selectedProduct && selectedUserId &&
                       <section>                                      <Grid container={true} spacing={3} >
                                      <Grid item xs={12}>
                                <p style={{ marginBottom:'0px'
                                }}>Upload PAN</p></Grid>
                                                <Grid item={true} xs={6}>
                  <TextField
                    variant="outlined"
                    type="occupation"
                    value={doctype}
                    select
                    label="Occupation"
                    name="occupation"
                    placeholder="Occupation"
                    onChange={handleosChange}
                    fullWidth={true}                    
                    >
                      {type.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                                <Grid item xs={3}>
                                <Button
                                  variant="outlined"
                                  component="label"
                                  color="primary"
                                  style={{overflow:'hidden'}}
                                  disabled={panfVerified || pending10}

                                >
                                    <input
                                      type="file"
                                      // hidden
                                      style={{display:"none"}}
                                      onChange={(e) => { setPanFile(e.target.files[0]) }} />
                                      <text style={{overflow:'hidden', width: '130px', height:'25px', display:'flex', alignItems:'center', justifyContent:'center' }}>{panFile?  panFile.name : "Choose File"}</text>
                                  </Button>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                      <Button variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        type="button"
                                                        onClick={uploadPan}
                                                        disabled={panfVerified || pending10}
                                                        >{pending10 && <CircularProgress size={28} />}
                                                        {panfVerified ? 'Document Uploaded' : 'Upload Document'}
                                                        </Button>
                                                    </Grid>
                                                    </Grid>
</section> 

                }
            </div>
        </>
    )
}

// export default EOIApprove;


export default requireAuth(EOIApprove, ["admin"]);
