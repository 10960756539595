import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import ChatIcon from "@material-ui/icons/Chat";
import VideogameAssetIcon from "@material-ui/icons/VideogameAsset";
import { makeStyles } from "@material-ui/core/styles";
import Sectionr145 from "./Sectionr145";
import SectionHeaderr from "./SectionHeaderr";
import SectionHeader23R from "./SectionHeader23R";

const useStyles = makeStyles((theme) => ({

  container: {
    padding: `0 ${theme.spacing(4)}px`,
  },
  imageWrapper: {
    margin: "0 auto",
    maxWidth: 570,
    width: "100%",
    "& > img": {
      width: "100%",
    },
  },
  row: {
    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(2)}px`,
    },
  },
}));

function Sectionr101(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Chat",
      description:
        "Imagine a world where you can chat with your friends without having to see their dumb faces.",
      icon: ChatIcon,
      iconColor: "primary.main",
    },
    {
      title: "Play",
      description:
        "We embedded a bunch free flash games inside our app. What we lack in quality we make up in quantity.",
      icon: VideogameAssetIcon,
      iconColor: "secondary.main",
    },
  ];

  return (
    <Sectionr145
    sx={{display:{sm: "flex", xs: "flex", lg: "none" }}}
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      style={{
        backgroundColor: "#262632",
      }}
    >
      <Container className={classes.container}>
        <Grid container={true} alignItems="center" spacing={8}>
          <Grid
            item={true}
            xs={12}
            md={12}
            style={{
              marginTop: "-15%",
              backgroundColor: "#262632",
              color: "#ffffff",
            }}
          >
            <SectionHeaderr
              title={props.title}
              subtitle={props.subtitle}
              subtitle1={props.subtitle1}
              type={props.type}
              size={5}
            />
            <SectionHeader23R
              title={props.title}
              text1={props.text1}
              ps={props.ps}
              psqft={props.psqft}
              pc={props.pc}
              tirr={props.tirr}
              ey={props.ey}
              aay={props.aay}
              ifr={props.ifr}
              mi={props.mi}
              size={6}
              size1={1}
              tp={props.tp}
            />
          </Grid>
        </Grid>
        <br /><br />
        <p style={{color: 'gray'}}>*Gross Entry Yield includes 1% Rental Top-up</p>
        <p style={{color: 'gray'}}>**IRR & Target Multiplier are calculated with an asset value appreciation of 5% CAGR</p>
        <br />
      </Container>
    </Sectionr145>
  );
}

export default Sectionr101;
