import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Flippy, { FrontSide, BackSide } from 'react-flippy';
const useStyles = makeStyles((theme) => ({
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    maxWidth: 700,
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: "inline-block",
  },
}));

function Page1News321(props) {
  const classes = useStyles();
  const [isFlipped, setIsFlipped] = React.useState(false)
  const [count, setCount] = React.useState(1)

  React.useEffect(() => {
    console.log(`initializing interval`);
    const interval = setInterval(() => {
      setIsFlipped(!isFlipped);
    }, 4000);
    return () => {
      console.log(`clearing interval`);
      clearInterval(interval);
    };

  }, [isFlipped]);
  React.useEffect(() => {
    const interva1 = setInterval(() => {
      setCount(count+1)
    }, 9000);
    
  
    return () => {
      console.log(`clearing interval`);
      clearInterval(interva1);
    };
  }, []);

  const { subtitle, subtitle1, title, size, className, ...otherProps } = props;

  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <Box
    component="header"
    className={classes.root + (props.className ? ` ${props.className}` : "")}
    {...otherProps}
    textAlign="left"
    color="#ffffff"
  >

    {title && (
      <>
        <Typography
          variant={`h${size}`}
          gutterBottom={props.subtitle ? true : false}
          style={{ fontWeight: "bold", marginBottom: "15px" }}
        />

        {subtitle1 && (
          <Typography variant="subtitle1" className={classes.subtitle}>
            {subtitle1}
          </Typography>
        )}
      </>
    )}
            <Flippy 
        isFlipped={isFlipped}
  flipDirection="vertical"
>
   <FrontSide
    style={{
      backgroundColor: "#262632",
      textAlign: "center",
      height: "90px",
      marginBottom: "20px",
      justifyContent: "flex-start",
      alignItems: "center",
      display: "flex",
    }}
  >
      <img
        src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/building-2.png?alt=media&token=3404c7c3-a714-444b-9c6c-afca17e419c1"
        style={{ paddingLeft: "30px", paddingRight: "30px" }}
      />
      <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
        The investment is secured by the underlying asset
      </a>
  </FrontSide>
  <BackSide
    style={{
      backgroundColor: "#616161",
      textAlign: "center",
      height: "90px",
      marginBottom: "20px",
      justifyContent: "flex-start",
      alignItems: "center",
      display: "flex",
    }}>
      <img
        src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/calendar.png?alt=media&token=e2fb4549-4635-4fd7-9009-70e930b07347"
        style={{ paddingLeft: "30px", paddingRight: "30px" }}
      />
      <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
        Monthly pay-out of annualized return.
      </a>
  </BackSide>

</Flippy>
<Flippy 
        isFlipped={isFlipped}
  flipDirection="vertical"
>
   <FrontSide
    style={{
      backgroundColor: "#3DAEB6",
      textAlign: "center",
      height: "40px",
      height: "90px",
      marginBottom: "20px",
      justifyContent: "flex-start",
      alignItems: "center",
      display: "flex",
    }}
  >
      <img
        src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/piggy-bank.png?alt=media&token=ca40d982-aa62-4cd2-9f4b-9b3f52aac177"
        style={{ paddingLeft: "30px", paddingRight: "30px" }}
      />
      <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
        No need for External Funding, your Savings are enough.
      </a>
  </FrontSide>
  <BackSide
    style={{
      backgroundColor: "#262632",
      textAlign: "center",
      height: "40px",
      height: "90px",
      marginBottom: "20px",
      justifyContent: "flex-start",
      alignItems: "center",
      display: "flex",
    }}>
      <img
        src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/grid.png?alt=media&token=898f0715-e42b-4681-9396-7174d376ecb0"
        style={{ paddingLeft: "30px", paddingRight: "30px" }}
      />
      <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
        Multiple Choices in the Market
      </a>
  </BackSide>
  { count% 2 == 0 && <BackSide
       style={{
        backgroundColor: "#616161",
        textAlign: "center",
        height: "40px",
        height: "90px",
        marginBottom: "20px",
        justifyContent: "flex-start",
        alignItems: "center",
        display: "flex",
      }}
    >  <img
    src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/align-vertical-justify-center.png?alt=media&token=13db0f7e-a871-46e5-8b8b-620efa1358f6"
    style={{ paddingLeft: "30px", paddingRight: "30px" }}
  />
  <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
    Competes favourably with Equity funds
  </a>
  </BackSide> }

</Flippy>

    {/* <div
      style={{
        backgroundColor: "#616161",
        textAlign: "center",
        height: "40px",
        height: "90px",
        marginBottom: "20px",
        justifyContent: "flex-start",
        alignItems: "center",
        display: "flex",
      }}
    >
      <img
        src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/align-vertical-justify-center.png?alt=media&token=13db0f7e-a871-46e5-8b8b-620efa1358f6"
        style={{ paddingLeft: "30px", paddingRight: "30px" }}
      />
      <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
        Competes favourably with Equity funds
      </a>
    </div> */}
  </Box>
  );
}

export default Page1News321;
