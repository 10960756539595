import React from "react";
import Container from "@material-ui/core/Container";
import Sectionbks1 from "./Sectionbks1";
import SectionHeader3 from "./SectionHeader3";
import Auth from "./Authadmin";
import AuthFooter from "./AuthFooter";

function AuthSection(props) {
  // Options by auth type
  const optionsByType = {

    signin: {
      title: "Admin Login",
      buttonAction: "Sign in",
      showFooter: true,
      signupPath: "/auth/signup",
      forgotPassAction: "Forgot Password?",
      forgotPassPath: "/auth/forgotpass",
    },
    forgotpass: {
      title: "Get a new password",
      buttonAction: "Reset password",
      showFooter: true,
      signinText: "Remember it after all?",
      signinAction: "Sign in",
      signinPath: "/auth/signin",
    },
    changepass: {
      title: "Choose a new password",
      buttonAction: "Change password",
    },

  };

  // Ensure we have a valid auth type
  const type = optionsByType[props.type] ? props.type : "signup";
  // from git hub
  const referralCode = props.referralCode;
  // Get options object for current auth type
  const options = optionsByType[type];

  return (
    <Sectionbks1
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="xs">
        <SectionHeader3
          title={options.title}
          subtitle={<p style={{fontSize:"18px", fontWeight:"600"}}>{options.subtitle}</p>}
          size={4}
          textAlign="center"
        />
        <Auth
          type={type}
          // from git
          referralCode={referralCode}
          buttonAction={options.buttonAction}
          providers={props.providers}
          afterAuthPath={props.afterAuthPath}
          key={type}
        />

        {options.showFooter && <AuthFooter type={type} {...options} />}
      </Container>
    </Sectionbks1>
  );
}

export default AuthSection;
