import React from "react";
import Meta from "./../components/Meta";
import AuthSection from "./../components/AuthSection";
import { useRouter } from "./../util/router";
import Navbar3new from "./../components/Navbar3new";
import FeaturesSection from "./../components/FeaturesSectioncp";

function AuthPagecp(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="Auth" />
      <Navbar3new
        color="default"
        logo="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-black.png?alt=media&token=4df2ee2a-8696-48b5-8a65-98171d8179d9"
        logoInverted="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096"
      />
      <FeaturesSection
        
        bgColor="default"
        size="medium"
        bgImage="https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/cploginimg.jpg?alt=media&token=18d4a420-1f00-4618-a24a-05545d1c498d"
        bgImageOpacity={1}
        image="https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/cploginimg.jpg?alt=media&token=18d4a420-1f00-4618-a24a-05545d1c498d"
      />
    </>
  );
}

export default AuthPagecp;
