import React, { useEffect, useState } from "react";
import { requireAuth, useAuth } from "./../util/auth";
 function Signout() {
    const auth = useAuth();
    useEffect(() => {
        auth.signout();
      }, [])
  return (
    <div>signout</div>
  )
}
export default requireAuth(Signout);