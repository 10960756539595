import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    maxWidth: 700,
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: "inline-block",
  },
}));

function Page1New23Ss32(props) {
  const classes = useStyles();

  const { subtitle, subtitle1, title, size, className, ...otherProps } = props;

  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <Box
      component="header"
      className={classes.root + (props.className ? ` ${props.className}` : "")}
      {...otherProps}
      textAlign="left"
      color="#ffffff"
    >
      <p style={{ fontSize: "15px" }}>
        You don’t need to be a land shark, or a real estate giant, to enjoy a
        slice of the premium commercial real estate opportunities in India.
        <br />
        <br />
        The amalgamation of Finance, Real Estate, and Technology have made
        things exciting and favourable for the average Retail Investor.
      </p>
      <br />
    </Box>
  );
}

export default Page1New23Ss32;
