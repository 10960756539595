import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Roadtos1 from "./Roadtos1";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  media: {
    height: 350,
  },
  avatarWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: -(theme.spacing(15) / 2),
  },
  avatarBorder: {
    borderRadius: "0px",
    padding: "7px",
    backgroundColor: "white",
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

function TeamBiosSection(props) {
  const classes = useStyles();

  const items = [
    {
      namesub: <h2 style={{ color: "#3DAEB6" }}>The Experience</h2>,
      image:
        "https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/GAYN_Profile-02.jpg?alt=media&token=12a1e03c-1e2c-44a5-a959-a91584142399",
      name: <h2 style={{ color: "#3DAEB6" }}>Kalyan Jayaprakash</h2>,
      role: (
        <p style={{ fontWeight: "600", fontSize: "13px" }}>CEO & Co-founder</p>
      ),
      linkedin:'https://www.linkedin.com/in/kalyan-jayaprakash-668ab38/',
      roled: "Real Estate | Investment Banking | Acquisitions",
    },
    {
      namesub: <h2 style={{ color: "#3DAEB6" }}>The Expertise</h2>,
      image:
        "https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/ganeshpic.jpg?alt=media&token=5624abd8-5a80-4afc-8730-d099dcfa8bc2",
      name: <h2 style={{ color: "#3DAEB6" }}>Ganesh Jayaprakash</h2>,
      role: (
        <p style={{ fontWeight: "600", fontSize: "13px" }}>CTO & Co-founder</p>
      ),
      linkedin:'https://www.linkedin.com/in/ganeshjayaprakash/',

      roled: "Fintech Leadership | Digital Transformations | Design Thinking",
    },
    {
      namesub: <h2 style={{ color: "#3DAEB6" }}>The Energy</h2>,
      image:
        "https://firebasestorage.googleapis.com/v0/b/gayn-dev.appspot.com/o/kishore.jpg?alt=media&token=2c920484-e263-406c-a725-4285e6acd176",
      name: <h2 style={{ color: "#3DAEB6" }}>Kishore Janakiraman</h2>,
      role: (
        <p style={{ fontWeight: "600", fontSize: "13px" }}>CMO - Chief Marketing Officer</p>
      ),
      linkedin:'https://www.linkedin.com/in/kishore-janakiraman-750472135/',

      roled:
        "Marketing Strategy | Tactical Collaboration | Real Estate Expertise",
    },
    {
      namesub: '',
      image:
        "https://firebasestorage.googleapis.com/v0/b/gayn-dev.appspot.com/o/Lakshman%201.jpg?alt=media&token=269f721a-3446-43fb-8e1a-6f5ab8166486",
      name: <h2 style={{ color: "#3DAEB6" }}>Lakshmanan Vaidyanathan</h2>,
      role: (
        <p style={{ fontWeight: "600", fontSize: "13px" }}>Director - Finance</p>
      ),
      linkedin:'https://www.linkedin.com/in/lakshmanan-vaidyanathan-87800a3b/',

      roled:
        "Financial Strategy | Legal Advisory | Business Finance ",
    },
    {
      namesub: '',
      image:
        "https://firebasestorage.googleapis.com/v0/b/gayn-dev.appspot.com/o/Shashie.jpg?alt=media&token=3561f0bb-04d6-427c-8ee2-10c092bc15d1",
      name: <h2 style={{ color: "#3DAEB6" }}>Shashie Kumar</h2>,
      role: (
        <p style={{ fontWeight: "600", fontSize: "13px" }}>Director & Chief Growth Officer</p>
      ),
      linkedin:'https://www.linkedin.com/in/shashie-kumar-897688b0/',

      roled:
        <a>Growth Strategy | Market Expertise | Business Leadership <br/></a>,
    },
    {
      namesub: '',
      image:
        "https://firebasestorage.googleapis.com/v0/b/gayn-dev.appspot.com/o/mohamad.jpg?alt=media&token=ce74d41c-da6b-4590-adb6-15a7663bcfb4",
      name: <h2 style={{ color: "#3DAEB6" }}>Mohammed Aftab</h2>,
      role: (
        <p style={{ fontWeight: "600", fontSize: "13px" }}>Director - Acquisitions</p>
      ),
      linkedin:'https://www.linkedin.com/in/mohammed-aftab-a116086/',

      roled:
        "Realty | Acquisitions | Entrepreneurship",
    },
    {
      namesub: '',
      image:
        "https://firebasestorage.googleapis.com/v0/b/gayn-82759.appspot.com/o/AJM.jpg?alt=media&token=be3e8786-2973-4c78-ae4f-5203944a477e",
      name: <h2 style={{ color: "#3DAEB6" }}>Ajay Menon</h2>,
      role: (
        <p style={{ fontWeight: "600", fontSize: "13px" }}>Brand Evangelist</p>
      ),
      linkedin:'https://www.linkedin.com/in/ajmenon2019/',

      roled:
        "Fintech Branding | Creative Direction | Media Planning & Operations",
    },
    {
      namesub: '',
      image:
        "https://firebasestorage.googleapis.com/v0/b/gayn-82759.appspot.com/o/Kim.jpg?alt=media&token=bd287da0-b560-4a24-8464-9375573918c6",
      name: <h2 style={{ color: "#3DAEB6" }}>Kim Coyne</h2>,
      role: (
        <p style={{ fontWeight: "600", fontSize: "13px" }}>Head of Marketing & Communications</p>
      ),
      linkedin:'https://www.linkedin.com/in/kim-coyne-43608114/',

      roled:
        "Martech | Digital Marketing | Communications Strategy",
    },

    {
      namesub: '',
      image:
        "https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/avinash.png?alt=media&token=3d6f666e-8cc6-4c4d-bb6d-82bd83bdc92a",
      name: <h2 style={{ color: "#3DAEB6" }}>Avinash Boopal</h2>,
      role: (
        <p style={{ fontWeight: "600", fontSize: "13px" }}>Head of Products</p>
      ),
      linkedin:'https://www.linkedin.com/in/avinashboopal',

      roled:
      "Product Design | Data Visualization | Strategic Partnership",
    },
    {
      namesub: '',
      image: "https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/Aneetha%20J%20Photo.jpg?alt=media&token=ff7ae091-42c9-446d-86b3-4d896d8b6e06",
      name: <h2 style={{ color: "#3DAEB6" }}>Aneetha J</h2>,
      role: (
        <p style={{ fontWeight: "600", fontSize: "13px" }}>Head of CRM</p>
      ),
      linkedin:'https://www.linkedin.com/in/aneetha-j-413b79a0/',

      roled:
      "CRM Strategy | Data Analysis | IT Integration",
    },
    {
      namesub: '',
      image:
        "https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/Gopi.jpg?alt=media&token=5082b28d-697e-4243-afdf-c0f14e0fc322",
      name: <h2 style={{ color: "#3DAEB6" }}>Gopi Balu</h2>,
      role: (
        <p style={{ fontWeight: "600", fontSize: "13px" }}>Head - Channel and Distribution</p>
      ),
      linkedin:'https://www.linkedin.com/in/gopi-balu-49b16549',

      roled:
        <a>Channel Management | Distribution Networks <br/></a>,
    },

    {
      namesub: '',
      image:
        "https://firebasestorage.googleapis.com/v0/b/gayn-82759.appspot.com/o/wasiq.jpg?alt=media&token=108aca48-ba9e-4700-8c85-7a1be4fba6b7",
      name: <h2 style={{ color: "#3DAEB6" }}>Mohammed Nusaib Wasiq</h2>,
      role: (
        <p style={{ fontWeight: "600", fontSize: "13px" }}>Jr. Investment Manager </p>
      ),
      linkedin:'https://www.linkedin.com/in/mohammed-nusaib-wasiq-98398215a/',

      roled:
      "Market Strategy | Fundamental Analysis | Investment Advisory",
    },
    {
      namesub: '',
      image:
        "https://firebasestorage.googleapis.com/v0/b/gayn-82759.appspot.com/o/syed.jpg?alt=media&token=66fe05c3-a1a8-420a-9841-6c7cc646058c",
      name: <h2 style={{ color: "#3DAEB6" }}>Syed Mohammed Atir</h2>,
      role: (
        <p style={{ fontWeight: "600", fontSize: "13px" }}>Finance Executive</p>
      ),
      linkedin:'https://www.linkedin.com/in/syed-mohammed-atir-a9402b20b',

      roled:
      "P&L analysis | Financial Strategy | Asset Management",
    },



  ];
  const filteredItems = items.filter((item, index) => index < 3);
  const filteredItems1 = items.filter((item, index) => index > 3);
  return (
    <Roadtos1
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        {<h2 style={{ color: "#3DAEB6" }}>The Team</h2>}
        <h3>
          Your Partners{" "}
          <a style={{ fontWeight: "600", marginBottom: "20px" }}>on your</a>{" "}
          Personal Investment Journey
        </h3>
        <Grid
          container={true}
          justifyContent="center"
          spacing={4}
          style={{ marginTop: "-50px" }}
        >
          {filteredItems.map((item, index) => (
            <Grid
              item={true}
              xs={12}
              sm={6}
              md={4}
              key={index}
              style={{ marginTop: "30px", textAlign: "left" }}
            >
              {item.namesub}
              <Card className={classes.card} style={{ height: "90%" }}>
                <CardMedia
                  className={classes.media}
                  image={item.image}
                  title={item.name}
                />
                <Box p={3}>
                  <Box textAlign="left">
                    <Typography
                      variant="body2"
                      component="p"
                      style={{ marginBottom: "-10px" }}
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {item.role}
                      {item.roled}
                      <br />
                      <br />
                      <a
                      href={item.linkedin}
                      target='_blank'>
                      <img
                      
                        src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/linkedin.png?alt=media&token=656caf67-69dc-40f7-885e-7cce83e90ef5"
                        style={{ marginRight: "10px" }}
                      /></a>
                      {/* <img src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/mail.png?alt=media&token=02c9075b-b22c-49d3-b202-418299dffb88" /> */}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
               <Grid
              item={true}
              xs={12}
              sm={12}
              md={12}

           />
                    {filteredItems1.map((item, index) => (
            <Grid
              item={true}
              xs={12}
              sm={6}
              md={4}
              key={index}
              style={{ marginTop: "30px", textAlign: "left" }}
            >
              {item.namesub}
              <Card className={classes.card} style={{ height: "90%" }}>
                <CardMedia
                  className={classes.media}
                  image={item.image}
                  title={item.name}
                />
                <Box p={3}>
                  <Box textAlign="left">
                    <Typography
                      variant="body2"
                      component="p"
                      style={{ marginBottom: "-10px" }}
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {item.role}
                      {item.roled}
                      <br />
                      <br />
                      <a
                      href={item.linkedin}
                      target='_blank'>
                      <img
                      
                        src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/linkedin.png?alt=media&token=656caf67-69dc-40f7-885e-7cce83e90ef5"
                        style={{ marginRight: "10px" }}
                      /></a>
                      {/* <img src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/mail.png?alt=media&token=02c9075b-b22c-49d3-b202-418299dffb88" /> */}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Roadtos1>
  );
}

export default TeamBiosSection;
