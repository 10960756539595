import React, { useState, useEffect } from 'react'
import AdminNavbar from './../../components/AdminNavbar';
import {
    getEOIForTransactionByProduct,
    getTransactionByUserAndProduct,
    getAllProducts,
    updateTransactionByProduct,
    deleteTransaction,
    markProductActive
} from '../../util/db';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from "@material-ui/core/Button";
import Grid from '@mui/material/Grid';
import { Checkbox } from '@material-ui/core';
import { Alert } from '@mui/material';
import { auth as myAuth, requireAuth } from "../../util/auth";


function ProductTable() {
    const [eoi, setEOI] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [previous, setPrevious] = React.useState({});
    const [selectedUserId, setSelectedUserId] = React.useState(null);
    const [userProductTransaction, setUserProductTransaction] = React.useState([]);
    const [formAlert, setFormAlert] = useState(null);
    const [invst, setinvst] = React.useState('');
    const [invsto, setinvsto] = React.useState('');
    const handleiChange = (event) => {

        setinvst(event.target.value);
      };
      const handletoChange = (event) => {

        setinvsto(event.target.value);
      };
    const invstype = [
        {
          value: 'SPV',
          label: 'SPV',
        },
        {
          value: 'GAYN',
          label: 'GAYN',
        },
    ]  
    const invstypto = [
        {
          value: 'SPV',
          label: 'SPV',
        },
        {
          value: 'GAYN',
          label: 'GAYN',
        },
        {
            value: 'CP',
            label: 'CP',
          },
          {
            value: 'Investor',
            label: 'Investor',
          },
    ]   
    useEffect(() => {
        fetchProductsForFilter();
    }, []);

    useEffect(() => {
        fetchEOI();
    }, [selectedProduct]);

    useEffect(() => {
        if (selectedProduct && selectedUserId) {
            fetchUserProductTransaction(selectedProduct, selectedUserId);
        }
    }, [selectedUserId, selectedUserId]);

    const fetchUserProductTransaction = async (productId, userId) => {
        const response = await getTransactionByUserAndProduct(productId, userId);
        setUserProductTransaction(response);
    }

    const fetchProductsForFilter = async () => {
        const temp = await getAllProducts();
        setProducts(temp);
    }
    const fetchEOI = async () => {
        if (selectedProduct !== "-1") {
            const temp = await getEOIForTransactionByProduct(selectedProduct);
            setEOI(temp);
        }
    }

    const onChange = (e, row) => {
        if (!previous[row.id]) {
            setPrevious(state => ({ ...state, [row.id]: row }));
        }
        let value = '';
        let name = '';
        if (e instanceof Date) {
            value = e;
            name = "date"
        }
        else if (e.target.name === "selected") {
            value = e.target.checked;
            name = "selected"
        } else {
            value = e.target.value;
            name = e.target.name;
        }
        const { id } = row;
        const newRows = eoi.map(row => {
            if (row.id === id) {
                return { ...row, [name]: value };
            }
            return row;
        });
        setEOI(newRows);
    };
    const updateTransaction = async () => {
        try {
            if (eoi.filter(y => y.selected).length === 0) {
                setFormAlert({
                    type: "error",
                    message: `Atleast one transaction should be selected`,
                });
                return;
            }
            const selectedEOI = eoi.filter(y => y.selected);
            selectedEOI.forEach((data) => {
                if (!data.amount || Number(data.amount) <= 0) {
                    throw new Error(`Amount should be greater than 0 for Investor: ${data.name}`);
                }
                if (!data.reference_number || String(data.reference_number).length <= 0) {
                    throw new Error(`Invalid Reference Number for Investor: ${data.name}`);
                }
                if (data.date instanceof Date && String(data.date).length <= 0) {
                    throw new Error(`Invalid Date for Investor: ${data.name}`);
                }
                if (!(data.type == "credit" || data.type == "debit")) {
                    throw new Error(`Invalid Type for Investor: ${data.name}`);
                }
                if (!(data.payment_type == "Part Advance" ||
                    data.payment_type == "Full Advance" ||
                    data.payment_type == "Part Payment" ||
                    data.payment_type == "Full Amount" ||
                    data.payment_type == "Return" ||
                    data.payment_type == "Others"
                )) {
                    throw new Error(`Invalid Payment Type for Investor: ${data.name}`);
                }
            });
            console.log('aaaa', selectedEOI)
            await updateTransactionByProduct(selectedEOI);

            setFormAlert({
                type: "success",
                message: "Transaction Updated Successfully",
            });

            setTimeout(() => {
                setFormAlert(null);
                setSelectedProduct(null);
            }, 2000);
        } catch (error) {
            setFormAlert({
                type: "error",
                message: error.message,
            });
        }
    }

    const deleteUserTransaction = async (id) => {
        if (window.confirm("Are you sure you want to delete this transaction?")) {
            await deleteTransaction(id);
            setUserProductTransaction(userProductTransaction.filter(x => x.id !== id));
        }
    };
    const activateProduct = async (id) => {
        await markProductActive(id, "Active");
        fetchProductsForFilter();
    };
    const deActivateProduct = async (id) => {
        await markProductActive(id, "Inactive");
        fetchProductsForFilter();
    };
    const openTransactionPage = async (userId) => {
        setSelectedUserId(userId);
    }

    const checkPaymentType = (eoiPaymentType, menuItem) => {
        if (eoiPaymentType == null || eoiPaymentType == undefined || eoiPaymentType == '') {
            return true;
        } else if ((eoiPaymentType == "Full Advance" || eoiPaymentType == "Full Amount") && (menuItem == "Full Advance" || menuItem == "Part Advance")) {
            return false;
        } else if (eoiPaymentType == "Full Amount" && (menuItem == "Part Payment" || menuItem == "Full Amount")) {
            return false;
        }
        return true;
    }
    return (
        <>
            <AdminNavbar
                color="default"
                logo="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-black.png?alt=media&token=4df2ee2a-8696-48b5-8a65-98171d8179d9"
                logoInverted="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096"
            />
            <div style={{ padding: '2rem' }}>
                <h1>Admin Transaction Table</h1>
                <Button variant="contained" style={{ marginLeft:"80%", marginTop:"-50px", marginBottom:"50px" }} color="primary" >
                                                        Add Product
                                                    </Button>
                {formAlert && (
                    <Box mb={3}>
                        <Alert severity={formAlert.type}>{formAlert.message}</Alert>
                    </Box>
                )}
                {!selectedProduct && !selectedUserId && <Box >
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Product Name
                                    </TableCell>
                                    <TableCell>
                                        Total Value
                                    </TableCell>
                                    <TableCell>
                                        Invested Value
                                    </TableCell>
                                    <TableCell>
                                        Product type
                                    </TableCell>
                                    <TableCell>
                                        Status
                                    </TableCell>
                                    <TableCell>
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    products.map((row) => {
                                        return (
                                            <TableRow key={row.id}
                                                hover role="checkbox" tabIndex={-1}>
                                                <TableCell >
                                                    {row.Name}
                                                </TableCell>
                                                <TableCell >
                                                    {row.property_cost}
                                                </TableCell>
                                                <TableCell >
                                                    {row.total_amount_recieved}
                                                </TableCell>
                                                <TableCell >
                                                    {row.type}
                                                </TableCell>
                                                <TableCell >
                                                    {row.status != "Active" ? "Not Active" : "Active"}
                                                </TableCell>
                                                <TableCell>
                                                    <Button variant="contained" color="primary" onClick={() => row.status != "Active" ? activateProduct(row.id) : deActivateProduct(row.id)}>
                                                        {row.status != "Active" ? "Activate" : "De-Activate"}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                                {
                                    products.length == 0 &&
                                    <TableRow hover role="checkbox" tabIndex={-1}>
                                        <TableCell colSpan={5} >
                                            No products
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>}
                {!selectedUserId && selectedProduct && <><Paper sx={{ width: '100%' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Select
                                    </TableCell>
                                    <TableCell>
                                        Debit Transactions
                                    </TableCell>
                                    <TableCell>
                                        From
                                    </TableCell>
                                    
                                    <TableCell>
                                        To 
                                    </TableCell>
                                    <TableCell>
                                        To account
                                    </TableCell>
                                    <TableCell>
                                        Amount
                                    </TableCell>
                                    <TableCell >
                                        Reference Number
                                    </TableCell>
                                    <TableCell >
                                        Date(DD/MM/YYYY)
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    eoi.map((row) => {
                                        return (
                                            <TableRow key={row.id}
                                                hover role="checkbox" tabIndex={-1}>
                                                <TableCell >
                                                    <Checkbox
                                                        name="selected"
                                                        checked={row.selected}
                                                        onChange={(e) => onChange(e, row)} />
                                                </TableCell>
                                                <TableCell>
                                                    <Button variant="contained" color="primary" onClick={() => openTransactionPage(row.userid)}>
                                                        View
                                                    </Button>
                                                </TableCell>
                                                <TableCell >
                                                <TextField
                    variant="outlined"
                    select
                    fullWidth={true}
                    label="Type"
                    type="investmenttype"
                    name="from"
                    value={invst}
                    onChange={handleiChange}

                  >
                    {invstype.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                                                </TableCell>
                                               
                                                <TableCell >
                                                <TextField
                    variant="outlined"
                    select
                    fullWidth={true}
                    label="Type"
                    type="investmenttype"
                    name="to"
                    value={invsto}
                    onChange={handletoChange}
                    // required
                    // inputRef={register({
                    //   required: "Please select your Investment type",
                    // })}
                   
                  >
                    {invstypto.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                                                </TableCell>
                                                <TableCell >
                                                    <TextField
                                                        variant="outlined"
                                                        
                                                        name="taccount"
                                                        label="Enter selected user email"
                                                        
                                                    />
                                                </TableCell>
                                                <TableCell >
                                                    <TextField
                                                        variant="outlined"
                                                        value={row.amount}
                                                        name="amount"
                                                        onChange={e => onChange(e, row)}
                                                    />
                                                </TableCell>
                                                <TableCell >
                                                    <TextField
                                                        variant="outlined"
                                                        value={row.reference_number}
                                                        name="reference_number"
                                                        onChange={e => onChange(e, row)}
                                                    />
                                                </TableCell>
                                                <TableCell >
                                                    <DatePicker
                                                        label="DD/MM/YYYY"
                                                        value={row.date}
                                                        name="date"
                                                        onChange={e => onChange(e, row)}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </TableCell>

                                            </TableRow>
                                        );
                                    })}
                                {eoi.length == 0 &&
                                    <TableRow hover role="checkbox" tabIndex={-1}>
                                        <TableCell colSpan={5} >
                                            No Pending EOI
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                    <Grid spacing={2} container>
                        <Grid item xs={12} style={{ textAlign: 'center', paddingTop: '40px' }}>
                            <Button variant="contained" style={{ marginRight: '20px' }} color="primary" onClick={updateTransaction}>Update</Button>
                            <Button variant="contained" color="primary" onClick={() => setSelectedProduct(null)}>Cancel</Button>
                        </Grid>
                    </Grid></>}
                {selectedProduct && selectedUserId && <Box >
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Investor Name
                                    </TableCell>
                                    <TableCell>
                                        Amount
                                    </TableCell>
                                    <TableCell>
                                        Reference
                                    </TableCell>
                                    <TableCell>
                                        Date
                                    </TableCell>
                                    <TableCell>
                                        Type
                                    </TableCell>
                                    <TableCell>
                                        Payment Type
                                    </TableCell>
                                    <TableCell>
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    userProductTransaction.map((row) => {
                                        return (
                                            <TableRow key={row.id} hover role="checkbox" tabIndex={-1}>
                                                <TableCell>
                                                    {row.name}
                                                </TableCell>
                                                <TableCell>
                                                    {row.amount}
                                                </TableCell>
                                                <TableCell>
                                                    {row.reference_number}
                                                </TableCell>
                                                <TableCell>
                                                    {new Date(new Date(1970, 0, 1).setSeconds(row.date.seconds)).toLocaleDateString()}
                                                </TableCell>
                                                <TableCell>
                                                    {row.type}
                                                </TableCell>
                                                <TableCell>
                                                    {row.payment_type}
                                                </TableCell>
                                                <TableCell>
                                                    <Button variant="contained" color="primary" onClick={() => deleteUserTransaction(row.id)}>
                                                        Delete
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {userProductTransaction.length == 0 &&
                                    <TableRow hover role="checkbox" tabIndex={-1}>
                                        <TableCell colSpan={7} >
                                            No Transactions
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Grid spacing={2} container>
                        <Grid item xs={12} style={{ textAlign: 'center', paddingTop: '40px' }}>
                            <Button variant="contained" color="primary" onClick={() => setSelectedUserId(null)}>Cancel</Button>
                        </Grid>
                    </Grid>
                </Box>}
            </div>
        </>
    )
}

// export default TransactionTable;
export default requireAuth(ProductTable, ["admin"]);
