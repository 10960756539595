import { Container } from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Navbr from '../../components/usernav';
import { requireAuth,  useAuth  } from "../../util/auth";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import {getuserdocument, getAllEOIdocForUser, getAllemailEOI, getAllEOIProductsDocumentForUser} from '../../util/db';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const UseStyles = makeStyles((theme) => ({

  container: {
    paddingTop:"100px",
    // paddingLeft:"280px",
    // paddingRight:"0px",
    marginLeft:"18%",
    marginRight:"0px",
    // width:"100%"
  },
}));
function Documents() {
  const auth = useAuth();
  const userid = auth?.user?.id;
  const [uid, setUid] = useState(userid);
  const [value, setValue] = useState('1');
  const isSmall = useMediaQuery('(min-width:800px)');

  const [documents, setDocuments] = useState([]);
  const [pdocuments, setPdocuments] = useState([]);
  const [userproducteoi, setUserproducteoi] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    
    fetchdocForFilter();

}, []);
  const fetchdocForFilter = async () => {
    const temp = await getuserdocument(uid);
    setDocuments(temp)
    console.log('seee', temp)

    const pdtDocuments = await getAllEOIProductsDocumentForUser(auth?.user?.email);
    setPdocuments(pdtDocuments)
  }

  const classes = UseStyles();
  return (
   <>
    <Navbr/>
     {/* <Container className={classes.container}> */}
     <section style={{ paddingTop:"100px",

marginLeft:isSmall? "300px":"10px",
marginRight:"5%",}}>
       <br />
       <br />
      
       <h1>MY <a style={{color:'#3DAEB6'}}>Documents</a></h1>
       <br />
       <br />
       <h2>My Personal Documents</h2>
       <br />
       <Grid container spacing={3}>
       {documents.map((product) => (<>
         
           <Grid item={true} xs={12} md={3}>
            <Card style={{maxWidth:"300px", minwidth:"100px" }} >
              <CardActionArea
               target="_blank"
              href={product.image}>
                <CardMedia
                  component="img"
                  height="240px"
                  image={product.image}
                  alt="green iguana"
                  />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                   {product.name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            </Grid>
          
   </>))}
   { documents.length === 0 && <>
    <h4 style={{marginLeft:"5%"}}>No documents uploaded yet</h4>
   </>

   }
   </Grid>
   {/* {pdocuments.map((product) => (<>
 <h2>{product.name}</h2>
<Card style={{maxWidth:"300px", minwidth:"100px" ,width:'20vw'}} >
  <CardActionArea
   target="_blank"
  href={product.documents}>
    <CardMedia
      component="img"
      height="240px"
      image={product.documents}
      alt="green iguana"
      />
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
       {product.name}
      </Typography>
    </CardContent>
  </CardActionArea>
</Card>


</>))} */}
{/* <section style={{display:"flex", direction:'row', justifyContent:"space-evenly"}}> */}

<br />
       <h2>Property Documents</h2>
       <br />
          {pdocuments.map((product) => (<>
            
            <h2>{product.name}</h2>
            <Grid container spacing={3}>
            {Object.keys(product.documents).map(dimage => {
              return <Grid item><Card style={{ maxWidth: "300px", minwidth: "100px", width: '20vw' }} >

                <CardActionArea
                  target="_blank"
                  href={product.documents[dimage]}>
                  <CardMedia
                    key={dimage}
                    component="img"
                    height="240px"
                    image={product.documents[dimage]}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {product.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card></Grid>
            })}
         </Grid> </>))}
         { pdocuments.length === 0 && <>
    <h4 style={{marginLeft:"5%"}}>No documents uploaded yet</h4>
   </>

   }
          

      {/* </Container> */}
      </section>
    </>
  )
}
// export default requireAuth(Documents, "user"); from git
export default requireAuth(Documents);