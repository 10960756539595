import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from '@material-ui/core/Paper';
import TextField from "@material-ui/core/TextField";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { CreateUserAdmin, verifyKYCAadharAdmin, verifyKYCPAN, setCPnumber, verifyKYCBank,  genericEmail, generateKYCAadharOTP } from '../../util/functions';
import { uploadFileToBucket } from '../../util/firebase-storage';
import AadharOTPModal from "../modal";
import CloseIcon from '@material-ui/icons/Close';
import { getChannelPartner,getUser,getAddress, getBankAccount, updateCps, getCps,updateUser, addOrUpdateAddress, updateChannelPartner,addOrUpdateBankAccount } from "../../util/db";
import { requireAuth } from "../../util/auth";
import AdminNavbar from "../../components/AdminNavbar";
import { history } from "../../util/router";
import {IconButton , InputAdornment} from "@material-ui/core";
import MenuItem from '@mui/material/MenuItem';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ToastContainer, toast } from 'react-toastify';
import { getAllFiles, getFileUrl } from '../../util/firebase-storage'

function AdminCreateChannelPartner(props) { 
 
  const { userId } = props
  const handleChange = (event) => {
    const str = "" + Noofcp.no_of_cps
    const pad = "0000"
  const ans = pad.substring(0, pad.length - str.length) + str
    setLevels(event.target.value);
    setCpcode( cptype +event.target.value+ ans)


  };
  const handletChange = (event) => {
    const str = "" + Noofcp.no_of_cps
    const pad = "0000"
  const ans = pad.substring(0, pad.length - str.length) + str
    setCptype(event.target.value);
    setCpcode( event.target.value + levels +  ans)
  };
  const cptypel = [
    {
      value: 'VIP',
      label: 'VIP',
    },
    {
      value: 'INTERNAL',
      label: 'INTERNAL',
    },
  ];
  const leveltype = [
    {
      value: '1',
      label: 'Level 1',
    },
    {
      value: '2',
      label: 'Level 2',
    },
    {
      value: '3',
      label: 'Level 3',
    },
  ];
  const resitype = [
    {
      value: 'Indian Resident',
      label: 'Indian Resident',
    },
    {
      value: 'NRI',
      label: 'NRI',
    },
  ];
  const bactype = [
    {
      value: 'Savings Account',
      label: 'Savings Account',
    },
    {
      value: 'Current Account',
      label: 'Current Account',
    },
  ];
  const invstype = [
    {
      value: 'Joint Account',
      label: 'Joint Account',
    },
    {
      value: 'Individual',
      label: 'Individual',
    },
    {
      value: 'HUF',
      label: 'HUF',
    },
    {
      value: 'Partnership',
      label: 'Partnership',
    },
    {
      value: 'Private Limited',
      label: 'Private Limited',
    },

    {
      value: 'LLP',
      label: 'LLP',
    },


  ];
  const [values, setValues] = React.useState({
    password: '',
    cpassword: '',
    showPassword: false,
    showcPassword: false,
  });
  const [txt, setTxt] = useState('');
  const [txtl, setTxtl] = useState('');
  const [resit, setresit] = React.useState('');
  const [valued, setValued] = React.useState(new Date('1990-01-01'));
  const [valuend, setValuend] = React.useState(new Date('1990-01-01'));
  const [cptype, setCptype] = useState('');
  const [types, setTypes] = useState('');
  const [Noofcp,setNoofcp] = useState('');
  const [cpcode, setCpcode] = useState('');
  const [files, setFiles] = useState([]);
  const [userd, setUserd] = useState(null);
 const [cpdata, setCpdata] = useState([]);
 const [addressd, setAddressd] = useState([]);
 const [bankaccountd, setBankaccountd] = useState([]);
  const [levels, setLevels] = useState('');
  const [formAlert, setFormAlert] = useState(null);
  const [pending, setPending] = useState(false);
  const [uid, setUid] = useState(userId);
  const [bankacname, setBankacname] = useState(null);
  const [bankname, setBankname] = useState(null);
  const [bact, setbact] = React.useState('');
  const[pending17,setPending17]=React.useState(false);
  const { handleSubmit, register, errors, getValues } = useForm();
  const [aadharFile, setAadharFile] = useState(null);
  const [panFile, setPanFile] = useState(null);
  const [bankAccountFile, setBankAccountFile] = useState(null);
  const [aadhar, setAadhar] = useState(null);
  const [aadharVerified, setAadharVerified] = useState(false);
  const[kycverified,setKycverified] = useState(false);
  const [pan, setPAN] = useState(null);
  const [panVerified, setPANVerified] = useState(false);

  const [bankAccount, setBankAccount] = useState(null);
  const [bankAccountVerified, setBankAccountVerified] = useState(false);

  const [ifsc, setIFSC] = useState(null);

  const handlePChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handlecChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handlebChange = (event) => {

    setbact(event.target.value);

  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowcPassword = () => {
    setValues({
      ...values,
      showcPassword: !values.showcPassword,
    });
  };

  const handleMouseDowncPassword = (event) => {
    event.preventDefault();
  };
  const onfnInputChange = e => {
    const { value } = e.target;
    console.log('Input value: ', value);
    
   if (value !== null && value !== undefined) {
    const re = /^[A-Za-z]+$/;
    if (value === "" || re.test(value)) {
      setTxt(value);
    }}
  }
  const onlnInputChange = e => {
    const { value } = e.target;
    console.log('Input value: ', value);

    if (value !== null && value !== undefined) {
  
    const re = /^[A-Za-z]+$/;
    if (value === "" || re.test(value)) {
      setTxtl(value);
    }}
  }


  const submitHandlersByType = async ({ email, pass, first_name, last_name, phone, level, parentcp, type, cp_code }) => {
    const formattedPhone = `+91${phone.replace("+91", "")}`
    const user = await CreateUserAdmin(email, pass, first_name, last_name, formattedPhone, "cp", level, parentcp, type, cp_code);
    const add = await setCPnumber("test");
    setFormAlert({
      type: add.data.isSuccess ? "success" : "error",
      message: add.data.message
    });
    if (user?.data?.errorInfo) {
      setFormAlert({
        type: "error",
        message: user?.data?.errorInfo?.message,
      });
      setPending(false);
    } else {

      const data25 = {
        'email': email,
        'password': pass,
        'name': first_name,
        'templateid' : 'd-b733fc3c8bdf4607a8e44bae05505ce8'
    }
    const data26 = {
      'email': email,
      'password': pass,
      'name': first_name,
      'templateid' : 'd-f4d06696a9274431a6c6b8a8c727208c'
  }

      genericEmail(data25);
      genericEmail(data26);
   
      setFormAlert({
        type: "success",
        message: `Channel Partner ${user.data.email} created successfully, verifying online KYC..`,
      });
      history.push('/admin/users');
    }
  };

  // Handle form submission
  const onSubmit = (formData, {level, parentcp, type, cp_code }) => {
    // Show pending indicator
    setPending(true);
    if (uid == null) {
    // Call submit handler for auth type
    submitHandlersByType({
      email: formData.email,
      pass: formData.pass,
      first_name: formData.first_name,
      last_name: formData.last_name,
      phone: formData.phone,
      level: level ?? levels,
      parentcp: formData.parentcp,
      type: type ?? cptype,
      cp_code: cp_code ?? cpcode
    }).catch((error) => {
      setPending(false);
      setFormAlert({
        type: "error",
        message: error.message,
      });
    });} else {
      saveUserInformation(formData);
    }
  };
  const CompleteKYC = async () => {

    try {
      const personalDetails = {
        aadhaar_online_verified: true,
        pan_online_verified: true,
        bank_account_online_verified: true,
        aadhaar_offline_verified: true,
        pan_offline_verified: true,
        bank_account_offline_verified: true,
        kyc_status: "Completed"
      };
      await updateUser(uid, personalDetails);
      toast.success("KYC verified successfully", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }catch (error) {

    }

  };
  const saveUserInformation = async (formData) => {
    try {
      // personal details
      if(userd?.kyc_status !== "incomplete"){
        toast.error("Please fill KYC details", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }else{

      const personalDetails = {
        residentialType: resit,
        fatherName: formData.fathername,
        occupation: formData.occupation,
        date_of_birth: valued
      };
      await updateChannelPartner(uid, personalDetails);

      const address = {
        uid: uid,
        line1: formData.address1,
        line2: formData.address2,
        city: formData.city,
        country: formData.country,
        state: formData.state,
        pin: formData.pin,
        landmark: formData.landmark
      }

      await addOrUpdateAddress(address);


      const nominee = {
        first_name: formData.nfirst_name,
        last_name: formData.nlast_name,
        email: formData.nemail,
        phone: formData.nphone,
        relationship: formData.nrelationship,
        address: formData.naddress,
        date_of_birth: valuend
      }

      await updateChannelPartner(uid, { nominee: nominee });
      const kycdt = {
        kyc_status: "submitted"
      };
      await updateUser(uid, kycdt);
      history.push('/admin/users');
      setFormAlert({
        type: "success",
        message: "Information Saved successfully!!",
      });
      populateUser();
    }
    } catch (error) {
      setFormAlert({
        type: "error",
        message: error,
      });
    }
    setPending(false);
  }


  const populateUser = async () => {
    console.log("trst", userId)
    const noofcps = await getCps('gayn');
    console.log("noofcps", noofcps)
    setNoofcp(noofcps);
    try {
      if (userId !== null && userId !== undefined) {
        const user = await getUser(uid);
        setUserd(user);
        setKycverified(user?.kyc_status === "Completed" ? true : false);
        const address = await getAddress(uid);
        setAddressd(address);
        const bank = await getBankAccount(uid);
        setBankaccountd(bank);
        const cpdata1 = await getChannelPartner(uid);
        setCpdata(cpdata1);
        setAadhar(user?.aadhaar_number);
        setAadharVerified(user?.aadhaar_online_verified);
        setPAN(user?.pan_number);
        setValued(new Date(cpdata1?.date_of_birth.seconds * 1000));
        setValuend(new Date(cpdata1?.nominee?.date_of_birth.seconds * 1000));
        console.log('date', valuend);
        setPANVerified(user?.pan_online_verified);
        setBankAccount(bank?.account_number);
        setBankacname(bank?.account_name);
        setBankname(bank?.bank_name);
        setbact(bank?.account_type)
        setIFSC(bank?.ifsc);
        setBankAccountVerified(user?.bank_account_online_verified);
        setresit(cpdata1?.residentialType);

      }
    } catch (error) {
      console.log("trserr", error)
    }

  }
  
  const fetchKYCFiles = async () => {
    const respFiles = await getAllFiles(uid);
    const allFiles = [];
    for (let index = 0; index < respFiles.items.length; index++) {
        const element = respFiles.items[index];
        allFiles.push({
            bucket: element.bucket,
            name: element.name,
            fullPath: element.fullPath,
            url: await getFileUrl(element.fullPath)
        });
    }


    setFiles(allFiles);
    console.table(allFiles);
}

useEffect(() => {
  populateUser();
  fetchKYCFiles();
}, [])
const aadharcallbackFunction = (fileName) => { 
  console.log("fileUploadCompleteCallback", fileName); 
  fetchKYCFiles();
  toast.success("Aadhaar Uploaded Successfully", {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
  
}
const getFileExtension = (fileName) => String(fileName?.split('.').slice(-1)).toLowerCase();

const onbankSubmit = async (formData) => {
  
  
 try {
   verifyBankAccount1(formData);
  
 }catch (error) {
   toast.error("Bank verification failed", {
     position: "bottom-right",
     autoClose: 3000,
     hideProgressBar: true,
     closeOnClick: true,
     pauseOnHover: true,
     draggable: true,
     progress: undefined,
     });
   setFormAlert({
     type: "error",
     message: error,
     
   });
 }


} 
const verifyBankAccount1 = async (formData) => {
  try {
  const info = await verifyKYCBank(bankAccount, ifsc);
  setBankAccountVerified(info.data.isSuccess);
  setFormAlert({
    type: info.data.isSuccess ? "success" : "error",
    message: info.data.message,
  });
  if(info.data.isSuccess == true){
    const backAccount = {
      uid: uid,
      account_number: formData.bankAccount,
      bank_name: formData.bankname,
      ifsc: formData.ifsc,
      account_name: formData.accountname,
      account_type: bact
    };
    await addOrUpdateBankAccount(backAccount);

    setFormAlert({
      type: "success",
      message: "Information Saved successfully!!",
    
    }); 

    toast.success("Bank Verified Successfully", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
       } else{
        toast.error("Bank Verified failed", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    }catch (error) {      toast.error("Bank verification failed", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });}
      return false;
}
const uploadAadhar = () => {
  if ( aadharFile !== null) {
    uploadFileToBucket(uid,aadharcallbackFunction, aadharFile, `Aadhar`);
  setFormAlert({
    type: "success",
    message: "Aadhar document uploaded!!",
  });} else {  toast.error("Please Upload aadhar", {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
}
}
const pancallbackFunction = (fileName) => { 
  fetchKYCFiles();
  toast.success("PAN Uploaded Successfully", {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
}

const uploadPan = () => {
  if ( aadharFile !== null) {
    uploadFileToBucket(uid, pancallbackFunction, panFile, `PAN`);
  setFormAlert({
    type: "success",
    message: "PAN document uploaded!!",
  });} else {  toast.error("Please Upload PAN", {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
}
}
const bankcallbackFunction = (fileName) => { 
  fetchKYCFiles();
  toast.success("Bank Document Uploaded Successfully", {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
}
const uploadBankAccount = () => {
  if(bankAccountFile !== null){
  uploadFileToBucket(uid, bankcallbackFunction, bankAccountFile, `Bank Document`);
  setFormAlert({
    type: "success",
    message: "Bank Account document uploaded!!",
  });} else{
    toast.error("Please upload the bank document", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });

}
}


  const verifyAadhar = async () => {
    const info = await verifyKYCAadharAdmin(uid, 'cp', aadhar);
    setAadharVerified(info.data.isSuccess);
    setFormAlert({
      type: info.data.isSuccess ? "success" : "error",
      message: info.data.message
    });
  }

  const verifyPAN = async () => {
    const info = await verifyKYCPAN(uid, 'cp', pan, 'B Srinath', "06/15/1995");
    setPANVerified(info.data.isSuccess);
    setFormAlert({
      type: info.data.isSuccess ? "success" : "error",
      message: info.data.message
    });
  }

  const verifyBankAccount = async () => {
    const info = await verifyKYCBank("4557001500052608", "PUNB0455700");
    setBankAccountVerified(info.data.isSuccess);
    setFormAlert({
      type: info.data.isSuccess ? "success" : "error",
      message: info.data.message
    });
  }

  return (
    <>
           <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
<ToastContainer />
      {userId == null && <AdminNavbar
        color="default"
        logo="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-black.png?alt=media&token=4df2ee2a-8696-48b5-8a65-98171d8179d9"
        logoInverted="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096"
      />}
      {formAlert && (
        <Box mb={3}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}
      {
        uid == null && <Grid container spacing={3}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <h1>Create Channel Partner</h1>
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={6}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <TextField
                    variant="outlined"
                    type="first_name"
                    label="First Name"
                    name="first_name"
                    placeholder="First Name"
                  
                    onInput = {(e) =>{
                      e.target.value = e.target.value.replace(/\s{2,}/g, ' ').replace(/[^A-Za-z ]/g, '');
                  }}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter your first name",
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    variant="outlined"
                    type="last_name"
                    label="Last name"
                    name="last_name"
                    placeholder="Last Name"
                 
                                   onInput = {(e) =>{
                  e.target.value = e.target.value.replace(/\s{2,}/g, ' ').replace(/[^A-Za-z ]/g, '');
              }}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter your last name",
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    variant="outlined"
                    type="email"
                    label="Email"
                    name="email"
                    placeholder="user@example.com"
                    error={errors.email ? true : false}
                    helperText={errors.email && errors.email.message}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter your email",
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    variant="outlined"
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    minLength={8}
                    onChange={handlePChange('password')}
                    label="Password"
                    name="pass"
                    error={errors.pass ? true : false}
                    helperText={errors.pass && errors.pass.message}
                    fullWidth={true}
                  
                    inputRef={register({
                      required: "Please enter a password",
                      validate: (value) => {
                        if (value.length >= 8) {
                          return true;
                        } else {
                          return "Minimum 8 character required";
                        }
                      },
                    })}
                    InputProps={{ // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                      )
                      
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Confirm Password"
                    name="confirmPass"
                    type={values.showcPassword ? 'text' : 'password'}
                  value={values.cpassword}
                  onChange={handlecChange('cpassword')}
                    error={errors.confirmPass ? true : false}
                    helperText={errors.confirmPass && errors.confirmPass.message}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter your password again",
                      validate: (value) => {
                        if (value === getValues().pass) {
                          return true;
                        } else {
                          return "This doesn't match your password";
                        }
                      },
                    })}
                    InputProps={{ // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowcPassword}
                      onMouseDown={handleMouseDowncPassword}
                    >
                      {values.showcPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    variant="outlined"
                    type="tel"
                    label="Phone"
                    name="phone"
                    maxLength={10}
                    placeholder="99xxxxxx99"
                    error={errors.phone ? true : false}
                    helperText={errors.phone && errors.phone.message}
                    fullWidth={true}
                    InputProps={{maxLength: 10,
                      startAdornment: <InputAdornment position="start">+91</InputAdornment>
                    }}
                    onInput = {(e) =>{
                      e.target.value =  e.target.value.replace(/[^0-9]/g, '').slice(0,10);
                  }}
                    inputRef={register({
                      required: "Please enter your Phone number",
                      validate: (value) => {
                        if (value.length >= 10) {
                          return true;
                        } else {
                          return "Please enter a valid phone number";
                        }
                      },
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    variant="outlined"
                    select
                    fullWidth={true}
                    label="CP Type"
                    type="text"
                    name="cptype"
                    value={cptype}
                    onChange={handletChange}
                    // inputRef={register({
                    //   required: "Please enter your Level",
                    // })}
                    helperText="Please select CP type"
                  >
                    {cptypel.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    variant="outlined"
                    select
                    fullWidth={true}
                    label="Level"
                    type="text"
                    name="level"
                    value={levels}
                    onChange={handleChange}
                    // inputRef={register({
                    //   required: "Please enter your Level",
                    // })}
                    helperText="Please select CP level"
                  >
                    {leveltype.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    variant="outlined"
                    type="email"
                    label="CP Code"
                    name="cpcode"
                    value={cpcode}
                    fullWidth={true}
                    disabled
                  />
                </Grid>
                {(levels == '2' || levels == "3") &&
                  <Grid item={true} xs={12}>
                    <TextField
                      variant="outlined"
                      type="email"
                      label="Parent CP Email "
                      name="parentcp"
                      placeholder="Parent CP Email"
                      error={errors.parentcp ? true : false}
                      helperText={errors.parentcp && errors.parentcp.message}
                      fullWidth={true}
                      inputRef={register({
                        validate: (value) => {
                          if (value === "" && getValues().level !== "") {
                            return "Please enter Parent CP Email ";
                          } else {
                            return true;
                          }
                        },
                      })}
                    />
                  </Grid>
                }

                <Grid item={true} xs={12}>
                  <Button
                    id="sign-in-button"
                    className="sign-in-button"
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={pending}
                    fullWidth={true}
                  >
                    {!pending && <span>Submit</span>}

                    {pending && <CircularProgress size={28} />}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={3} />
        </Grid>}
      {
        uid != null &&
        <>
        <Button variant="" href={"/admin/users"} style={{color:"red", display:"flex", marginLeft:"0%", justifyContent:"flex-start"}} startIcon={<CloseIcon fontSize="33" />}>Close
      </Button>
        <form onSubmit={handleSubmit(onSubmit)}>
            <h1>Personal Details</h1>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={6}>
              <TextField
                  variant="outlined"
                  select
                  label="Residential Type"
                  type="residentialtype"
                  disabled={kycverified}
                  name="residentialtype"
                  value={resit}
                  fullWidth={true}
                  onChange={(e) => setresit(e.target.value)}
                >
                  {resitype.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="fathername"
                  label="Father's Name"
                  name="fathername"
                  value={cpdata?.fatherName}
                  disabled={kycverified}
                  placeholder="Father's Name"
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your Father's Name",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="occupation"
                  label="Occupation"
                  name="occupation"
                  value={cpdata?.occupation}
                  disabled={kycverified}
                  placeholder="Occupation"
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your Occupation",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableFuture
                    label="Date Of Birth"
                    name="date_of_birth"
                    openTo="year"
                    disabled={kycverified}
                    views={['year', 'month', 'day']}
                    value={valued}
                    sx={{ width: '100%' }}
                    onChange={(newValue) => {
                      console.log('date_of_birth', newValue);
                      setValued(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item={true} xs={12}><h2>Address</h2></Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Address Line 1"
                  name="address1"
                  value={addressd?.line1}
                  disabled={kycverified}
                  placeholder="Address Line 1"
                  error={errors.address1 ? true : false}
                  helperText={errors.address1 && errors.address1.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your Address Line 1",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Address Line 2"
                  name="address2"
                  value={addressd?.line2}
                  disabled={kycverified}
                  placeholder="Address Line 2"
                  error={errors.address2 ? true : false}
                  helperText={errors.address2 && errors.address2.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your Address Line 2",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="city"
                  label="City"
                  name="city"
                  value={addressd?.city}
                  disabled={kycverified}
                  placeholder="City"
                  error={errors.email ? true : false}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your City",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="pin"
                  label="PIN"
                  name="pin"
                  value={addressd?.pin}
                  disabled={kycverified}
                  maxLength={6}
                  placeholder="PIN"
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  onInput = {(e) =>{
                    e.target.value =  e.target.value.replace(/[^0-9]/g, '').slice(0,6);
                }}
                  inputRef={register({
                    required: "Please enter your PIN Code",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="country"
                  label="Country"
                  name="country"
                  value={addressd?.country}
                  disabled={kycverified}
                  placeholder="Last Name"
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your last name",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="state"
                  label="State"
                  name="state"
                  value={addressd?.state}
                  disabled={kycverified}
                  placeholder="State"
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your State",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="landmark"
                  value={addressd?.landmark}
                  disabled={kycverified}
                  label="Landmark"
                  name="landmark"
                  placeholder="Landmark"
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your Landmark",
                  })}
                />
              </Grid>

              <Grid item={true} xs={12}><h2>Nominee</h2></Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="nfirst_name"
                  label="First Name"
                  value={cpdata?.nominee?.first_name}
                  disabled={kycverified}
                  name="nfirst_name"
                  placeholder="First Name"
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your first name",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="nlast_name"
                  label="Last name"
                  name="nlast_name"
                  value={cpdata?.nominee?.last_name}
                  disabled={kycverified}
                  placeholder="Last Name"
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your last name",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="nemail"
                  label="Email"
                  value={cpdata?.nominee?.email}
                  disabled={kycverified}
                  name="nemail"
                  placeholder="user@example.com"
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your email",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="nphone"
                  label="Phone"
                  value={cpdata?.nominee?.phone}
                  disabled={kycverified}
                  name="nphone"
                  placeholder="99xxxxxx99"
                  error={errors.phone ? true : false}
                  helperText={errors.phone && errors.phone.message}
                  fullWidth={true}
                  InputProps={{maxLength: 10,
                    startAdornment: <InputAdornment position="start">+91</InputAdornment>
                  }}
                  onInput = {(e) =>{
                    e.target.value =  e.target.value.replace(/[^0-9]/g, '').slice(0,10);
                }}
                  inputRef={register({
                    required: "Please enter your Phone number",
                    validate: (value) => {
                      if (value.length >= 10) {
                        return true;
                      } else {
                        return "Please enter a valid phone number";
                      }
                    },
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Relationship"
                  value={cpdata?.nominee?.relationship}
                  disabled={kycverified}
                  name="nrelationship"
                  placeholder="Relationship"
                  error={errors.email ? true : false}
                  helperText={errors.nrelationship && errors.nrelationship.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your Relationship",
                  })}
                />
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disableFuture
                  label="Date Of Birth"
                  name="ndate_of_birth"
                  openTo="year"
                  views={['year', 'month', 'day']}
                  value={valuend}

                  sx={{ width: '100%' }}
                  onChange={(newValue) => {

                    setValuend(newValue);
                  }}
                  inputRef={register({
                    required: "Please enter your Data of Birth",
                  })}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <Grid item={true} xs={12}>
                <TextField
                  variant="outlined"
                  type="naddress"
                  label="Address"
                  name="naddress"
                  value={cpdata?.nominee?.address}
                  disabled={kycverified}
                  placeholder="Address"
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter nominee address",
                  })}
                />
              </Grid>

                </Grid>
                <Grid item xs={12}></Grid>
                <Grid container={true} spacing={2}>
            <Grid item={true} xs={6}>
              <Button
                id="save-button"
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={pending || userd?.kyc_status === "Completed"}
                fullWidth={true}
              >
                {!pending && <span>Save</span>}
                {pending && <CircularProgress size={28} />}
              </Button>
            </Grid>
            <Grid item={true} xs={6}>
              <Button
                id="save-button"
                variant="contained"
                color="primary"
                size="large"
                onClick={() => CompleteKYC()}
                disabled={userd?.kyc_status === "Completed"}
                fullWidth={true}
              >
                {!pending && <span>Complete KYC</span>}
                {pending && <CircularProgress size={28} />}
              </Button>
            </Grid>
          </Grid>  
         </form>
         <Box style={{ paddingTop: '45px' }} >
          <h1>Proof verification section</h1>
    
          <form onSubmit={handleSubmit(onbankSubmit)}> 
          <Grid container={true} spacing={2} >
          <Grid item={true} xs={12}>
                <br />
                <br />
                <h1>Verify bank account</h1>
                <br />
                <br /></Grid>
                <Grid item={true} xs={12} md={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  onInput = {(e) =>{
                    e.target.value =     e.target.value.replace(/[^A-Za-z0-9]/g, '');
                }}
                  placeholder="Bank Account Number"
                  label="Bank Account Number"
                  name="bankAccount"
                  required={true}
                  InputLabelProps={{ shrink: true }}
                  value={bankAccount}
                  fullWidth={true}
                  onChange={(e) => setBankAccount(e.target.value)}
                  onBlur={(e) => setBankAccount(e.target.value)}
                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||bankAccountVerified}
                  inputRef={register({
                    required: "Please enter your Bank Account number",
                  })}
                />
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  placeholder="Account Name"
                  label="Account Name"
                  onInput = {(e) =>{
                    e.target.value =  e.target.value.replace(/[^A-Za-z ]/g, '')
                }}
                  InputLabelProps={{ shrink: true }}
                  required={true}
                  name="accountname"
                  value={bankacname}
                  fullWidth={true}
                  onChange={(e) => setBankacname(e.target.value)}
                  onBlur={(e) => setBankacname(e.target.value)}
                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||bankAccountVerified}
                  inputRef={register({
                    required: "Please enter your Bank Account Name",
                  })}
                />
              </Grid>



              <Grid item={true} xs={12} md={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  placeholder="IFSC"
                  onInput = {(e) =>{
                    e.target.value =     e.target.value.replace(/[^A-Za-z0-9]/g, '');
                }}
                  required={true}
                  InputLabelProps={{ shrink: true }}
                  label="IFSC"
                  name="ifsc"
                  value={ifsc}
                  fullWidth={true}
                  onChange={(e) => setIFSC(e.target.value)}
                  onBlur={(e) => setIFSC(e.target.value)}
                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||bankAccountVerified}
                  inputRef={register({
                    required: "Please enter your IFSC",
                  })}
                />
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  placeholder="Bank Name"
                  onInput = {(e) =>{
                    e.target.value =  e.target.value.replace(/[^A-Za-z ]/g, '')
                }}
                  label="Bank Name"
                  InputLabelProps={{ shrink: true }}
                  name="bankname"
                  value={bankname}
                  fullWidth={true}
                  onChange={(e) => setBankname(e.target.value)}
                  onBlur={(e) => setBankname(e.target.value)}
                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||bankAccountVerified}
                  inputRef={register({
                    required: "Please enter your Bank Account number",
                  })}
                />
              </Grid>

              <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    select
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" || bankAccountVerified}
                    required={true}
                    label="Account Type"
                    type="accounttype"
                    name="accounttype"
                    value={bact}
                    fullWidth={true}
                    onChange={handlebChange}
                  >
                    {bactype.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item={true} xs={6}>
                  <Button
                    id="save-button"
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={ userd?.kyc_status === "Completed" || pending17}
                    fullWidth={true}
                  >
                    {!pending17 && <span>Verify</span>}
                    {pending17 && <CircularProgress size={28} />}
                  </Button>
                  <br />
                  <br />
                  <br />
                </Grid>
                </Grid>
                </form>
               
              <Grid container spacing={3} style={{alignItems: 'center'}}>
                
                <Grid item={true} xs={3}>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Aadhar Number"
                    name="aadhar"
                    value={aadhar}
                    fullWidth={true}
                    onChange={(e) => setAadhar(e.target.value)}
                    onBlur={(e) => setAadhar(e.target.value)}
                    disabled={aadharVerified}
                  />
                </Grid>
                <Grid item={true} xs={3}  >
                  <Button
                 
                    variant="contained"
                    color="primary"
                    size="large"
                    type="button"
                    fullWidth={true}
                    onClick={verifyAadhar}
                    disabled={aadharVerified}
                  >
                    {pending && <CircularProgress size={28} />}
                    {aadharVerified ? 'Aadhar Verified' : 'Verify Aadhar'}
                  </Button>
                </Grid>

                <Grid item={true} xs={3}>
                  <TextField
                    variant="outlined"
                    type="text"
                    // label="Pan Number"
                    placeholder="PAN number"
                    name="pan"
                    value={pan}
                    fullWidth={true}
                    disabled={panVerified}
                    onChange={(e) => setPAN(e.target.value)}
                    onBlur={(e) => setPAN(e.target.value)}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="button"
                    fullWidth={true}
                    onClick={verifyPAN}
                    disabled={panVerified}
                  >
                    {pending && <CircularProgress size={28} />}
                    {panVerified ? 'Pan Verified' : 'Verify Pan'}
                  </Button>

                </Grid>

    

                <Grid item xs={2}><h2>Aadhaar</h2> </Grid>
                <Grid item xs={2}> 
                <Button
                                  variant="outlined"
                                  component="label"
                                  disabled={userd?.kyc_status === "Completed"}
                                  color="primary"
                                  size="large"
                                  style={{overflow:'hidden', marginLeft:'10%'}}
                                >
                                    <input
                                      type="file"
                                      // hidden
                                      accept=".pdf, .jpg, .jpeg, .png"
                                      style={{display:"none"}}
                                      onChange={(e) => { setAadharFile(e.target.files[0]) }} />
                                      <text style={{overflow:'hidden', width: '130px', height:'25px', display:'flex', alignItems:'center', justifyContent:'center' }}>{aadharFile?  aadharFile.name : "Choose File"}</text>
                                  </Button>
   
                                  </Grid>
                                  <Grid item xs={2}>                              
                                     <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="button"
                    disabled={userd?.kyc_status === "Completed"}
                    fullWidth={true}
                    onClick={uploadAadhar}
             
                  >
                  
                    {'Upload Aadhaar'}
                  </Button></Grid>
                <Grid item xs={2}><h2>PAN</h2> </Grid>
                <Grid item xs={2}> 
                <Button
                                  variant="outlined"
                                  component="label"
                                  color="primary"
                                  size="large"
                                  disabled={userd?.kyc_status === "Completed"}
                                  style={{overflow:'hidden', marginLeft:'10%'}}
                                  // disabled={panfVerified || pending10}

                                >
                                    <input
                                      type="file"
                                      // hidden
                                      style={{display:"none"}}
                                      onChange={(e) => { setPanFile(e.target.files[0]) }} />
                                      <text style={{overflow:'hidden', width: '130px', height:'25px', display:'flex', alignItems:'center', justifyContent:'center' }}>{panFile?  panFile.name : "Choose File"}</text>
                                  </Button>
                                  </Grid>
                                  <Grid item xs={2}>                              
                                     <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={userd?.kyc_status === "Completed"}
                    type="button"
                    fullWidth={true}
                    onClick={uploadPan}
             
                  >
                  
                    {'Upload PAN'}
                  </Button></Grid>

                  <Grid item xs={2}><h2>Cheque Leaf</h2> </Grid>
                  <Grid item xs={2}>
                  <Button
                                  variant="outlined"
                                  component="label"
                                  color="primary"
                                  disabled={userd?.kyc_status === "Completed"}
                                  size="large"
                                  style={{overflow:'hidden', marginLeft:'10%'}}
                                  // disabled={panfVerified || pending10}

                                >
                <input type="file" style={{display:"none"}} onChange={(e) => { setBankAccountFile(e.target.files[0]) }} />
                <text style={{overflow:'hidden', width: '130px', height:'25px', display:'flex', alignItems:'center', justifyContent:'center' }}>{bankAccountFile?  bankAccountFile.name : "Choose File"}</text>
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button variant="contained"
                  color="primary"
                  disabled={userd?.kyc_status === "Completed"}
                  size="large"
                  type="button"
                  onClick={uploadBankAccount}>Upload Cheque Leaf</Button>
              </Grid>
                                  </Grid>
                                  </Box>

                                  <Grid item xs={12} >
                        {files.length > 0 && <Paper sx={{ width: '100%' }}>
                            <h1>User Documents</h1>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableBody>
                                        {
                                            files.map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.name}>
                                                        <TableCell >
                                                            {row.name}{row.time_stamp?.toDateString()}
                                                        </TableCell>
                                                        <TableCell >
                                                        </TableCell>
                                                        <TableCell >
                                                            <a href={row.url} target={'_blank'}>View</a>
                                                        </TableCell>
                                                        {/* <TableCell >
                                                            <div style={{ textAlign: 'right', paddingTop: '15px', paddingRight: '30px' }}>
                                                                {
                                                                    showOfflineApproveButton(row.name?.split('.')[0]) ? <Button variant="contained"
                                                                        style={{ width: '200px' }}
                                                                        type="button"
                                                                        color="primary"
                                                                        size="large" onClick={() => updateOfflineKYC(row.name?.split('.')[0])} >Approve {row.name?.split('.')[0]}</Button> :
                                                                        "Approved"
                                                                }
                                                                                                                                {
                                                                    showOfflineApproveButton(row.name?.split('.')[0]) ? <Button variant="contained"
                                                                        style={{ width: '200px' }}
                                                                        type="button"
                                                                        color="primary"
                                                                        size="large" onClick={() => updateOfflineKYC1(row.name?.split('.')[0])} >Reject {row.name?.split('.')[0]}</Button> :
                                                                        "Rejected"
                                                                }
                                                            </div>
                                                        </TableCell> */}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Paper>}
                    </Grid>

        </>
      }
    </>
  );
}

export default requireAuth(AdminCreateChannelPartner,  ["admin"]);
// export default AdminCreateChannelPartner