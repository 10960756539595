import React from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { Link } from "./../util/router";

function Section3(props) {
  return (
    <Container    >
 <div  style={{
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      alignContent: "center",
      marginLeft: "-5px",
      overflowX: 'scroll',
      position:'sticky'
    }}>
        <Button style={{
      marginLeft: "15px",
      marginRight: "15px",
    }} href="#Overview" color="inherit">
          Overview
        </Button>
        <Button style={{
      marginLeft: "15px",
      marginRight: "15px",
    }} href="#Highlights" color="inherit">
          Highlights
        </Button>
        <Button  style={{
      marginLeft: "15px",
      marginRight: "15px",
    }} href="#Location" color="inherit">
          Location
        </Button>
        <Button  style={{
      marginLeft: "15px",
      marginRight: "15px",
    }} href="#Pricing" color="inherit">
          Pricing
        </Button>
        <Button style={{
      marginLeft: "15px",
      marginRight: "15px",
    }} href="#Tenants" color="inherit">
          Tenants
        </Button>
        <Button  style={{
      marginLeft: "15px",
      marginRight: "15px",
    }} href="#Gallery" color="inherit">
          Gallery
        </Button>
        <Button style={{
      marginLeft: "15px",
      marginRight: "15px",
    }} href="#Floor_Plan" color="inherit">
          Floor Plan
        </Button>
        </div>
      <Box textAlign="center" />
    </Container>
  );
}

export default Section3;
