import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Roadtos1 from "./Roadtos1";
import SectionHeader from "./SectionHeader";

function ClientsSection(props) {
  const items = [
    {
      name: "Instagram",
      image:
        "https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/Rectangle%2012.png?alt=media&token=a8c8aa63-1525-4996-b054-9b887e2222af",
      width: "150px",
    },
    {
      name: "Slack",
      image:
        "https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/Rectangle%2012.png?alt=media&token=a8c8aa63-1525-4996-b054-9b887e2222af",
      width: "150px",
    },
    {
      name: "Tinder",
      image:
        "https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/Rectangle%2012.png?alt=media&token=a8c8aa63-1525-4996-b054-9b887e2222af",
      width: "150px",
    },
    {
      name: "Spotify",
      image:
        "https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/Rectangle%2012.png?alt=media&token=a8c8aa63-1525-4996-b054-9b887e2222af",
      width: "150px",
    },
  ];

  return (
    <Roadtos1
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Box textAlign="left">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={4}
            textAlign="left"
          />
          <Grid container={true} justifyContent="left" spacing={8}>
            {items.map((item, index) => (
              <Grid item={true} xs={12} md="auto" key={index}>
                <Box py={2} px={3}>
                  <img src={item.image} width={item.width} alt={item.name} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Roadtos1>
  );
}

export default ClientsSection;
