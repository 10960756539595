import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Roadtos1 from "./Roadtos1";
import SectionHeader4As1 from "./SectionHeader4As1";
import Aos from 'aos';
import { useRouter } from "../util/router";
import "aos/dist/aos.css";
import useMediaQuery from '@material-ui/core/useMediaQuery';
const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  image: {
    margin: "0 auto",
    maxWidth: 570,
    display: "block",
    height: "auto",
    width: "100%",
  },
}));

function Section4A(props) {
  const isSmall = useMediaQuery('(min-width:800px)');
  const classes = useStyles();
  React.useEffect(() => {
    Aos.init({ duration: 2000 });
}, [])
  return (
    <Roadtos1
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <Grid container={true} alignItems="left" spacing={6} style={{marginBottom:'25px'}}>
          <Grid item={true} xs={12} md={true}>
            <figure>
              <img
                src={props.image}
                alt="illustration"
                className={classes.image}
                style={{ marginLeft: isSmall ? "-34px" : "0px" }}
              />
            </figure>
          </Grid>
          <Grid container={true} item={true} direction="column" xs={12} md={6}>
            <Box textAlign={{ xs: "left", md: "left" }}>
              <SectionHeader4As1
                title={props.title}
                subtitle={props.subtitle}
                size={5}
              />
            </Box>
          </Grid>
        </Grid>
        <br />
        <a style={{ fontSize: "18px", fontWeight: "600"}}>
          <a style={{ fontSize: "18px", fontWeight: "700" }}>
            G<a style={{ color: "#3DAEB6" }}>A</a>YN Capital's
          </a>{" "}
          rent-generating Commercial Properties go through
        </a>
        <Grid container={true} alignItems="center" spacing={0} style={{marginBottom:"-70px"}}>
          <Grid
            item={true}
            xs={12}
            md={true}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "5%",
            }}
          >    
            <img data-aos="fade-up" data-aos-duration="600"
              src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/01.png?alt=media&token=1f3ab1b5-c757-406e-b1bc-987778327d92"
              style={{ width: "35%" }}
            />
            
            <img data-aos="fade-up" data-aos-duration="1000"
              src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/02.png?alt=media&token=29b65051-4a23-4b4f-8911-03d6b120fdc7"
              style={{ width: "35%" }}
            />
          </Grid>
          <Grid
            item={true}
            xs={12}
            md={true}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "5%",
            }}
          >           

            <img data-aos="fade-up" data-aos-duration="1400"
              src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/03.png?alt=media&token=5a29a6f6-17f7-4d75-b958-3c0b3d882fcf"
              style={{ width: "35%" }}
            /> 


            <img data-aos="fade-up" data-aos-duration="1800"
              src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/04.png?alt=media&token=458d1636-01eb-47f7-8b46-b6442cee04e8"
              style={{ width: "35%" }}
            /> 
          </Grid>
        </Grid>
        {isSmall ? <br />:  
        <>
        <br />
         <br />
         <br />
         <br /> </>}
      </Container>
    </Roadtos1>
  );
}

export default Section4A;
