import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import VideogameAssetIcon from "@material-ui/icons/VideogameAsset";
import PetsIcon from "@material-ui/icons/Pets";
import { makeStyles } from "@material-ui/core/styles";
import Page1S31 from "./Page1S31";
import Page2S32 from "./Page2S32";
import Box from "@material-ui/core/Box";
import { Doughnut } from 'react-chartjs-2';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Chart as ChartJS,
  CategoryScale,
  ArcElement,
  DoughnutController,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';


ChartJS.register(
  CategoryScale,
  ArcElement,
  DoughnutController,
  Title,
  Tooltip,
  Legend
);


const options = {
  plugins: { 
  legend: {
    display: true,
    position: 'bottom',
    labels: {
      color: "white",  // not 'fontColor:' anymore
      // fontSize: 18  // not 'fontSize:' anymore
      font: {
        size: 12, // 'size' now within object 'font {}'
        weight: 'bold',
        family: 'Montserrat'
      }
    }
  }},
  responsive: true,
};

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
  },
  imageWrapper: {
    margin: "0 auto",
    maxWidth: 350,
    paddingTop:'4%',
    width: "100%",
    "& > img": {
      width: "100%",
    },
  },
  row: {
    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(2)}px`,
    },
  },
}));

function Page2S3(props) {
  const classes = useStyles();
  const isSmall = useMediaQuery('(min-width:800px)');
  const data = {
    // labels: ['Base Purchase Price', 'Acquisition Fee', 'Registration & Taxes', 'Legal Fee', 'Reserve'],
    labels: ['Base Purchase Price', 'Acquisition Fee'],
    datasets: [
      {
        data: [Number(props.pridata.base_price), Number(props.pridata.acquision_fee)],

        backgroundColor: ['#3DAEB6', '#ffce56', '#cc65fe', '#ffce56', '#ff6384'],
        hoverBackgroundColor: ['#3DAEB6', '#ffce56', '#cc65fe', '#ffce56', '#ff6384'],
        cutout: '60%',
        borderColor: 'transparent',
        color: '#ffffff',
      },
    ],
  };
  const items = [
    {
      title: "Play",
      description:
        "We embedded a bunch free flash games inside our app. What we lack in quality we make up in quantity.",
      icon: VideogameAssetIcon,
      iconColor: "secondary.main",
    },
    {
      title: "Cats",
      description:
        "Our market research told us people like cats so we put some cat icons on our website.",
      icon: PetsIcon,
      iconColor: "#00d1b2",
    },
  ];

  return (
    <Page1S31
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container
        className={classes.container}
        style={{ backgroundColor: "#262632", marginTop: "-70px" }}
      >
        <h2
          style={{
            color: "#3DAEB6",
            paddingTop: "30px",
            marginBottom: "-40px",
            fontSize: "20px",
          }}
        >
          Pricing
        </h2>
        <Grid container={true} alignItems="center" spacing={8}>
          <Grid container={true} item={true} direction="column" xs={12} md={6}>
          
            <figure className={classes.imageWrapper}>
            <Doughnut data={data} options={options} />
              {/* <img src={props.image} alt="" /> */}
            </figure>
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <Page2S32
              title={props.title}
              subtitle={props.subtitle}
              subtitle1={props.subtitle1}
              size={5}
            />
          </Grid>

        </Grid>
        <Box textAlign="center" >
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth={isSmall? false : true}
            href="/enquiry/dWjFy1y1XVJ3Ue8EYFBx"
            style={{
              marginTop: "10px",
              borderRadius: "0px",
              marginBottom: "50px",
            }}
          >
            Invest Now
          </Button>
          </Box>
      </Container>
    </Page1S31>
  );
}

export default Page2S3;
