import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import VideogameAssetIcon from "@material-ui/icons/VideogameAsset";
import PetsIcon from "@material-ui/icons/Pets";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
  },
  imageWrapper: {
    margin: "0 auto",
    maxWidth: 570,
    width: "100%",
    "& > img": {
      width: "100%",
    },
  },
  row: {
    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(2)}px`,
    },
  },
}));

function Page1S3Fs(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Play",
      description:
        "We embedded a bunch free flash games inside our app. What we lack in quality we make up in quantity.",
      icon: VideogameAssetIcon,
      iconColor: "secondary.main",
    },
    {
      title: "Cats",
      description:
        "Our market research told us people like cats so we put some cat icons on our website.",
      icon: PetsIcon,
      iconColor: "#00d1b2",
    },
  ];

  return (
    <Container className={classes.container}>
      <Grid
        container={true}
        alignItems="center"
        spacing={8}
        style={{ marginTop: "0px" }}
      >
        <Grid item={true} xs={12} md={6}>
          <Box   style={
    {
     border: '2px solid #3DAEB6'
    }
  }>
            <p style={{ fontSize: "13px", fontWeight: "500", padding:"10px" }}>
              65 combined human years of experience across multifarious
              businesses, both globally and locally, with a key focus on
              investment and real estate.
            </p>
          </Box>
        </Grid>
        <Grid container={true} item={true} direction="column" xs={12} md={6}>
        <Box   style={
    {
     border: '2px solid #000000'
    }
  }>
          <p style={{ fontSize: "13px", fontWeight: "500", padding:"10px" }}>
            5000 crores of real investment exposure, across offices,
            residentials, industrials, retail, hospitality, and student housing.{" "}
          </p>
          </Box>
        </Grid>

      </Grid>
    </Container>
  );
}

export default Page1S3Fs;
