
import React, { useState, useRef, useEffect, useContext } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import '../pages/App.css';
import 'font-awesome/css/font-awesome.min.css';
import Aos from 'aos';
import "aos/dist/aos.css";
function Allyouhavecard() {
    useEffect(() => {
      Aos.init({ duration: 2000 });
  }, [])
  return (    <>  <Container><section className="grid_scetion light_dark" data-aos="fade-up" data-aos-duration="1200" style={{fontSize: "15px"}}> <h3 data-aos="fade-up" data-aos-duration="1200" style={{fontSize: "20px", fontWeight: "600"}}>All YOU have to do:</h3>
  <div className="card" data-aos="fade-up" data-aos-duration="1200">
      <div className="card-body margin-space">
          <h4>EXPLORE</h4>
          <p>Our professionally curated
              commercial properties with a
              detailed market analysis.</p>
      </div>
  </div>
  <div className="card" data-aos="fade-up" data-aos-duration="1200">
      <div className="card-body margin-space">
          <h4>ENQUIRE</h4>
          <p>Schedule a call with our Relationship
              Manager OR Write to us and we will
              respond to you promptly.</p>
      </div>
  </div>
  <div className="card before-sec" data-aos="fade-up" data-aos-duration="1200">
      <div className="card-body margin-space">
          <h4>EXPRESS</h4>
          <p>Subscribe to our Primary Offering and confirm your interest with a token deposit.</p>
      </div>
  </div> </section></Container>   </>
  )
  }
  
  export default Allyouhavecard;