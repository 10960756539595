import { Container } from '@material-ui/core';
import Navbr from '../../components/usernav';
import { requireAuth,  useAuth } from "../../util/auth";
import { makeStyles } from "@material-ui/core/styles";
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import React, { useState, useEffect } from 'react'
import AdminNavbar from './../../components/AdminNavbar';
import {
    getEOIForTransactionByProduct,
    getTransactionByUserAndProduct,
    getAllProducts,
    updateTransactionByProduct,
    deleteTransaction,
    markProductActive,
    getUser,
    getUserTranasactions
} from '../../util/db';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from "@material-ui/core/Button";
import Grid from '@mui/material/Grid';
import { Checkbox } from '@material-ui/core';
import { Alert } from '@mui/material';
import MaterialTable from 'material-table'


const UseStyles = makeStyles((theme) => ({

  container: {
    paddingTop:"100px",
    // paddingLeft:"280px",
    // paddingRight:"0px",
    marginLeft:"18%",
    marginRight:"0px",
    // width:"100%"
  },
}));

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;


    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    td {
      background-color: #DCDCDC;
      color: black;
    }
    th {
      background-color: #262632;
      color: white;
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`

function Transactions() {
  const auth = useAuth();
  const userId = auth?.user?.id; 
  const [uid, setUid] = useState(userId);
  const [userd, setUserd] = React.useState([]);
  const [value, setValue] = React.useState('1');
  const [transactions, setTransactions] = React.useState([]);
  const isSmall = useMediaQuery('(min-width:800px)');
  const columns = [
    { title: "Type", field: "type", render: rowData => rowData.type === "debit"?<img src="https://firebasestorage.googleapis.com/v0/b/evareap-app.appspot.com/o/ic_buy.png?alt=media&token=0c2f3222-196e-493c-beb2-f5d326694676" style={{width: 30}}/> :<img src="https://firebasestorage.googleapis.com/v0/b/evareap-app.appspot.com/o/ic_sell.png?alt=media&token=d6807872-04c3-4d0c-bc01-e51ef4045b4c" style={{width: 30}}/>},
    { title: "Transaction Id", field: "reference_number"},
    { title: "Date", field: "dates", type: "date"},
    { title: "Amount", field: 'amount', render: rowData => rowData.type === "credit"?<span style={{color:"red"}}>-{rowData.amount}</span>:<span style={{color:"green"}}>+{rowData.amount}</span>},
    
    { title: "Payment Type", field: "payment_type"},
    { title: "Status", field: "status"}
  ]

  const populateUser = async () => {
    if (userId !== null && userId !== undefined) {
      const user = await getUser(uid);
      const trans = await getUserTranasactions(uid);
      setUserd (user);
      console.log('sdsdsds',trans);
      setTransactions(trans);
    }
  }
  useEffect(() => {
    populateUser();
  }, [])

  const classes = UseStyles();
  return (
   <>
    <Navbr/>
     {/* <Container className={classes.container}> */}
     <section style={{ paddingTop:"100px",

marginLeft:isSmall? "300px":"10px",
marginRight:"5%",}}>
    <h1>My Transactions</h1>

    <MaterialTable
        title="Transactions"
        data={transactions}
        columns={columns}
        options={{
          exportButton: true,
          search: true,
          actionsColumnIndex: -1, addRowPosition: "first"
        }}
      />

                </section>
      {/* </Container> */}
    </>
  )
}
export default requireAuth(Transactions, ["user"]);