import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import VideogameAssetIcon from "@material-ui/icons/VideogameAsset";
import PetsIcon from "@material-ui/icons/Pets";
import { makeStyles } from "@material-ui/core/styles";
import Page1News41 from "./Page1News41";
import Page1News321 from "./Page1News321";
import Page1News322 from "./Page1News322";
import Page1News323 from "./Page1News323";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
  },
  imageWrapper: {
    margin: "0 auto",
    maxWidth: 570,
    width: "100%",
    "& > img": {
      width: "100%",
    },
  },
  row: {
    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(2)}px`,
    },
  },
}));

function Page1News4(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Play",
      description:
        "We embedded a bunch free flash games inside our app. What we lack in quality we make up in quantity.",
      icon: VideogameAssetIcon,
      iconColor: "secondary.main",
    },
    {
      title: "Cats",
      description:
        "Our market research told us people like cats so we put some cat icons on our website.",
      icon: PetsIcon,
      iconColor: "#00d1b2",
    },
  ];

  return (
    <Page1News41
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <p style={{ color: "#3DAEB6", fontWeight: "700", fontSize: "18px" }}>
          CRE & FRACTIONAL INVESTING
        </p>
        <p style={{ color: "#3DAEB6", fontWeight: "500", fontSize: "18px" }}>
          Fractional Ownership of <a style={{ fontWeight: "700" }}>CRE</a>{" "}
          Assets Makes Sense for <a style={{ fontWeight: "700" }}>YOU</a>
        </p>
        <Grid container={true} alignItems="top" spacing={2}>
          <Grid item={true} xs={12} md={4}>
            <Page1News321
              title={props.title}
              subtitle={props.subtitle}
              subtitle1={props.subtitle1}
              size={5}
            />
          </Grid>
          <Grid item={true} xs={12} md={4}>
            <Page1News322
              title={props.title}
              subtitle={props.subtitle}
              subtitle1={props.subtitle1}
              size={5}
            />
          </Grid>
          <Grid item={true} xs={12} md={4}>
            <Page1News323
              title={props.title}
              subtitle={props.subtitle}
              subtitle1={props.subtitle1}
              size={5}
            />
          </Grid>
        </Grid>
      </Container>
    </Page1News41>
  );
}

export default Page1News4;
