import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    maxWidth: 700,
    // So we can have max-width but still
    // have alignment controlled by text-align.
    //display: "inline-block",
  },
}));

function SectionHeaderbk2S2(props) {
  const classes = useStyles();

  const { subtitle, title, size, className, ...otherProps } = props;

  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <Container style={{ marginTop: "-70px" }}>
    <Box
      component="header"
      className={classes.root + (props.className ? ` ${props.className}` : "")}
      {...otherProps}
    >
      <section>
        {title && (
          <Typography
            variant={`h${size}`}
            gutterBottom={props.subtitle ? true : false}
            style={{ marginBottom: "40px" }}
          >
            {title}
          </Typography>
        )}

        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/image13.png?alt=media&token=30156d37-7db9-4900-bad2-24d0d231f280"
          style={{ width: "500px", marginLeft: "25%" }}
        />
      </section>
    </Box>
    </Container>
  );
}

export default SectionHeaderbk2S2;
