import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    maxWidth: 700,
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: "inline-block",
  },
}));

function SectionHeader(props) {
  const classes = useStyles();

  const { subtitle, subtitle1, title, size, className, ...otherProps } = props;

  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <>
      <section
        style={{
          marginLeft: "89%",
          marginBottom: "-40px",
          height: "40px",
          width: "130px",
          backgroundColor: "#3DAEB6",
          color: "#ffffff",
          textAlign: "center",
        }}
      >
        <h4 style={{ paddingTop: "10px" }}>{props.type}</h4>
        {/* <h4 style={{ paddingTop: "10px" }}> By <br /> <a href="/seller" style={{color: '#3DAEB6'}}>BHOOMI BUILDERS</a></h4> */}
      </section>
      <Box
        component="header"
        className={
          classes.root + (props.className ? ` ${props.className}` : "")
        }
        {...otherProps}
      >
        {title && (
          <>
            <Typography
              variant={`h${size}`}
              gutterBottom={props.subtitle ? true : false}
              style={{ fontWeight: "700" }}
            >
              {title}
            </Typography>

            {subtitle && (
              <Typography variant="subtitle1" className={classes.subtitle}>
                {subtitle}
              </Typography>
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default SectionHeader;
