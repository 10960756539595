import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  getFirestore,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  getDoc,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  getDocs,
  serverTimestamp,
  documentId,FieldValue 
} from "firebase/firestore";

import { firebaseApp,  } from "./firebase";
import { auth } from "./auth";
import {genericEmail} from "./functions";

// Initialize Firestore
const db = getFirestore(firebaseApp);

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Subscribe to user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", { uid }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, "users", uid)),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}

// get all transactions by product
export async function getEOIForTransactionByProduct(productId) {
  const eq = query(collection(db, "investments"), where("productid", "==", productId));
  const eqSnapshot = await getDocs(eq);
  const eoiTransactionData = [];
  eqSnapshot.forEach(doc => {
    eoiTransactionData.push({
      ...doc.data(),
      p_type: doc.data().payment_type,
      id: doc.id,
      date: new Date(),
      reference_number: "",
      payment_type: "",
      type: "",
      amount: 0,
      selected: false
    });
  });

  return eoiTransactionData;
}


export async function getTransactionByUserAndProduct(productId, userId) {
  const q = query(collection(db, "transaction"), where("productid", "==", productId), where("userid", "==", userId));
  const querySnapshot = await getDocs(q);
  const transactionData = [];
  querySnapshot.forEach(doc => {
    transactionData.push({ ...doc.data(), id: doc.id });
  });
  return transactionData;
}

export async function getTransactionByProduct(productId) {
  const q = query(collection(db, "transaction"), where("productid", "==", productId), orderBy('time_stamp', 'asc'));
  const querySnapshot = await getDocs(q);
  const transactionData = [];
  querySnapshot.forEach(doc => {
    transactionData.push({ ...doc.data(), id: doc.id,  });
  });
  return transactionData;
}

export async function updateTransactionByProduct(transactions) {
  for (let i = 0; i < transactions.length; i++) {
    const data = transactions[i];
    const obj = {
      name: data.name,
      date: data.date,
      amount: data.amount,
      payment_type: data.payment_type,
      reference_number: data.reference_number,
      type: data.type,
      //Changes need to be done
      userid: auth.currentUser.uid,
      productid: data.productid,
      eoi_id: data.id,
      time_stamp: serverTimestamp(),
    };
    await addDoc(collection(db, "transaction"), obj);

    if (data.type === "debit") {
      const pdt = await getProductId(data.productid);
      const updated_totalAmountReceived = Number(pdt.total_amount_received) + Number(data.amount);
      await updateDoc(doc(db, "product_details", data.productid), {
        total_amount_received: updated_totalAmountReceived,
        total_percentage: (updated_totalAmountReceived / pdt.property_cost) * 100,
      });

      // update eoi payment type
      const userPaidTotal = Number(data.total_amount_paid) + Number(data.amount);
      updateDoc(doc(db, "investments", data.id), {
        payment_type: data.payment_type,
        total_amount_paid: userPaidTotal,
        status: data.payment_type === "Full Amount" ? "Full Drawdown Completed" : "Registered",
      });

      if (data.payment_type === "Full Amount") {
        const lead = await getLeadsByUserId(data.userid);
        if (lead.length > 0) {
          const cp_id = lead[0].cp_id;
          const cp = await getChannelPartner(cp_id);

          if (cp.level == 3) {
            const cpp = await getChannelPartner(cp.parentcp_id);
            await addDoc(collection(db, "transaction"), {
              date: data.date,
              amount: userPaidTotal * 0.01,
              payment_type: "",
              reference_number: "",
              type: "credit",
              userid: cpp.parentcp_id,
              productid: data.productid,
              eoi_id: data.id,
              time_stamp: serverTimestamp(),
            });
            await addDoc(collection(db, "transaction"), {
              date: data.date,
              amount: userPaidTotal * 0.01,
              payment_type: "",
              reference_number: "",
              type: "credit",
              userid: cp.parentcp_id,
              productid: data.productid,
              eoi_id: data.id,
              time_stamp: serverTimestamp(),
            });
            await addDoc(collection(db, "transaction"), {
              date: data.date,
              amount: userPaidTotal * 0.01,
              payment_type: "",
              reference_number: "",
              type: "credit",
              userid: cp_id,
              productid: data.productid,
              eoi_id: data.id,
              time_stamp: serverTimestamp(),
            });
          } else if (cp.parentcp_id !== null && cp.parentcp_id !== undefined && cp.parentcp_id !== "") {
            await addDoc(collection(db, "transaction"), {
              date: data.date,
              amount: userPaidTotal * 0.02,
              payment_type: "",
              reference_number: "",
              type: "credit",
              userid: cp.parentcp_id,
              productid: data.productid,
              eoi_id: data.id,
              time_stamp: serverTimestamp(),
            });
            await addDoc(collection(db, "transaction"), {
              date: data.date,
              amount: userPaidTotal * 0.01,
              payment_type: "",
              reference_number: "",
              type: "credit",
              userid: cp_id,
              productid: data.productid,
              eoi_id: data.id,
              time_stamp: serverTimestamp(),
            });

          }
          else {
            await addDoc(collection(db, "transaction"), {
              date: data.date,
              amount: userPaidTotal * 0.03,
              payment_type: "",
              reference_number: "",
              type: "credit",
              userid: cp_id,
              productid: data.productid,
              eoi_id: data.id,
              time_stamp: serverTimestamp(),
            });
          }
        }
      }
    }
  }
  return true;
}

// Delete an deleteTransaction
export async function deleteTransaction(id) {
  const trans = await getTransaction(id);


  if (trans.type === "debit") {
    const pdt = await getProductId(trans.productid);
    const updated_totalAmountReceived = Number(pdt.total_amount_received) - Number(trans.amount);
    await updateDoc(doc(db, "product_details", trans.productid), {
      total_amount_received: updated_totalAmountReceived,
      total_percentage: (updated_totalAmountReceived / pdt.property_cost) * 100,
    });
  }

  const eoiData = await getEOI(trans.eoi_id);
  const userPaidTotal = Number(eoiData.total_amount_paid) - Number(trans.amount);

  let updatePaymentType = "";
  if (eoiData.payment_type === "Full Amount" && trans.payment_type !== "Full Amount") {
    updatePaymentType = "Full Amount";
  } else if (eoiData.payment_type === "Full Advance" && trans.payment_type !== "Full Advance") {
    updatePaymentType = "Full Advance";
  } else {
    updatePaymentType = eoiData.payment_type;
  }

  updateDoc(doc(db, "investments", trans.eoi_id), {
    payment_type: updatePaymentType,
    total_amount_paid: userPaidTotal,
    status: trans.payment_type === "Full Amount" ? "Registered" : trans.payment_type,
  });

  if (trans.payment_type === "Full Amount") {
    const lead = await getLeadsByUserId(trans.userid);
    if (lead.length > 0) {
      const cp_id = lead[0].cp_id;
      const cp = await getChannelPartner(cp_id);
      if (cp.parentcp_id !== null && cp.parentcp_id !== undefined && cp.parentcp_id !== "") {

        await deleteDoc(collection(db, "transaction"), where("userid", "==", cp.parentcp_id),
          where("productid", "==", trans.productid), where("eoi_id", "==", trans.eoi_id));

        await deleteDoc(collection(db, "transaction"), where("userid", "==", cp_id),
          where("productid", "==", trans.productid), where("eoi_id", "==", trans.eoi_id));

      } else {
        await deleteDoc(collection(db, "transaction"), where("userid", "==", cp_id),
          where("productid", "==", trans.productid), where("eoi_id", "==", trans.eoi_id));
      }
    }
  }

  return deleteDoc(doc(db, "transaction", id));
}

//from git
export async function getAllEOIProductsForUserid(userid) {   
  const q = query(collection(db, "investments"), where("uid", "==", userid));
  const eoiSnapshot = await getDocs(q);
  const productIds = [];
  eoiSnapshot.forEach((eoi) => {
    productIds.push(eoi.data().productid)
  });

  const products = [];
  for (let index = 0; index < productIds.length; index++) {
    const PdtDoc = await getDoc(doc(db, "product_details", productIds[index]));
    products.push(PdtDoc.data());
  }
  return products;
}

// get all EOI
export async function getAllEOI(productId) {
  // productId valid check
  const q = query(collection(db, "investments"), productId === "-1" ? null : where("productid", "==", productId));
  const querySnapshot = await getDocs(q);
  const eoiData = [];
  querySnapshot.forEach((docInfo) => {
    // from git
    eoiData.push({ ...docInfo.data(), id: docInfo.id })
  });
  return eoiData;
}
export async function getAllemailEOI(email) {
  // productId valid check
  const q = query(collection(db, "investments"), where("email", "==", email));
  const querySnapshot = await getDocs(q);
  const eoiData = [];
  querySnapshot.forEach((docInfo) => {
    //from git
    eoiData.push({ ...docInfo.data(), id: docInfo.id })
  });
  return eoiData;
}
export async function getAllEOIProductsForUser(userid) {  
  const q = query(collection(db, "investments"), where("email", "==", userid));
  const eoiSnapshot = await getDocs(q);
  const productIds = [];
  const eoistat = [];
  eoiSnapshot.forEach((eoi) => {
    productIds.push(eoi.data().productid)
    eoistat.push(eoi.data())
  });

  const products = [];
  for (let index = 0; index < productIds.length; index++) {
    const PdtDoc = await getDoc(doc(db, "product_details", productIds[index]));
    products.push({ ...PdtDoc.data(), id: PdtDoc.id });
  }
  return {products, eoistat};
}

export async function getAllEOIProductsDocumentForUser(email) {
  const q = query(collection(db, "investments"), where("email", "==", email));
  const eoiSnapshot = await getDocs(q);
  const productIds = [];
  const eoistat = [];
  eoiSnapshot.forEach((eoi) => {
    productIds.push(eoi.data().productid)
    eoistat.push(eoi.data())
  });

  const docData = [];
  if (productIds.length > 0) {
    const queryDocuments = query(collection(db, "documents"), where("uid", "in", productIds));
    const queryDocumentsSnapshot = await getDocs(queryDocuments);
    queryDocumentsSnapshot.forEach((docInfo) => {
      docData.push({ ...docInfo.data(), id: docInfo.id })
    });
  }
  return docData;
}
// get all EOI
export async function getAllProducts() {
  const q = query(collection(db, "product_details"));
  const querySnapshot = await getDocs(q);
  const data = [];
  querySnapshot.forEach((doc) => {
    data.push({ ...doc.data(), id: doc.id })
  });
  return data;
}
export async function getusereoi(email) {
  const q = query(collection(db, "investments"), where("email", "==", email));
  const querySnapshot = await getDocs(q);
  const data = [];
  querySnapshot.forEach((doc) => {
    data.push({ ...doc.data(), id: doc.id })
  });
  return data;
}

export async function getCPbyemail(email) {
  const q = query(collection(db, "channel_partner"), where("email", "==", email));
  const querySnapshot = await getDocs(q);
  const data = [];
  querySnapshot.forEach((doc) => {
    data.push({ ...doc.data(), id: doc.id })
  });
  return data;
}

export async function getuserdocument(uid) {
  const q = query(collection(db, "documents"), where("uid", "==", uid),orderBy('name'), orderBy('time_stamp', 'desc'));
  const querySnapshot = await getDocs(q);
  const data = [];
  let currentName = null;
  querySnapshot.forEach((doc) => {
    if (!currentName || currentName !== doc.data().name) {
      currentName = doc.data().name;
      data.push({ ...doc.data(), id: doc.id })
    }
  
  });
  return data;
}
export async function getuserproduct(productId) {
  const q = query(collection(db, "product_details"), where("productId", "==", productId));
  const querySnapshot = await getDocs(q);
  const data = [];
  querySnapshot.forEach((doc) => {
    data.push({ ...doc.data(), id: doc.id })
  });
  return data;
}
export async function getaProducts(uid) {
  const products = await getDoc(doc(db, "product_details", uid));
  return products.data();
}
export async function getUser(uid) {
  // get user data from uid
  const user = await getDoc(doc(db, "users", uid));
  return user.data();
}

export async function getUserTranasactions(uid) {
  
  const q = query(collection(db, "transaction"), where("user_id", "==", uid), where("status", "==", "completed"), orderBy('time_stamp', 'desc'));
  const querySnapshot = await getDocs(q);
  const userData = [];
  querySnapshot.forEach((doc) => {
    userData.push({ ...doc.data(), id: doc.id, dates: doc.data().date.toDate().toLocaleString()})
  });
  return userData;
}
export async function getCpd(uid) {
  // get user data from uid
  const user = await getDoc(doc(db, "channel_partner", uid));
  return user.data();
}

export async function getProductId(uid) {
  // get user data from uid
  const pdt = await getDoc(doc(db, "product_details", uid));
  return pdt.data();
}

export async function getTransaction(uid) {
  // get user data from uid
  const trans = await getDoc(doc(db, "transaction", uid));
  return trans.data();
}


export async function getChannelPartner(uid) {
  // get user data from uid
  const cp = await getDoc(doc(db, "channel_partner", uid));
  return cp.data();
}

export async function getEOI(uid) {
  // get user data from uid
  const result = await getDoc(doc(db, "investments", uid));
  return result.data();
}
export async function getCps(uid) {
  // get user data from uid
  const result = await getDoc(doc(db, "admin", uid));
  return result.data();
}

export async function filteremailEOI(email, productId) {
  const q = query(collection(db, "investments"), where("email", "==", email), where("productid", "==", productId));
  const querySnapshot = await getDocs(q);
  const transactionData = [];
  querySnapshot.forEach(doc => {
    transactionData.push({ ...doc.data(), id: doc.id });
  });
  return transactionData;
  
}
// export async function filterpropEOI(productId) {
//   const q = query(collection(db, "investments"), where("productid", "==", productId));
//   const querySnapshot = await getDocs(q);
//   const transactionData = [];
//   querySnapshot.forEach(doc => {
//     transactionData.push({ ...doc.data(), id: doc.id });
//   });
//   return transactionData;
  
// }
// export async function filterEOI(email) {
//   // get user data from uid
//   const result = await getDoc(doc(db, "investments"), where("email", "==", email ));
//   return result.data();
// }

// get all EOI
export async function getAllUsers() {
  const q = query(collection(db, "users"));
  const querySnapshot = await getDocs(q);
  const userData = [];
  querySnapshot.forEach((doc) => {
    userData.push({ ...doc.data(), id: doc.id, userType: "user", name: doc.data().first_name + " " + doc.data().last_name })
  });
  return userData;
}

export async function getAllChannelPartners() {
  const qcp = query(collection(db, "channel_partner"));
  const querycpSnapshot = await getDocs(qcp);
  const cpData = [];
  querycpSnapshot.forEach((doc) => {
    cpData.push({ ...doc.data(), id: doc.id, userType: "cp" })
  });

  return cpData;
}

// Update EOI
export  function updateEOI(uid, data) {
  return updateDoc(doc(db, "investments", uid), data);
}
export  function updateProduct(uid, data) {
  return updateDoc(doc(db, "product_details", uid), data);
}
export  function updateTransaction(uid, data) {
  return updateDoc(doc(db, "transaction", uid), data);
}

// export  function updateTransactions(uid, data) {
//   return updateDoc(doc(db, "transactions", uid), data);
// }
export async function approveEOI(uid, iv, data) {

  await updateDoc(doc(db, "investments", uid), {'status': 'Registered', 'investment_amount': iv });

  const obj = {
    name: "SPV",
    date: '',
    amount: 0.10*Number(iv) ,
    payment_type: "Advance",
    reference_number: '',
    type: "credit",
    user_stamp: auth.currentUser.uid,
    user_id: data.user_id,
    productid: data.productid,
    eoi_id: data.id,
    from: data.email,
    to: 'SPV-ESC',
    status: "pending",
    time_stamp: serverTimestamp(),
  };
  await addDoc(collection(db, "transaction"), obj);


  const obj1 = {
    name: "SPV",
    date: '',
    amount: 0.90*Number(iv) ,
    payment_type: "Full Drawdown",
    reference_number: '',
    type: "credit",
    user_stamp: auth.currentUser.uid,
    user_id: data.user_id,
    productid: data.productid,
    eoi_id: data.id,
    from: data.email,
    to: 'SPV-ESC',
    status: "pending",
    time_stamp: serverTimestamp(),
  };
  await addDoc(collection(db, "transaction"), obj1);
  return 
}

export async function activationTransactions(data, product, eoi) {

  const obj = {
    name: "SPV",
    date: '',
    amount: Number(product.base_price) ,
    payment_type: "Asset Purchase",
    reference_number: '',
    type: "debit",
    user_stamp: auth.currentUser.uid,
    user_id: '',
    productid: data.productid,
    eoi_id: '',
    from: 'SPV-ESC',
    to: 'seller',
    status: "pending",
    time_stamp: serverTimestamp(),
  };
  await addDoc(collection(db, "transaction"), obj);

  const obj1 = {
    name: "SPV",
    date: '',
    amount: Number(product.investor_comission) ,
    payment_type: "Investor Commission",
    reference_number: '',
    type: "debit",
    user_stamp: auth.currentUser.uid,
    user_id: '',
    productid: data.productid,
    eoi_id: '',
    from: 'SPV-ESC',
    to: 'GAYN',
    status: "pending",
    time_stamp: serverTimestamp(),
  };
  await addDoc(collection(db, "transaction"), obj1);

  const obj2 = {
    name: 'GAYN',
    date: '',
    amount: Number(product.investor_comission) ,
    payment_type: "Investor Commission",
    reference_number: '',
    type: "credit",
    user_stamp: auth.currentUser.uid,
    user_id: '',
    productid: data.productid,
    eoi_id: '',
    from: 'SPV-ESC',
    to: 'GAYN',
    status: "pending",
    time_stamp: serverTimestamp(),
  };
  await addDoc(collection(db, "transaction"), obj2);

  const obj3 = {
    name: 'GAYN',
    date: '',
    amount: Number(product.seller_comission) ,
    payment_type: "Seller Commission",
    reference_number: '',
    type: "credit",
    user_stamp: auth.currentUser.uid,
    user_id: '',
    productid: data.productid,
    eoi_id: '',
    from: 'seller',
    to: 'GAYN',
    status: "pending",
    time_stamp: serverTimestamp(),
  };
  await addDoc(collection(db, "transaction"), obj3);

  await Promise.all(eoi.map(async (file) => {
    const lead = await getLeadsByUserId(file.user_id);
    if (lead.length > 0) {
      const cp_id = lead[0].cp_id;
      const cp = await getChannelPartner(cp_id);

      if (cp.level == 3) {
        const cpp = await getChannelPartner(cp.parentcp_id);
        const cppp = await getChannelPartner(cpp.parentcp_id);
        await addDoc(collection(db, "transaction"), {
          name: "GAYN",
          date: '',
          amount: 0.001*Number(file.investment_amount),
          payment_type: "CP Commission",
          reference_number: "",
          type: "debit",
          userid: cpp.parentcp_id,
          productid: file.productid,
          eoi_id: file.id,
          time_stamp: serverTimestamp(),
          user_stamp: auth.currentUser.uid,
          from: 'GAYN',
          to:  cpp.parentcp,
          status: "pending",
        });
        await addDoc(collection(db, "transaction"), {
          name: "GAYN",
          date: '',
          amount: 0.001*Number(file.investment_amount),
          payment_type: "CP Commission",
          reference_number: "",
          type: "debit",
          userid: cp.parentcp_id,
          productid: file.productid,
          eoi_id: file.id,
          time_stamp: serverTimestamp(),
          user_stamp: auth.currentUser.uid,
          from: 'GAYN',
          to:  cp.parentcp,
          status: "pending",
        });
        await addDoc(collection(db, "transaction"), {
          name: "GAYN",
          date:  '',
          amount: 0.001*Number(file.investment_amount),
          payment_type: "CP Commission",
          reference_number: "",
          type: "debit",
          userid: cp_id,
          productid: file.productid,
          eoi_id: file.id,
          time_stamp: serverTimestamp(),
          user_stamp: auth.currentUser.uid,
          from: 'GAYN',
          to:  cp.email,
          status: "pending",
        });
      } else if (cp.parentcp_id !== null && cp.parentcp_id !== undefined && cp.parentcp_id !== "") {
        await addDoc(collection(db, "transaction"), {
          name: "GAYN",
          date: '',
          amount: 0.002*Number(file.investment_amount),
          payment_type: "CP Commission",
          reference_number: "",
          type: "debit",
          userid: cp.parentcp_id,
          productid: file.productid,
          eoi_id: file.id,
          time_stamp: serverTimestamp(),
          user_stamp: auth.currentUser.uid,
          from: 'GAYN',
          to:  cp.parentcp,
          status: "pending",
        });
        await addDoc(collection(db, "transaction"), {
          name: "GAYN",
          date:  '',
          amount: 0.001*Number(file.investment_amount),
          payment_type: "CP Commission",
          reference_number: "",
          type: "debit",
          userid: cp_id,
          productid: file.productid,
          eoi_id: file.id,
          time_stamp: serverTimestamp(),
          user_stamp: auth.currentUser.uid,
          from: 'GAYN',
          to:  cp.email,
          status: "pending",
        });

      }
      else {
        await addDoc(collection(db, "transaction"), {
          name: "GAYN",
          date: '',
          amount: 0.003*Number(file.investment_amount),
          payment_type: "CP Commission",
          reference_number: "",
          type: "debit",
          userid: cp_id,
          productid: file.productid,
          eoi_id: file.id,
          time_stamp: serverTimestamp(),
          user_stamp: auth.currentUser.uid,
          from: 'GAYN',
          to:  cp.email,
          status: "pending",
        });
      }
    }

  }));

  return 
}

export async function activationRentals(data, product, eoi) {

  const obj = {
    name: "SPV",
    date: '',
    amount: Number(product.annual_rent)/12,
    payment_type: "Rent",
    reference_number: '',
    type: "credit",
    user_stamp: auth.currentUser.uid,
    user_id: '',
    productid: data.productid,
    eoi_id: '',
    from: 'Tenant 1',
    to: 'SPV-ESC',
    status: "pending",
    time_stamp: serverTimestamp(),
  };
  await addDoc(collection(db, "transaction"), obj);

  const obj1 = {
    name: "SPV",
    date: '',
    amount: (Number(product.annual_rent)/12)*0.10 ,
    payment_type: "Rent TDS",
    reference_number: '',
    type: "credit",
    user_stamp: auth.currentUser.uid,
    user_id: '',
    productid: data.productid,
    eoi_id: '',
    from: 'Tenant 1',
    to: 'SPV-ESC',
    status: "pending",
    time_stamp: serverTimestamp(),
  };
  await addDoc(collection(db, "transaction"), obj1);

  const obj2 = {
    name: 'SPV',
    date: '',
    amount:  Number(product.management_fee)/12,
    payment_type: "Management Fee",
    reference_number: '',
    type: "debit",
    user_stamp: auth.currentUser.uid,
    user_id: '',
    productid: data.productid,
    eoi_id: '',
    from: 'SPV-ESC',
    to: 'GAYN',
    status: "pending",
    time_stamp: serverTimestamp(),
  };
  await addDoc(collection(db, "transaction"), obj2);

  const obj4 = {
    name: 'GAYN',
    date: '',
    amount: Number(product.management_fee)/12 ,
    payment_type: "Management Fee",
    reference_number: '',
    type: "credit",
    user_stamp: auth.currentUser.uid,
    user_id: '',
    productid: data.productid,
    eoi_id: '',
    from: 'SPV-ESC',
    to: 'GAYN',
    status: "pending",
    time_stamp: serverTimestamp(),
  };
  await addDoc(collection(db, "transaction"), obj4);

  const obj3 = {
    name: 'SPV-CA',
    date: '',
    amount: Number(product.annual_tax)/12 ,
    payment_type: "Tax/Expense",
    reference_number: '',
    type: "debit",
    user_stamp: auth.currentUser.uid,
    user_id: '',
    productid: data.productid,
    eoi_id: '',
    from: 'SPV-CA',
    to: 'SPV-ESC',
    status: "pending",
    time_stamp: serverTimestamp(),
  };
  await addDoc(collection(db, "transaction"), obj3);

  const obj5 = {
    name: 'SPV',
    date: '',
    amount: Number(product.annual_tax)/12 ,
    payment_type: "Tax/Expense",
    reference_number: '',
    type: "credit",
    user_stamp: auth.currentUser.uid,
    user_id: '',
    productid: data.productid,
    eoi_id: '',
    from: 'SPV-CA',
    to: 'SPV-ESC',
    status: "pending",
    time_stamp: serverTimestamp(),
  };
  await addDoc(collection(db, "transaction"), obj5);

  await Promise.all(eoi.map(async (file) => {
    
    const obj22 = {
      name: "SPV",
      date: '',
      amount: (Number(product.net_annual_rent)/12)*(Number(file.investmenta)/Number(product.property_cost))*0.10 ,
      payment_type: "Monthly PayOut TDS",
      reference_number: '',
      type: "debit",
      user_stamp: auth.currentUser.uid,
      user_id: file.user_id,
      productid: data.productid,
      eoi_id: file.id,
      from: 'SPV-ESC',
      to: file.email,
      status: "pending",
      time_stamp: serverTimestamp(),
    };
    await addDoc(collection(db, "transaction"), obj22);
    const obj33 = {
      name: "SPV",
      date: '',
      amount: (Number(product.net_annual_rent)/12)*(Number(file.investmenta)/Number(product.property_cost))*0.90,
      payment_type: "Monthly PayOut",
      reference_number: '',
      type: "debit",
      user_stamp: auth.currentUser.uid,
      user_id: file.user_id,
      productid: data.productid,
      eoi_id: file.id,
      from: 'SPV-ESC',
      to: file.email,
      status: "pending",
      time_stamp: serverTimestamp(),
    };
    await addDoc(collection(db, "transaction"), obj33);
  }));

  return 
}

export function updateCps(uid) {
  return updateDoc(doc(db, "admin", uid), {'no_of_cps': "10" });
}
// Update EOI
export async function markProductActive(uid, status) {
  await updateDoc(doc(db, "product_details", uid), { "status": status });
  const eoi = getEOIForTransactionByProduct(uid);
  for (let i = 0; i < eoi.length; i++) {
    await updateEOI(eoi[i].id, { "status": (status === "Active" ? "Active" : "Full Drawdown Completed") });
  }
}

// Create a new EOI
export function createEOI(data) {
  // add data to eoi collection in the firestore
  const data25 = {
    'email': data.email,
    'name': data.name,
    'status': 'Requested',
    'investment_amount': data.investment_amount,
    'templateid' : 'd-9b484f1fbb6741f8bfe8a533fbb10fc2'
}

    genericEmail(data25);
  return addDoc(collection(db, "investments"), data);

  
}


// Create a new user
export function createUser(uid, data) {
  return setDoc(doc(db, "users", uid), data, { merge: true });
  
}

export function createinvestmentaccount(data) {
  return addDoc(collection(db, "investment_account"), data);
}
// Update an existing user
export function updateUser(uid, data) {
  return updateDoc(doc(db, "users", uid), data);
}

// Update an existing channel partner
export function updateChannelPartner(uid, data) {
  return updateDoc(doc(db, "channel_partner", uid), data);
}


/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// Subscribe to item data
export function useItem(id) {
  return useQuery(
    ["item", { id }],
    createQuery(() => doc(db, "items", id)),
    { enabled: !!id }
  );
}

// Fetch item data once
export function useItemOnce(id) {
  return useQuery(
    ["item", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "items", id)).then(format),
    { enabled: !!id }
  );
}

// Fetch item data once (non-hook)
// Useful if you need to fetch data from outside of a component
export function getItem(id) {
  return getDoc(doc(db, "items", id)).then(format);
}

// Subscribe to all items by owner
export function useItemsByOwner(owner) {
  return useQuery(
    ["items", { owner }],
    createQuery(() =>
      query(
        collection(db, "items"),
        where("owner", "==", owner),
        orderBy("time_stamp", "desc")
      )
    ),
    { enabled: !!owner }
  );
}

// Create a new item
export function createItem(data) {
  return addDoc(collection(db, "items"), {
    ...data,
    time_stamp: serverTimestamp(),
  });
}
export function createdocument(uid, file, fileName, url) {

  return addDoc(collection(db, "documents"), {
    uid: uid, name: fileName, image: url, createdby: auth.currentUser.uid, 
    time_stamp: serverTimestamp(),
  });
}

// Update an item
export function updateItem(id, data) {
  return updateDoc(doc(db, "items", id), data);
}

export function updateCpcreateuser(id, g) {
  return updateDoc(doc(db, "lead_table", g), {'user_id': id, status: "incomplete"});
}

export function deleteItem(id) {
  return deleteDoc(doc(db, "items", id));
}

export async function getCpportfolio() {
  const querySnapshot = await getDocs(query(collection(db, "channel_partner"), where("parentcp_id", "==", auth.currentUser.uid)));
  const querySnapshot1 = await getDocs(query(collection(db, "channel_partner"), where("uid", "==", auth.currentUser.uid)));
  const querySnapshot2 = await getDocs(query(collection(db, "lead_table")));
  const querySnapshot3 = await getDocs(query(collection(db, "investments")));

  const data = [];
  const data1 = [];
  const data2 = [];
  const data3 = [];
  querySnapshot.forEach((doc) => {
    data.push({ ...doc.data(), id: doc.id });
  });
  querySnapshot1.forEach((doc) => {
    console.log('3333333',doc.data());
    data.push({ ...doc.data(), id: doc.id });
  });
  querySnapshot2.forEach((doc) => {
    console.log('444444444',doc.data());
    data2.push({ ...doc.data(), id: doc.id });

  });
  querySnapshot3.forEach((doc) => {
    console.log('444444444',doc.data());
    data3.push({ ...doc.data(), id: doc.id });

  });
  data3.forEach((doc) => {
      
    data3.push({ id: doc.uid, cp_name: "", cp_level: "", investor_name: doc.name, property_name: "Athulya", status: doc.status, invested_amount: doc.investment_amount , returns: 0, payment_status: "Pending", subRows: undefined  });

  });
  data2.forEach((doc) => {
      
    data2.push({ id: doc.cp_id, cp_name: "", cp_level: "", investor_name: doc.first_name + ''+ doc.last_name, property_name: "", status: '', invested_amount: "", returns: 0, payment_status: "", email: doc.email, subRows: data3.filter(x => x.id == doc.email)  });

  });
  data.forEach(async (doc) => {
    data1.push({ id: doc.id, cp_name: doc.first_name, cp_level: doc.level, investor_name: "", property_name: "", status: '', invested_amount: doc.ti, returns: doc.tr, payment_status: "", subRows: data2.filter(x => x.id == doc.id)  });

  });
  return data1;
}
/**** Channel Partners ****/
export async function getAllLeads() {
  const querySnapshot = await getDocs(query(collection(db, "lead_table"), where("cp_id", "==", auth.currentUser.uid)));
  const data = [];
  querySnapshot.forEach((doc) => {
    data.push({ ...doc.data(), id: doc.id });
  });
  return data;
}

export async function getCpteamdata() {
  const querySnapshot = await getDocs(query(collection(db, "channel_partner"), where("parentcp_id", "==", auth.currentUser.uid)));
  const data = [];
  querySnapshot.forEach((doc) => {
    data.push({ ...doc.data(), id: doc.id });
  });
  return data;
}
export async function getLeadsByUserId(userId) {
  const querySnapshot = await getDocs(query(collection(db, "lead_table"), where("user_id", "==", userId)));
  const data = [];
  querySnapshot.forEach((doc) => {
    data.push({ ...doc.data(), id: doc.id });
  });
  return data;
}
// from git
export async function getChannelPartnerFromUserId(userId) {
  const querySnapshot = await getDocs(query(collection(db, "channel_partner"), where("uid", "==", userId)));
  const data = [];
  querySnapshot.forEach((doc) => {
    data.push({ ...doc.data(), id: doc.id });
  });
  return data;
}

// Add New Client
export async function addLeads(data) {
  // const { name, email, phone } = data; // from git
  const { first_name, last_name, email, phone, cpId } = data;
  let currentCpId = auth.currentUser.uid;
  if (cpId !== undefined && cpId !== null) {
    currentCpId = cpId;
    const cpInfo = await getChannelPartnerFromUserId(cpId);
    if (cpInfo.length == 0) {
      return;
    }
  }

  const leadTableRef = collection(db, "lead_table");
  const userTableRef = collection(db, "users");

  const queryEmail = getDocs(query(leadTableRef, where("email", "==", email)));
  const queryPhoneNumber = getDocs(query(leadTableRef, where("phone_number", "==", phone)));
  const [queryEmailSnapshot, queryPhoneNumberSnapshot] = await Promise.all([queryEmail, queryPhoneNumber]);

  // check if queryEmailSnapshot is empty, if not check the value of cp_id in the snapshot
  if (!queryEmailSnapshot.empty) {
    // queryEmailSnapshot.forEach((doc) => {
    //   const lead = doc.data();
    queryEmailSnapshot.forEach((qEmData) => {
      const lead = qEmData.data();
      if (!(lead.cp_id === null || lead.cp_id === "" || lead.cp_id === undefined)) {
        // throw exception channel partner is already available for the given email
        throw new Error("Channel Partner is already available for the given email");
      }
    });
  }

  // check if queryPhoneNumberSnapshot is empty, if not check the value of cp_id in the snapshot
  if (!queryPhoneNumberSnapshot.empty) {
    queryPhoneNumberSnapshot.forEach((doc) => {
      const lead = doc.data();
      if (!(lead.cp_id === null || lead.cp_id === "" || lead.cp_id === undefined)) {
        // throw exception channel partner is already available for the given phone
        throw new Error("Channel Partner is already available for the given phone Number");
      }
    });
  }

  // get user info based on email or phonenumber

  let userDocument = null;
  const queryUserEmail = await getDocs(query(userTableRef, where("email", "==", email)));
  if (!queryUserEmail.empty) {
    userDocument = queryUserEmail.docs[0];
  }

  if (userDocument == null) {
    const queryUserPhone = await getDocs(query(userTableRef, where("phone_number", "==", phone)));
    if (!queryUserPhone.empty) {
      userDocument = queryUserPhone.docs[0];
    }
  }

  let userId = null;
  // let kycStatus = "Pending";
  // from git
  let kycStatus = "Account Not Created";
  if (userDocument != null) {
    // get user data from uid and check the kyc status
    const user = userDocument.data();
    if (user.role === "cp") {
      throw new Error("You cannot add a channel partner as a lead");
    }
    userId = userDocument.id;
    if (user.kyc_status === "Complete") {
      kycStatus = "Complete";
    }else if (user.kyc_status === "submitted") {
      kycStatus = "Pending";
    } else{
      kycStatus = "incomplete";
    }
  }
  addDoc(leadTableRef, {
    first_name, 
    last_name,
    email,
    phone_number: phone,
    // cp_id: auth.currentUser.uid, from git
    cp_id: currentCpId,
    user_id: userId,
    entry: "Added by you",
    status: kycStatus,
    time_stamp: serverTimestamp(),
  });
  // const cpInfo = await getChannelPartnerFromUserId(auth.currentUser.uid);
  // const cpcvalue = cpInfo[0].tc + 1;
  // await updateDoc(doc(db, "channel_partner", auth.currentUser.uid), {tc: cpcvalue});
  if (userId != null) {
    const investmentTableRef = collection(db, "investment_account");
    // update investment tableref cp_id based on 
    const queryInvestment = query(investmentTableRef, where("uid", "==", userId));
    const queryInvestmentSnapshot = await getDocs(queryInvestment);
    queryInvestmentSnapshot.forEach((res) => {
      updateDoc(doc(db, "investment_account", res.id), { cp_id: auth.currentUser.uid });
      // updateDoc(doc, { cp_id: auth.currentUser.uid });
    });
  }

  return "success";
}

export async function addLeads1(data) {

  const { first_name, last_name, email, phone, cpId } = data;
 
  const leadTableRef = collection(db, "lead_table");
  const userTableRef = collection(db, "users");
  const queryEmail = getDocs(query(leadTableRef, where("email", "==", email)));
  const queryPhoneNumber = getDocs(query(leadTableRef, where("phone_number", "==", phone)));
  const [queryEmailSnapshot, queryPhoneNumberSnapshot] = await Promise.all([queryEmail, queryPhoneNumber]);

  // check if queryEmailSnapshot is empty, if not check the value of cp_id in the snapshot
  if (!queryEmailSnapshot.empty) {
    // queryEmailSnapshot.forEach((doc) => {
    //   const lead = doc.data();
    queryEmailSnapshot.forEach((qEmData) => {
      const lead = qEmData.data();
      if (!(lead.cp_id === null || lead.cp_id === "" || lead.cp_id === undefined)) {
        // throw exception channel partner is already available for the given email
        throw new Error("Channel Partner is already available for the given email");
      }
    });
  }

  // check if queryPhoneNumberSnapshot is empty, if not check the value of cp_id in the snapshot
  if (!queryPhoneNumberSnapshot.empty) {
    queryPhoneNumberSnapshot.forEach((doc) => {
      const lead = doc.data();
      if (!(lead.cp_id === null || lead.cp_id === "" || lead.cp_id === undefined)) {
        // throw exception channel partner is already available for the given phone
        throw new Error("Channel Partner is already available for the given phone Number");
      }
    });
  }

  // get user info based on email or phonenumber

  let userDocument = null;
  const queryUserEmail = await getDocs(query(userTableRef, where("email", "==", email)));
  if (!queryUserEmail.empty) {
    userDocument = queryUserEmail.docs[0];
    console.log("sssd",userDocument);
  }

  if (userDocument == null) {
    const queryUserPhone = await getDocs(query(userTableRef, where("phone_number", "==", phone)));
    if (!queryUserPhone.empty) {
      userDocument = queryUserPhone.docs[0];
    }
  }

  let userId = auth.currentUser.uid;

  let kycStatus = "Account Not Created";
  if (userDocument != null) {
    // get user data from uid and check the kyc status
    const user = userDocument.data();
    if (user.role === "cp") {
      throw new Error("You cannot add a channel partner as a lead");
    }
    userId = userDocument.id;
    if (user.kyc_status === "Complete") {
      kycStatus = "Complete";
    }else if (user.kyc_status === "submitted") {
      kycStatus = "Pending";
    } else{
      kycStatus = "incomplete";
    }
  }
  addDoc(leadTableRef, {
    first_name, 
    last_name,
    email,
    phone_number: phone,
    cp_id: cpId,
    user_id: auth.currentUser.uid,
    status: kycStatus,
    entry: "Added via your Referral Link",
    time_stamp: serverTimestamp(),
  });
  const cpInfo = await getChannelPartnerFromUserId(auth.currentUser.uid);
  const cpcvalue = cpInfo[0].tc + 1;
  await updateDoc(doc(db, "channel_partner", auth.currentUser.uid), {tc: cpcvalue});
  if (userId != null) {
    const investmentTableRef = collection(db, "investment_account");
    // update investment tableref cp_id based on 
    const queryInvestment = query(investmentTableRef, where("uid", "==", userId));
    const queryInvestmentSnapshot = await getDocs(queryInvestment);
    queryInvestmentSnapshot.forEach((res) => {
      updateDoc(doc(db, "investment_account", res.id), { cp_id: auth.currentUser.uid });
      // updateDoc(doc, { cp_id: auth.currentUser.uid });
    });
  }

  return "success";
}

export const addOrUpdateAddress = async (data) => {
  const addressTableRef = collection(db, "address");
  const queryAddress = query(addressTableRef, where("uid", "==", data.uid));
  const queryAddressSnapshot = await getDocs(queryAddress);
  if (queryAddressSnapshot.empty) {
    addDoc(addressTableRef, data);
  } else {
    updateDoc(doc(db, "address", queryAddressSnapshot.docs[0].id), data);
  }
}

export const updateLeadstatus = async (uid, kyc_status) => {
  const addressTableRef = collection(db, "lead_table");
  const queryAddress = query(addressTableRef, where("user_id", "==", uid));
  const queryAddressSnapshot = await getDocs(queryAddress);
  if (queryAddressSnapshot.empty) {
   console.log("no lead found")
  } else {
    updateDoc(doc(db, "lead_table", queryAddressSnapshot.docs[0].id), {status: kyc_status});
  }
}


export const addOrUpdateBankAccount = async (data) => {
  const bankAccountTableRef = collection(db, "bank_account");
  const queryBankAccount = query(bankAccountTableRef, where("uid", "==", data.uid));
  const queryBankAccountSnapshot = await getDocs(queryBankAccount);
  if (queryBankAccountSnapshot.empty) {
    addDoc(bankAccountTableRef, data);
  } else {
    updateDoc(doc(db, "bank_account", queryBankAccountSnapshot.docs[0].id), data);
  }
}

export const updateInvestments = async (userId, data) => {
  const investmentTableRef = collection(db, "investment_account");
  const queryInvestment = query(investmentTableRef, where("uid", "==", userId));
  const queryInvestmentSnapshot = await getDocs(queryInvestment);
  if (queryInvestmentSnapshot.empty) {
    addDoc(investmentTableRef, data);
  } else {
    updateDoc(doc(db, "investment_account", queryInvestmentSnapshot.docs[0].id), data);
  }
}

export const getInvestments = async (userId) => {
  const investmentTableRef = collection(db, "investment_account");
  const queryInvestment = query(investmentTableRef, where("uid", "==", userId));
  const queryInvestmentSnapshot = await getDocs(queryInvestment);
  if (queryInvestmentSnapshot.empty) {
    return null;
  } else {
    return queryInvestmentSnapshot.docs[0].data();
  }
}

export const getAddress = async (userId) => {
  const addressTableRef = collection(db, "address");
  const queryAddress = query(addressTableRef, where("uid", "==", userId));
  const queryAddressSnapshot = await getDocs(queryAddress);
  if (queryAddressSnapshot.empty) {
    return null;
  } else {
    return queryAddressSnapshot.docs[0].data();
  }
}

export const getBankAccount = async (userId) => {
  const bankAccountTableRef = collection(db, "bank_account");
  const queryBankAccount = query(bankAccountTableRef, where("uid", "==", userId));
  const queryBankAccountSnapshot = await getDocs(queryBankAccount);
  if (queryBankAccountSnapshot.empty) {
    return null;
  } else {
    return queryBankAccountSnapshot.docs[0].data();
  }
}

/**** HELPERS ****/

// Store Firestore unsubscribe functions
const unsubs = {};

function createQuery(getRef) {
  // Create a query function to pass to `useQuery`
  return async ({ queryKey }) => {
    let unsubscribe;
    let firstRun = true;
    // Wrap `onSnapshot` with a promise so that we can return initial data
    const data = await new Promise((resolve, reject) => {
      unsubscribe = onSnapshot(
        getRef(),
        // Success handler resolves the promise on the first run.
        // For subsequent runs we manually update the React Query cache.
        (response) => {
          const data = format(response);
          if (firstRun) {
            firstRun = false;
            resolve(data);
          } else {
            client.setQueryData(queryKey, data);
          }
        },
        // Error handler rejects the promise on the first run.
        // We can't manually trigger an error in React Query, so on a subsequent runs we
        // invalidate the query so that it re-fetches and rejects if error persists.
        (error) => {
          if (firstRun) {
            firstRun = false;
            reject(error);
          } else {
            client.invalidateQueries(queryKey);
          }
        }
      );
    });

    // Unsubscribe from an existing subscription for this `queryKey` if one exists
    // Then store `unsubscribe` function so it can be called later
    const queryHash = hashQueryKey(queryKey);
    unsubs[queryHash] && unsubs[queryHash]();
    unsubs[queryHash] = unsubscribe;

    return data;
  };
}

// Automatically remove Firestore subscriptions when all observing components have unmounted
client.queryCache.subscribe(({ type, query }) => {
  if (
    type === "observerRemoved" &&
    query.getObserversCount() === 0 &&
    unsubs[query.queryHash]
  ) {
    // Call stored Firestore unsubscribe function
    unsubs[query.queryHash]();
    delete unsubs[query.queryHash];
  }
});

// Format Firestore response
function format(response) {
  // Converts doc into object that contains data and `doc.id`
  const formatDoc = (doc) => ({ id: doc.id, ...doc.data() });
  if (response.docs) {
    // Handle a collection of docs
    return response.docs.map(formatDoc);
  } else {
    // Handle a single doc
    return response.exists() ? formatDoc(response) : null;
  }
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}
