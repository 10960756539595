import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Sectionbks1 from "./Sectionbks1";
import SectionHeader2314 from "./SectionHeader2314";

function HeroSection(props) {
  return (
    <Sectionbks1
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container style={{ marginBottom: "-40px" }}>
        <Box textAlign="center" style={{ marginLeft: "2px" }}>
          <SectionHeader2314
            title={props.title}
            subtitle={props.subtitle}
            size={4}
            style={{ textAlign: "left" }}
          />
        </Box>
      </Container>
    </Sectionbks1>
  );
}

export default HeroSection;
