import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from '@material-ui/core/Paper';
import TextField from "@material-ui/core/TextField";
import { getAllFiles, getFileUrl } from '../../util/firebase-storage'
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { CreateUserAdmin, verifyKYCAadharAdmin, verifyKYCPAN, verifyKYCBank, generateKYCAadharOTP, genericEmail } from '../../util/functions';
import { uploadFileToBucket } from '../../util/firebase-storage';
import {IconButton , InputAdornment} from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import CloseIcon from '@material-ui/icons/Close';
import AadharOTPModal from "../modal";
import InfoPopup from "../../components/Infopopup";
import {
  getUser,
  addLeads,
  updateLeadstatus,
  updateInvestments,
  addOrUpdateAddress,
  addOrUpdateBankAccount,
  getInvestments,
  getAddress,
  getBankAccount,updateUser, getCPbyemail
} from "../../util/db";
import { auth as myAuth, requireAuth } from "../../util/auth";
import AdminNavbar from "../../components/AdminNavbar";
import { history } from "../../util/router";
// import { InputAdornment } from "@material-ui/core";
import MenuItem from '@mui/material/MenuItem';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { RecaptchaVerifier } from 'firebase/auth';
import { async } from "analytics/lib/analytics.cjs";


function AdminCreateUser(props) {
  const { userId } = props
  const [formAlert, setFormAlert] = useState(null);
  const [pending, setPending] = useState(false);
  const [uid, setUid] = useState(userId);
  const [userd, setUserd] = useState(null);
  const [values, setValues] = React.useState({
    password: '',
    cpassword: '',
    showPassword: false,
    showcPassword: false,
  });
  // const [uid, setUid] = useState("1HHrAnOQFnPHMprCph6gvdqlTBl1");
  const { handleSubmit, register, errors, getValues, reset } = useForm();
  const [aadharFile, setAadharFile] = useState(null);
  const [panFile, setPanFile] = useState(null);
  
  const [bankAccountFile, setBankAccountFile] = useState(null);
  const [certificateofincorp, setCertificateofincorp] = useState(null);
  const [companypan, setCompanypan] = useState(null);
  const [boardresolution, setBoardresolution] = useState(null);
  const [gstcerti, setGstcerti] = useState(null);
  const [hulpan, setHulpan] = useState(null);
  const [aadhar, setAadhar] = useState(null);
  const [aadharVerified, setAadharVerified] = useState(false);
  const [investmentd, setInvestmentd] = useState(null);
  const [addressd, setAddressd] = useState(null);
  const [bankaccountd, setBankaccountd] = useState(null);
  const [aadharGeneratedOTP, setAadharGeneratedOTP] = useState(false);
  const [aadharTransactionID, setAadharTransactionID] = useState(null);
  const [aadharCode, setAadharCode] = useState(null);
  const [txt, setTxt] = useState('');
  const [txtl, setTxtl] = useState('');
  
  const [pan, setPAN] = useState(null);
  const [panVerified, setPANVerified] = useState(false);
  const [bankacname, setBankacname] = useState(null);
  const [bankname, setBankname] = useState(null);
  const [bankAccount, setBankAccount] = useState(null);
  const [bankAccountVerified, setBankAccountVerified] = useState(false);
  const [valued, setValued] = React.useState(null);
  const [valuesid, setValuesid] = React.useState(null);
  const [valuend, setValuend] = React.useState(null);
  const [valuedoi, setValuedoi] = React.useState(null);
  const [ifsc, setIFSC] = useState(null);
  const [files, setFiles] = useState([]);
  const resitype = [
    {
      value: 'Indian Resident',
      label: 'Indian Resident',
    },
    {
      value: 'NRI',
      label: 'NRI',
    },
  ];
  const bactype = [
    {
      value: 'Savings Account',
      label: 'Savings Account',
    },
    {
      value: 'Current Account',
      label: 'Current Account',
    },
  ];
  const invstype = [
    {
      value: 'Joint Account',
      label: 'Joint Account',
    },
    {
      value: 'Individual',
      label: 'Individual',
    },
    {
      value: 'HUF',
      label: 'HUF',
    },
    {
      value: 'Partnership',
      label: 'Partnership',
    },
    {
      value: 'Private Limited',
      label: 'Private Limited',
    },

    {
      value: 'LLP',
      label: 'LLP',
    },


  ];
  const [bact, setbact] = React.useState('');
  const [resit, setresit] = React.useState('');
  const [siresit, setsiresit] = React.useState('');
  const [invst, setinvst] = React.useState('');
  const [userrolee, setUserrolee] = React.useState('');

  const handlePChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    
  };

  const handlecChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowcPassword = () => {
    setValues({
      ...values,
      showcPassword: !values.showcPassword,
    });
  };

  const handleMouseDowncPassword = (event) => {
    event.preventDefault();
  };



  const handleChange = (event) => {
    setresit(event.target.value);
    setinvst(event.target.value);
    setbact(event.target.value);
  };
  const submitHandlersByType = async ({ email, pass, first_name, last_name, phone, cpId }) => {
    const formattedPhone = `+91${phone.replace("+91", "")}`;
    const user = await CreateUserAdmin(email, pass, first_name, last_name, formattedPhone, "user"); //change to user
    if (cpId !== undefined || cpId !== null) {
    const leadsdata = {
      email: email,
      first_name: first_name,
      last_name: last_name,
      phone: phone,
      cpId: cpId
    };
    addLeads(leadsdata);
  }
    if (user?.data?.errorInfo) {
      setFormAlert({
        type: "error",
        message: user?.data?.errorInfo?.message,
      });
      setPending(false);
    } else {
      const data25 = {
        'email': email,
        'password': pass,
        'name': first_name,
        'templateid' : 'd-8196b372b2e141a49cbf39c039a5b132'
    };
    const data26 = {
      'email': email,
      'password': pass,
      'name': first_name,
      'templateid' : 'd-3ddf0bb447244aa59e29f4eabb94dc65'
  };
  const data27 = {
    'email': 'enquiry@gayncapital.com',
    'uemail': email,
    'phone': formattedPhone,
    'name': first_name,
    'templateid' : 'd-747db5af3ef7421089944e57f5340c6d'
};
      genericEmail(data27);
      genericEmail(data25);
      genericEmail(data26);
      setFormAlert({
        type: "success",
        message: `User ${user.data.email} created successfully, verifying online KYC..`,
      });
      setPending(false);
      history.push('/admin/users');
    }
  };

  const fetchKYCFiles = async () => {
    const respFiles = await getAllFiles(uid);

    const allFiles = [];
    for (let index = 0; index < respFiles.items.length; index++) {
        const element = respFiles.items[index];
        allFiles.push({
            bucket: element.bucket,
            name: element.name,
            fullPath: element.fullPath,
            url: await getFileUrl(element.fullPath)
        });
    }

    setFiles(allFiles);
    console.table(allFiles);
}
  useEffect(() => {
    fetchKYCFiles();
    populateUser();
  }, [])

  const getDatePickerFormat = (date) => {
    const val = new Date(date);
    return { year: val.getFullYear(), month: val.getMonth() + 1, day: val.getDate() };
  }

  const populateUser = async () => {
    if (userId !== null && userId !== undefined) {
      const user = await getUser(uid);
      setUserd(user);
      const investment = await getInvestments(uid);
      setInvestmentd(investment);
      const address = await getAddress(uid);
      setAddressd(address);
      const bank = await getBankAccount(uid);
      setBankaccountd(bank);
      setAadhar(user?.aadhaar_number);
      setAadharVerified(user.aadhaar_online_verified);
      setPAN(user.pan_number);
      setPANVerified(user.pan_online_verified);
      setBankAccount(user.bankaccount_number);
      setIFSC(user.ifsc);
      setBankAccountVerified(user.bank_account_online_verified);
      setUserrolee(user.role)
      setresit(investment.residentialType);
      console.log('from db', investment.date_of_birth);
      setValued(new Date(1970, 0, 1).setSeconds(investment.date_of_birth.seconds));
      setValuend(new Date(1970, 0, 1).setSeconds(investment.nominee.date_of_birth.seconds));
      setbact(bank.account_type);
      let investFields = {};
      setinvst(investment.investment_type);
      if (investment.investment_type == "Joint Account") {
        setValuesid(new Date(1970, 0, 1).setSeconds(investment.secondaryInvestor.date_of_birth.seconds));
        setresit(investment.secondaryInvestor.residentialType)
        investFields = {
          sifirst_name: investment.secondaryInvestor.first_name,
          silast_name: investment.secondaryInvestor.last_name,
          siemail: investment.secondaryInvestor.email,
          siphone: investment.secondaryInvestor.phone,
          sifathername: investment.secondaryInvestor.fatherName,
          sioccupation: investment.secondaryInvestor.occupation,
          siPANnumber: investment.secondaryInvestor.pan_number,
          siaddress1: investment.secondaryInvestor.address1,
          siaddress2: investment.secondaryInvestor.address2,
          sicity: investment.secondaryInvestor.city,
          sipin: investment.secondaryInvestor.pin,
          sicountry: investment.secondaryInvestor.country,
          sistate: investment.secondaryInvestor.state,
          silandmark: investment.secondaryInvestor.landmark,
        };
      } else if (investment.investment_type == "HUF") {
        investFields = {
          hufpan: investment.hufPAN
        }
      } else if (investment.investment_type === "LLP" || investment.investment_type === "Partnership" || investment.investment_type === "Private Limited") {
        setValuedoi(new Date(1970, 0, 1).setSeconds(investment.company.date_of_incorporation.seconds));
        investFields = {
          companyname: investment.company.company_name,
          companyPAN: investment.company.company_pan,
        }
      }


      reset({
        fathername: investment.fatherName,
        occupation: investment.occupation,
        address1: address.line1,
        address2: address.line2,
        city: address.city,
        country: address.country,
        state: address.state,
        pin: address.pin,
        landmark: address.landmark,
        bankAccount: bank.account_number,
        bankname: bank.bank_name,
        ifsc: bank.ifsc,
        accountname: bank.account_name,
        nfirst_name: investment.nominee.first_name,
        nlast_name: investment.nominee.last_name,
        nemail: investment.nominee.email,
        nphone: investment.nominee.phone,
        nrelationship: investment.nominee.relationship,
        naddress: investment.nominee.address,
        ...investFields
      });
    }
  }
  // Handle form submission
  const onSubmit = async(formData) => {
    // Show pending indicator
    console.log('user_details', userd)
    setPending(true);
    if (uid == null) {
      const cpdetails = await getCPbyemail(formData.cpemail);
      submitHandlersByType({
        email: formData.email,
        pass: formData.pass,
        first_name: formData.first_name,
        last_name: formData.last_name,
        phone: formData.phone,
        cpId: cpdetails[0]?.id
      }).catch((error) => {
        setPending(false);
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
    } else {
      saveUserInformation(formData);
    }
  };

  const saveUserInformation = async (formData) => {
    try {
      debugger;
      const personalDetails = {
        residentialType: resit,
        fatherName: formData.fathername,
        occupation: formData.occupation,
        date_of_birth: valued,
        investment_type: invst,
        email: userd?.email,
       phone: userd?.phone? userd.phone: userd.phone_number,
       first_name: userd?.first_name,
       last_name: userd?.last_name
      };
      await updateInvestments(uid, personalDetails);
      const personalDetails1 = {
        kyc_status: "submitted"
      };
      await updateUser(uid, personalDetails1);
      const address = {
        uid: uid,
        line1: formData.address1,
        line2: formData.address2,
        city: formData.city,
        country: formData.country,
        state: formData.state,
        pin: formData.pin,
        landmark: formData.landmark
      }

      await addOrUpdateAddress(address);



      const nominee = {
        first_name: formData.nfirst_name,
        last_name: formData.nlast_name,
        email: formData.nemail,
        phone: formData.nphone,
        relationship: formData.nrelationship,
        address: formData.naddress,
        date_of_birth: valuend
      }

      await updateInvestments(uid, { nominee: nominee });

      if (invst == "Joint Account") {
        const jointAccount = {
          first_name: formData.sifirst_name,
          last_name: formData.silast_name,
          email: formData.siemail,
          phone: formData.siphone,
          residentialType: siresit,
          fatherName: formData.sifathername,
          occupation: formData.sioccupation,
          date_of_birth: valuesid,
          pan_number: formData.siPANnumber,
          aadhaar_number: formData.siaadhaarnumber,
          address1: formData.siaddress1,
          address2: formData.siaddress2,
          city: formData.sicity,
          pin: formData.sipin,
          country: formData.sicountry,
          state: formData.sistate,
          landmark: formData.silandmark
        };
        await updateInvestments(uid, { investment_type: invst, secondaryInvestor: jointAccount });
      } else if (invst == "HUF") {
        await updateInvestments(uid, { investment_type: invst, hufPAN: formData.hufpan });
      } else if (invst === "LLP" || invst === "Partnership" || invst === "Private Limited") {
        const company = {
          company_name: formData.companyname,
          date_of_incorporation: valuedoi,
          company_pan: formData.companyPAN,
        }
        await updateInvestments(uid, { investment_type: invst, company: company });
      }
      const data27 = {
        'email': 'support@gayncapital.com',
        'uemail': userd.email,
        'phone': userd.phone_number,
        'name': userd.first_name + ' ' + userd.last_name,
        'templateid' : 'd-152d16bde5cb4a77a89642ff557178d1'
    };
    genericEmail(data27);
      setFormAlert({
        type: "success",
        message: "Information Saved successfully!!",
      });
    } catch (error) {
      setFormAlert({
        type: "error",
        message: error,
      });
    }
    setPending(false);
  }
  const aadharcallbackFunction = (fileName) => { 
    console.log("fileUploadCompleteCallback", fileName); 
    toast.success("Aadhaar Uploaded Successfully", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
    
}
  const getFileExtension = (fileName) => String(fileName?.split('.').slice(-1)).toLowerCase();

  const uploadAadhar = () => {
    if ( aadharFile !== null) {
      uploadFileToBucket(uid,aadharcallbackFunction, aadharFile, `Aadhar`);
    setFormAlert({
      type: "success",
      message: "Aadhar document uploaded!!",
    });} else {  toast.error("Please Upload aadhar", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
}
  }
  const pancallbackFunction = (fileName) => { 
    toast.success("PAN Uploaded Successfully", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }

  const uploadPan = () => {
    if ( aadharFile !== null) {
      uploadFileToBucket(uid, pancallbackFunction, panFile, `PAN`);
    setFormAlert({
      type: "success",
      message: "PAN document uploaded!!",
    });} else {  toast.error("Please Upload PAN", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
}
  }
  const bankcallbackFunction = (fileName) => { 
    toast.success("Bank Document Uploaded Successfully", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }
  const uploadBankAccount = () => {
    if(bankAccountFile !== null){
    uploadFileToBucket(uid, bankcallbackFunction, bankAccountFile, `Bank Document`);
    setFormAlert({
      type: "success",
      message: "Bank Account document uploaded!!",
    });} else{
      toast.error("Please upload the bank document", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

  }
}

  const uploadCIN = () => {
    uploadFileToBucket(uid, certificateofincorp, `certificateofincorp.${getFileExtension(bankAccountFile.name)}`);
    setFormAlert({
      type: "success",
      message: "Bank Account document uploaded!!",
    });
  }

  const uploadGST = () => {
    uploadFileToBucket(uid, gstcerti, `gstcerti.${getFileExtension(bankAccountFile.name)}`);
    setFormAlert({
      type: "success",
      message: "Bank Account document uploaded!!",
    });
  }

  const uploadCPAN = () => {
    uploadFileToBucket(uid, companypan, `companypan.${getFileExtension(bankAccountFile.name)}`);
    setFormAlert({
      type: "success",
      message: "Bank Account document uploaded!!",
    });
  }

  const uploadBoardres = () => {
    uploadFileToBucket(uid, boardresolution, `boardresolution.${getFileExtension(bankAccountFile.name)}`);
    setFormAlert({
      type: "success",
      message: "Bank Account document uploaded!!",
    });
  }

  const uploadHUFPAN = () => {
    uploadFileToBucket(uid, hulpan, `hulpan.${getFileExtension(bankAccountFile.name)}`);
    setFormAlert({
      type: "success",
      message: "Bank Account document uploaded!!",
    });
  }



  const generateAadharOTP = async () => {
    const info = await generateKYCAadharOTP(uid, 'user', aadhar);
    setAadharTransactionID(info.data.data.transaction_id)
    setAadharCode(info.data.data.code);
    setAadharGeneratedOTP(true);
    setFormAlert({
      type: "info",
      message: info.data.data.message,
    });
  }

  const handleAadharOTPModalClose = (info) => {
    setAadharGeneratedOTP(false);
    if (info != null && info != undefined) {
      setAadharVerified(info.data.isSuccess);
      setFormAlert({
        type: info.data.isSuccess ? "success" : "error",
        message: info.data.message
      });
    }
  }

  const verifyAadhar = async () => {
    const info = await verifyKYCAadharAdmin(uid, 'user', aadhar);
    setAadharVerified(info.data.isSuccess);
    setFormAlert({
      type: info.data.isSuccess ? "success" : "error",
      message: info.data.message
    });
  }

  const verifyPAN = async () => {
    const info = await verifyKYCPAN(uid, 'user', pan, '', "");
    setPANVerified(info.data.isSuccess);
    setFormAlert({
      type: info.data.isSuccess ? "success" : "error",
      message: info.data.message
    });

  }
  const CompleteKYC = async () => {

    try {
      if(userd?.kyc_status !== "submitted"){
        toast.error("Please fill KYC details", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }else if (!aadharVerified){
        toast.error("Please Verify the aadhaar", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }else if (!panVerified){
        toast.error("Please Verify the PAN", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      } else if (files === null){
        toast.error("Please Upload the documents", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      } else {
        const personalDetails = {
          aadhaar_online_verified: true,
          pan_online_verified: true,
          bank_account_online_verified: true,
          aadhaar_offline_verified: true,
          pan_offline_verified: true,
          bank_account_offline_verified: true,
          kyc_status: "Completed"
        };
        await updateUser(uid, personalDetails);
        await updateLeadstatus(uid, "Completed");
        const data28 = {
          'email': 'enquiry@gayncapital.com',
          'uemail': userd.email,
          'phone': userd.phone_number,
          'name': userd.first_name + ' ' + userd.last_name,
          'templateid' : 'd-ecb5d206f6e2497287affefe9a82460f'
      };
      genericEmail(data28);
      const data27 = {
        'email': userd.email,
        'phone': userd.phone_number,
        'name': userd.first_name + ' ' + userd.last_name,
        'templateid' : 'd-10dd5060e1284d9c98656bad092e2091'
    };
    genericEmail(data27);
        
        toast.success("KYC verified successfully", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
  
      }
    }catch (error) {

    }

  }

  const verifyBankAccount = async () => {
    const info = await verifyKYCBank(bankAccount, ifsc);
    const backAccount = {
      uid: uid,
      account_number: bankAccount,
      bank_name: bankname,
      ifsc: ifsc,
      account_name: bankacname,
      account_type: bact
    };
    await addOrUpdateBankAccount(backAccount);
    setBankAccountVerified(info.data.isSuccess);
    setFormAlert({
      type: info.data.isSuccess ? "success" : "error",
      message: info.data.message
    });
  }

  return (
    <>
<ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
<ToastContainer />
      {userId == null && <AdminNavbar
        color="default"
        logo="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-black.png?alt=media&token=4df2ee2a-8696-48b5-8a65-98171d8179d9"
        logoInverted="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096"
      />}
      {formAlert && (
        <Box mb={3}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}
      {
        uid == null && <Grid container spacing={3}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <h1>Create User</h1>
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={6}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="First Name"
                    name="first_name"
                    placeholder="First Name"
                   
                    onInput = {(e) =>{
                      e.target.value = e.target.value.replace(/\s{2,}/g, ' ').replace(/[^A-Za-z ]/g, '');
                  }}
                    error={errors.email ? true : false}
                    helperText={errors.email && errors.email.message}
                    fullWidth={true}
                    InputLabelProps={{ shrink: true }}
                    inputRef={register({
                      required: "Please enter your first name",
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    variant="outlined"
                    type="last_name"
                    label="Last name"
                    name="last_name"
                    placeholder="Last Name"
                    
                                   onInput = {(e) =>{
                  e.target.value = e.target.value.replace(/\s{2,}/g, ' ').replace(/[^A-Za-z ]/g, '');
              }}    
                    error={errors.email ? true : false}
                    helperText={errors.email && errors.email.message}
                    fullWidth={true}
                    InputLabelProps={{ shrink: true }}
                    inputRef={register({
                      required: "Please enter your last name",
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    variant="outlined"
                    type="email"
                    label="Email"
                    name="email"
                    placeholder="user@example.com"
                    error={errors.email ? true : false}
                    helperText={errors.email && errors.email.message}
                    fullWidth={true}
                    InputLabelProps={{ shrink: true }}
                    inputRef={register({
                      required: "Please enter your email",
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    variant="outlined"
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    minLength={8}
                    onChange={handlePChange('password')}
                    label="Password"
                    name="pass"
                    error={errors.pass ? true : false}
                    helperText={errors.pass && errors.pass.message}
                    fullWidth={true}
                  
                    inputRef={register({
                      required: "Please enter a password",
                      validate: (value) => {
                        if (value.length >= 8) {
                          return true;
                        } else {
                          return "Minimum 8 character required";
                        }
                      },
                    })}
                    InputProps={{ // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                      )
                      
                    }}
                  />
                    </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Confirm Password"
                    name="confirmPass"
                    type={values.showcPassword ? 'text' : 'password'}
                  value={values.cpassword}
                  onChange={handlecChange('cpassword')}
                    error={errors.confirmPass ? true : false}
                    helperText={errors.confirmPass && errors.confirmPass.message}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter your password again",
                      validate: (value) => {
                        if (value === getValues().pass) {
                          return true;
                        } else {
                          return "This doesn't match your password";
                        }
                      },
                    })}
                    InputProps={{ // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowcPassword}
                      onMouseDown={handleMouseDowncPassword}
                    >
                      {values.showcPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                      )
                    }}
                  />
                    </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    variant="outlined"
                    type="tel"
                    label="Phone"
                    name="phone"
                    maxLength={10}
                    placeholder="99xxxxxx99"
                    error={errors.phone ? true : false}
                    helperText={errors.phone && errors.phone.message}
                    fullWidth={true}
                    InputProps={{maxLength: 10,
                      startAdornment: <InputAdornment position="start">+91</InputAdornment>
                    }}
                    onInput = {(e) =>{
                      // e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                      e.target.value =  e.target.value.replace(/[^0-9]/g, '').slice(0,10);
                  }}
                    inputRef={register({
                      required: "Please enter your Phone number",
                      validate: (value) => {
                        if (value.length >= 10) {
                          return true;
                        } else {
                          return "Please enter a valid phone number";
                        }
                      },
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    variant="outlined"
                    type="email"
                    label="CP Email"
                    name="cpemail"
                    placeholder="user@example.com"
                    error={errors.email ? true : false}
                    helperText={errors.email && errors.email.message}
                    fullWidth={true}
                    InputLabelProps={{ shrink: true }}
                    inputRef={register}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <Button
                    id="sign-in-button"
                    className="sign-in-button"
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={pending}
                    fullWidth={true}
                  >
                    {!pending && <span>Submit</span>}

                    {pending && <CircularProgress size={28} />}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={3} />
        </Grid>}
      {uid != null &&
        <>
        <Button variant="medium" href={"/admin/users"} style={{color:"red", display:"flex", marginLeft:"0%", justifyContent:"flex-start"}} startIcon={<CloseIcon fontSize="33" />}>Close
      </Button>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h1>Personal Details</h1>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  select
                  label="Residential Type"
                  type="residentialtype"
                  name="residentialtype"
                  value={resit}
                  fullWidth={true}
                  disabled={userd?.kyc_status === "Completed"}
                  onChange={(e) => setresit(e.target.value)}
                >
                  {resitype.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="fathername"
                  label="Father's Name"
                  name="fathername"
                  placeholder="Father's Name"
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  value={investmentd?.fatherName}
                  disabled={userd?.kyc_status === "Completed"}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your Father's Name",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="occupation"
                  label="Occupation"
                  name="occupation"
                  placeholder="Occupation"
                  value={investmentd?.occupation}
                  disabled={userd?.kyc_status === "Completed"}
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your Occupation",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableFuture
                    label="Date Of Birth"
                    name="date_of_birth"
                    openTo="year"
                    views={['year', 'month', 'day']}
                    value={valued}
                    disabled={userd?.kyc_status === "Completed"}
                    sx={{ width: '100%' }}
                    onChange={(newValue) => {
                      console.log('date_of_birth', newValue);
                      setValued(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item={true} xs={12}><h2>Address</h2></Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Address Line 1"
                  name="address1"
                  placeholder="Address Line 1"
                  defaultValue={addressd?.line1}
                  disabled={userd?.kyc_status === "Completed"}
                  error={errors.address1 ? true : false}
                  helperText={errors.address1 && errors.address1.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your Address Line 1",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Address Line 2"
                  name="address2"
                  placeholder="Address Line 2"
                  value={addressd?.line2}
                  disabled={userd?.kyc_status === "Completed"}
                  error={errors.address2 ? true : false}
                  helperText={errors.address2 && errors.address2.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your Address Line 2",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="city"
                  label="City"
                  name="city"
                  placeholder="City"
                  value={addressd?.city}
                  disabled={userd?.kyc_status === "Completed"}
                  error={errors.email ? true : false}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({ required: "Please enter your City", })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="pin"
                  label="PIN"
                  name="pin"
                  onInput = {(e) =>{
                    e.target.value =  e.target.value.replace(/[^0-9]/g, '').slice(0,6);
                }}
                  maxLength={6}
                  placeholder="PIN"
                  value={addressd?.pin}
                  disabled={userd?.kyc_status === "Completed"}
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your PIN Code",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="country"
                  label="Country"
                  name="country"
                  placeholder="Country"
                  value={addressd?.country}
                  disabled={userd?.kyc_status === "Completed"}
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your last name",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="state"
                  label="State"
                  name="state"
                  value={addressd?.state}
                  disabled={userd?.kyc_status === "Completed"}

                  placeholder="State"
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your State",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="landmark"
                  label="Landmark"
                  name="landmark"
                  value={addressd?.landmark}
                  disabled={userd?.kyc_status === "Completed"}

                  placeholder="Landmark"
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your Landmark",
                  })}
                />
              </Grid>

              <Grid item={true} xs={12}><h2>Nominee</h2></Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="nfirst_name"
                  label="First Name"
                  value={investmentd?.nominee?.first_name}
                  disabled={userd?.kyc_status === "Completed"}

                  name="nfirst_name"
                  placeholder="First Name"
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your first name",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="nlast_name"
                  label="Last name"
                  name="nlast_name"
                  placeholder="Last Name"
                  value={investmentd?.nominee?.last_name}
                  disabled={userd?.kyc_status === "Completed"}

                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your last name",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="nemail"
                  label="Email"
                  name="nemail"
                  value={investmentd?.nominee?.email}
                  disabled={userd?.kyc_status === "Completed"}

                  placeholder="user@example.com"
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your email",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="nphone"
                  label="Phone"
                  name="nphone"
                  value={investmentd?.nominee?.phone}
                  disabled={userd?.kyc_status === "Completed"}

                  placeholder="99xxxxxx99"
                  error={errors.phone ? true : false}
                  helperText={errors.phone && errors.phone.message}
                  fullWidth={true}
                  InputProps={{maxLength: 10,
                    startAdornment: <InputAdornment position="start">+91</InputAdornment>
                  }}
                  onInput = {(e) =>{
                    e.target.value =  e.target.value.replace(/[^0-9]/g, '').slice(0,10);
                }}
                  inputRef={register({
                    required: "Please enter your Phone number",
                    validate: (value) => {
                      if (value.length >= 10) {
                        return true;
                      } else {
                        return "Please enter a valid phone number";
                      }
                    },
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Relationship"
                  name="nrelationship"
                  placeholder="Relationship"
                  value={investmentd?.nominee?.relationship}
                  disabled={userd?.kyc_status === "Completed"}

                  error={errors.email ? true : false}
                  helperText={errors.nrelationship && errors.nrelationship.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your Relationship",
                  })}
                />
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disableFuture
                  label="Date Of Birth"
                  name="ndate_of_birth"
                  openTo="year"
                  views={['year', 'month', 'day']}
                  value={valuend}
                  sx={{ width: '100%' }}
                  onChange={(newValue) => {
                    setValuend(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <Grid item={true} xs={12}>
                <TextField
                  variant="outlined"
                  type="naddress"
                  label="Address"
                  name="naddress"
                  placeholder="Address"
                  value={investmentd?.nominee?.address}
                  disabled={userd?.kyc_status === "Completed"}

                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter nominee address",
                  })}
                />
              </Grid>
              { userrolee === 'user'  && <>
              <Grid item={true} xs={12}><h2>Account Type</h2></Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  select
                  fullWidth={true}
                  label="Type"
                  type="investmenttype"
                  name="investmenttype"
                  value={invst}
                  onChange={(e) => setinvst(e.target.value)}
                  helperText="Please select your Investment type"
                >
                  {invstype.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid> </>}
              {(invst === "LLP" || invst === "Partnership" || invst === "Private Limited") &&
                <>
                  <Grid item={true} xs={12}><h2>Company details</h2></Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="companyname"
                      label="Company Name"
                      name="companyname"
                      placeholder="Company Name"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your company name",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disableFuture
                        label="Date Of Incorporation"
                        openTo="year"
                        name="date_of_incorporation"
                        views={['year', 'month', 'day']}
                        value={valuedoi}
                        sx={{ width: '100%' }}
                        onChange={(newValue) => {
                          setValuedoi(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="companyPAN"
                      label="Company PAN"
                      name="companyPAN"
                      placeholder="Company PAN"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your Company PAN",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={12}><h2>Company Address</h2></Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="caddress1"
                      label="Address Line 1"
                      name="caddress1"
                      placeholder="Address Line 1"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your Address Line 1",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="caddress2"
                      label="Address Line 2"
                      name="caddress2"
                      placeholder="Address Line 2"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your Address Line 2",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="ccity"
                      label="City"
                      name="ccity"
                      placeholder="City"
                      error={errors.email ? true : false}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your City",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="cpin"
                      label="PIN"
                      name="cpin"
                      placeholder="PIN"
                      onInput = {(e) =>{
                        e.target.value =  e.target.value.replace(/[^0-9]/g, '').slice(0,6);
                    }}
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your PIN Code",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="ccountry"
                      label="Country"
                      name="clast_name"
                      placeholder="Last Name"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your last name",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="cstate"
                      label="State"
                      name="cstate"
                      placeholder="State"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your State",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="clandmark"
                      label="Landmark"
                      name="clandmark"
                      placeholder="Landmark"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your Landmark",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={12}><h2>Company Documents</h2></Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={3}>
                    <label>Certification of Incorporation / Partnership Deed </label>
                    <input type="file" onChange={(e) => { setCertificateofincorp(e.target.files[0]) }} />

                  </Grid>
                  <Grid item xs={3}>
                    <Button variant="contained"
                    disabled={userd?.kyc_status === "Completed"}
                      color="primary"
                      size="large"
                      type="button"
                      onClick={uploadCIN}>Upload document</Button>
                  </Grid>
                  <Grid item xs={3} />
                  <Grid item xs={12}></Grid>
                  <Grid item xs={3}>
                    <label>Cheque Leaf </label>
                    <input type="file" onChange={(e) => { setBankAccountFile(e.target.files[0]) }} />

                  </Grid>
                  <Grid item xs={3}>
                    <Button variant="contained"
                      color="primary"
                      size="large"
                      disabled={userd?.kyc_status === "Completed"}
                      type="button"
                      onClick={uploadBankAccount}>Upload Cheque Leaf</Button>
                  </Grid>
                  <Grid item xs={3} />
                  <Grid item xs={12}></Grid>
                  <Grid item xs={3}>
                    <label>Cheque Leaf </label>
                    <input type="file"  onChange={(e) => { setBankAccountFile(e.target.files[0]) }} />

                  </Grid>
                  <Grid item xs={3}>
                    <Button variant="contained"
                      color="primary"
                      size="large"
                      disabled={userd?.kyc_status === "Completed"}
                      type="button"
                      onClick={uploadBankAccount}>Upload Cheque Leaf</Button>
                  </Grid>
                  <Grid item xs={3} />
                  <Grid item xs={12}></Grid>
                  <Grid item xs={3}>
                    <label>Cheque Leaf </label>
                    <input type="file" onChange={(e) => { setBankAccountFile(e.target.files[0]) }} />

                  </Grid>
                  <Grid item xs={3}>
                    <Button variant="contained"
                      color="primary"
                      size="large"
                      disabled={userd?.kyc_status === "Completed"}
                      type="button"
                      onClick={uploadBankAccount}>Upload Cheque Leaf</Button>
                  </Grid>
                  <Grid item xs={3} />

                </>
              }
              {invst === "HUF" &&
                <>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="HUFPAN"
                      label="HUF PAN"
                      name="hufpan"
                      placeholder="HUF PAN"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your HUF PAN",
                      })}
                    />
                  </Grid>
                </>
              }
              {invst == 'Joint Account' &&
                <>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="sifirst_name"
                      label="First Name"
                      name="sifirst_name"
                      placeholder="First Name"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your first name",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="silast_name"
                      label="Last name"
                      name="silast_name"
                      placeholder="Last Name"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your last name",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="siemail"
                      label="Email"
                      name="siemail"
                      placeholder="user@example.com"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your email",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="siphone"
                      label="Phone"
                      name="siphone"
                      placeholder="99xxxxxx99"
                      error={errors.phone ? true : false}
                      helperText={errors.phone && errors.phone.message}
                      fullWidth={true}
                      InputProps={{maxLength: 10,
                        startAdornment: <InputAdornment position="start">+91</InputAdornment>
                      }}
                      onInput = {(e) =>{
                        e.target.value =  e.target.value.replace(/[^0-9]/g, '').slice(0,10);
                    }}
                      inputRef={register({
                        required: "Please enter your Phone number",
                        validate: (value) => {
                          if (value.length >= 10) {
                            return true;
                          } else {
                            return "Please enter a valid phone number";
                          }
                        },
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      select
                      label="Residential Type"
                      type="siresidentialtype"
                      name="siresidentialtype"
                      value={siresit}
                      fullWidth={true}
                      onChange={(e) => setsiresit(e.target.value)}
                    >
                      {resitype.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="fathername"
                      label="Father's Name"
                      name="sifathername"
                      placeholder="Father's Name"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your Father's Name",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="sioccupation"
                      label="Occupation"
                      name="sioccupation"
                      placeholder="Occupation"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your Occupation",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Basic example"
                      value={valued}
                      onChange={(newValue) => {
                        setValued(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider> */}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disableFuture
                        label="Date Of Birth"
                        name="sidate_of_brith"
                        openTo="year"
                        views={['year', 'month', 'day']}
                        value={valuesid}
                        sx={{ width: '100%' }}
                        onChange={(newValue) => {
                          setValuesid(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="siPANnumber"
                      label="PAN Number"
                      name="siPANnumber"
                      placeholder="PAN Number"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your PAN Number",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="siaadhaarnumber"
                      label="Aadhaar Number"
                      name="siaadhaarnumber"
                      placeholder="Aadhaar Number"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your Aadhaar Number",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="siaddress1"
                      label="Address Line 1"
                      name="siaddress1"
                      placeholder="Address Line 1"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your Address Line 1",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="siaddress2"
                      label="Address Line 2"
                      name="siaddress2"
                      placeholder="Address Line 2"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your Address Line 2",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="sicity"
                      label="City"
                      name="sicity"
                      placeholder="City"
                      error={errors.email ? true : false}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your City",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="sipin"
                      label="PIN"
                      name="sipin"
                      placeholder="PIN"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your PIN Code",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="sicountry"
                      label="Country"
                      name="sicountry"
                      placeholder="Last Name"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your last name",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="sistate"
                      label="State"
                      name="sistate"
                      placeholder="State"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your State",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      variant="outlined"
                      type="silandmark"
                      label="Landmark"
                      name="silandmark"
                      placeholder="Landmark"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your Landmark",
                      })}
                    />
                  </Grid>


                </>
              }

            </Grid>
            <Grid container={true} spacing={2}>
              {/* <Grid item={true} xs={12}><h2>Bank Details</h2></Grid>

              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  placeholder="Bank Account Number"
                  // label="Bank Account Number"
                  name="bankAccount"
                  value={bankAccount}
                  fullWidth={true}
                  onChange={(e) => setBankAccount(e.target.value)}
                  onBlur={(e) => setBankAccount(e.target.value)}
                  disabled={bankAccountVerified}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your Bank Account number",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  placeholder="Account Name"
                  // label="Bank Account Number"
                  name="accountname"
                  value={bankacname}
                  fullWidth={true}
                  onChange={(e) => setBankacname(e.target.value)}
                  onBlur={(e) => setBankacname(e.target.value)}
                  disabled={bankAccountVerified}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your Bank Account Name",
                  })}
                />
              </Grid>



              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  placeholder="IFSC"
                  // label="IFSC"
                  name="ifsc"
                  value={ifsc}
                  fullWidth={true}
                  onChange={(e) => setIFSC(e.target.value)}
                  onBlur={(e) => setIFSC(e.target.value)}
                  disabled={bankAccountVerified}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your IFSC",
                  })}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  placeholder="Bank Name"
                  name="bankname"
                  value={bankname}
                  fullWidth={true}
                  onChange={(e) => setBankname(e.target.value)}
                  onBlur={(e) => setBankname(e.target.value)}
                  disabled={bankAccountVerified}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your Bank Account number",
                  })}
                />
              </Grid>

              <Grid item={true} xs={6}>
                <TextField
                  variant="outlined"
                  select
                  label="Account Type"
                  type="accounttype"
                  name="accounttype"
                  value={bact}
                  fullWidth={true}
                  onChange={(e) => setbact(e.target.value)}
                >
                  {bactype.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item={true} xs={4}>
                <Button
                  variant="contained"
                  
                  color="primary"
                  size="large"
                  type="button"
                  fullWidth={true}
                  onClick={verifyBankAccount}
                  disabled={bankAccountVerified}
                >
                  {pending && <CircularProgress size={28} />}
                  {bankAccountVerified ? 'Bank Account Verified' : 'Verify Account'}
                </Button>

              </Grid> */}

              <Grid item xs={12}></Grid>
            
                <Grid item={true} xs={6}>
                  <Button
                    id="save-button"
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={pending || userd?.kyc_status === "Completed"}
                    fullWidth={true}
                  >
                    {!pending && <span>Save</span>}
                    {pending && <CircularProgress size={28} />}
                  </Button>
                </Grid>
                <Grid item={true} xs={6}>
                  <Button
                    id="save-button"
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => CompleteKYC()}
                    disabled={userd?.kyc_status === "Completed"}
                    fullWidth={true}
                  >
                    {!pending && <span>Complete KYC</span>}
                    {pending && <CircularProgress size={28} />}
                  </Button>
                </Grid>
              
              <Grid item xs={3} />

            </Grid>
          </form>

          <Box style={{ paddingTop: '45px' }} >
          <h1>Proof verification section</h1>
              <Grid container spacing={3} style={{alignItems: 'center'}}>
                
                <Grid item={true} xs={3}>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Aadhar Number"
                    name="aadhar"
                    value={aadhar}
                    fullWidth={true}
                    onChange={(e) => setAadhar(e.target.value)}
                    onBlur={(e) => setAadhar(e.target.value)}
                    disabled={aadharVerified}
                  />
                </Grid>
                <Grid item={true} xs={3}  >
                  <Button
                 
                    variant="contained"
                    color="primary"
                    size="large"
                    type="button"
                    fullWidth={true}
                    onClick={verifyAadhar}
                    disabled={aadharVerified}
                  >
                    {pending && <CircularProgress size={28} />}
                    {aadharVerified ? 'Aadhar Verified' : 'Verify Aadhar'}
                  </Button>
                </Grid>

                <Grid item={true} xs={3}>
                  <TextField
                    variant="outlined"
                    type="text"
                    // label="Pan Number"
                    placeholder="PAN number"
                    name="pan"
                    value={pan}
                    fullWidth={true}
                    disabled={panVerified}
                    onChange={(e) => setPAN(e.target.value)}
                    onBlur={(e) => setPAN(e.target.value)}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="button"
                    fullWidth={true}
                    onClick={verifyPAN}
                    disabled={panVerified}
                  >
                    {pending && <CircularProgress size={28} />}
                    {panVerified ? 'Pan Verified' : 'Verify Pan'}
                  </Button>

                </Grid>

                <Grid item={true} xs={12}><h2>Bank Details</h2></Grid>

<Grid item={true} xs={6}>
  <TextField
    variant="outlined"
    type="text"
    placeholder="Bank Account Number"
    // label="Bank Account Number"
    name="bankAccount"
    value={bankAccount}
    fullWidth={true}
    onChange={(e) => setBankAccount(e.target.value)}
    onBlur={(e) => setBankAccount(e.target.value)}
    disabled={bankAccountVerified}
    InputLabelProps={{ shrink: true }}
    inputRef={register({
      required: "Please enter your Bank Account number",
    })}
  />
</Grid>
<Grid item={true} xs={6}>
  <TextField
    variant="outlined"
    type="text"
    placeholder="Account Name"
    // label="Bank Account Number"
    name="accountname"
    value={bankacname}
    fullWidth={true}
    onChange={(e) => setBankacname(e.target.value)}
    onBlur={(e) => setBankacname(e.target.value)}
    disabled={bankAccountVerified}
    InputLabelProps={{ shrink: true }}
    inputRef={register({
      required: "Please enter your Bank Account Name",
    })}
  />
</Grid>



<Grid item={true} xs={6}>
  <TextField
    variant="outlined"
    type="text"
    placeholder="IFSC"
    // label="IFSC"
    name="ifsc"
    value={ifsc}
    fullWidth={true}
    onChange={(e) => setIFSC(e.target.value)}
    onBlur={(e) => setIFSC(e.target.value)}
    disabled={bankAccountVerified}
    InputLabelProps={{ shrink: true }}
    inputRef={register({
      required: "Please enter your IFSC",
    })}
  />
</Grid>
<Grid item={true} xs={6}>
  <TextField
    variant="outlined"
    type="text"
    placeholder="Bank Name"
    name="bankname"
    value={bankname}
    fullWidth={true}
    onChange={(e) => setBankname(e.target.value)}
    onBlur={(e) => setBankname(e.target.value)}
    disabled={bankAccountVerified}
    InputLabelProps={{ shrink: true }}
    inputRef={register({
      required: "Please enter your Bank Account number",
    })}
  />
</Grid>

<Grid item={true} xs={6}>
  <TextField
    variant="outlined"
    select
    label="Account Type"
    type="accounttype"
    name="accounttype"
    value={bact}
    fullWidth={true}
    onChange={(e) => setbact(e.target.value)}
  >
    {bactype.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </TextField>
</Grid>

<Grid item={true} xs={4}>
  <Button
    variant="contained"
    
    color="primary"
    size="large"
    type="button"
    fullWidth={true}
    onClick={verifyBankAccount}
    disabled={bankAccountVerified}
  >
    {pending && <CircularProgress size={28} />}
    {bankAccountVerified ? 'Bank Account Verified' : 'Verify Account'}
  </Button>

</Grid>

<Grid item xs={12}></Grid>
                <Grid item xs={2}><h2>Aadhaar</h2> </Grid>
                <Grid item xs={2}> 
                <Button
                                  variant="outlined"
                                  component="label"
                                  disabled={userd?.kyc_status === "Completed"}
                                  color="primary"
                                  size="large"
                                  style={{overflow:'hidden', marginLeft:'10%'}}
                                >
                                    <input
                                      type="file"
                                      // hidden
                                      accept=".pdf, .jpg, .jpeg, .png"
                                      style={{display:"none"}}
                                      onChange={(e) => { setAadharFile(e.target.files[0]) }} />
                                      <text style={{overflow:'hidden', width: '130px', height:'25px', display:'flex', alignItems:'center', justifyContent:'center' }}>{aadharFile?  aadharFile.name : "Choose File"}</text>
                                  </Button>
   
                                  </Grid>
                                  <Grid item xs={2}>                              
                                     <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="button"
                    disabled={userd?.kyc_status === "Completed"}
                    fullWidth={true}
                    onClick={uploadAadhar}
             
                  >
                  
                    {'Upload Aadhaar'}
                  </Button></Grid>
                <Grid item xs={2}><h2>PAN</h2> </Grid>
                <Grid item xs={2}> 
                <Button
                                  variant="outlined"
                                  component="label"
                                  color="primary"
                                  size="large"
                                  disabled={userd?.kyc_status === "Completed"}
                                  style={{overflow:'hidden', marginLeft:'10%'}}
                                  // disabled={panfVerified || pending10}

                                >
                                    <input
                                      type="file"
                                      // hidden
                                      style={{display:"none"}}
                                      onChange={(e) => { setPanFile(e.target.files[0]) }} />
                                      <text style={{overflow:'hidden', width: '130px', height:'25px', display:'flex', alignItems:'center', justifyContent:'center' }}>{panFile?  panFile.name : "Choose File"}</text>
                                  </Button>
                                  </Grid>
                                  <Grid item xs={2}>                              
                                     <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={userd?.kyc_status === "Completed"}
                    type="button"
                    fullWidth={true}
                    onClick={uploadPan}
             
                  >
                  
                    {'Upload PAN'}
                  </Button></Grid>

                  <Grid item xs={2}><h2>Cheque Leaf</h2> </Grid>
                  <Grid item xs={2}>
                  <Button
                                  variant="outlined"
                                  component="label"
                                  color="primary"
                                  disabled={userd?.kyc_status === "Completed"}
                                  size="large"
                                  style={{overflow:'hidden', marginLeft:'10%'}}
                                  // disabled={panfVerified || pending10}

                                >
                <input type="file" style={{display:"none"}} onChange={(e) => { setBankAccountFile(e.target.files[0]) }} />
                <text style={{overflow:'hidden', width: '130px', height:'25px', display:'flex', alignItems:'center', justifyContent:'center' }}>{bankAccountFile?  bankAccountFile.name : "Choose File"}</text>
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button variant="contained"
                  color="primary"
                  disabled={userd?.kyc_status === "Completed"}
                  size="large"
                  type="button"
                  onClick={uploadBankAccount}>Upload Cheque Leaf</Button>
              </Grid>
                                  </Grid>
                                  </Box>


                                  <Grid item xs={12} >
                        {files.length > 0 && <Paper sx={{ width: '100%' }}>
                            <h1>User Documents</h1>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableBody>
                                        {
                                            files.map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.name}>
                                                        <TableCell >
                                                            {row.name}{row.time_stamp?.toDateString()}
                                                        </TableCell>
                                                        <TableCell >
                                                        </TableCell>
                                                        <TableCell >
                                                            <a href={row.url} target={'_blank'}>View</a>
                                                        </TableCell>
                                                        {/* <TableCell >
                                                            <div style={{ textAlign: 'right', paddingTop: '15px', paddingRight: '30px' }}>
                                                                {
                                                                    showOfflineApproveButton(row.name?.split('.')[0]) ? <Button variant="contained"
                                                                        style={{ width: '200px' }}
                                                                        type="button"
                                                                        color="primary"
                                                                        size="large" onClick={() => updateOfflineKYC(row.name?.split('.')[0])} >Approve {row.name?.split('.')[0]}</Button> :
                                                                        "Approved"
                                                                }
                                                                                                                                {
                                                                    showOfflineApproveButton(row.name?.split('.')[0]) ? <Button variant="contained"
                                                                        style={{ width: '200px' }}
                                                                        type="button"
                                                                        color="primary"
                                                                        size="large" onClick={() => updateOfflineKYC1(row.name?.split('.')[0])} >Reject {row.name?.split('.')[0]}</Button> :
                                                                        "Rejected"
                                                                }
                                                            </div>
                                                        </TableCell> */}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Paper>}
                    </Grid>
        </>
      }
    </>
  );
}

export default requireAuth(AdminCreateUser, ["admin"]);
// export default AdminCreateUser