import { Container } from '@material-ui/core';
import Navbr from '../../components/cpnav';
import { requireAuth,  useAuth } from "../../util/auth";
import { makeStyles } from "@material-ui/core/styles";
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import React, { useState, useEffect } from 'react'
import AdminNavbar from './../../components/AdminNavbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
    getEOIForTransactionByProduct,
    getTransactionByUserAndProduct,
    getAllProducts,
    updateTransactionByProduct,
    deleteTransaction,
    markProductActive, getUser
} from '../../util/db';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from "@material-ui/core/Button";
import Grid from '@mui/material/Grid';
import { Checkbox } from '@material-ui/core';
import { Alert } from '@mui/material';
const UseStyles = makeStyles((theme) => ({

  container: {
    paddingTop:"100px",
    marginLeft:"18%",
    marginRight:"0px",
  },
}));
function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,

  ) {
    return { name, calories, fat, carbs };
  }
  
  const rows = [
    createData('Property 1', 1, 1000000, 30000),
    createData('Property 2', 1, 1500000, 45000),
    createData('Property 3', 1, 2000000, 60000),

  ];
  
export default function Myprofile() {
  const auth = useAuth();
  const userid = auth?.user?.id;
const [value, setValue] = React.useState('1');
//changes need to be done
const [selectedProduct, setSelectedProduct] = useState('dWjFy1y1XVJ3Ue8EYFBx');
const [selectedUserId, setSelectedUserId] = React.useState(userid);
const [userd, setUserd] = React.useState([]);
const [userProductTransaction, setUserProductTransaction] = React.useState([]);
const isSmall = useMediaQuery('(min-width:800px)');

const populateUser = async () => {
    const user = await getUser(selectedUserId);
    setUserd(user);
  }

useEffect(() => {
    populateUser();
  if (selectedProduct && selectedUserId) {
      fetchUserProductTransaction(selectedProduct, selectedUserId);
  }
}, [selectedUserId, selectedUserId]);
const fetchUserProductTransaction = async (productId, userId) => {
  const response = await getTransactionByUserAndProduct(productId, userId);
  setUserProductTransaction(response);
}

const classes = UseStyles();
return (
 <>
  <Navbr/>
   {/* <Container className={classes.container}> */}
   <section style={{ paddingTop:"100px",

marginLeft:isSmall? "300px":"10px",
marginRight:"5%",}}>
        
        <div style={{fontSize: 25, fontWeight:"bold",}}>My Profile</div>
    <Grid container={true} spacing={2} style={{marginLeft:isSmall? "-32px": "-12px"}}> 
                <Grid item={true} xs={6}>
                  <TextField
                    variant="outlined"
                    type="first_name"
                    InputLabelProps={{ shrink: true }} 
                    label="First Name"
                    name="first_name"
                    disabled
                    value={userd.first_name}
                    placeholder="First Name"
                    fullWidth={true}
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <TextField
                    variant="outlined"
                    type="last_name"
                    InputLabelProps={{ shrink: true }} 
                    label="Last Name"
                    name="last_name"
                    disabled
                    value={userd.last_name}
                    placeholder="Last Name"
                    fullWidth={true}
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <TextField
                    variant="outlined"
                    type="email"
                    InputLabelProps={{ shrink: true }} 
                    label="Email"
                    disabled
                    value={userd.email}
                    name="email"
                    placeholder="user@example.com"
                    fullWidth={true}
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <TextField
                    variant="outlined"
                    type="phone"
                    name="phone"
                    InputLabelProps={{ shrink: true }} 
                    label="Phone Number"
                    disabled
                    value={userd.phone_number}
                    placeholder="99xxxxxx99"
                    fullWidth={true}
                    InputProps={{
                    }}
                  />
                </Grid>
  </Grid>
  <Box >
    <br />
 

  {/* <Grid container={true} spacing={2} style={{marginLeft:"-16px"}}> 
  <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow  >
            <TableCell style={{fontWeight:'800'}}> PROPERTY NAME </TableCell>
            <TableCell align="right"style={{fontWeight:'800'}} ><p >NUMBER OF INVESTORS</p> </TableCell>
            <TableCell align="right" style={{fontWeight:'800'}}>INVESTMENT VALUE </TableCell>
            <TableCell align="right" style={{fontWeight:'800'}}>TOTAL COMMISSION</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Grid> */}
    </Box>
  {/* {selectedProduct && selectedUserId && <Box >

    <h2>Total commission</h2>
                  <TableContainer sx={{ maxHeight: 440 }}>
                      <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                              <TableRow>
                                  <TableCell>
                                     Property Name
                                  </TableCell>
                                  <TableCell>
                                      Number of investors
                                  </TableCell>
                                  <TableCell>
                                      Investment value
                                  </TableCell>
                                  <TableCell>
                                      Total commission
                                  </TableCell>
                                 
                                
                              </TableRow>
                          </TableHead>
                          <TableBody>
                          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.property_name}</TableCell>
              <TableCell align="right">{row.numberinvs}</TableCell>
              <TableCell align="right">{row.investment_value}</TableCell>
              <TableCell align="right">{row.total_comission}</TableCell>
            </TableRow>
          ))}
        </TableBody>


                      </Table>
                  </TableContainer>

              </Box>}
              {selectedProduct && selectedUserId && <Box >
                <h2>My Transactions</h2>
                  <TableContainer sx={{ maxHeight: 440 }}>
                      <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Invester Name
                                    </TableCell>
                                    <TableCell>
                                        Amount
                                    </TableCell>
                                    <TableCell>
                                        Reference
                                    </TableCell>
                                    <TableCell>
                                        Date
                                    </TableCell>
                                    <TableCell>
                                        Type
                                    </TableCell>
                                    <TableCell>
                                        Payment Type
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                          <TableBody>
                              {
                                  userProductTransaction.map((row) => {
                                      return (
                                          <TableRow key={row.id} hover role="checkbox" tabIndex={-1}>
                                              <TableCell>
                                                  {row.name}
                                              </TableCell>
                                              <TableCell>
                                                  {row.amount}
                                              </TableCell>
                                              <TableCell>
                                                  {row.reference_number}
                                              </TableCell>
                                              <TableCell>
                                                  {new Date(new Date(1970, 0, 1).setSeconds(row.date.seconds)).toLocaleDateString()}
                                              </TableCell>
                                              <TableCell>
                                                  {row.type}
                                              </TableCell>

                                          </TableRow>
                                      );
                                  })}
                              {userProductTransaction.length == 0 &&
                                  <TableRow hover role="checkbox" tabIndex={-1}>
                                      <TableCell colSpan={7} >
                                           No transactions yet.
                                      </TableCell>
                                  </TableRow>
                              }
                          </TableBody>
                      </Table>
                  </TableContainer>

              </Box>} */}

              </section>
    {/* </Container> */}
  </>
)
}

