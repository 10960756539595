import React, { useState, useEffect } from "react";
import Meta from "./../components/Meta";
import Navbar4 from "./../components/Navbar4";
import ContactSection from "./../components/ContactSection";
import { requireAuth, useAuth } from "../util/auth";
import { getaProducts, getUser } from '../util/db';
import { useRouter } from "../util/router";
import { Container } from "@material-ui/core";

function EnquiryPage(props) {
  const auth = useAuth();
  const [products, setProducts] = useState([]);
  const [nshares, setNshares] = useState(1);
  const [user, setUser] = useState([]);
  const route = useRouter();
  useEffect(() => {
      fetchProducts();
  }, []);
  
  const fetchProducts = async () => {
      const temp = await getaProducts(route.query.productId);
      const sharesn =  Number(temp.min_investment/250000);
      setProducts({...temp, sharesn: sharesn });
    console.log("1223", products) 
      
  }

  return (
   <>
   
   <Meta title="Enquiry" />
      <Navbar4
        color="default"
        logo="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-black.png?alt=media&token=4df2ee2a-8696-48b5-8a65-98171d8179d9"
        logoInverted="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096"
      />


      <>
      <ContactSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Invest Now"
        subtitle=""
        minimuminv={products.min_investment}
        minimumshares={products.sharesn}
        propertycost={products.property_cost}
        buttonText="Submit"
        buttonColor="primary"
        showNameField={true}
      /></>

    </>
  );
}

// export default requireAuth(EnquiryPage);
export default requireAuth(EnquiryPage);