import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import Sectionak4S1 from "./Sectionak4S1";
import SectionHeaderak4S11 from "./SectionHeaderak4S11";
import { Link } from "./../util/router";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 300,
  },
}));

function Sectionak4(props) {
  const classes = useStyles();

  const items = [
    {
      image: props.images[2],
      title: "Property Name",
      body: (
        <div>
          <p>Target IRR</p>
          <p>Average Rental Yelid</p>
          <p>Asset Value</p>
        </div>
      ),
    },
    {
      image: props.images[1],
      title: "Property Name",
      body: (
        <div>
          <p>Target IRR</p>
          <p>Average Rental Yelid</p>
          <p>Asset Value</p>
        </div>
      ),
    },
    {
      image: props.images[3],
      title: "Property Name",
      body: (
        <div>
          <p>Target IRR</p>
          <p>Average Rental Yelid</p>
          <p>Asset Value</p>
        </div>
      ),
    },
    {
      image: props.images[4],
      title: "Property Name",
      body: (
        <div>
          <p>Target IRR</p>
          <p>Average Rental Yelid</p>
          <p>Asset Value</p>
        </div>
      ),
    },
    {
      image: props.images[0],
      title: "Property Name",
      body: (
        <div>
          <p>Target IRR</p>
          <p>Average Rental Yelid</p>
          <p>Asset Value</p>
        </div>
      ),
    },
    {
      image: props.images[5],
      title: "Property Name",
      body: (
        <div>
          <p>Target IRR</p>
          <p>Average Rental Yelid</p>
          <p>Asset Value</p>
        </div>
      ),
    },
  ];

  return (
    <Sectionak4S1
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeaderak4S11
          title={props.title}
          subtitle={props.subtitle}
          size={5}
          textAlign="center"
        />
        <Grid container={true} justifyContent="center" spacing={5}>
          {items.map((item, index) => (
            <Grid item={true} xs={12} md={5} lg={4} key={index}>
              <Card>
                <CardActionArea component={Link} to={item.url}>
                  <CardMedia
                    image={item.image}
                    title={item.name}
                    className={classes.media}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Sectionak4S1>
  );
}

export default Sectionak4;
