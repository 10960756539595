import React, { useEffect, useState } from "react";
import Meta from "./../components/Meta";
import Navbar2 from "./../components/Navbar2";
import Sectionr101 from "./../components/Sectionr101";
import Section101 from "./../components/Section101";
import Section3 from "./../components/Section3";
import HeroSection from "./../components/HeroSection";
import Section621 from "./../components/Section621";
import Page2S3 from "./../components/Page2S3";
import Page2S4 from "./../components/Page2S4";
import Page2S5 from "./../components/Page2S5";
import Sectionak4 from "./../components/Sectionak4";
import Sectionak5 from "./../components/Sectionak5";
import Footer2 from "./../components/Footer2";
import SectionHeaderbk2S2 from "./../components/SectionHeaderbk2S2";
import { requireAuth } from "./../util/auth";
import { getaProducts, getuserdocument } from '../util/db';
import Aos from 'aos';
import { useRouter } from "../util/router";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import "aos/dist/aos.css";
import { Button, Container } from "@material-ui/core";
import { ColorLensOutlined } from "@material-ui/icons";
import { Carousel } from 'react-carousel-minimal';
const data = [
  {
    image: "https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/Front%20elevation.png?alt=media&token=47a73771-d114-4a0a-a701-c4ac19eb169f",
    caption: "Floor Elevation"
  },
  {
    image: "https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/Basement%20Floor%20plan.png?alt=media&token=0b606359-b090-4d28-8a09-5dcaf85cc59d",
    caption: "Basement"
  },
  {
    image: "https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/Ground%20floor%20plan.png?alt=media&token=557517b6-4aaa-431a-b919-11d359043ed8",
    caption: "Ground Floor"
  },
  {
    image: "https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/First%20and%20Third%20Floor%20plan.png?alt=media&token=3483dd88-020f-4b0b-8439-797f45db688f",
    caption: "First and Third Floor"
  },
  {
    image: "https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/Second%20floor%20plan.png?alt=media&token=9b56a5aa-bedf-4c26-914f-48fe16a34f47",
    caption: "Second Floor"
  },
  {
    image: "https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/Terrace%20Floor%20Plan.png?alt=media&token=675a562a-8281-47ef-a054-b77cd44ab8df",
    caption: "Terrace Floor"
  },
];

const captionStyle = {
  fontSize: '20px',
  fontWeight: 'bold',
  color: 'black',
}
const slideNumberStyle = {
  fontSize: '20px',
  fontWeight: 'bold',
  color: 'black',
}
function ProductPage(props) {
  const isSmall = useMediaQuery('(min-width:800px)');
  const route = useRouter();
  useEffect(() => {
    Aos.init({ duration: 2000 });
}, [])
  //  console.log(props.testtext)
   const [products, setProducts] = useState([]);
   const [documents, setDocuments] = useState([]);
   const [productimages, setProductimages] = useState([]);
   const [producttext, setProducttext] = useState([]);
 useEffect(() => {
     fetchProducts();
 }, []);
 
 const fetchProducts = async () => {
     const temp = await getaProducts(route.query.productId);
     setProducts(temp);
     setProducttext(temp.highlight_text);
     setProductimages(temp.images);
     console.log("ssae", temp)
     const temp1 = await getuserdocument(route.query.productId);
     setDocuments(temp1)
   
 }
 
  return (
    <>
      <Meta title="Product" />
      <Navbar2
        color="default"
        logo="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-black.png?alt=media&token=4df2ee2a-8696-48b5-8a65-98171d8179d9"
        logoInverted="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096"
      />
      <img data-aos="fade-up" data-aos-duration="1200"
        src={productimages[1]}
        style={{ height: isSmall? "700px" : "400px", overflow: "hidden", objectFit: "cover" }}
      />
    
      <Sectionr101
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        text1="Property Size"
        type={products.type}
        ps={products.property_size}
        psqft={products.sqft_cost}
        pc={products.propcost}
        tirr={products.target_irr}
        ey={products.entry_yeild}
        aay={products.avg_annual_yeild}
        ifr={products.income_frequency}
        mi={products.micost}
        title= {products.property_name}
        tp={~~products.total_percentage}
        subtitle={products.location}
        subtitle1="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nunc massa habitant amet. Suscipit gravida nisl, ipsum nulla neque ornare. Condimentum nisi, sed nunc amet enim. Dolor venenatis sed gravida sodales lobortis feugiat odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nunc massa habitant amet. Suscipit gravida nisl, ipsum nulla neque ornare. Condimentum nisi, sed nunc amet enim. Dolor venenatis sed gravida sodales lobortis feugiat odio."
        image="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/current-offer.png?alt=media&token=66841019-8150-4511-b576-97fe3cdc5af7"
      />
      <section data-aos="fade-up" data-aos-duration="1200">
      <Section101 
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        type={products.type}
        text1="Property Size"
        tp={~~products.total_percentage}
        ps={products.property_size}
        psqft={products.sqft_cost}
        pc={products.propcost}
        tirr={products.target_irr}
        ey={products.entry_yeild}
        aay={products.avg_annual_yeild}
        ifr={products.income_frequency}
        mi={products.micost}
        path= {route.query.productId}
        minimuminv={products.min_investment}
        title={products.property_name}
        subtitle={products.location}
        subtitle1="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nunc massa habitant amet. Suscipit gravida nisl, ipsum nulla neque ornare. Condimentum nisi, sed nunc amet enim. Dolor venenatis sed gravida sodales lobortis feugiat odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nunc massa habitant amet. Suscipit gravida nisl, ipsum nulla neque ornare. Condimentum nisi, sed nunc amet enim. Dolor venenatis sed gravida sodales lobortis feugiat odio."
        image="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/current-offer.png?alt=media&token=66841019-8150-4511-b576-97fe3cdc5af7"
      />
      </section>
      <section  style={{marginTop:'10px',     position: 'sticky',
    top: 0, backgroundColor:"white",
    zIndex: 10,}} >
      <Section3  /></section>
       <section id="Overview" style={{marginBottom: isSmall? '0px': '60px'}}></section>
      <section  data-aos="fade-up" data-aos-duration="1200">
      <HeroSection
        bgColor="default"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
        title="Investment Overview"
        subtitle={
          <div style={{ textAlign: "left" }}>
            {" "}
            <p>
            {producttext[0]}{" "}
            </p>{" "}
            <p>
              {" "}
              {producttext[1]}{" "}
            </p>{" "}
          </div>
        }
       
      />
      </section>
      <section id="Highlights" style={{marginBottom: isSmall? '30px': '70px'}}></section>
      <section data-aos="fade-up" data-aos-duration="1200">
      <Section621
       highlight_text={producttext}
       images={productimages}
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle="All the features you need to move faster"
      /></section>
      <section id="Pricing" style={{marginBottom: isSmall? '30px': '60px'}}></section>
        <section  data-aos="fade-up" data-aos-duration="1200">
      <Page2S3
        bgColor="default"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
        pridata={products}
        text1="Property Size"
        ps="80000"
        psqft="8000"
        pc="317,229,880"
        tirr="16%"
        ey="9%"
        ifr="Monthly"
        mi="Rs. 25,00,000"
        title="Why Commerical Real Estate?"
        subtitle={
          <section style={{alignItems:"center", }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "space-between",
              flexDirection: "row",
 
            }}
          >
            <div
              style={{
           
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                flexDirection: "column",
                
              }}
            >
              <h3
                style={{
                  marginBottom: "-10px",
                  color: "#ffffff",
                  fontWeight: "500",
                  width: "200px"
                }}
              >
                Base Purchase Price
              </h3>
              <h3
                style={{
                  marginBottom: "-10px",
                  color: "#ffffff",
                  fontWeight: "500",
                }}
              >
                Acquisition Fee
              </h3>
              <h3
                style={{
                  marginBottom: "-10px",
                  color: "#ffffff",
                  fontWeight: "500",
                }}
              >
                Registration & Taxes
              </h3>
              <h3
                style={{
                  marginBottom: "-10px",
                  color: "#ffffff",
                  fontWeight: "500",
                }}
              >
                Legal Fee
              </h3>
              <h3
                style={{
                  marginBottom: "-10px",
                  color: "#ffffff",
                  fontWeight: "500",
                }}
              >
                Reserve
              </h3>

              <h3
                style={{
                  marginBottom: "-10px",
                  color: "#ffffff",
                  fontWeight: "500",
                }}
              >
                                              <hr
        style={{
          color: 'transparent',
          backgroundColor: 'transparent',
          border: 'none',
          height: 2,
          width: "100%",
      }}
    />
               Total Gross price
                <hr
        style={{
            color: 'transparent',
            backgroundColor: 'transparent',
            border: 'none',
            height: 2,
            width: "100%",
        }}
    />
              </h3>
            </div>
            <div></div>
            <div
              style={{
                width: "70%",
                display: "flex",
                whiteSpace: "nowrap",
                justifyContent: "end",
                alignItems: "end",
                flexDirection: "column",
                marginLeft: isSmall?"45%" : "0%",
                
              }}
            >
              <h3
                style={{
                  color: "#3DAEB6",
                  marginBottom: "-10px",
                  fontWeight: "500",
                }}
              >
               ₹ {Number(products.base_price).toLocaleString("en-IN")}
              </h3>
              <h3
                style={{
                  color: "#3DAEB6",
                  marginBottom: "-10px",
                  fontWeight: "500",
                }}
              >
               ₹ {Number(products.acquision_fee).toLocaleString("en-IN")}
              </h3>
              <h3
                style={{
                  color: "#3DAEB6",
                  marginBottom: "-10px",
                  fontWeight: "500",
                }}
              >
               ₹ {Number(products.legal_expense).toLocaleString("en-IN")}
              </h3>
              <h3
                style={{
                  color: "#3DAEB6",
                  marginBottom: "-10px",
                  fontWeight: "500",
                }}
              >
                ₹ {Number(products.legal_expense).toLocaleString("en-IN")}
              </h3>
              <h3
                style={{
                  color: "#3DAEB6",
                  marginBottom: "-10px",
                  fontWeight: "500",
                }}
              >
               ₹ {Number(products.reserve).toLocaleString("en-IN")}
              </h3>

              <h3
                style={{
                  color: "#3DAEB6",
                  marginBottom: "-10px",
                  fontWeight: "500",
                }}
              >
                                              <hr
        style={{
            color: 'white',
            backgroundColor: 'white',
            height: 1,
            width: "100%",
        }}
    />
                ₹ {Number(products.property_cost).toLocaleString("en-IN")}
                <hr
        style={{
            color: 'white',
            backgroundColor: 'white',
            height: 1,
            width: "100%",
        }}
    />
              </h3>
            </div>
          </div>
          </section>
        }
        image="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/image14.png?alt=media&token=c7a5b859-78d4-4d06-b388-e4a9e1cf86ea"
      /> </section> 
      <section id="Location" style={{marginBottom: isSmall? '30px': '70px'}}></section>
      <section data-aos="fade-up"  data-aos-duration="1200">

      <Page2S4
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="How Fragmented Investment Works?"
        subtitle={
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              flexDirection: "column",
              color: "#262632",
            }}
          >
            {!isSmall && <> <br /><br /></>}
            <h4 style={{ color: "#3DAEB6", marginBottom: "-10px" }}>
            Kempegowda International Airport
            </h4>{" "}
            <p>39 KM from the property</p>
            <h4 style={{ color: "#3DAEB6", marginBottom: "-10px" }}>
            Whitefield Railway Station
            </h4>{" "}
            <p> 5.5 KM from the property</p>
            <h4 style={{ color: "#3DAEB6", marginBottom: "-10px" }}>
            Varthur Kodi Bus Stop
            </h4>{" "}
            <p> 200 M from the property</p>
            <h4 style={{ color: "#3DAEB6", marginBottom: "-10px" }}>
            Metro station
            </h4>{" "}
            <p style={{ marginBottom: "-15px" }}>4.5 KM from the property</p>
          </div>
        }
        image="https://firebasestorage.googleapis.com/v0/b/gayn-dev.appspot.com/o/Properties%2FBIBA%2Flocation%20of%20property.png?alt=media&token=7ed07ce5-27bd-4120-80b4-6eebd8646806"
        buttonText="Download"
        buttonColor="primary"
        buttonPath="/pricing"
      /></section>
            <section id="Tenants"  style={{marginBottom: '0px'}}></section>
      <section data-aos="fade-up" data-aos-duration="1200">
      <Page2S5
        bgColor="default"
        size="medium"
        bgImage="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/image2.png?alt=media&token=384d4c26-94c7-4ac6-b719-b37e2c3d1ba7"
        bgImageOpacity={1}
        text1="Property Size"
        ps="80000"
        psqft="8000"
        pc="317,229,880"
       
        tirr="16%"
        ey="9%"
        ifr="Monthly"
        mi="Rs. 25,00,000"
        title="t"
        subtitle={<p><br />The tenant, Athulya Assisted Living is an established and highly preferred provider of assisted senior healthcare. They are transforming the lives of senior citizens through their world class service, and efficiently designed facilities, aimed at providing a safe experience, and an engaging environment.
         <br /> <br />One of the first providers of this level of Assisted Living in India, they are well on their way to becoming India’s largest assisted living center for senior citizens. Founded in 2016, Athulya currently offers assisted living, transition care and home healthcare and recently announced plans to introduce palliative care services in the near future. They have also recently raised funding from one of the world's largest investment management firms. </p>}
        image="https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/shutterstock_1014559447.jpg?alt=media&token=de128d61-03fc-42ba-bb4b-c833b7013f21"
      /></section>
      <section id="Gallery" style={{marginBottom: isSmall? '30px': '60px'}}></section>
      <section data-aos="fade-up"  data-aos-duration="1200">
      <Sectionak4
        bgColor="default"
        size="medium"
        bgImage=""
        images={productimages}
        bgImageOpacity={1}
        title="Gallery"
        subtitle=""
      /></section>
<section id="Floor_Plan" style={{marginBottom: isSmall? '50px': '70px'}}></section>
<Container  style={{marginBottom: isSmall? '250px': '90px'}}>
<h2 style={{ color: "#3DAEB6", marginBottom: "20px" }}>
    Floor Plan
  </h2>
<Carousel
            data={data}
            time={4000}
            width="850px"
            height="650px"
            captionStyle={captionStyle}
            radius="10px"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={true}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              maxWidth: "850px",
              maxHeight: "650px",
              margin: "40px auto",
            }}
          />

      
        </Container>
        <section id="Documents" style={{marginBottom: isSmall? '50px': '70px'}}>
        
        < Sectionak5
         />
        
        </section>
      <Footer2
        bgColor="secondary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        copyright={`GAYN © ${new Date().getFullYear()} Company`}
        logo="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096"
        logoInverted="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096"
        sticky={true}
      />
    </>
  );
}

export default requireAuth(ProductPage, ["user", "cp"], "dWjFy1y1XVJ3Ue8EYFBx" ) ;
// export default ProductPage;
