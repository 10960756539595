import { initializeApp, getApps, getApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";
// window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = "ACF533C3-FB0F-4058-81F8-4A30EFB4E9EC";
// window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = "22895C2D-5953-40A3-9F4E-FCF9F5BB12C7";
window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = "DA1504D9-688D-4555-9619-7653963F170A";

let app;
if (getApps().length === 0) {
  // Initialize Firebase app
  app = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    storageBucket: "gayn-dev.appspot.com",

  });
} else {
  // Use existing app if already initialized
  app = getApp();
}

initializeAppCheck(app, {
  // provider: new ReCaptchaV3Provider('6Ld4ADIhAAAAAOK2obW4Ehb9LzQOz1uCQtSTVhpQ'),
  // provider: new ReCaptchaV3Provider('6LdCSKYhAAAAAOWJxrJ1ucjyrNs0nQ4dELZ2w3eA'),
  // provider: new ReCaptchaV3Provider('6LdVK6khAAAAAD12ZoEbguy0lRrwEfS03o5maFQr'),
  provider: new ReCaptchaV3Provider('6LcvwKwhAAAAALkwAWDLBTABG_D359tDprAWV0Xp'),
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

export const firebaseApp = app;
