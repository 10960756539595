import React, { useEffect, useState } from "react";
import Meta from "./../components/Meta";
import Container from "@material-ui/core/Container";
import Navbar from "./../components/navbarfaq";
import FaqSection from "../components/faqsection";
import Footer from "./../components/Footer";

function Faq(props) {

    return( <>
        <Meta />
                  <Navbar
        color="default"
        logo="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-black.png?alt=media&token=4df2ee2a-8696-48b5-8a65-98171d8179d9"
        logoInverted="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096"
      />
      <FaqSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Frequently Asked Questions"
        subtitle=""
      />
       <Footer
        bgColor="secondary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        copyright={`Copyright ©  ${new Date().getFullYear()} GAYN Technologies Pvt Ltd`}
        logo="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096"
        logoInverted="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096"
        sticky={true}
      />
</>
    )

}





export default Faq;
