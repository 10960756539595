import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
  },
  imageWrapper: {
    margin: "0 auto",
    maxWidth: 570,
    width: "100%",
    "& > img": {
      width: "100%",
    },
  },
  row: {
    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(2)}px`,
    },
  },
}));

function Section4B(props) {
  const classes = useStyles();
  const isSmall = useMediaQuery('(min-width:800px)');
  return (
    
    <Container style={{ marginBottom: "100px", marginTop: isSmall ? "20px" : "0" }}>
      <a style={{ fontSize: "18px", fontWeight: "700",}}>
        <a style={{ fontSize: "18px", fontWeight: "700" }}>
          G<a style={{ color: "#3DAEB6" }}>A</a>YN Capital's
        </a>{" "}
        Commitment is to provide
        <br />
      </a>
      <Grid
        container={true}
        alignItems="left"
        spacing={isSmall ? 10 : 0}
        // spacing={10}
        style={{ marginTop: isSmall ? "-20px" : "20px" }}
      >
        <Grid container={true} item={true} direction="column" xs={12} md={6} style={{ marginBottom: isSmall ? "20px" : "-20px" }}>
          <div
            style={{
              textAlign: "left",
              height: "90px",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <hr
              style={{
                width: "100%",
                backgroundColor: "#3DAEB6",
                height: "2px",
                borderWidth: "0px",
              }}
            />
            <a
              style={{ fontWeight: "600", fontSize: "15px", textAlign: "left" }}
            >
              Extensive Sourcing
            </a>
            <hr
              style={{
                width: "100%",
                backgroundColor: "#3DAEB6",
                height: "2px",
                borderWidth: "0px",
              }}
            />
            <a style={{ fontWeight: "600", fontSize: "15px" }}>
              Meticulous Vetting
            </a>
            <hr
              style={{
                width: "100%",
                backgroundColor: "#3DAEB6",
                height: "2px",
                borderWidth: "0px",
                display: isSmall ? "flex" : "none"
              }}
            />
          </div>
        </Grid>
        <Grid container={true} item={true} direction="column" xs={12} md={6}>
          <div
            style={{
              textAlign: "left",
              height: "90px",
              justifyContent: "center",
              alignItems: "flex-start",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <hr
              style={{
                width: "100%",
                backgroundColor: "#3DAEB6",
                height: "2px",
                borderWidth: "0px",
              }}
            />
            <a style={{ fontWeight: "600", fontSize: "15px" }}>
              Robust Digital Platform
            </a>
            <hr
              style={{
                width: "100%",
                backgroundColor: "#3DAEB6",
                height: "2px",
                borderWidth: "0px",
              }}
            />
            <a style={{ fontWeight: "600", fontSize: "15px" }}>
              Safe & Transparent Investing
            </a>
            <hr
              style={{
                width: "100%",
                backgroundColor: "#3DAEB6",
                height: "2px",
                borderWidth: "0px",
              }}
            />
          </div>
        </Grid>
      </Grid>
      <Grid
        container={true}
        alignItems="center"
        spacing={0}
        style={{ marginTop: "50px", fontSize: "15px" }}
      >
        <Grid container={true} item={true} direction="column" xs={12} md={4}>
          <div
            style={{
              backgroundColor: "#616161",
              textAlign: "center",
              height: "60px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <a style={{ fontWeight: "600", color: "#ffffff" }}>
              Access to a Booming Asset Class
            </a>
          </div>
        </Grid>
        <Grid container={true} item={true} direction="column" xs={12} md={4}>
          <div
            style={{
              backgroundColor: "#8E8E8E",
              textAlign: "center",
              height: "60px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <a style={{ fontWeight: "600", color: "#ffffff" }}>
              Fractional Investment Opportunities
            </a>
          </div>
        </Grid>
        <Grid container={true} item={true} direction="column" xs={12} md={4}>
          <div
            style={{
              backgroundColor: "#22CFDB",
              textAlign: "center",
              height: "60px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <a style={{ fontWeight: "600", color: "#ffffff" }}>
              Accountability through real-time Digital Insights
            </a>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Section4B;
