import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { requireAuth, useAuth } from "../util/auth";
import {contactsq} from "./../util/contactsq";
import { ToastContainer, toast } from 'react-toastify';
import {getUser} from "../util/db";
import useMediaQuery from '@material-ui/core/useMediaQuery';
const useStyles = makeStyles((theme) => ({
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    maxWidth: 700,
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: "inline-block",
    fontSize: "12px",
    display: "inline-block",
    [theme.breakpoints.up("sm")]: {
      fontSize: "14px",
    },
  },
}));



function Page1S22(props) {
  const auth = useAuth();
  const isSmall = useMediaQuery('(min-width:800px)');
  const userId = auth?.user?.id;
  const [uid, setUid] = React.useState(userId);
  const classes = useStyles();
  const [userd, setUserd] = React.useState([]);
  const [requested, setRequested] = React.useState(false);
  const productId = props.productid;
  const { subtitle, subtitle1, title, size, className, ...otherProps } = props;
  const populateUser = async () => {
    const user = await getUser(uid);
    setUserd(user);
  }
  React.useEffect(() => {
    populateUser();
  }, [])
  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }
  const handlesub= () => {
    setRequested(true);
    // contactsq(userd)
    toast.success("Your request has been registered successfully. Our team will contact you shortly", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  };

  return (
    <>
       <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>


      <Box
        component="header"
        className={
          classes.root + (props.className ? ` ${props.className}` : "")
        }
        {...otherProps}
      >
        {title && (
          <>
            <Typography
              variant={`h${size}`}
              gutterBottom={props.subtitle ? true : false}
              style={{ fontWeight: "bold", marginBottom: "-5px" }}
            >
              {title}
            </Typography>

            {subtitle && (
              <Typography
                variant="subtitle1"
                className={classes.subtitle}
                style={{ color: "#3DAEB6", marginBottom: "10px" }}
              >
                {subtitle}
                <br />
              </Typography>
            )}

            {subtitle1 && (
              <Typography variant="subtitle1" className={classes.subtitle}>
                {subtitle1}
                
              </Typography>
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default Page1S22;
