import React, {useState} from "react";
import IndexPage from "./index";
import ProductPage from "./product";
import Enquirypage from "./enquiry";
import AuthPage from "./auth";
import AuthPageadmin from "./authadmin";
import AuthPagecp from "./authcp";
import LandingPage from "./LandingPage";

import { Switch, Route, Router } from "./../util/router";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";
import "./../util/analytics";
import { AuthProvider, useAuth } from "./../util/auth";
import { ThemeProvider } from "./../util/theme";
import { QueryClientProvider } from "./../util/db";
import AdminCreateUser from "./Admin/CreateUser";
import AdminCreateChannelPartner from "./Admin/CreateChannelPartner";
import UserList from "./Admin/UserList";
import EOIApprove from "./Admin/EOIApprove";
import UnAuthorizedPage from "./unauthorized";
import MyClients from "./ChannelPartner/my-clients";
// import TransactionTable  from './Admin/TransactionTable';
import TransactionTablenew from "./Admin/TransactionTablenew"; 
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Userdasboard from './Investor/dashboard';
import Userdocuments from './Investor/documents';
import Userdetails from './Investor/investordetails';
import Userrealestate from './Investor/myrealestate';
import Usertransaction from './Investor/transactions';
import CPdashboard from './ChannelPartner/dashboard';
import CPdocumentscp from './ChannelPartner/documents';
import CPclients from './ChannelPartner/my-clients';
import SellerPage from "./seller";
import CPteam from './ChannelPartner/myteam';
import AdminProduct from './Admin/ProductTable';
import Faq from "./faq";
import privacy from './privacy';
import ChannelPartnerClientdetails from './ChannelPartner/investordetails';
import './animation.css';
import Signoutpage from './signout';
import CPprofile from "./ChannelPartner/myprofile"
import Userofferings from "./Investor/offerings"
import Myportfolio from "./ChannelPartner/myportfolio";
function App(props) {
  const auth = useAuth();
  const [data, setData] = useState([]);
  // const [loading, setloading] = useState(undefined);
  // const [completed, setcompleted] = useState(undefined);

  
  // React.useEffect(() => {
  //   setTimeout(() => {
  //     fetch("https://jsonplaceholder.typicode.com/posts")
  //       .then((response) => response.json())
  //       .then((json) => {
  //         console.log(json);
  //         setData(json);
  //         setloading(true);

  //         setTimeout(() => {
  //           setcompleted(true);
  //         }, 1000);
  //       });
  //   }, 2000);
  // }, []);
  // Confirms user has a specific role
  // const confirmRole = role => ((nextState, replace) => {
  //   if (auth.userRole !== role) {
  //     replace({ pathname: '/', state: { nextPathname: nextState.location.pathname } });
  //   }
  // });

  return (  <>
    {/* {!completed ? (
      <>
        {!loading ? (
    <div style={{display:'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                     alignContent: 'center',
                     height: '100%',
                     marginTop: '15%',}}>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1241.9 1395.3" width="400px" height="300px">

    <path className="st0 svg-elem-1" d="M260,157.79h-21.3c-20.55,0-41.09-0.01-61.63,0c-5.52,0.02-10.12-1.51-11.17-7.68
        c-1.03-6.07,3.19-10.43,10.33-10.48c19.63-0.15,39.25,0.03,58.89-0.13c10.48-0.07,20.96-0.46,31.43-0.93
        c6.93-0.31,11.08,3.72,10.73,11.75c-0.96,21.68-6.72,41.93-20.39,59.16c-27.97,35.29-64.61,48.96-108.07,37.95
        c-41.78-10.58-67.83-38.84-75.45-81.39c-10.81-60.36,30.36-110.92,83.54-120.02c32.35-5.52,60.35,3.47,85.09,24.25
        c1.58,1.31,3.15,2.68,4.47,4.23c3.35,3.97,5.36,8.29,1.02,12.75c-4.36,4.48-8.96,3.69-13.29-0.25
        c-12.91-11.79-27.57-19.7-45.03-22.96c-39.5-7.38-80.87,16.31-94.16,54.63c-14.94,43.06,4.32,88.8,45.14,107.16
        c42.24,19.01,91.55,0.6,111.17-41.93C254.89,176.17,256.77,167.67,260,157.79"></path>
    <path className="st0 svg-elem-2" d="M1101.63,212.85v-9.3c0.01-47.92,0.01-95.84,0.05-143.76c0.01-10.09,3.34-15.56,9.32-15.6
        c5.91-0.04,8.95,5.25,8.95,15.71c0.01,58.89,0,117.76,0,176.64c0,1.58,0.48,3.43-0.15,4.73c-1.54,3.25-3.06,8.19-5.54,8.84
        c-3.16,0.84-8.15-0.93-10.72-3.32c-4.78-4.44-8.4-10.15-12.48-15.36c-37.68-48.08-75.34-96.2-113.02-144.28
        c-1.48-1.88-3.05-3.68-5.64-6.79c-0.28,3.47-0.55,5.17-0.55,6.87c0,49.97,0.01,99.95,0.02,149.92c0,2.06,0.14,4.16-0.24,6.15
        c-0.96,4.93-4.19,7.68-9.16,7.43c-4.88-0.24-8.05-2.9-8.3-8.17c-0.07-1.83-0.07-3.66-0.07-5.49c0-59.34,0-118.65,0-177.99
        c0-1.83-0.6-4.01,0.2-5.41c1.76-3.1,3.68-6.97,6.55-8.32c2.12-0.97,6.87,0.61,8.72,2.63c6.42,7.07,12.15,14.77,18.03,22.3
        c36.51,46.67,73,93.38,109.51,140.06c0.82,1.04,1.79,1.95,2.68,2.91C1100.43,213.12,1101.03,213,1101.63,212.85"></path>
    <path className="st1 svg-elem-3" d="M481.97,73.65c-5.36,10.61-10,19.67-14.5,28.79c-22.65,46.03-45.26,92.07-67.92,138.08
        c-1.21,2.44-2.43,5-4.19,7.03c-2.82,3.25-6.21,5.35-10.81,2.81c-4.57-2.52-6.13-6.41-4.27-10.93c3.03-7.38,6.57-14.53,10.12-21.66
        c26.6-53.51,53.25-106.97,79.89-160.44c1.01-2.06,1.72-4.41,3.22-6.01c2.53-2.65,5.76-6.82,8.39-6.61c3.26,0.25,7.42,3.69,9.1,6.88
        c8.54,16.1,16.4,32.58,24.51,48.92c22.13,44.5,44.25,89.02,66.38,133.53c0.41,0.82,0.82,1.62,1.2,2.45
        c2.51,5.88,1.21,11.69-3.12,13.83c-4.73,2.31-10.5-0.11-13.41-5.98c-23.04-46.37-46.02-92.77-69.01-139.16
        C492.6,95.22,487.69,85.24,481.97,73.65"></path>
    <path className="st0 svg-elem-4" d="M770.08,133.3c14.39-18.61,28.17-36.43,41.94-54.23c7.37-9.52,14.46-19.31,22.31-28.43
        c2.46-2.88,7.08-5.76,10.55-5.58c7.07,0.38,9.07,9.47,3.94,16.15c-12.77,16.61-25.61,33.17-38.46,49.69
        c-9.32,12.06-18.85,24-27.95,36.26c-1.88,2.51-3.17,6.12-3.26,9.24c-0.65,27.82-0.99,55.63-1.43,83.46
        c-0.12,7.56-3.1,11.17-8.98,11.07c-5.47-0.1-8.73-4.29-8.63-11.62c0.33-26.66,0.67-53.34,1.24-80.04c0.1-5.27-1.24-9.42-4.66-13.68
        c-22.16-27.5-44.06-55.24-65.9-83c-2.04-2.6-4.47-6.08-4.21-8.93c0.29-2.95,3.35-7.68,5.65-7.96c3.54-0.42,8.65,1.21,11.02,3.84
        c8.2,9.16,15.48,19.14,23.14,28.76C740.67,96.31,754.99,114.31,770.08,133.3"></path>
    <path className="st0 svg-elem-5" d="M71.64,325.1h1048.5v4.68H71.66C71.65,328.21,71.65,326.65,71.64,325.1"></path>
    <polygon className="st2 svg-elem-6" points="314.56,474.17 313.49,474.26 311.52,473.94 310.45,473.76 309.39,473.67 308.39,473.44 307.41,473.19 
        306.59,472.94 305.77,472.78 304.79,472.46 303.71,472.04 302.73,471.71 301.83,471.3 301.09,470.89 300.18,470.32 299.44,469.9 
        298.54,469.32 297.63,468.75 296.97,468.26 295.91,467.61 295.34,466.87 294.44,466.21 293.86,465.55 293.28,464.81 292.63,464.07 
        291.81,463.24 291.4,462.42 290.66,461.68 290.33,460.87 289.84,460.04 289.26,459.05 288.85,458.24 288.52,457.33 288.19,456.44 
        287.94,455.44 287.62,454.46 287.2,453.56 286.87,452.57 286.87,451.59 286.63,450.59 286.47,449.61 286.47,448.71 286.22,447.63 
        286.05,446.66 285.97,445.58 286.05,443.61 286.05,442.63 286.22,441.65 286.22,440.74 286.38,439.6 286.71,438.68 286.63,437.78 
        287.04,436.8 287.12,435.8 287.53,434.74 287.62,433.85 288.11,432.93 288.52,431.95 288.76,431.05 289.18,430.14 289.84,429.24 
        290.33,428.5 290.66,427.68 291.15,426.76 291.81,425.95 292.55,425.21 292.95,424.39 293.61,423.66 294.52,422.99 295.18,422.26 
        295.91,421.68 296.74,421.02 298.13,419.88 299.03,419.38 299.69,418.8 300.84,418.39 301.58,417.98 302.56,417.49 303.39,417.16 
        304.46,416.75 305.44,416.51 306.26,416.09 307.17,415.93 308.06,415.59 309.95,415.27 311.11,415.19 311.93,414.95 313.08,414.95 
        314.15,414.85 315.22,414.85 316.94,414.77 318.17,414.85 319.08,414.85 320.06,415.04 320.97,415.19 321.96,415.27 323.1,415.44 
        325.24,415.85 325.98,416.09 326.89,416.43 327.87,416.75 328.93,416.99 329.92,417.33 330.82,417.75 331.65,418.15 332.55,418.64 
        333.46,418.96 334.61,419.55 336.08,420.44 336.5,421.84 335.68,423.41 334.19,423.66 333.46,423.15 332.39,422.75 331.57,422.42 
        330.75,422.01 330,421.6 329.02,421.1 327.29,420.44 326.06,420.28 325.16,419.96 324.42,419.7 323.27,419.46 321.55,419.05 
        320.56,418.96 319.66,418.88 318.42,418.8 317.68,418.73 316.69,418.64 314.64,418.64 313.57,418.64 312.59,418.8 311.52,418.88 
        310.45,418.96 309.72,419.22 308.73,419.38 307.73,419.62 306.68,419.88 305.77,420.2 304.79,420.54 304.04,421.02 303.14,421.44 
        302.24,421.84 301.25,422.26 300.43,422.83 299.77,423.49 298.95,424.07 298.13,424.63 297.39,425.29 296.58,426.03 296.07,426.86 
        295.34,427.6 294.76,428.34 294.11,429.24 293.61,430.06 293.21,430.87 292.71,431.69 292.46,432.69 291.81,433.59 291.64,434.41 
        291.4,435.48 290.9,436.46 290.9,437.28 290.57,438.28 290.41,439.26 290.41,440.33 290.33,441.31 290.08,442.31 290.08,443.29 
        289.91,445.26 290.24,446.24 290.08,447.24 290.33,448.22 290.33,449.2 290.49,450.19 290.82,451.17 291.15,452.08 291.15,453.07 
        291.56,453.96 291.96,455.04 292.3,455.85 292.88,456.84 293.12,457.65 293.69,458.57 294.02,459.31 294.68,460.13 295.25,461.02 
        295.99,461.76 296.65,462.5 297.39,463.16 297.8,463.82 298.62,464.4 299.61,465.13 300.34,465.72 301.17,466.29 301.91,466.7 
        302.89,467.19 303.55,467.61 304.54,468.09 305.28,468.43 306.51,468.75 307.25,469.17 308.23,469.32 309.13,469.66 310.05,469.9 
        311.19,469.98 312.18,470.23 313.16,470.23 314.07,470.4 315.13,470.48 316.12,470.48 317.93,470.48 319.16,470.4 319.99,470.48 
        321.14,470.4 322.11,470.14 323.19,470.14 324.17,469.9 325.07,469.75 326.06,469.66 327.12,469.41 328.11,469.09 328.77,468.83 
        329.76,468.67 330.75,468.26 331.73,467.93 332.71,467.69 333.79,467.27 334.61,466.87 335.26,466.45 336.08,466.77 336.25,467.69 
        335.92,468.58 336,469.49 334.77,470.8 334.19,471.14 333.04,471.62 332.22,471.88 331.15,472.2 330.33,472.46 329.51,472.69 
        328.52,472.94 327.45,473.11 326.3,473.35 325.33,473.6 324.33,473.67 323.51,473.76 321.63,474.01 320.39,474.17 318.5,474.34 
        317.52,474.34 315.46,474.34 "></polygon>
    <path className="st2 svg-elem-7" d="M438.26,471.46l-0.58-0.9l-0.16-0.82l-0.66-0.99l-0.41-0.91l-0.08-0.9l-0.41-0.82l-0.65-0.83l-0.17-0.98
        l-0.49-0.9l-0.41-0.82l-0.5-0.92l-0.32-0.98l-0.25-0.81l-0.58-0.92l-1.15-0.9l-1.23-0.08l-0.98,0.08h-0.74l-1.07-0.08l-0.9,0.08
        l-1.23-0.08l-0.98,0.08l-0.99-0.08h-0.74l-0.99,0.08h-2.22l-0.98-0.08l-0.99,0.08l-0.74-0.08h-1.07l-1.15,0.08h-1.07l-0.82-0.08
        h-6.9l-1.15,0.08h-0.98l-0.74-0.08h-2.05l-1.4,0.98l-0.58,0.84l-0.08,0.9l-0.66,0.91l-0.24,0.9l-0.5,0.92l-0.33,0.9l-0.49,0.98
        l-0.16,0.74l-0.49,0.99l-0.49,0.9l-0.17,0.82l-0.58,0.91l-0.25,0.98l-0.41,0.82l-0.58,0.99l-1.32,0.83h-1.23l-0.91,0.07l-0.74-1
        l0.33-0.9l0.32-0.9l0.66-0.82l0.33-0.91l0.25-0.99l0.41-0.9l0.32-0.91l0.66-0.82l0.41-0.9l0.25-0.9l0.41-0.92l0.33-0.82l0.41-0.98
        l0.57-0.9l0.33-0.91l0.25-0.82l0.58-0.9l0.25-0.92l0.57-0.9l0.26-0.98l0.24-0.91l0.5-0.83l0.4-0.98l0.49-0.74l0.16-0.91l0.41-0.99
        l0.66-0.9l0.32-0.9l0.41-0.84l0.16-0.9l0.66-0.9l0.41-0.9l0.25-0.99l0.41-0.82l0.33-0.9l0.49-0.91l0.41-0.99l0.41-0.9l0.17-0.91
        l0.58-0.82l0.33-0.9l0.49-0.82l0.16-1l0.41-0.82l0.49-0.98l0.58-0.9l0.41-0.84l0.08-0.98l0.66-0.9l0.33-0.84l0.49-0.98l0.07-0.9
        l0.5-0.92l0.33-0.82l0.65-0.91l0.24-0.81l0.26-0.91l0.65-0.91l0.16-0.98l0.59-0.82l0.16-0.99l0.65-0.82l1.23-0.9h1.31l0.82-0.07
        l1.48,0.9l0.32,0.98l0.58,0.91l0.33,0.91l0.49,0.9l0.33,0.82l0.33,0.99l0.25,0.81l0.49,0.83l0.33,0.9l0.58,0.99l0.17,0.82l0.65,0.9
        l0.41,1l0.16,0.9l0.5,0.82l0.32,0.99l0.32,0.81l0.5,0.98l0.33,0.82l0.57,0.91l0.17,0.91l0.66,0.9l0.66,1.73l0.4,0.9l0.42,0.99
        l0.24,0.83l0.58,0.9l0.25,0.98l0.34,0.91l0.57,0.83l0.41,0.97l0.25,0.82l0.49,0.99l0.41,0.82l0.41,0.9l0.58,1.82l0.49,0.82
        l0.41,0.98l0.98,1.82l0.08,0.9l0.58,0.9l0.49,0.84l0.41,0.9l0.16,0.91l0.41,0.99l0.58,0.81l0.16,0.98l0.58,0.82l0.41,0.92l0.32,0.9
        l0.4,0.9l0.26,0.82l0.49,0.99l0.25,0.81l0.83,1.82l0.4,0.98l0.58,0.91l0.33,0.91l-0.73,0.83l-1.16,0.07h-1.07l-1.23-1L438.26,471.46
        z M403.42,454.3h1.73l1.23-0.08h0.83l0.82,0.08l1.07-0.08h0.98l0.99,0.08l0.98-0.08l0.98,0.08l0.91-0.08l1.15,0.08h0.98l0.74-0.08
        h1.15l0.82,0.08l1.07-0.08h1.97l0.9,0.08h0.99l1.15-0.08l1.07,0.08l0.65-0.08l0.98,0.08l1.07-0.08l0.98,0.08l0.74-0.99l-0.58-0.9
        l-0.32-0.82l-0.41-0.99l-0.48-0.91l-0.26-0.9l-0.49-0.82l-0.41-0.92l-0.24-0.98l-0.99-1.82l-0.33-0.9l-0.41-0.9l-0.17-0.91
        l-0.58-0.83l-0.32-0.9l-0.58-0.98l-0.41-0.83l-0.08-0.98l-0.41-0.82l-0.49-0.99l-1.15-2.7l-0.33-0.92l-0.66-0.81l-0.41-0.99
        l-0.17-0.91l-0.49-0.9l-0.49-0.91l-0.41-0.9l-0.33-0.84l-0.25-0.9l-0.65-0.9l-0.08-0.99l-0.41-0.82l-0.82-0.08l-0.41,0.91
        l-0.49,0.99l-0.41,0.9l-0.24,0.82l-0.5,0.99l-0.33,0.82l-0.4,0.98l-0.49,0.82l-0.33,0.99l-0.99,1.8l-0.17,0.84l-0.49,0.98l-0.4,0.9
        l-0.41,0.91l-0.26,0.83l-0.4,0.9l-0.49,0.98l-0.33,0.83l-0.41,0.98l-0.41,0.82l-0.41,0.99l-0.58,0.82l-0.16,0.98l-0.41,0.91
        l-0.41,0.82l-0.33,0.98l-0.41,0.9l-0.58,0.84l-0.25,0.9l-0.66,0.97l-0.17,0.92l-0.49,0.9l-0.25,0.9l-0.41,0.91l0.49,0.83
        L403.42,454.3z"></path>
    <path className="st2 svg-elem-8" d="M500.42,471.39l-0.07-0.98l0.07-1.08l0.09-0.91l-0.09-0.99l-0.16-0.98l0.16-1.16l-0.16-0.98l0.16-0.98v-0.91
        l-0.07-1.06v-0.91l0.17-0.99l-0.26-0.98l0.09-0.99l0.17-0.97l-0.26-1l0.16-1.06v-0.9l-0.16-0.99v-1.15l0.16-0.91l0.09-1.06
        l-0.17-0.92l0.17-1.06l-0.26-0.9l0.16-1.08l-0.07-0.98l0.17-0.99l-0.17-0.9l0.07-0.99v-0.99l0.09-0.98l-0.17-0.99l-0.09-1.06v-1
        l0.26-1.06l-0.09-0.9l-0.16-0.99v-0.98l0.16-0.99l-0.07-1.06l0.07-0.98v-0.99l-0.16-0.9l0.16-1.08l0.09-0.9l-0.26-0.98l0.09-0.99
        l0.07-1.15v-0.99l0.09-0.91l-0.09-1.07l-0.07-0.98l0.07-0.9l0.09-1.08l0.81-0.98l0.91,0.08l1.15-0.08h0.82l0.9,0.08h1.23l0.91-0.08
        h0.91l0.98,0.08h0.91l0.98-0.08H512l1.24,0.08h0.74l1.14-0.08l0.91,0.08h0.82l1.23-0.08l0.99,0.08l0.82-0.08h0.91l0.98,0.08h0.91
        l1.07-0.08l0.91,0.08l2.06-0.08l1.06,0.16h0.92l0.98,0.07l0.9,0.25l0.92,0.17l1.14,0.24l0.82,0.32l0.91,0.42l1.96,0.83l0.75,0.42
        l0.91,0.58l0.49,0.65l0.98,0.66l0.42,0.64l0.74,0.66l0.66,0.74l0.49,0.92l0.33,0.82l0.66,0.82l0.07,0.98l0.41,0.91l0.17,0.91
        l0.49,0.98l-0.07,0.99l0.07,0.81l0.08,1.08l0.09,1.96v1l-0.17,0.9v0.9l-0.49,1.08l-0.25,0.82l-0.33,0.98l-0.16,0.91l-0.58,0.91
        l-0.59,0.81l-0.48,0.82l-0.41,0.83l-0.74,0.74l-0.49,0.57l-0.91,0.74l-0.75,0.58l-0.9,0.49l-0.74,0.59l-0.9,0.32l-0.99,0.49
        l-1.64,0.59l-1.23,0.32l-0.82,0.26l-0.98,0.08l-1.14,0.07l-0.92,0.17h-0.98h-2.95l-0.91,0.08h-0.98l-0.82-0.08l-1.15,0.08
        l-0.91-0.08h-0.91l-0.91,0.08l-0.98-0.08h-5l-1,0.08h-0.73l-1.15-0.08l-0.91,0.08h-1.97l-0.98,0.99l0.08,0.9l-0.08,0.98l0.16,1.07
        l-0.08,0.98l-0.16,0.99l0.16,0.99v0.9l0.08,0.99l-0.25,0.98l0.08,1.08v0.82l0.16,1.06l-0.16,1l0.08,0.97v0.99l-0.16,0.98l0.08,0.98
        l-0.08,0.99l0.16,0.91v1.97l-0.08,0.98l-0.91,0.99h-1.81l-1.23-0.99L500.42,471.39z M506.34,445.92l0.91,0.08l0.82-0.08l0.98,0.08
        l0.99-0.08h0.91l1.23,0.08l0.74-0.08l1.07,0.08l0.9-0.08h3.04l0.82,0.08l0.98-0.08l0.99,0.08h3.95l2.06-0.08l0.81-0.08l0.98-0.09
        l1.07-0.16l0.98-0.08l0.74-0.32l1.23-0.26l0.66-0.4l1.06-0.33l0.82-0.49l0.74-0.58l0.74-0.58l0.74-0.74l0.66-0.74l0.4-0.74
        l0.42-0.91l0.49-0.9l0.25-0.9l0.08-1l0.33-0.9v-0.97l0.15-0.91l0.17-1.89l-0.08-1l-0.42-1.06l-0.16-0.99l-0.08-0.82l-0.49-0.9
        l-0.25-0.98l-0.49-0.92l-0.66-0.74l-0.41-0.73l-0.82-0.67l-0.74-0.73l-0.58-0.59l-1.07-0.48l-0.82-0.42l-0.99-0.24l-0.82-0.33
        l-1.15-0.25l-0.81-0.16l-1.07-0.18l-1.07-0.15l-1.07-0.08h-2.79l-0.98,0.08l-1.23-0.08h-1.73l-1.31,0.08l-0.82-0.08h-1.15
        l-0.81,0.08h-2.23l-0.82-0.08l-1.14,0.08l-0.92-0.08h-3.11l-1.07,0.08l-0.91-0.08l-0.82,0.99l-0.16,1.06v1.97l0.08,0.83l-0.16,1.07
        l0.25,0.98l-0.16,0.98l0.08,0.91l-0.16,0.98l0.16,0.99l0.08,0.99v0.98l-0.16,0.91l0.08,1.06v0.9l-0.16,1.08v0.9l0.08,1.08l0.16,0.9
        l-0.25,1.08l0.08,0.98v0.9l0.16,0.99v0.91l-0.25,0.98l0.98,1.08L506.34,445.92z"></path>
    <polygon className="st2 svg-elem-9" points="606.21,415.85 605.05,415.77 604.24,416.83 604.24,417.74 604.32,418.8 604.07,419.78 604.32,420.7 
        604.15,421.67 604.32,422.68 604.07,423.81 604.32,424.81 604.24,425.71 604.24,426.69 604.07,427.68 604.15,428.74 604.07,429.65 
        604.24,431.62 604.15,432.69 604.07,433.59 604.32,434.67 604.32,436.63 604.15,437.62 604.15,438.6 604.32,439.68 604.32,440.58 
        604.24,441.57 604.07,442.62 604.24,443.53 604.15,444.53 604.15,445.58 604.07,446.5 604.15,447.48 604.07,448.45 604.24,449.46 
        604.15,450.51 604.15,451.5 604.24,452.49 604.32,453.56 604.07,454.54 604.07,456.51 604.15,457.5 604.15,458.49 604.24,459.47 
        604.32,460.36 604.07,461.36 604.24,462.34 604.07,463.42 604.24,464.32 604.15,465.3 604.15,466.37 604.32,467.35 604.24,468.34 
        604.32,469.41 604.15,470.41 604.24,471.3 604.24,472.35 605.22,473.27 607.11,473.27 608.09,472.35 608.01,471.3 608.01,470.41 
        608.17,469.32 608.27,468.43 608.09,467.43 608.17,466.45 608.09,465.39 608.09,463.42 608.27,462.43 608.01,461.36 608.27,460.36 
        608.09,459.4 608.17,458.49 608.01,457.5 608.27,456.51 608.09,455.44 608.01,454.46 608.17,453.56 608.17,452.49 608.01,451.5 
        608.27,450.43 608.17,449.46 608.01,448.54 608.09,447.48 608.27,445.5 608.01,444.6 608.27,443.53 608.17,442.54 608.27,441.57 
        608.01,440.65 608.01,439.68 608.27,438.68 608.01,437.62 608.09,436.63 608.17,435.56 608.17,433.67 608.01,432.61 608.09,431.62 
        608.01,430.71 608.01,427.68 608.27,426.69 608.01,425.71 608.27,424.7 608.27,422.76 608.01,421.67 608.01,420.78 608.17,419.78 
        608.17,418.8 608.01,417.74 608.27,416.83 607.2,415.77 "></polygon>
    <polygon className="st2 svg-elem-10" points="713.04,417.74 712.63,418.57 711.72,419.54 709.76,419.54 708.69,419.63 706.55,419.63 705.57,419.54 
        704.58,419.54 703.6,419.63 701.54,419.63 700.64,419.54 699.56,419.63 698.75,419.54 697.85,419.63 692.66,419.63 691.61,420.54 
        691.84,421.52 691.61,422.5 691.61,423.57 691.76,424.47 691.84,425.37 691.84,426.37 691.76,427.43 691.61,429.4 691.84,430.29 
        691.68,431.37 691.68,432.28 691.76,433.27 691.76,434.25 691.84,435.22 691.84,436.22 691.84,437.12 691.61,438.2 691.68,439.1 
        691.76,440.17 691.68,441.15 691.84,441.97 691.61,443.04 691.68,444.02 691.84,445 691.84,445.92 691.76,446.9 691.68,447.98 
        691.76,448.96 691.93,449.93 691.76,450.92 691.76,451.82 691.93,452.82 691.76,453.72 691.76,454.78 691.93,455.69 691.93,456.67 
        691.76,457.75 691.76,458.73 691.76,459.63 691.84,460.63 691.93,461.61 691.93,462.6 691.76,463.58 691.76,464.48 691.68,465.55 
        691.93,466.54 691.93,467.53 691.68,468.42 691.93,469.4 691.68,470.32 691.84,471.3 691.76,472.28 690.94,473.28 689.95,473.36 
        688.73,473.36 687.89,472.37 687.89,471.3 687.74,470.4 687.82,469.32 687.74,468.51 687.74,467.53 687.89,466.54 687.82,465.48 
        687.82,464.48 687.89,463.58 687.89,462.5 687.65,461.52 687.65,460.54 687.89,459.71 687.89,457.75 687.65,456.75 687.82,455.78 
        687.89,454.78 687.89,453.8 687.74,452.73 687.65,451.75 687.89,450.92 687.65,449.93 687.82,448.87 687.74,447.98 687.82,446.98 
        687.82,445.92 687.74,444.93 687.82,444.02 687.65,442.96 687.65,441.97 687.82,441.07 687.74,440.17 687.74,439.17 687.89,438.2 
        687.65,437.2 687.65,436.22 687.74,435.22 687.65,434.25 687.74,433.27 687.65,432.28 687.82,431.29 687.74,430.29 687.82,429.4 
        687.65,428.34 687.82,427.34 687.82,426.45 687.89,425.46 687.65,424.4 687.65,422.5 687.82,421.52 687.82,420.62 686.83,419.54 
        685.94,419.63 684.94,419.63 683.71,419.54 682.72,419.63 681.91,419.63 680.83,419.54 679.85,419.63 678.78,419.63 677.88,419.54 
        676.72,419.63 675.66,419.54 674.76,419.63 673.93,419.54 672.87,419.54 671.89,419.63 668.92,419.63 667.94,419.54 666.87,418.64 
        666.87,416.75 667.86,415.77 669.83,415.77 670.65,415.85 671.89,415.77 672.71,415.85 673.84,415.77 674.59,415.85 675.83,415.77 
        676.82,415.77 677.63,415.85 678.78,415.77 683.54,415.77 684.54,415.85 685.68,415.77 686.67,415.85 687.5,415.85 688.55,415.77 
        691.34,415.77 692.34,415.85 693.33,415.85 694.39,415.77 695.45,415.85 696.28,415.77 697.35,415.77 698.17,415.85 699.41,415.85 
        700.22,415.77 701.38,415.85 702.28,415.85 703.1,415.77 704.33,415.77 705.07,415.85 706.23,415.85 707.29,415.77 708.27,415.77 
        709.03,415.85 710.24,415.77 711.23,415.77 712.21,415.85 713.04,416.75 "></polygon>
    <path className="st2 svg-elem-11" d="M805.52,471.46l-0.58-0.9l-0.16-0.82l-0.66-0.99l-0.41-0.91l-0.08-0.9l-0.42-0.82l-0.64-0.83l-0.18-0.98
        l-0.48-0.9l-0.42-0.82l-0.5-0.92l-0.32-0.98l-0.26-0.81l-0.56-0.92l-1.16-0.9l-1.23-0.08l-0.98,0.08h-0.74l-1.08-0.08l-0.9,0.08
        l-1.24-0.08l-0.98,0.08l-0.99-0.08h-0.74l-0.98,0.08h-2.22l-0.99-0.08l-0.98,0.08l-0.74-0.08h-1.07l-1.14,0.08h-1.08l-0.82-0.08
        h-6.9l-1.14,0.08h-0.99l-0.74-0.08h-2.05l-1.4,0.98L766,459.8l-0.08,0.9l-0.66,0.91l-0.24,0.9l-0.5,0.92l-0.32,0.9l-0.5,0.98
        l-0.16,0.74l-0.5,0.99l-0.49,0.9l-0.16,0.82l-0.58,0.91l-0.24,0.98l-0.42,0.82l-0.57,0.99l-1.32,0.83h-1.23l-0.9,0.07l-0.74-1
        l0.32-0.9l0.33-0.9l0.66-0.82l0.32-0.91l0.24-0.99l0.42-0.9l0.32-0.91l0.65-0.82l0.42-0.9l0.24-0.9l0.42-0.92l0.32-0.82l0.42-0.98
        l0.57-0.9l0.32-0.91l0.25-0.82l0.57-0.9l0.25-0.92l0.58-0.9l0.25-0.98l0.24-0.91l0.5-0.83l0.41-0.98l0.49-0.74l0.16-0.91l0.42-0.99
        l0.66-0.9l0.32-0.9l0.42-0.84l0.16-0.9l0.66-0.9l0.42-0.9l0.24-0.99l0.42-0.82l0.32-0.9l0.49-0.91l0.42-0.99l0.4-0.9l0.16-0.91
        l0.59-0.82l0.33-0.9l0.48-0.82l0.18-1l0.4-0.82l0.49-0.98l0.58-0.9l0.4-0.84l0.08-0.98l0.66-0.9l0.34-0.84l0.48-0.98l0.09-0.9
        l0.48-0.92l0.34-0.82l0.65-0.91l0.24-0.81l0.25-0.91l0.66-0.91l0.17-0.98l0.57-0.82l0.17-0.99l0.66-0.82l1.23-0.9h1.31l0.81-0.07
        l1.48,0.9l0.33,0.98l0.58,0.91l0.33,0.91l0.49,0.9l0.32,0.82l0.33,0.99l0.24,0.81l0.5,0.83l0.32,0.9l0.58,0.99l0.16,0.82l0.66,0.9
        l0.42,1l0.16,0.9l0.5,0.82l0.32,0.99l0.33,0.81l0.49,0.98l0.33,0.82l0.58,0.91l0.16,0.91l0.66,0.9l0.66,1.73l0.41,0.9l0.4,0.99
        l0.24,0.83l0.58,0.9l0.26,0.98l0.32,0.91l0.58,0.83l0.42,0.97l0.24,0.82l0.5,0.99l0.4,0.82l0.42,0.9l0.58,1.82l0.48,0.82l0.41,0.98
        l0.98,1.82l0.08,0.9l0.59,0.9l0.49,0.84l0.42,0.9l0.16,0.91l0.4,0.99l0.58,0.81l0.16,0.98l0.58,0.82l0.42,0.92l0.32,0.9l0.42,0.9
        l0.24,0.82l0.5,0.99l0.24,0.81l0.82,1.82l0.41,0.98l0.59,0.91l0.32,0.91l-0.74,0.83l-1.14,0.07h-1.08l-1.23-1L805.52,471.46z
         M770.69,454.3h1.74l1.22-0.08h0.82l0.84,0.08l1.05-0.08h1l0.98,0.08l0.98-0.08l0.99,0.08l0.9-0.08l1.16,0.08h0.98l0.74-0.08h1.16
        l0.81,0.08l1.06-0.08h1.97l0.92,0.08h0.98l1.15-0.08l1.06,0.08l0.66-0.08l0.98,0.08l1.07-0.08l0.98,0.08l0.73-0.99l-0.56-0.9
        l-0.33-0.82l-0.4-0.99l-0.49-0.91l-0.25-0.9l-0.49-0.82l-0.42-0.92l-0.24-0.98l-0.98-1.82l-0.34-0.9l-0.42-0.9l-0.16-0.91
        l-0.58-0.83l-0.32-0.9l-0.58-0.98l-0.4-0.83l-0.1-0.98l-0.4-0.82l-0.49-0.99l-1.16-2.7l-0.32-0.92l-0.66-0.81l-0.42-0.99l-0.16-0.91
        l-0.48-0.9l-0.5-0.91l-0.41-0.9l-0.32-0.84l-0.26-0.9l-0.66-0.9l-0.08-0.99l-0.39-0.82l-0.82-0.08l-0.42,0.91l-0.49,0.99l-0.4,0.9
        l-0.26,0.82l-0.49,0.99l-0.33,0.82l-0.4,0.98l-0.49,0.82l-0.34,0.99l-0.98,1.8l-0.16,0.84l-0.5,0.98l-0.39,0.9l-0.42,0.91
        l-0.25,0.83l-0.41,0.9l-0.5,0.98l-0.32,0.83l-0.42,0.98l-0.4,0.82l-0.41,0.99l-0.58,0.82l-0.16,0.98l-0.42,0.91l-0.4,0.82
        l-0.34,0.98l-0.4,0.9l-0.58,0.84l-0.24,0.9l-0.65,0.97l-0.18,0.92l-0.48,0.9l-0.27,0.9l-0.39,0.91l0.5,0.83L770.69,454.3z"></path>
    <polygon className="st2 svg-elem-12" points="873.67,469.57 874.67,469.48 875.56,469.57 882.23,469.57 883.37,469.48 884.27,469.57 886.32,469.57 
        887.4,469.48 890.35,469.48 891.26,469.57 893.06,469.57 894.3,469.48 895.2,469.48 896.18,469.57 903.91,469.57 904.9,469.48 
        905.71,470.4 905.79,471.38 905.79,472.36 904.56,473.35 899.63,473.35 898.89,473.27 896.84,473.27 895.77,473.35 893.88,473.35 
        892.99,473.27 891.83,473.27 890.93,473.35 890.11,473.27 889.12,473.27 887.97,473.35 887.06,473.27 886,473.35 884.11,473.35 
        883.37,473.27 882.4,473.35 880.26,473.35 879.34,473.27 878.28,473.35 877.2,473.35 876.3,473.27 875.33,473.35 874.58,473.27 
        873.44,473.27 872.46,473.35 871.45,473.27 870.56,473.35 869.74,473.27 868.5,473.27 867.76,472.28 867.76,471.3 867.68,470.32 
        867.52,469.33 867.52,468.35 867.68,467.43 867.52,466.37 867.76,465.29 867.52,464.31 867.52,463.34 867.76,462.34 867.68,461.36 
        867.68,460.37 867.76,459.47 867.52,458.49 867.52,457.41 867.61,456.43 867.61,453.48 867.52,452.57 867.68,451.51 867.76,450.43 
        867.76,449.53 867.52,448.53 867.76,447.47 867.52,446.49 867.68,445.58 867.68,444.6 867.52,443.52 867.52,441.65 867.61,440.65 
        867.61,439.67 867.76,438.68 867.52,437.62 867.76,436.54 867.61,435.56 867.52,434.59 867.76,433.67 867.52,432.69 867.76,431.7 
        867.68,430.71 867.61,429.66 867.61,428.66 867.68,427.76 867.61,426.77 867.61,425.79 867.68,424.81 867.68,423.82 867.52,422.76 
        867.61,421.68 867.61,419.71 867.68,418.8 867.76,417.74 867.52,416.83 868.58,415.85 869.48,415.77 870.56,415.77 871.54,416.75 
        871.45,417.82 871.71,418.8 871.45,419.71 871.62,420.7 871.71,421.6 871.62,422.67 871.45,423.65 871.54,424.55 871.62,425.63 
        871.54,426.6 871.54,427.51 871.62,428.5 871.62,429.48 871.54,430.39 871.62,431.45 871.54,432.36 871.62,433.35 871.54,434.33 
        871.71,435.32 871.54,436.39 871.54,438.2 871.45,439.25 871.71,440.17 871.71,443.12 871.45,444.1 871.62,445.09 871.71,446.16 
        871.54,447.06 871.71,447.97 871.62,449.04 871.45,449.93 871.62,451.01 871.71,451.98 871.62,452.91 871.45,453.97 871.62,454.88 
        871.54,455.85 871.54,456.83 871.62,457.83 871.62,459.81 871.54,460.71 871.62,461.77 871.45,463.74 871.71,464.73 871.71,466.53 
        871.54,467.61 871.62,468.5 872.53,469.57 "></polygon>
    </svg>
</div>
        ) : (
          <div className="completed">&#x2713;</div>
        )}
      </>
    ) : (
      <> */}
            <QueryClientProvider>
      <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AuthProvider>
          <Router>
            <Switch>
              <Route exact path="/" component={IndexPage} />
              <Route exact path="/seller" component={SellerPage} />
              <Route exact path="/product/:productId" component={ProductPage} />
              <Route exact path="/enquiry/:productId" component={Enquirypage} />
              <Route exact path="/faq" component={Faq} />
              <Route exact path="/privacy" component={privacy} />
              {/* <Route exact path="/auth/:type" component={AuthPage} />  from git */}
              <Route exact path="/leads" component={LandingPage} />
              <Route exact path="/auth/:type/:referralCode?" component={AuthPage} />
              <Route exact path="/admin/auth/:type" component={AuthPageadmin} />
              <Route exact path="/cp/auth/:type" component={AuthPagecp} />
              <Route exact path="/auth/:type/:referralCode?" component={AuthPagecp} />
              <Route exact path="/signout" component={Signoutpage} />
              <Route exact path="/cp/myclient/:alert?" component={MyClients} />
              <Route exact path="/admin/createuser" component={AdminCreateUser} />
              <Route exact path="/admin/products" component={AdminProduct} />
              <Route exact path="/admin/createchannelpartner" component={AdminCreateChannelPartner} />
              <Route exact path="/admin/users" component={UserList} />
              <Route exact path="/admin/eoi" component={EOIApprove} />
              <Route exact path="/admin/transaction" component={TransactionTablenew} />
              <Route exact path="/user/dashboard" component={Userdasboard}/>
              <Route exact path="/user/offerings" component={Userofferings}/>
              <Route exact path="/user/documents" component={Userdocuments} />
              <Route exact path="/user/details" component={Userdetails} />
              <Route exact path="/cp/investor/:userId/details" component={ChannelPartnerClientdetails} />
              <Route exact path="/user/properties" component={Userrealestate} />
              <Route exact path="/user/transactions" component={Usertransaction} />
              <Route exact path="/cp/dashboard" component={CPdashboard} />
              <Route exact path="/cp/documents" component={CPdocumentscp} />
              <Route exact path="/cp/clients" component={CPclients} />
              <Route exact path="/cp/teams" component={CPteam} />
              <Route exact path="/cp/portfolio" component={Myportfolio} />
              <Route exact path="/cp/profile" component={CPprofile} />
              <Route exact path="/unauthorized" component={UnAuthorizedPage} />
              <Route
                exact
                path="/firebase-action"
                component={FirebaseActionPage}
              />
              <Route component={NotFoundPage} />
            </Switch>
          </Router>
        </AuthProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
      {/* </>
    )} */}
  </>

  );
}

export default App;
