import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Roadtos1 from "./Roadtos1";
import SectionHeader4As1 from "./SectionHeader4As1";
import Aos from 'aos';
import { useRouter } from "../util/router";
import "aos/dist/aos.css";
import useMediaQuery from '@material-ui/core/useMediaQuery';
const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  image: {
    margin: "0 auto",
    maxWidth: 570,
    display: "block",
    height: "auto",
    width: "100%",
  },
}));

function Section4A(props) {
  const isSmall = useMediaQuery('(min-width:800px)');
  const classes = useStyles();
  React.useEffect(() => {
    Aos.init({ duration: 2000 });
}, [])
  return (
    <Roadtos1
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <Grid container={true} alignItems="left" spacing={6} style={{marginBottom:'25px'}}>
          <Grid item={true} xs={12} md={true}>
            <figure>
              <img
              loading=" lazy"
                src={props.image}
                alt="illustration"
                className={classes.image}
                style={{ marginLeft: isSmall ? "-34px" : "0px" }}
              />
            </figure>
          </Grid>
          <Grid container={true} item={true} direction="column" xs={12} md={6}>
            <Box textAlign={{ xs: "left", md: "left" }}>
              <SectionHeader4As1
                title={props.title}
                subtitle={props.subtitle}
                size={5}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Roadtos1>
  );
}

export default Section4A;
