import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    maxWidth: 700,
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: "inline-block",
  },
}));

function Page1S23(props) {
  const classes = useStyles();
  const isSmall = useMediaQuery('(min-width:800px)');
  const {
    subtitle,
    subtitle1,
    text1,
    text2,
    title,
    ps,
    psqft,
    pc,
    tirr,
    ey,
    ifr,
    mi,
    size,
    size1,
    className,
    ...otherProps
  } = props;

  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <>
      <Box
        component="header"
        className={
          classes.root + (props.className ? ` ${props.className}` : "")
        }
        {...otherProps}
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignContent: "space-between",
          }}
        >
          {text1 && (
            <Typography
              variant={size1}
              style={{fontSize:isSmall? "20px": "15px", fontWeight:"700"}}
              gutterBottom={props.subtitle ? true : false}
            >
             {ps} <br/>
              {ps && (
                <Typography
                
                  color="primary"
                  style={{fontSize:isSmall? "15px": "10px", fontWeight:"600"}}
                  gutterBottom={props.subtitle ? true : false}
                  
                >
                  Invested Amount
                </Typography>
              )}
            </Typography>
          )}


        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignContent: "space-between",
          }}
        >
          {text1 && (
            <Typography
              variant={size1}
              style={{fontSize:isSmall? "20px": "15px", fontWeight:"700"}}
              gutterBottom={props.subtitle ? true : false}
            >
              {psqft}%    <br/>
              {ps && (
                <Typography
               
                  color="primary"
                  gutterBottom={props.subtitle ? true : false}
                  style={{fontSize:isSmall? "15px": "10px", fontWeight:"600"}}
                >
                  Avg. Rental Yield
                </Typography>
              )}
            </Typography>
          )}


        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignContent: "space-between",
          }}
        >
          {text1 && (
            <Typography
            style={{fontSize:isSmall? "20px": "15px", fontWeight:"700"}}
            variant={size1}
              gutterBottom={props.subtitle ? true : false}
            >
              {pc} <br/>
              {psqft && (
                <Typography
                
                  color="primary"
                  gutterBottom={props.subtitle ? true : false}
                  style={{fontSize:isSmall? "15px": "10px", fontWeight:"600"}}
                >
                 Asset Value
                </Typography>
              )}
            </Typography>
          )}

        </div>
      </Box>

    </>
  );
}

export default Page1S23;
