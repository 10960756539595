import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import VideogameAssetIcon from "@material-ui/icons/VideogameAsset";
import PetsIcon from "@material-ui/icons/Pets";
import { makeStyles } from "@material-ui/core/styles";
import Page1New223S31 from "./Page1New223S31";
import Page1New23Ss32 from "./Page1New23Ss32";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
  },
  imageWrapper: {
    margin: "0 auto",
    maxWidth: 570,
    width: "100%",
    "& > img": {
      width: "100%",
    },
  },
  row: {
    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(2)}px`,
    },
  },
}));

function Page1S3New(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Play",
      description:
        "We embedded a bunch free flash games inside our app. What we lack in quality we make up in quantity.",
      icon: VideogameAssetIcon,
      iconColor: "secondary.main",
    },
    {
      title: "Cats",
      description:
        "Our market research told us people like cats so we put some cat icons on our website.",
      icon: PetsIcon,
      iconColor: "#00d1b2",
    },
  ];

  return (
    <Page1New223S31
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <Grid container={true} alignItems="center" spacing={8}>
          <Grid item={true} xs={12} md={6}>
            <Page1New23Ss32
              title={props.title}
              subtitle={props.subtitle}
              subtitle1={props.subtitle1}
              size={5}
            />
          </Grid>
          <Grid
            item={true}
            xs={12}
            md={6}
            style={{ color: "#ffffff", textAlign: "center" }}
          >
            <div
              style={{
                backgroundColor: "#262632",
                height: "50px",
                marginBottom: "10px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <a style={{ fontSize: "14px", fontWeight: "700" }}>
                Minimum Investment: ₹5 lakhs
              </a>
            </div>
            <div
              style={{
                backgroundColor: "#3DAEB6",
                height: "50px",
                marginBottom: "10px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <a style={{ fontSize: "14px", fontWeight: "700" }}>
                Rental Yield: 7-9%
              </a>
            </div>
            <div
              style={{
                backgroundColor: "#262632",
                height: "50px",
                marginBottom: "10px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <a style={{ fontSize: "14px", fontWeight: "700" }}>IRR: 12-15%</a>
            </div>
          </Grid>
        </Grid>
        <Grid container={true} alignItems="top" spacing={8}>
          <Grid
            item={true}
            xs={12}
            md={6}
            style={{ color: "#ffffff", textAlign: "left" }}
          >
            <p style={{ fontSize: "15px" }}>
              <a
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  color: "#3DAEB6",
                }}
              >
                Small Bites, Big GAYNs.
              </a>
              <br /> <br />
              At GAYN CAPITAL, we unlock such potential by harnessing the power
              of Collective Ownership. We are proud to present the country's
              most advanced digital ecosystem for Fractional Ownership in
              institutional-grade Commercial Real Estate (CRE) assets.
            </p>
          </Grid>
          <Grid
            item={true}
            xs={12}
            md={6}
            style={{ color: "#ffffff", textAlign: "left" }}
          >
            <p style={{ fontSize: "15px" }}>
              <a
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  color: "#3DAEB6",
                }}
              >
                Lakhs, not crores.
              </a>
              <br /> <br />
              We make CRE investments a reality for you. We democratize CRE
              ownership by breaking it up into smaller affordable fragments, and
              we make these fragments accessible to Indian investors with a
              varying appetite.
            </p>
          </Grid>
        </Grid>
      </Container>
    </Page1New223S31>
  );
}

export default Page1S3New;
