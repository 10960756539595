import React, { useEffect, useState } from "react";
import { getAllFiles, getFileUrl } from '../../util/firebase-storage'
import { getAllUsers, getAllChannelPartners, updateUser, updateChannelPartner, getChannelPartner, getUser, getAllEOIProductsForUserid  } from '../../util/db'
import Button from "@material-ui/core/Button";
import Paper from '@material-ui/core/Paper';
import TextField from "@material-ui/core/TextField";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import SearchIcon from '@material-ui/icons/Search';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import AdminCreateUser from "./CreateUser";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { InputAdornment } from "@material-ui/core";
import AdminCreateChannelPartner from "./CreateChannelPartner";
import { requireAuth } from "../../util/auth";
import AdminNavbar from "../../components/AdminNavbar";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MaterialTable from 'material-table';
import { DataGrid } from '@mui/x-data-grid';


function UserList() {
    const [showFilter, setShowFilter] = useState(false);
    // call firebase and get all files list
    const [users, setUsers] = useState([]);
    const [uid, setUid] = useState(null);
    const [files, setFiles] = useState([]);
    const [userType, setUserType] = useState(null);
    const [adkyc, setAdkyc] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedUserType, setSelectedUserType] = useState("user");
    const [copyList, setCopyList] = useState(users)
    const columns = [
        { title: "Name", field: "name", editable: false},
        { title: "Email", field: 'email', editable: false },
        { title: "Phone", field: "phone_number", editable: false },
        { title: "Role", field: "role", lookup: { "user": 'Investor', "cp": 'Channel Partner' }, },
        { title: "KYC Status", field: "kyc_status",  lookup: { "incomplete": 'Incomplete', "submitted": 'Submitted', "Completed": 'Completed' }},
        {
            title: 'Actions',
            field: 'actions',
            tooltip: 'Actions',
            render: rowData => (
              <>
                <Button  variant="contained"
                 color="primary" 
                 size="small"
                 onClick={() => {if(rowData.role === "cp"){setUid(rowData.id);setUserType("cp"); setAdkyc(true);}else{setUid(rowData.id);setUserType("user"); setAdkyc(true);}}}>
                    View</Button>
              </>
            ),
          },
      ]
  const requestSearch = (searched) => {
    setCopyList(users.filter((item) => item.email.includes(searched)))
  }
  const requestSearchn = (searched) => {
    setCopyList(users.filter((item) => item.first_name.includes(searched)))
  }

    useEffect(() => {
        getRegisteredUsers();
    }, []);


    const getRegisteredUsers = () => {
        getAllUsers().then(res => {
            setUsers(res.filter(x => x.role !== "admin"));
            setUid(null);
        });
    };

    useEffect(() => {
        getRegisteredUsers();
    }, []);

    useEffect(() => {
        if (uid != null) {
            fetchKYCFiles();
            // from git
            getAllEOIProductsForUserid(uid);
            getUser(uid).then(user => setSelectedUser(user));
        }
    }, [uid]);

    const fetchKYCFiles = async () => {
        const respFiles = await getAllFiles(uid);

        const allFiles = [];
        for (let index = 0; index < respFiles.items.length; index++) {
            const element = respFiles.items[index];
            allFiles.push({
                bucket: element.bucket,
                name: element.name,
                fullPath: element.fullPath,
                url: await getFileUrl(element.fullPath)
            });
        }

        setFiles(allFiles);
        console.table(allFiles);
    }

    const showOfflineApproveButton = (fileName) => {
        if (fileName == "Aadhar") {
            return !selectedUser?.aadhaar_offline_verified;
        } else if (fileName == "Back Document") {
            return !selectedUser?.bank_account_offline_verified;
        } else if (fileName == "PAN") {
            return !selectedUser?.pan_offline_verified;
        }
    }

    const updateOfflineKYC = (fileName) => {
        const currentUid = uid;
        let updateProps = { offlineKYCVerified: true };
        if (fileName == "Aadhar") {
            updateProps = { ...updateProps, aadhaar_offline_verified: true };
        } else if (fileName == "Back Document") {
            updateProps = { ...updateProps, bank_account_offline_verified: true };
        } else if (fileName == "PAN") {
            updateProps = { ...updateProps, pan_offline_verified: true };
        }
        setUid(null);
        updateUser(currentUid, updateProps).then(setUid(currentUid));
    }

    const updateOfflineKYC1 = (fileName) => {
        const currentUid = uid;
        let updateProps = { offlineKYCVerified: false };
        if (fileName == "Aadhar") {
            updateProps = { ...updateProps, aadhaar_offline_verified: false };
        } else if (fileName == "Back Document") {
            updateProps = { ...updateProps, bank_account_offline_verified: false };
        } else if (fileName == "PAN") {
            updateProps = { ...updateProps, pan_offline_verified: false };
        }
        setUid(null);
        updateUser(currentUid, updateProps).then(setUid(currentUid));
    } 

    return (
        
        <Box >
            <AdminNavbar
                color="default"
                logo="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-black.png?alt=media&token=4df2ee2a-8696-48b5-8a65-98171d8179d9"
                logoInverted="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096"
            />
            <div style={{ padding: '2rem' }}>


                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        {uid != null && userType === "user" && <AdminCreateUser userId={uid} />}
                        {uid != null && userType === "cp" && <AdminCreateChannelPartner userId={uid} />}
                    </Grid>
                    {adkyc === false  &&
                    <Grid item xs={12} >
                          <MaterialTable
        title="User List"
        data={users}
        columns={columns}
        actions={[
            {
                icon: 'filter_list',
                tooltip: 'Filter List',
                isFreeAction: true,
                onClick: () => setShowFilter(!showFilter)
              }
          ]}
        options={{
            search: true,
            filtering: showFilter,
            exportButton: true,
            headerStyle: {
                backgroundColor: '#3DAEB6',
                color: '#FFFFFF',
                '&:active': {
                    color: "#3DAEB6",
                  },
              },
              rowStyle: {
                backgroundColor: '#DCDCDC',
              },
          actionsColumnIndex: -1, addRowPosition: "first"
        }}

        
      />
                    </Grid>
                    }
                </Grid>
            </div>
        </ Box >
    )
}

export default requireAuth(UserList, ["admin"]);
// export default UserList