import React, { useEffect, useState } from "react";
import Meta from "./../components/Meta";
import Container from "@material-ui/core/Container";
import Navbar from "./../components/Navbar";
import Page1S2 from "./../components/Page1S2";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { uploaddocToBucket } from '../util/firebase-storage';
import TextField from "@material-ui/core/TextField";
import MenuItem from '@mui/material/MenuItem';
import Section5421 from "./../components1/Section542";
import Page1S31 from "./../components1/Page1S3";
import Page1News41 from "./../components1/Page1News4";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import Section4A1 from "./../components1/Section4A";
import Section4B1 from "./../components1/Section4B";
import Page1Snew1 from "./../components1/Page1Snew";
import Sectionhp11 from "./../components1/Sectionhp1";
import Roadto1 from "./../components1/Roadto";
import Page1S2New1 from "./../components1/Page1S2New";
import Ever1 from "./../components1/Ever";
import { ToastContainer, toast } from 'react-toastify';
import Page1S3New1 from "./../components1/Page1S3New";
import TeamBiosSection1 from "./../components1/TeamBiosSection";
import Page1S3Fs1 from "./../components1/Page1S3Fs";
import ClientsSection1 from "./../components1/ClientsSection";
import Footer from "./../components/Footer";
import { getaProducts} from '../util/db';
import Aos from 'aos';
import { useRouter } from "../util/router";
import "aos/dist/aos.css";
import Ever1alt from "./../components1/ever1alt";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {Helmet} from "react-helmet";


function IndexPage(props) {
  const isSmall = useMediaQuery('(min-width:800px)');
  const route = useRouter();
  useEffect(() => {
    Aos.init({ duration: 2000 });
}, [])
debugger;
const [mobileyes, setMobileYes] = useState(useMediaQuery('(max-width:800px)'));
const [panFile, setPanFile] = useState(null);
const [panfVerified, setPANfVerified] = useState(false);
const [pending10, setPending10] = useState(false);
const pancallbackFunction = (fileName) => { 
  setPending10(false);
  setPANfVerified(true);
  toast.success("PAN Uploaded Successfully", {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
}
const [doctype, setDoctype] = React.useState('');
const [isMobileView, setIsMobileView] = React.useState(false);

React.useEffect(() => {
  const handleResize = () => setIsMobileView(window.innerWidth < 768);

  handleResize();
  window.addEventListener('resize', handleResize);

  return () => window.removeEventListener('resize', handleResize);
}, []);
const uploadPan = () => {
  setPending10(true);
  uploaddocToBucket('dWjFy1y1XVJ3Ue8EYFBx', pancallbackFunction, panFile, doctype, doctype);
}
const handleosChange = (event) => {
  setDoctype(event.target.value);
};
  const [products, setProducts] = useState([]);
  const fetchProductsForFilter = async () => {
    const temp = await getaProducts('dWjFy1y1XVJ3Ue8EYFBx');
   

    setProducts(temp);
}
useEffect(() => {
  fetchProductsForFilter();
}, []);

  return (
    <>
{isMobileView ? (
    <Helmet>
        <script>
{`(function (w, d, s, u) {
    w.gbwawc = {
        url: u,
        options: {
            waId: "919884900130",
            siteName: "GAYN Capital",
            siteTag: "Usually reply in 4 minutes",
            siteLogo: "https://files.gallabox.com/643908feb2c07f9481782a19/9bf19785-db3f-4c89-b55a-c9d9d519261c-logo.jpg",
            widgetPosition: "RIGHT",
            welcomeMessage: "Welcome to GAYN Capital!!!",
            brandColor: "#25D366",

        },
    };
    var h = d.getElementsByTagName(s)[0],
        j = d.createElement(s);
    j.async = true;
    j.src = u + "/whatsapp-widget.min.js?_=" + Math.random();
    h.parentNode.insertBefore(j, h);
})(window, document, "script", "https://waw.gallabox.com")`}
</script>
       
      </Helmet>
      ):(<Helmet></Helmet>)}

      <Meta />
      <Navbar
        color="default"
        logo="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-black.png?alt=media&token=4df2ee2a-8696-48b5-8a65-98171d8179d9"
        logoInverted="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096"
      />
 <section  data-aos="fade-up" data-aos-duration="1200">
            <Section5421
        bgColor="black"
        size="large"
        bgImage="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/roadto.png?alt=media&token=765588f5-43fb-4ed1-a8d2-3ed485c4fa26"
        bgImageOpacity={1}
        title={<h2
          style={{
            fontSize: isSmall?'40px': "35px",
            fontWeight: "600",
            color: "white",
            letterSpacing: "1vw",
            marginTop:'-3%'
          }}
        >G<a style={{color: "#3DAEB6"}}>A</a>YN CAPITAL</h2>}
        subtitle={
          <h2
            style={{
              fontSize: '20px',
              fontWeight: "500",
              color: "primary",
              marginTop:'-3%'
            }}
          > 
            <p style={{  fontSize: isSmall?'22px': "16px", fontWeight: "500", marginTop: "6%", color: "#ffffff"}}>
            THE ROAD TO  FINANCIAL  FREEDOM IS LESS ABOUT FINANCES AND MORE ABOUT FREEDOM
            </p>
          </h2>
        }
        buttonText="View properties"
        buttonColor="primary"
        buttonPath="/Product"
      /></section>

      <section       style={{
          bottom: "0px",
          backgroundColor: "rgba(61, 174, 182, 0.8)",
          marginTop: isSmall? "-100px" : "-85px",
          height: "5%",
          zIndex: "999",
        }}>
        <div
          style={{
            height: "5%",
            width: "100%",
            color: "#ffffff",
            textAlign: "center",
            marginTop: "-10px",
            zIndex: "2",
          }}
        >
          <p style={{ fontStyle: "italic", paddingTop: "13px", fontSize: "15px"}}>
            <q>
              If you don't find a way to make money while you sleep, you will
              work until you die.
            </q>{" "}
            Warren Buffet
          </p>
        </div>
        </section>
      {/* </Container> */}
      <section id="sectionp">

      <Page1S2
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        text1="Property Size"
        ps="80000"
        psqft="8000"
        pc="317,229,880"
        tirr="16%"
        ey="9%"
        testext="22dda"
        ifr="Monthly"
        mi="Rs. 25,00,000"
        title= {products.property_name}
        subtitle={products.location}
        subtitle1="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nunc massa habitant amet. Suscipit gravida nisl, ipsum nulla neque ornare. Condimentum nisi, sed nunc amet enim. Dolor venenatis sed gravida sodales lobortis feugiat odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nunc massa habitant amet. Suscipit gravida nisl, ipsum nulla neque ornare. Condimentum nisi, sed nunc amet enim. Dolor venenatis sed gravida sodales lobortis feugiat odio."
        image="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/current-offer.png?alt=media&token=66841019-8150-4511-b576-97fe3cdc5af7"
      /> </section>
                <section  id="section2" data-aos="fade-up" data-aos-duration="1200">

            <Page1S31
        bgColor="default"
        size="medium"
        bgImage="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/backg.jpg?alt=media&token=5d02fc61-bc82-404c-a56c-858b59a208b6"
        bgImageOpacity={1}
        text1="Property Size"
        ps="80000"
        psqft="8000"
        pc="317,229,880"
        tirr="16%"
        ey="9%"
        ifr="Monthly"
        mi="Rs. 25,00,000"
        title={
          <a style={{ color: "#3DAEB6", fontWeight: "800" }}>
            4 Reasons to choose CRE Investing in 2023
          </a>
        }
        subtitle={
          
          <div 
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              flexDirection: "column",
            
            }}
          >
            {" "}
            <p style={{ color: "#ffffff"}} >
              Unprecedented leasing growth thanks to the pent-up demand finally
              being fulfilled.Â 
            </p>
            <p style={{ color: "#ffffff" }}>
              The CRE market in India is estimated to grow by a{" "}
              <a style={{ color: "#3DAEB6" }}>CAGR of 13-16% </a>in the next
              five years.
            </p>
            <p style={{ color: "#ffffff" }}>
              Close to <a style={{ color: "#3DAEB6" }}>30 per cent of NRIs</a>{" "}
              are interested in fractional ownership of CRE in India.
            </p>
            <p style={{ color: "#ffffff" }}>
              Tipped to become a $5 billion industry in the next three years.
            </p>
          </div>
        }
        image="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/02.png?alt=media&token=e5ed6cd2-a108-4393-bf46-d8ac59659735"
      /> </section>
                <section data-aos="fade-up" data-aos-duration="1200">

      <Page1News41
        bgColor="default"
        size="medium"
        bgImage="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/Rectangle%20108.png?alt=media&token=b1ca174c-40fb-4e70-8ee1-15e88c84964b"
        bgImageOpacity={1}
        text1="Property Size"
        ps="80000"
        psqft="8000"
        pc="317,229,880"
        psqft="8000"
        tirr="16%"
        ey="9%"
        ifr="Monthly"
        mi="Rs. 25,00,000"
        title={
          <a style={{ color: "#3DAEB6" }}>
            4 Reasons to choose CRE Investing in 202
          </a>
        }
        subtitle='a'
        image="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/02.png?alt=media&token=e5ed6cd2-a108-4393-bf46-d8ac59659735"
      /></section>
                <section id="section1" data-aos="fade-up" data-aos-duration="1200">

                <Section4A1
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title={
          <h3>
            GAYN Capital <br />
            <a
              style={{
                fontStyle: "italic",
                fontSize: "15px",
                color: "#000000",
              }}
            >
              Passive Income for Life.
            </a>
          </h3>
        }
        subtitle={
          <p style={{ fontSize: "15px" }}>
            <a style={{ fontWeight: "600", fontSize: "15px" }}>
              <a style={{ fontWeight: "700" }}>
                G<a style={{ color: "#3DAEB6" }}>A</a>YN
              </a>{" "}
              Capital is a FinTech Company that leverages technology to make
              Premium CRE Investing Possible for Everyone.{" "}
            </a>
            <br /> <br />
            The{" "}
            <a style={{ fontWeight: "700" }}>
              G<a style={{ color: "#3DAEB6" }}>A</a>YN Capital
            </a>{" "}
            team sources the best real estate opportunities in India’s thriving industries such as Retail, Logistics, Healthcare, and Entertainment. Our properties are carefully curated with a clear focus on high-quality tenants.
          </p>
        }
        image="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/sec2img.png?alt=media&token=445f9a86-2162-44b4-92a6-a1b918bae094"
        buttonText="View Properties"
        buttonColor="primary"
        buttonPath="/pricing"
      /> </section>
                <section data-aos="fade-up" data-aos-duration="1200">

      <Section4B1 /> </section>
      <section data-aos="fade-up" data-aos-duration="1200">

 <Grid
        container={true}
        alignItems="center"
        spacing={0}
      > 
 <Grid item={true} xs={12} md={12} style={{marginBottom:"-5px"}}>
 <video controls
        src= {"https://firebasestorage.googleapis.com/v0/b/gayn-82759.appspot.com/o/gayn%20final%20HD.mp4?alt=media&token=a8d0bd93-84bb-46dd-89b7-06d1b755d102"}
        style={{ width: "100%"}}
      />
      </Grid>
      
      </Grid>
    </section>

      <section data-aos="fade-up" data-aos-duration="1200">

      <Page1Snew1
        bgColor="default"
        size="medium"
        bgImage="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/06.jpg?alt=media&token=ea20af68-933e-4edc-a1b6-f088a51bc124"
        bgImageOpacity={1}
        text1="Property Size"
        ps="80000"
        psqft="8000"
        pc="317,229,880"
        tirr="16%"
        ey="9%"
        ifr="Monthly"
        mi="Rs. 25,00,000"
        title={
          <a style={{ color: "#ffffff" }}>
            An Alternative Investment Platform defined by{" "}
            <a style={{ color: "#3DAEB6" }}>
              {" "}
              Scalability, Simplicity, and Speed
            </a>
          </a>
        }
        subtitle={
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              flexDirection: "column",
            }}
          >
            {" "}
            <p style={{ color: "#ffffff" }}>
              Unprecedented leasing growth thanks to the pent-up demand finally
              being fulfilled.Â 
            </p>
            <p style={{ color: "#ffffff" }}>
              The CRE market in India is estimated to grow by a{" "}
              <a style={{ color: "#3DAEB6" }}>CAGR of 13-16% </a>in the next
              five years.
            </p>
            <p style={{ color: "#ffffff" }}>
              Close to <a style={{ color: "#3DAEB6" }}>30 per cent of NRIs</a>{" "}
              are interested in fractional ownership of CRE in India.
            </p>
            <p style={{ color: "#ffffff" }}>
              Tipped to become a $5 billion industry in the next three years.
            </p>
          </div>
        }
        image="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/02.png?alt=media&token=e5ed6cd2-a108-4393-bf46-d8ac59659735"
      /></section>
                <section data-aos="fade-up" data-aos-duration="1200">

      <Sectionhp11
        bgColor="default"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
        title="The GAYN Edge"
        subtitle="Get access to data-driven insights and quantitative analytics on your property portfolio"
        image="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/image4.png?alt=media&token=aeb07d38-15f9-47ef-9862-30da49b4bcb0"
        buttonText="View Properties"
        buttonColor="primary"
        buttonPath="/pricing"
      /> </section>
                {/* <section data-aos="fade-up" data-aos-duration="1200">

      <Roadto1
        bgColor="default"
        size="large"
        bgImage="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/roadto.png?alt=media&token=765588f5-43fb-4ed1-a8d2-3ed485c4fa26"
        bgImageOpacity={1}
        title="GAYN CAPITAL"
        subtitle={
          <a
            style={{
              fontSize: "2vw",
              fontWeight: "700",
              marginTop: "2%",
              color: "white",
            }}
          >
            THE ROAD TO FINACIAL FREEDOM IS LESS ABOUT FINANCES AND MORE ABOUT
            FREEDOM
          </a>
        }
        buttonText="View properties"
        buttonColor="primary"
        buttonPath="/Product"
      /> </section> */}
                <section data-aos="fade-up" data-aos-duration="1200">

      <Page1S2New1
        bgColor="default"
        size="medium"
        bgImage="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/06.jpg?alt=media&token=ea20af68-933e-4edc-a1b6-f088a51bc124"
        bgImageOpacity={1}
        text1="Property Size"
        ps="80000"
        psqft="8000"
        pc="317,229,880"
        psqft="8000"
        tirr="16%"
        ey="9%"
        ifr="Monthly"
        mi="Rs. 25,00,000"
        title={
          <a style={{ color: "#ffffff" }}>
            An Alternative Investment Platform defined by{" "}
            <a style={{ color: "#3DAEB6" }}>
              {" "}
              Scalability, Simplicity, and Speed
            </a>
          </a>
        }
        subtitle={
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              flexDirection: "column",
            }}
          >
            {" "}
            <p style={{ color: "#ffffff" }}>
              Unprecedented leasing growth thanks to the pent-up demand finally
              being fulfilled.Â 
            </p>
            <p style={{ color: "#ffffff" }}>
              The CRE market in India is estimated to grow by a{" "}
              <a style={{ color: "#3DAEB6" }}>CAGR of 13-16% </a>in the next
              five years.
            </p>
            <p style={{ color: "#ffffff" }}>
              Close to <a style={{ color: "#3DAEB6" }}>30 per cent of NRIs</a>{" "}
              are interested in fractional ownership of CRE in India.
            </p>
            <p style={{ color: "#ffffff" }}>
              Tipped to become a $5 billion industry in the next three years.
            </p>
          </div>
        }
        image="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/02.png?alt=media&token=e5ed6cd2-a108-4393-bf46-d8ac59659735"
      /> 
      <Ever1
        bgColor="primary"
        size="large"
        // bgImage="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/ever.png?alt=media&token=7f32963a-104d-4847-82db-58571358abb4"
        bgImageOpacity={1}
        title="GAYN CAPITAL"
        subtitle={
          <p
            style={{
              fontSize: isSmall?"25px": "20px",
              fontWeight: "700",
              marginTop: "2%",
              color: "white",
              fontVariant:'all-petite-caps'
            }}
          >
            Ever imagined earning a monthly rental income from your
            neighbourhood mall or owning the very building you work in? <br />{" "}
            <br />
            Well, you can stop imagining and start acting. Now!
          </p>
        }
        buttonText="View properties"
        buttonColor="primary"
        buttonPath="/Product"
      />
      </section><section data-aos="fade-up" data-aos-duration="1200">
      <Ever1alt></Ever1alt></section>
      {/* <section data-aos="fade-up" data-aos-duration="1200">

      <Page1S3New1
        bgColor="default"
        size="medium"
        bgImage="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/06.jpg?alt=media&token=ea20af68-933e-4edc-a1b6-f088a51bc124"
        bgImageOpacity={1}
        text1="Property Size"
        ps="80000"
        psqft="8000"
        pc="317,229,880"
        psqft="8000"
        tirr="16%"
        ey="9%"
        ifr="Monthly"
        mi="Rs. 25,00,000"
        title={
          <a style={{ color: "#ffffff" }}>
            An Alternative Investment Platform defined by{" "}
            <a style={{ color: "#3DAEB6" }}>
              {" "}
              Scalability, Simplicity, and Speed
            </a>
          </a>
        }
        subtitle={
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              flexDirection: "column",
            }}
          >
            {" "}
            <p style={{ color: "#ffffff" }}>
              Unprecedented leasing growth thanks to the pent-up demand finally
              being fulfilled.Â 
            </p>
            <p style={{ color: "#ffffff" }}>
              The CRE market in India is estimated to grow by a{" "}
              <a style={{ color: "#3DAEB6" }}>CAGR of 13-16% </a>in the next
              five years.
            </p>
            <p style={{ color: "#ffffff" }}>
              Close to <a style={{ color: "#3DAEB6" }}>30 per cent of NRIs</a>{" "}
              are interested in fractional ownership of CRE in India.
            </p>
            <p style={{ color: "#ffffff" }}>
              Tipped to become a $5 billion industry in the next three years.
            </p>
          </div>
        }
        image="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/02.png?alt=media&token=e5ed6cd2-a108-4393-bf46-d8ac59659735"
      /> </section> */}
                <section id="section3" data-aos="fade-up" data-aos-duration="1200">

      <TeamBiosSection1
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Meet the Team"
        subtitle=""
      /> </section>
                <section data-aos="fade-up" data-aos-duration="1200">

      <Page1S3Fs1 bgColor="default" size="medium" />
      {/* <ClientsSection1
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title={<h6 style={{ color: "#3DAEB6" }}>Our Partners</h6>}
        subtitle=""
      /> */}
 </section>
 <br />  <br /> <br /> <br /> <br />
   
                <section data-aos="fade-up" data-aos-duration="1200">

      <Footer
        bgColor="secondary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        copyright={`Copyright ©  ${new Date().getFullYear()} GAYN Technologies Pvt Ltd`}
        logo="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096"
        logoInverted="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096"
        sticky={true}
      />
      </section>
    </>
  );
}

export default IndexPage;
