import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Sectionak2S1 from "./Sectionak2S1";
import SectionHeaderbk2S2 from "./SectionHeaderbk2S2";
import { Link } from "./../util/router";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { blue } from "@material-ui/core/colors";
const useStyles = makeStyles((theme) => ({}));

function Sectionak5(props) {
  const classes = useStyles();
  const bactypew1 = [
    {
      value: 'Investment Brochure',
      label: "https://firebasestorage.googleapis.com/v0/b/gayn-dev.appspot.com/o/GAYNCapital_Whitefield_Brochure.pdf?alt=media&token=2e97605f-4d77-4794-9e63-6e8e8c5bb8bc" ,
    },
    {
      value: 'Financial sheet',
      label: "https://firebasestorage.googleapis.com/v0/b/gayn-dev.appspot.com/o/Athulya%20Investment%20Model.xlsx?alt=media&token=23fb39d2-eb36-4839-b8c9-cb2b7f560b31",
    },
    {
      value: 'Occupational Certificate',
      label: "https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/Occupancy_Certificate.pdf?alt=media&token=cd76b455-f278-442e-a655-2627f80908f5",
    },
    {
      value: 'RERA',
      label: "https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/RERA_CERTIFICATE.pdf?alt=media&token=a3d56153-5240-4a07-a93a-2d57f8ec2dc3",
    },
    {
      value: 'Lease Agreement',
      label: "https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/NESTPLUS%20LEASE%20DEED-ATHULYA.pdf?alt=media&token=22bcaff2-25c0-42f8-95e2-85c7f21dfad9",
    },
    {
      value: 'Valuation Report',
      label: "https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/Colliers_Valuation%20Report_Whitefield%20.pdf?alt=media&token=2a2028ee-d011-434d-be68-0470872a3b6a",
    },
  ];

  return (
    <Sectionak2S1
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >

      <Container style={{ marginTop: "-120px" }}>
        <section id="Documents">

        <br />
        <br />
        <h2 style={{color: "#3DAEB6"}}>Documents</h2>
        <br />
          <Grid container={true} alignItems="start" spacing={2}>
          
    
      {bactypew1.map((item, index) => (
        <>
        <Grid item={true} xs={12} md={6}>
             <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        <ListItem
          key={index}

        >
          <ListItemText style={{fontWeight: "bold"}} primary={`${item.value}`} />
          <Button         
                // component={Link}
                // to={'/user/details'} 
                color="primary"
                variant="outlined"
                target={"_blank"}
                href={item.label}
            size="normal"
            >Download</Button>
        </ListItem>
        </List>
        </Grid>
     </>
      ))}
    
    
    {/* <Grid item={true} xs={12} md={6}>       <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {bactype.map((item, index) => (
        <ListItem
          key={index}

        >
          <ListItemText primary={`${item.value}`} />
          <Button 
           color="primary"
           variant="outlined"
          target={"_blank"}
          href={item.label}   
            size="Large"
            >Download</Button>
        </ListItem>
      ))}
    </List></Grid> */}
    </Grid>
       </section></Container>
    </Sectionak2S1>
  );
}

export default Sectionak5;
