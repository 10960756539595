import { Container } from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Navbr from './../../components/usernav';
import { requireAuth,  useAuth  } from "../../util/auth";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { getaProducts} from '../../util/db';
import Page1S2 from "./../../components/Page1S2";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const UseStyles = makeStyles((theme) => ({

    container: {
      paddingTop:"100px",
      // paddingLeft:"280px",
      // paddingRight:"0px",
      marginLeft:"18%",
      marginRight:"0px",
      // width:"100%"
    },
  }));
 function Offerings() {
    const auth = useAuth();
    const userid = auth?.user?.id;
    const [uid, setUid] = useState(userid);
    const isSmall = useMediaQuery('(min-width:800px)');
    const [products, setProducts] = useState([]);
    const fetchProductsForFilter = async () => {
      const temp = await getaProducts('dWjFy1y1XVJ3Ue8EYFBx');
  
      setProducts(temp);
    }
      useEffect(() => {
        fetchProductsForFilter();
      }, []);
  return (
    <>
    <Navbr/>
    <section style={{ paddingTop:"100px",

marginLeft:isSmall? "300px":"0px",
marginRight:"5%",}}>


       <Page1S2
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        text1="Property Size"
        ps="80000"
        psqft="8000"
        pc="317,229,880"
        tirr="16%"
        ey="9%"
        testext="22dda"
        ifr="Monthly"
        mi="Rs. 25,00,000"
        title= {products.property_name}
        subtitle={products.location}
        subtitle1="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nunc massa habitant amet. Suscipit gravida nisl, ipsum nulla neque ornare. Condimentum nisi, sed nunc amet enim. Dolor venenatis sed gravida sodales lobortis feugiat odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nunc massa habitant amet. Suscipit gravida nisl, ipsum nulla neque ornare. Condimentum nisi, sed nunc amet enim. Dolor venenatis sed gravida sodales lobortis feugiat odio."
        image="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/current-offer.png?alt=media&token=66841019-8150-4511-b576-97fe3cdc5af7"
      />
</section>
    </>
  )
}
export default requireAuth(Offerings);