import React, {useEffect, useState} from 'react';
import Navbr from '../../components/cpnav';
import { requireAuth,  useAuth  } from "../../util/auth";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import {getuserdocument, getAllEOIdocForUser, getAllemailEOI, getAllEOIProductsDocumentForUser} from '../../util/db';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';



function Documentscp() {
  const auth = useAuth();
  const userid = auth?.user?.id;
  const [uid, setUid] = useState(userid);
  const [value, setValue] = useState('1');
  const [documents, setDocuments] = useState([]);
  const [specificp, setSpecificp] = useState(false);
  const [specificp1, setSpecificp1] = useState(false);
  const isSmall = useMediaQuery('(min-width:800px)');

  const [selectedproduct, setSlectedproduct] = useState(false);
  const [pdocuments, setPdocuments] = useState([]);
  const [userproducteoi, setUserproducteoi] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 
  useEffect(() => {  
    fetchdocForFilter();
}, []);

const fetchdocForFilter = async () => {
  const temp = await getuserdocument(uid);
  
  setDocuments(temp)
}
  return (
   <>
    <Navbr/>
     <section style={{ paddingTop:"100px",

marginLeft:isSmall? "300px":"20px",
marginRight:"5%",}}>
       <br />
       <br />
       <div style={{fontSize: 25, fontWeight:"bold",}}>My <a style={{color:'#3DAEB6'}}>Documents</a></div>
       <br />
       <br />
<br />
<h2>My Personal Documents</h2>
       <br />
       <Grid container spacing={3}>
       {documents.map((product) => (<>
         
           <Grid item={true} xs={12} md={3}>
            <Card style={{maxWidth:"300px", minwidth:"100px" }} >
              <CardActionArea
               target="_blank"
              href={product.image}>
                <CardMedia
                  component="img"
                  height="240px"
                  image={product.image}
                  alt="green iguana"
                  />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                   {product.name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            </Grid>
          
   </>))}
   { documents.length === 0 && <>
    <h4 style={{marginLeft:"5%"}}>No documents uploaded yet</h4>
   </>

   }
   </Grid>
{selectedproduct === false  &&<>
       <h2>Property Documents</h2>
       <br />
       <Grid container spacing={3}>
       <Card style={{maxWidth:"300px", minwidth:"100px" ,width:isSmall?'20vw':""}} >
      <CardActionArea
 onClick={() => setSlectedproduct(true)}
      >

        <CardMedia
          component="img"
          height="240px"
          image='https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/sss.png?alt=media&token=8ac50220-0e94-4803-a639-fa5499ca5b56'
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
           Athulya
          </Typography>

        </CardContent>
      </CardActionArea>
    </Card>
   </Grid></>}
   {selectedproduct && <Grid container spacing={3}>
   <Grid container spacing={3}>  <Button variant=""  onClick={() => setSlectedproduct(false)} style={{color:"red", display:"flex", marginLeft:"0%", justifyContent:"flex-start"}} startIcon={<CloseIcon fontSize="33" />}>Close </Button>
    </Grid>
   <br />
       <br /><br /><br />
   <Grid container spacing={3}>
       <Card style={{maxWidth:"300px", minwidth:"100px" ,width:isSmall?'20vw':""}} >
      <CardActionArea
 target="_blank"
 href='https://drive.google.com/drive/folders/1PRSA0Gn6T_wOucWoYjGM_Ba4J-uiBCqM'
      >

        <CardMedia
          component="img"
          height="240px"
          image='https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/sss.png?alt=media&token=8ac50220-0e94-4803-a639-fa5499ca5b56'
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
           Vault
          </Typography>

        </CardContent>
      </CardActionArea>
    </Card>

   </Grid>
    </Grid>}
      </section>
    </>
  )
}

export default requireAuth(Documentscp, ["cp"]);