import React, { useState, useEffect } from 'react'
import AdminNavbar from './../../components/AdminNavbar';
import {
    getEOIForTransactionByProduct,
    getTransactionByUserAndProduct,
    getAllProducts,
    updateTransactionByProduct,
    getTransactionByProduct,
    updateTransaction,
    deleteTransaction,
    markProductActive,
    updateEOI,
    getaProducts, getUser,
    updateProduct,
    activationTransactions,
    activationRentals
} from '../../util/db';
import { genericEmail, setAddTotal } from '../../util/functions';

import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import MaterialTable from 'material-table';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { ToastContainer, toast } from 'react-toastify';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from "@material-ui/core/Button";
import Grid from '@mui/material/Grid';
import { Checkbox } from '@material-ui/core';
import { Alert } from '@mui/material';
import { auth as myAuth, requireAuth } from "../../util/auth";
import { async } from 'analytics/lib/analytics.cjs';

const empList = [
    { product: 'PROD1', Account: "SPV1-ESC", payment_type: 'part-advance', Type: 'credit', from: 'user', To: 'SPV1-ESC', amount: 0, Reference: 0, Date : null, status: 'pending'},
    { product: 'PROD1', Account: "SPV1-ESC", payment_type: 'advance', Type: 'credit', from: 'user', To: 'SPV1-ESC', amount: 0, Reference: 0, Date : null, status: 'pending'},
    { product: 'PROD1', Account: "SPV1-ESC", payment_type: 'full-drawdown', Type: 'credit', from: 'user', To: 'SPV1-ESC', amount: 0, Reference: 0, Date : null, status: 'pending'},
    { product: 'PROD1', Account: "SPV1-ESC", payment_type: 'asset-purchase', Type: 'credit', from: 'user', To: 'SPV1-ESC', amount: 0, Reference: 0, Date : null, status: 'pending'},
    { product: 'PROD1', Account: "SPV1-ESC", payment_type: 'part-advance', Type: 'credit', from: 'user', To: 'SPV1-ESC', amount: 0, Reference: 0, Date : null, status: 'pending'},
    
  ]

function TransactionTablenew() {
    const [eoi, setEOI] = useState([]);
    const [eoiapproved, setEOIapproved] = useState([]);
    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [previous, setPrevious] = React.useState({});
    const [selectedUserId, setSelectedUserId] = React.useState(null);
    const [ProductTransactionp, setProductTransactionp] = React.useState([]);
    const [ProductTransactionc, setProductTransactionc] = React.useState([]);
    const [value1, setValue1] = React.useState('1');
    const [formAlert, setFormAlert] = useState(null);
    const [data, setData] = React.useState(empList);
    const [userd, setUserd] = useState([]);
    const columns = [
      { title: "Account", field: "name", editable: false},
      { title: "From", field: "from", editable: false},
      { title: "To", field: 'to', editable: false },
      { title: "Type", field: "type", editable: false },
      { title: "Amount", field: "amount", render: rowdata => <div>{rowdata.amount.toFixed(2)}</div> },
      { title: "Reference no", field: "reference_number", validate: rowData => rowData.reference_number === '' ? 'Required' : true },
      { title: "Date", field: "date", type:'date', validate: rowData => rowData.reference_number === '' ? 'Required' : true },
      { title: "Payment type", field: "payment_type", editable: false }
    ]
    const columns1 = [
        { title: "Account", field: "name", editable: false},
        { title: "From", field: "from", editable: false},
        { title: "To", field: 'to', editable: false },
        { title: "Type", field: "type", editable: false },
        { title: "Amount", field: "amount", editable: false,  render: rowdata => <div>{rowdata.amount.toFixed(2)}</div>  },
        { title: "Reference no", field: "reference_number",editable: false },
        { title: "Date", field: "dates", },
        { title: "Payment type", field: "payment_type", editable: false }
      ]
    useEffect(() => {
        fetchProductsForFilter();
    }, []);

    useEffect(() => {
        fetchEOI();
        fetchapprovedEOI();
    }, [selectedProduct]);

    useEffect(() => {
        if (selectedProduct) {
            fetchProductTransaction(selectedProduct);
        }
    }, [selectedProduct]);


    const fetchProductTransaction = async (productId) => {
        const response = await getTransactionByProduct(productId);
        const filtered = response.filter((item) => item.status === "pending");
        const filtered1 = response.filter((item) => item.status === "completed");
        const userData = [];
        setProductTransactionp(filtered);
        filtered1.forEach((doc) => {
          userData.push({ ...doc, dates: doc.date.toDate().toLocaleString()})
        });
        setProductTransactionc(userData);
    }


    const fetchProductsForFilter = async () => {
        const temp = await getAllProducts();
        setProducts(temp);
    }
    const fetchEOI = async () => {
        if (selectedProduct !== "-1") {
            const temp = await getEOIForTransactionByProduct(selectedProduct);
            setEOI(temp);
        
        }
    };

    const fetchapprovedEOI = async () => {
        if (selectedProduct !== "-1") {
            const temp = await getEOIForTransactionByProduct('dWjFy1y1XVJ3Ue8EYFBx');
            const filtered = temp.filter((item) => item.status !== 'Requested');

            setEOIapproved(filtered);
        
        }
    };

    const Getproductd = async (uid) => {
        const temp = await getaProducts(uid);
        setProduct(temp);
    }

    const GetUserdetails = async (uid) => {
        const user = await getUser(uid);
      setUserd (user)
    }
        const activateProduct = async (id) => {
        await markProductActive(id, "Active");
        fetchProductsForFilter();
    };
    const deActivateProduct = async (id) => {
        await markProductActive(id, "");
        fetchProductsForFilter();
    };

    const handleChanges = (event,newValue) => {
          setValue1(newValue);
      
      };

      const handleRowValidate = (newData, oldData) => {
       
        const validationRules = {
          "Part Advance": (data) => {
            const filtered = eoi.filter((item) => item.email === data.from);
            if(Number(filtered.investment_a) !== 0){
            if (Number(filtered.investment_a)+Number(data.amount) > 0.10*Number(filtered.investment_amount)) {
              return "The part Amount you have entered exceed the value of Investment amount of the user";
            } else {
                return null;
            }
        } else if (Number(data.amount) === 0.10*Number(filtered[0].investment_amount))  {
            return "The part Amount you have entered is equal to full advance value so please carry over the transaction using full advance as a payment type";
        }
            return null;
          },
          "Advance": (data) => {
          
            const filtered = eoi.filter((item) => item.email === data.from);
            if (Number(data.amount) !== 0.10*Number(filtered[0].investment_amount)) {
                console.log('data', data);
              return "The Amount you have entered is not equal to 10% of the user's investment amount";
              
            }
            return null;
          },
          "Part Payment": (data) => {
            if (data.amount <= 0) {
              return "Amount must be greater than 0";
            }
            if (!data.date) {
              return "Date is required";
            }
            return null;
          },
          "Full Drawdown": (data) => {
            if (!data.date) {
              return "Date is required";
            }

            return null;
          },
          "Asset Purchase": (data) => {
            if (data.amount <= 0) {
              return "Amount must be greater than 0";
            }

            return null;
          },
          "Investor Commission": (data) => {
            if (data.amount <= 0) {
              return "Amount must be greater than 0";
            }
 
            return null;
          },
          "Seller Commission": (data) => {
            if (data.amount <= 0) {
              return "Amount must be greater than 0";
            }

            return null;
          },
          "CP Commission": (data) => {
            if (data.amount <= 0) {
              return "Amount must be greater than 0";
            }

            return null;
          },
          "Rent TDS": (data) => {
            if (data.amount <= 0) {
              return "Amount must be greater than 0";
            }

            return null;
          },
          "Rent": (data) => {
            if (data.amount <= 0) {
              return "Amount must be greater than 0";
            }
            if (!data.date) {
              return "Date is required";
            }
            return null;
          },
          "Management Fee": (data) => {

            if (!data.date) {
              return "Date is required";
            }
            return null;
          },
          "Tax/Expense": (data) => {
            if (data.amount <= 0) {
              return "Amount must be greater than 0";
            }
            if (!data.date) {
              return "Date is required";
            }
            return null;
          },
          "Monthly PayOut TDS": (data) => {
            if (data.amount <= 0) {
              return "Amount must be greater than 0";
            }
            if (!data.date) {
              return "Date is required";
            }
            return null;
          },
          "Monthly PayOut": (data) => {
            if (data.amount <= 0) {
              return "Amount must be greater than 0";
            }
            if (!data.date) {
              return "Date is required";
            }
            return null;
          },
          "Cashback": (data) => {
            if (data.amount <= 0) {
              return "Amount must be greater than 0";
            }
            if (!data.date) {
              return "Date is required";
            }
            return null;
          },
        };
      
        const paymentType = newData.payment_type;
        const validationFn = validationRules[paymentType];
        const errorMessage = validationFn(newData);
        if (errorMessage) {
            toast.error( errorMessage, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
          return;
        } else {
            handleRowUpdate(newData, oldData);
        }


      };

      const handleRowUpdate = async (newData, oldData) => {
        try {

            const UpdationRules = {
                "Part Advance": (data) => {
                  if (data.amount <= 0) {
                    return "Amount must be greater than 0";
                  }
                  return null;
                },
                "Advance": (data) => {
                    const filtered = eoi.filter((item) => item.email === data.from);
                    const data11={
                        investmenta: Number(data.amount),
                        status: "Advance Paid",
                    }
                    const data2={
                        reference_number: data.reference_number,
                        date: data.date,
                        status: "completed"
                    }
                    updateEOI(filtered[0].id, data11);
                    updateTransaction(data.id, data2);
                    
                    const data25 = {
                        'email': filtered[0].email,
                        'name': filtered[0].name,
                        'amount': data.amount,
                        'status' : "Advance Paid",
                         'property_name' : product.property_name,
                         'transactionid' : data.reference_number,
                        'templateid' : 'd-7c62d92f19454b23aa9b3b41ec37207b'
                    }
                    

                    setAddTotal(product.uid, Number(data.amount))

                    genericEmail(data25);
                    fetchProductTransaction(selectedProduct);
                },
                "Part Payment": (data) => {
                  if (data.amount <= 0) {
                    return "Amount must be greater than 0";
                  }
                  if (!data.date) {
                    return "Date is required";
                  }
                  return null;
                },
                "Full Drawdown": async(data) => {
                    const filtered = eoi.filter((item) => item.email === data.from);

                    const data11={
                        investmenta: Number(data.amount)+ Number(filtered[0].investmenta),
                        status: "Full Drawdown Completed",
                    }
                    const data2={
                        reference_number: data.reference_number,
                        date: data.date,
                        status: "completed"
                    }
                    const backdata = await setAddTotal(product.uid, Number(data.amount));
                    console.log('sdsdsd', backdata);
                    console.log('sdsfvscdvdsdsd', JSON.stringify(backdata));
                    console.log('s34434343d', JSON.stringify(backdata.data));
                    updateEOI(filtered[0].id, data11);
                    updateTransaction(data.id, data2);
                    
                    const data25 = {
                        'email': filtered[0].email,
                        'name': data.name,
                        'amount': data.amount,
                        'status' : "Full Amount Paid",
                        'transactionid' : data.reference_number,
                         'property_name' : product.property_name,
                        'templateid' : 'd-6ab2fd27a01c40a39723f06ef5cef194'
                    }
                    

                    genericEmail(data25);
                    Getproductd(selectedProduct);
                    
                    if(backdata.data.fullamount){
                    
                     activationTransactions(data, product, eoiapproved);
                    }
                    fetchProductTransaction(selectedProduct);
                },
                "Asset Purchase": (data) => {
                    const data2={
                        reference_number: data.reference_number,
                        date: data.date,
                        status: "completed"
                    }
                    
                    updateTransaction(data.id, data2);
                 
                    activationRentals(data, product, eoiapproved);
                 
                },
                "Investor Commission": (data) => {
                  if (data.amount <= 0) {
                    return "Amount must be greater than 0";
                  }
                  if (product.status !== "Active") {
                    return "Property is not active";
                  }
                  const data2={
                    reference_number: data.reference_number,
                    date: data.date,
                    status: "completed"
                }
                
                updateTransaction(data.id, data2);
                },
                "Seller Commission": (data) => {
                  if (data.amount <= 0) {
                    return "Amount must be greater than 0";
                  }
                  if (product.status !== "Active") {
                    return "Property is not active";
                  }
                  const data2={
                    reference_number: data.reference_number,
                    date: data.date,
                    status: "completed"
                }
                
                updateTransaction(data.id, data2);
                },
                "CP Commission": (data) => {
                  if (data.amount <= 0) {
                    return "Amount must be greater than 0";
                  }
                  if (product.status !== "Active") {
                    return "Property is not active";
                  }
                  const data2={
                    reference_number: data.reference_number,
                    date: data.date,
                    status: "completed"
                }
                
                updateTransaction(data.id, data2);
                },
                "Rent TDS": (data) => {
                  if (data.amount <= 0) {
                    return "Amount must be greater than 0";
                  }
                  if (!data.date) {
                    return "Date is required";
                  }
                  if (product.status !== "Active") {
                    return "Property is not active";
                  }
                  const data2={
                    reference_number: data.reference_number,
                    date: data.date,
                    status: "completed"
                }
                
                updateTransaction(data.id, data2);
                },
                "Rent": (data) => {
                  if (data.amount <= 0) {
                    return "Amount must be greater than 0";
                  }
                  if (!data.date) {
                    return "Date is required";
                  }
                  if (product.status !== "Active") {
                    return "Property is not active";
                  }
                  const data2={
                    reference_number: data.reference_number,
                    date: data.date,
                    status: "completed"
                }
                const oneyearup = ProductTransactionc.filter((item) => item.payment_type === "Rent" );
                if (oneyearup.length === 11){
                  updateProduct(data.product_id, {management_fee: Number(product.annual_fd)});
                }
                updateTransaction(data.id, data2);
                },
                "Management Fee": (data) => {

                  if (!data.date) {
                    return "Date is required";
                  }
                  if (product.status !== "Active") {
                    return "Property is not active";
                  }
                  const data2={
                    reference_number: data.reference_number,
                    date: data.date,
                    status: "completed"
                }
                
                updateTransaction(data.id, data2);
                },
                "Tax/Expense": (data) => {
                  if (data.amount <= 0) {
                    return "Amount must be greater than 0";
                  }
                  if (!data.date) {
                    return "Date is required";
                  }
                  if (product.status !== "Active") {
                    return "Property is not active";
                  }
                  const data2={
                    reference_number: data.reference_number,
                    date: data.date,
                    status: "completed"
                }
                
                updateTransaction(data.id, data2);
                },
                "Monthly PayOut TDS": (data) => {
                  if (data.amount <= 0) {
                    return "Amount must be greater than 0";
                  }
                  if (!data.date) {
                    return "Date is required";
                  }
                  if (product.status !== "Active") {
                    return "Property is not active";
                  }
                  const data2={
                    reference_number: data.reference_number,
                    date: data.date,
                    status: "completed"
                }
                
                updateTransaction(data.id, data2);
                },
                "Monthly PayOut": async(data) => {
                  if (data.amount <= 0) {
                    return "Amount must be greater than 0";
                  }
                  if (!data.date) {
                    return "Date is required";
                  }
                  if (product.status !== "Active") {
                    return "Property is not active";
                  }
                  const data2={
                    reference_number: data.reference_number,
                    date: data.date,
                    status: "completed"
                }
                const filtered = eoi.filter((item) => item.email === data.to);
                const data25 = {
                  'email': filtered[0].email,
                  'name': data.name,
                  'amount': data.amount,
                  'month': data.date.getMonth()+1,
                  'transactionid' : data.reference_number,
                   'property_name' : product.property_name,
                  'templateid' : 'd-2b506fbffd634460acba2ef47486b30e'
              }
              

              genericEmail(data25);
                
                 await updateTransaction(data.id, data2);
                 const nextrent = ProductTransactionp.filter((item) => item.payment_type === "Monthly PayOut");
                 if ( nextrent.length === 0 || nextrent.length === 1) {
                 await activationRentals(data, product, eoiapproved);
                 }
                },
                "Cashback": (data) => {
                  if (data.amount <= 0) {
                    return "Amount must be greater than 0";
                  }
                  if (!data.date) {
                    return "Date is required";
                  }
                  return null;
                },
              };
              const paymentType = newData.payment_type;
        const validationFn = UpdationRules[paymentType];
        const errorMessage = await validationFn(newData);
        if (errorMessage) {
          alert(errorMessage);
          return;
        } else {
            return newData
        }
        } catch (error) {
          console.error(error);
        }
      };

    return (
        <>

<ToastContainer
position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
            <AdminNavbar
                color="default"
                logo="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-black.png?alt=media&token=4df2ee2a-8696-48b5-8a65-98171d8179d9"
                logoInverted="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096" />
            <div style={{ padding: '2rem' }}>
                <h1>Admin Transaction Table</h1>
                {formAlert && (
                    <Box mb={3}>
                        <Alert severity={formAlert.type}>{formAlert.message}</Alert>
                    </Box>
                )}
                {!selectedProduct && !selectedUserId && <Box >
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Product Name
                                    </TableCell>
                                    <TableCell>
                                        Status
                                    </TableCell>
                                    <TableCell>
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    products.map((row) => {
                                        return (
                                            <TableRow key={row.id}
                                                hover role="checkbox" tabIndex={-1}>
                                                <TableCell >
                                                    {row.Name}
                                                </TableCell>
                                                <TableCell >
                                                    {row.status != "Active" ? "Not Active" : "Active"}
                                                </TableCell>
                                                <TableCell>
                                                    <Button variant="contained" style={{ marginRight: '15px' }} color="primary" onClick={() => {setSelectedProduct(row.id); Getproductd(row.id)}}>
                                                        View
                                                    </Button>
                                                    <Button  variant="contained" color="primary" onClick={() => row.status != "Active" ? activateProduct(row.id) : deActivateProduct(row.id)}>
                                                        {row.status != "Active" ? "Activate" : "De-Activate"}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                                {
                                    products.length == 0 &&
                                    <TableRow hover role="checkbox" tabIndex={-1}>
                                        <TableCell colSpan={5} >
                                            No products
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>}
                {!selectedUserId && selectedProduct && <>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                      <TabContext TabIndicatorProps={{style: {background:"#3DAEB6"}}} value={value1}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <TabList variant="scrollable" TabIndicatorProps={{style: {background:"#3DAEB6"}}} onChange={handleChanges} aria-label="lab API tabs example">
                            <Tab  label="Pending Transactions" value="1" />
                            <Tab  label="Completed Transactions" value="2" />
                          </TabList>
                        </Box>
                          <TabPanel value="1">
                            <>
                          <MaterialTable
        title="Pending Transactions"
        data={ProductTransactionp}
        columns={columns}
        editable={{
          onRowAdd: (newRow) => new Promise((resolve, reject) => {
            const updatedRows = [...data, { id: Math.floor(Math.random() * 100), ...newRow }]
            setTimeout(() => {
              setData(updatedRows)
              resolve()
            }, 2000)
          }),
          onRowDelete: selectedRow => new Promise((resolve, reject) => {
            const index = selectedRow.tableData.id;
            const updatedRows = [...data]
            updatedRows.splice(index, 1)
            setTimeout(() => {
              setData(updatedRows)
              resolve()
            }, 2000)
          }),
          onRowUpdate:(newData,oldData)=>new Promise((resolve,reject)=>{
         
            
            handleRowValidate(newData, oldData);

            const updatedData = data.map((row) => {
              if (row.id === newData.id) {
                return {  ...newData,};
              } else {
                return { ...row, };
              }
            });
    
            setTimeout(() => {
            //   setData(updatedData);
            fetchProductsForFilter();
            fetchEOI();
            fetchProductTransaction(selectedProduct);
              resolve();
            }, 3000);}),

        }}
        options={{exportButton: true,
          actionsColumnIndex: -1, addRowPosition: "first"
        }}
      />
      </>
                          </TabPanel>
                          <TabPanel value="2">
                          <MaterialTable
        title="Completed Transactions"
        data={ProductTransactionc}
        columns={columns1}
        editable={{
          onRowDelete: selectedRow => new Promise((resolve, reject) => {
            const index = selectedRow.tableData.id;
            const updatedRows = [...data]
            updatedRows.splice(index, 1)
            setTimeout(() => {
              setData(updatedRows)
              resolve()
            }, 2000)
          }),
        }}
        options={{exportButton: true,
          actionsColumnIndex: -1, addRowPosition: "first"
        }}
      />
                          </TabPanel>
                        </TabContext>
                    </Box>

                    </>}

            </div>
        </>
    )
}

// export default TransactionTable;
export default requireAuth(TransactionTablenew, ["admin"]);
