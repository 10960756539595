
import React, { useState, useRef, useEffect, useContext } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import '../pages/App.css';
import { makeStyles } from "@material-ui/core/styles";
import 'font-awesome/css/font-awesome.min.css';
import Aos from 'aos';
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({

    container: {
      padding: `0 ${theme.spacing(4)}px`,
    },
    imageWrapper: {
      margin: "0 auto",
      maxWidth: 570,
      width: "100%",
      "& > img": {
        width: "100%",
      },
    },
    row: {
      // Spacing between rows
      "&:not(:last-child)": {
        marginBottom: `${theme.spacing(2)}px`,
      },
    },
  }));
function Ever1alt() {
    const classes = useStyles();
    useEffect(() => {
      Aos.init({ duration: 2000 });
  }, [])
  return (    <>      
  {/* <section className='grid_scetion img_banner_big bg_gradient'>  <img alt="scene" src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/ever.png?alt=media&token=7f32963a-104d-4847-82db-58571358abb4" className="img_icons" data-aos="zoom-in" data-aos-duration="1000" />
 
  <h5 data-aos="fade-up" data-aos-duration="2000" style={{ fontFamily: 'bronovo-bold' }}>Ever imagined earning a monthly rental income from <br />
      your neighbourhood mall or owning the very building you work in? <br />
      Well, you can stop imagining and start acting. Now!</h5>
</section> */}
<section className='grid_scetion light_dark light_dark2'>
<Container>       <Grid container={true} alignItems="center" spacing={6}>
          <Grid container={true} item={true} direction="column" xs={12} md={6}>          <p className="font_p_18 p-0" data-aos="fade-up" data-aos-duration="2000">You don’t need to be a land shark, or a real estate giant,
              to enjoy a slice of the premium commercial real estate
              opportunities in India.</p>
          <p className="font_p_18 p-0" data-aos="fade-up" data-aos-duration="2200">The amalgamation of Finance, Real Estate, and Technology
              have made things exciting and favourable for the average
              Retail Investor.</p>
          <ul className="list_inset">
              <li data-aos="fade-up" data-aos-duration="2500"> Minimum Investment </li>
              <li data-aos="fade-up" data-aos-duration="2800"> Average Rental Yield: 8-10% </li>
              <li data-aos="fade-up" data-aos-duration="3100"> Target IRR: 12-15% </li>
          </ul></Grid>
          <Grid container={true} item={true} direction="column" xs={12} md={6} style={{ zIndex:"99"}}  >           <div className="box-align">
              <div className="grid_content_bx" data-aos="fade-up" data-aos-duration="2500" >
                  <h3 >Small Bites,<br /> Big GAYNs.</h3>
                  <p>At G<span className="blue">A</span>YN CAPITAL, we unlock such potential
                      by harnessing the power of Collective
                      Ownership. We are proud to present
                      the country's most advanced digital
                      ecosystem for Fractional Ownership
                      in institutional-grade
                      Commercial Real Estate
                      <span className="blue">(CRE)</span> assets.</p>
                  <h3 >Lakhs, <br />not crores.</h3>
                  <p>We make <span className="blue">CRE</span> investments a reality for you.
                      We democratize CRE ownership
                      by breaking it up into smaller affordable
                      fragments, and we make these fragments
                      accessible to Indian investors
                      with a varying appetite</p>
              </div>
          </div></Grid>
          </Grid>
  {/* <Row>
      <Col className="col-lg-6 col-sm-12 col-12">
          <p className="font_p_18 p-0" data-aos="fade-up" data-aos-duration="2000">You don’t need to be a land shark, or a real estate giant,
              to enjoy a slice of the premium commercial real estate
              opportunities in India.</p>
          <p className="font_p_18 p-0" data-aos="fade-up" data-aos-duration="2200">The amalgamation of Finance, Real Estate, and Technology
              have made things exciting and favourable for the average
              Retail Investor.</p>
          <ul className="list_inset">
              <li data-aos="fade-up" data-aos-duration="2500"> Minimum Investment: ₹5 lakhs </li>
              <li data-aos="fade-up" data-aos-duration="2800"> Rental Yield: 7-9% </li>
              <li data-aos="fade-up" data-aos-duration="3100"> IRR: 12-15% </li>
          </ul>
      </Col>
      <Col className="col-lg-6 col-sm-12 col-12 mt-lg-5 mb-lg-5 mt-3 mb-3">

          <div className="box-align">
              <div className="grid_content_bx" data-aos="fade-up" data-aos-duration="2500">
                  <h3 style={{ fontFamily: 'bronovo-bold' }}>Small Bites,<br /> Big GAYNs.</h3>
                  <p>At G<span className="blue">A</span>YN CAPITAL, we unlock such potential
                      by harnessing the power of Collective
                      Ownership. We are proud to present
                      the country's most advanced digital
                      ecosystem for Fractional Ownership
                      in institutional-grade
                      Commercial Real Estate
                      <span className="blue">(CRE)</span> assets.</p>
                  <h3 style={{ fontFamily: 'bronovo-bold' }}>Lakhs, <br />not crores.</h3>
                  <p>We make <span className="blue">CRE</span> investments a reality for you.
                      We democratize CRE ownership
                      by breaking it up into smaller affordable
                      fragments, and we make these fragments
                      accessible to Indian investors
                      with a varying appetite</p>
              </div>
          </div>
      </Col>
  </Row> */}
  </Container>
  {/* <img alt="logo_v" className="bottom" src="assets/img/banner_bg.png" /> */}
</section>

</>
)
}

export default Ever1alt;