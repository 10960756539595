import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Sectionhps1 from "./Sectionhps1";
import SectionHeaderhps2 from "./SectionHeaderhps2";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  image: {
    margin: "0 auto",
    maxWidth: 570,
    display: "block",
    height: "auto",
    width: "100%",
  },
}));

function Sectionhp1(props) {
  const classes = useStyles();

  return (
    <Sectionhps1
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <Grid container={true} alignItems="center" spacing={6}>
          <Grid container={true} item={true} direction="column" xs={12} md={6}>
            <Box textAlign={{ xs: "center", md: "left" }}>
              <SectionHeaderhps2
                title={props.title}
                subtitle={props.subtitle}
                size={5}
              />
            </Box>
          </Grid>
          <Grid item={true} xs={12} md={true}>
            <figure>
              <img
                src={props.image}
                alt="illustration"
                className={classes.image}
                style={{ marginLeft: "-34px" }}
              />
            </figure>
          </Grid>
        </Grid>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/image%205.png?alt=media&token=c77345e6-0d22-46b9-965a-de7f191410c3"
          style={{ width: "85%", marginLeft: "5%", marginTop: "20px" }}
        />
      </Container>
    </Sectionhps1>
  );
}

export default Sectionhp1;
