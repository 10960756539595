import React, {useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import VideogameAssetIcon from "@material-ui/icons/VideogameAsset";
import PetsIcon from "@material-ui/icons/Pets";
import { makeStyles } from "@material-ui/core/styles";
import Page1S21 from "./Page1S21";
import Page1S22 from "./Page1S22";
import Page1S23 from "./Page1S23";
import { Link } from "./../util/router";
import Slider from "react-slick";
import { useTheme } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons//KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { getAllProducts } from "../util/db";
import Aos from 'aos';
import "aos/dist/aos.css";
import useMediaQuery from '@material-ui/core/useMediaQuery';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
 
const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
    overflow: 'hidden',
  },
  imageWrapper: {
    margin: "0 auto",
    maxWidth: 570,
    width: "100%",
    "& > img": {
      width: "100%",
    },
  },
  step: {
    "& $completed": {
      color: "lightgreen"
    },
    "& $active": {
      color: "pink"
    },
    "& $disabled": {
      color: "red"
    }
  },
  row: {
    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(2)}px`,
    },
  },
}));
const images = [
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:
      'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Bird',
    imgPath:
      'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
  },
];

function Page1S2(props) {
  const classes = useStyles();
  const isSmall = useMediaQuery('(min-width:800px)');
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [products, setProducts] = useState([]);
  const maxSteps = products.length;

useEffect(() => {
    fetchProducts();
}, []);

useEffect(() => {
  Aos.init({ duration: 2000 });
}, [])
const fetchProducts = async () => {
    const temp = await getAllProducts();
    setProducts(temp);
}

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (     <Page1S21
    bgColor={props.bgColor}
    size={props.size}
    bgImage={props.bgImage}
    bgImageOpacity={props.bgImageOpacity}
  >
        <>
    <Container className={classes.container}>

        <h1
          style={{
            color: "#3DAEB6",
            fontSize: "20px",
            marginLeft: "-5px",
            marginBottom: "30px",
          }}
        >
          Current offering
        </h1>
        </Container>
        <section  data-aos="fade-up" data-aos-duration="1500">

    <AutoPlaySwipeableViews
      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
      index={activeStep}
      
      onChangeIndex={handleStepChange}
      enableMouseEvents
    >
        {products.map((product) => (
      <Container className={classes.container}>
        
        <Grid container={true} alignItems="center" spacing={8}>
          <Grid>
            <figure className={classes.imageWrapper}>
              <img  src={'https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/Athyla.webp?alt=media&token=0da856a5-bbf7-4df3-9cdf-d7555abd6ab5'} alt="" style={{ marginLeft: "15px" }} />
            </figure>
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <Page1S22
              property_type={product.type}
              title={product.property_name}
              subtitle={product.location}
              subtitle1={product.description || props.subtitle1}
              size={5}
              productid={product.uid}
            />
            <Page1S23
              title={props.title}
              text1={props.text1}
              ps={product.property_size}
              psqft={product.sqft_cost}
              pc={product.propcost}
              tirr={product.target_irr}
              ey={product.entry_yeild}
              ifr={product.income_frequency}
              mi={product.micost}
              tp={~~product.total_percentage}
              size={6}
              size1={1}
            />

          </Grid>
        </Grid>
      </Container>
      ))}
    </AutoPlaySwipeableViews>
    {/* <Container>
    <MobileStepper
      steps={maxSteps}
      position="static"
      variant="text"
      activeStep={activeStep}
      nextButton={
        <Button
          size="large"
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
        >
          Next
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </Button>
      }
      backButton={
        <Button size="large" onClick={handleBack} disabled={activeStep === 0}>
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
          Back
        </Button>
      }
    />
 
    </Container> */}
    </section>
    </> 
  </Page1S21> 

 
   


  );
}

export default Page1S2;
