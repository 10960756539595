import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Roadtos1 from "./Roadtos1";
import Roadtos2 from "./Roadtos2";

const useStyles = makeStyles((theme) => ({}));

function Roadto(props) {
  const classes = useStyles();

  return (
    <Roadtos1
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      bgcol ={props.bgcol}
    >
      <Container>
        <Box textAlign="center" color="#ffffff">
          <Roadtos2 title={props.title} subtitle={props.subtitle} size={2} />
        </Box>
      </Container>
    </Roadtos1>
  );
}

export default Roadto;
