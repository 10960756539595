import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Roadtos22S1 from "./Roadtos22S1";
import SectionHeaderak101 from "./SectionHeaderak101";
import useMediaQuery from '@material-ui/core/useMediaQuery';
const useStyles = makeStyles((theme) => ({}));

function Section542(props) {
  const classes = useStyles();
  const isSmall = useMediaQuery('(min-width:800px)');

  return (
    <Roadtos22S1
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Box textAlign="center" color="#ffffff">
        { isSmall   ? <><br/>

<br/></> : <><br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/></>
}
          <SectionHeaderak101
            title={props.title}
            subtitle={props.subtitle}
            size={2}
          />
          {/* <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ borderRadius: "0px", fontSize: isSmall ? "15px" : "1.5vh" , fontWeight:"500" }}
            href = '#sectionprop'
          >
            View Properties
          </Button> */}
         { isSmall   ? <><br/>

          <br/></> : <><br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/></>
        }
        

        </Box>
        
      </Container>

    </Roadtos22S1>
    
  );
}

export default Section542;
