import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Flippy, { FrontSide, BackSide } from 'react-flippy';
const useStyles = makeStyles((theme) => ({
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    maxWidth: 700,
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: "inline-block",
  },
}));

function Page1News323(props) {
  const classes = useStyles();
  const [isFlipped, setIsFlipped] = React.useState(false)
  React.useEffect(() => {
    console.log(`initializing interval`);
    const interval = setInterval(() => {
      setIsFlipped(!isFlipped);
    }, 4000);
  
    return () => {
      console.log(`clearing interval`);
      clearInterval(interval);
    };
  }, [isFlipped]);

  const { subtitle, subtitle1, title, size, className, ...otherProps } = props;

  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <Box
      component="header"
      className={classes.root + (props.className ? ` ${props.className}` : "")}
      {...otherProps}
      textAlign="left"
      color="#ffffff"
    >
      {title && (
        <>
          <Typography
            variant={`h${size}`}
            gutterBottom={props.subtitle ? true : false}
            style={{ fontWeight: "bold", marginBottom: "15px" }}
          />

          {subtitle1 && (
            <Typography variant="subtitle1" className={classes.subtitle}>
              {subtitle1}
            </Typography>
          )}
        </>
      )}
              <Flippy 
          isFlipped={isFlipped}
    flipDirection="vertical"
  >
     <FrontSide
      style={{
        backgroundColor: "#616161",
        textAlign: "center",
        height: "90px",
        marginBottom: "20px",
        justifyContent: "flex-start",
        alignItems: "center",
        display: "flex",
      }}
    >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/shield-check.png?alt=media&token=20bd7796-d211-446c-850f-c6f149f8d010"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
        <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
          The returns are secured by tenancy agreements with blue chip companies
        </a>
    </FrontSide>
    <BackSide
      style={{
        backgroundColor: "#3DAEB6",
        textAlign: "center",
        height: "90px",
        marginBottom: "20px",
        justifyContent: "flex-start",
        alignItems: "center",
        display: "flex",
      }}>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/ticket.png?alt=media&token=08345d55-c2d3-46ad-82ee-7504f28d2310"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
        <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
          Affordable Ticket size
        </a>
    </BackSide>

  </Flippy>
  <Flippy 
          isFlipped={isFlipped}
    flipDirection="vertical"
  >
     <FrontSide
      style={{
        backgroundColor: "#262632",
        textAlign: "center",
        height: "40px",
        height: "90px",
        marginBottom: "20px",
        justifyContent: "flex-start",
        alignItems: "center",
        display: "flex",
      }}
    >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/smartphone.png?alt=media&token=6476e03c-a98b-465c-bdc6-39923ecc90b7"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
        <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
          Digital & Seamless
        </a>
    </FrontSide>
    <BackSide
      style={{
        backgroundColor: "#616161",
        textAlign: "center",
        height: "40px",
        height: "90px",
        marginBottom: "20px",
        justifyContent: "flex-start",
        alignItems: "center",
        display: "flex",
      }}>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/wallet.png?alt=media&token=2347cbe4-f0bb-4433-a11c-04f3815117b6"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
        <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
          Investible Corpus can go into different RE asset classes
        </a>
    </BackSide>

  </Flippy>
      {/* <div
        style={{
          backgroundColor: "#616161",
          textAlign: "center",
          height: "90px",
          marginBottom: "20px",
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/shield-check.png?alt=media&token=20bd7796-d211-446c-850f-c6f149f8d010"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
        <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
          The returns are secured by tenancy agreements with blue chip companies
        </a>
      </div>
      <div
        style={{
          backgroundColor: "#3DAEB6",
          textAlign: "center",
          height: "90px",
          marginBottom: "20px",
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/ticket.png?alt=media&token=08345d55-c2d3-46ad-82ee-7504f28d2310"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
        <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
          Affordable Ticket size
        </a>
      </div> */}
      {/* <div
        style={{
          backgroundColor: "#262632",
          textAlign: "center",
          height: "40px",
          height: "90px",
          marginBottom: "20px",
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/smartphone.png?alt=media&token=6476e03c-a98b-465c-bdc6-39923ecc90b7"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
        <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
          Digital & Seamless
        </a>
      </div>
      <div
        style={{
          backgroundColor: "#616161",
          textAlign: "center",
          height: "40px",
          height: "90px",
          marginBottom: "20px",
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/wallet.png?alt=media&token=2347cbe4-f0bb-4433-a11c-04f3815117b6"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
        <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
          Investible Corpus can go into different RE asset classes
        </a>
      </div> */}
    </Box>
  );
}

export default Page1News323;
