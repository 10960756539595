import React from "react";
import Meta from "../components/Meta";

function UnAuthorizedPage(props) {
  return (
    <>
      <Meta title="404" />
      <div
        style={{
          padding: "50px",
          width: "100%",
          textAlign: "center",
        }}
      >
        Unauthorized Access!!
      </div>
    </>
  );
}

export default UnAuthorizedPage;
