import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    maxWidth: 700,
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: "inline-block",
  },
}));

function Page2S52(props) {
  const classes = useStyles();

  const { subtitle, subtitle1, title, size, className, ...otherProps } = props;

  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <Box
      component="header"
      className={classes.root + (props.className ? ` ${props.className}` : "")}
      {...otherProps}
      textAlign="left"
      color="#ffffff"
    >
      {title && (
        <>
          <Typography
            variant={`h${size}`}
            gutterBottom={props.subtitle ? true : false}
            style={{ fontWeight: "bold", marginBottom: "-5px" }}
          />

          {subtitle && (
            <Typography
              variant="subtitle1"
              className={classes.subtitle}
              style={{ marginBottom: "10px", textAlign:"left" }}
            >
              {subtitle}
            </Typography>
          )}

          {subtitle1 && (
            <Typography variant="subtitle1" className={classes.subtitle}>
              {subtitle1}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
}

export default Page2S52;
