import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    maxWidth: 700,
    // So we can have max-width but still
    // have alignment controlled by text-align.
    //display: "inline-block",
  },
}));

function SectionHeaderak4S11(props) {
  const classes = useStyles();

  const { subtitle, title, size, className, ...otherProps } = props;

  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <Box
      component="header"
      className={classes.root + (props.className ? ` ${props.className}` : "")}
      {...otherProps}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignContent: "space-between",
        }}
      >
        <section>
          {title && (
            <Typography
              variant={`h${size}`}
              gutterBottom={props.subtitle ? true : false}
              style={{ fontWeight: "700", color: "#3DAEB6" }}
            >
              {title}
            </Typography>
          )}

          {subtitle && (
            <Typography variant="subtitle1" className={classes.subtitle}>
              {subtitle}
            </Typography>
          )}
        </section>
      </div>
    </Box>
  );
}

export default SectionHeaderak4S11;
