import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
const useStyles = makeStyles((theme) => ({
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  progressbar: {
    background: '#000000',
  
    '& .MuiLinearProgress-bar': {
      backgroundColor: 'primary',
    },
  },
  subtitle: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    maxWidth: 700,
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: "inline-block",
  },
}));

function SectionHeader23R(props) {
  const classes = useStyles();

  const {
    subtitle,
    subtitle1,
    text1,
    text2,
    title,
    ps,
    psqft,
    pc,
    tirr,
    aay,
    ey,
    ifr,
    mi,
    size,
    size1,
    className,
    ...otherProps
  } = props;

  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <>
      <Box
        component="header"
        className={
          classes.root + (props.className ? ` ${props.className}` : "")
        }
        {...otherProps}
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignContent: "space-between",
            color: "#8E8E8E",
          }}
        >
          {text1 && (
            <Typography
              variant={size1}
              gutterBottom={props.subtitle ? true : false}
            >
              Leased Area
              {ps && (
                <Typography
                  variant={'body2'}
                  color="primary"
                  gutterBottom={props.subtitle ? true : false}
                  style={{ marginBottom: "30px" , fontWeight: '600' }}
                >
                  {ps.toLocaleString("en-IN")} Sq.ft
                </Typography>
              )}
            </Typography>
          )}

          {text1 && (
            <Typography
              variant={size1}
              gutterBottom={props.subtitle ? true : false}
            >
              *Entry Yield
              {tirr && (
                <Typography
                variant={'body2'}
                  color="primary"
                  gutterBottom={props.subtitle ? true : false}
                  style={{ fontWeight: '600' }}
                >
                  {ey} %
                </Typography>
              )}
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignContent: "space-between",
            color: "#8E8E8E",
          }}
        >
          {text1 && (
            <Typography
              variant={size1}
              gutterBottom={props.subtitle ? true : false}
            >
              **Investment Multiplier
              {ps && (
                <Typography
                variant={'body2'}
                  color="primary"
                  style={{ fontWeight: '600' }}
                  gutterBottom={props.subtitle ? true : false}
                >
                 1.7x
                </Typography>
              )}
            </Typography>
          )}

          {text1 && (
            <Typography
              variant={size1}
              gutterBottom={props.subtitle ? true : false}
            >
              Income Frequency
              {tirr && (
                <Typography
                variant={'body2'}
                  color="primary"
                  style={{ fontWeight: '600' }}
                  gutterBottom={props.subtitle ? true : false}
                >
                  {ifr}
                </Typography>
              )}
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignContent: "space-between",
            color: "#8E8E8E",
          }}
        >
          {text1 && (
            <Typography
              variant={size1}
              gutterBottom={props.subtitle ? true : false}
            >
              Property Cost
              {ps && (
                <Typography
                variant={'body2'}
                  color="primary"
                  style={{ fontWeight: '600' }}
                  gutterBottom={props.subtitle ? true : false}
                >
                 ₹ {pc.toLocaleString("en-IN")}
                </Typography>
              )}
            </Typography>
          )}

          {text1 && (
            <Typography
              variant={size1}
              gutterBottom={props.subtitle ? true : false}
            >
              **Target IRR
              {tirr && (
                <Typography
                variant={'body2'}
                  color="primary"
                  style={{ fontWeight: '600' }}
                  gutterBottom={props.subtitle ? true : false}
                >
                  {tirr} %
                </Typography>
              )}
            </Typography>
          )}
        </div>
      </Box>
      <Box
        component="header"
        className={
          classes.root + (props.className ? ` ${props.className}` : "")
        }
        {...otherProps}
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignContent: "space-between",
          }}
        >
          {text1 && (
            <Typography
              variant={size1}
              gutterBottom={props.subtitle ? true : false}
            >
              Minimum Investment
              {ps && (
                <Typography
                variant={'body2'}
                  color="primary"
                  style={{ fontWeight: '600' }}
                  gutterBottom={props.subtitle ? true : false}
                >
                 ₹ {mi.toLocaleString("en-IN")}
                </Typography>
              )}
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignContent: "space-between",
            marginTop: "-15px",
          }}
        >
          <h4>{props.tp}% funded</h4>
          <LinearProgress variant="determinate" style={{ width: "200px" }} className={classes.progressbar}
                      value={props.tp} />
          {/* <h4>0% funded</h4>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/bar.png?alt=media&token=76d614da-24dd-4c40-a190-5d441556d359"
            style={{ width: "130px" }}
          /> */}
        </div>
      </Box>
    </>
  );
}

export default SectionHeader23R;
