import React from 'react'
import Navbar from "./../components/navbarfaq";
import Meta from "./../components/Meta";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./../components/Footer";

  
export default function privacy() {

  return (<>
    <Meta />
    <Navbar
        color="default"
        logo="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-black.png?alt=media&token=4df2ee2a-8696-48b5-8a65-98171d8179d9"
        logoInverted="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096"
      />
      <Container>
        <br />
        <br />
    <h2>Privacy Policy</h2>
    <h2>This Privacy Policy applies to
GaynCapital.com.</h2>
<p>Gayncapital.com is a property of GAYN Capital, an Indian Company registered under the
Companies Act, 2013 having its registered office at 5-2 Maharaja Surya Rao Road, Vannia
Teynampet, Venus Colony, Alwarpet, Chennai 600018 Tamil Nadu, India.</p>

<p>GaynCapital.com recognizes the importance of maintaining your privacy. We value your privacy
and appreciate your trust in us.</p>
<p>This Privacy Policy provides you with details about the manner in which your data is collected,
stored, and used by us. You are advised to read this Privacy Policy carefully. If you do not agree
to the terms of this Privacy Policy, please do not further use or access GaynCapital.com.</p>
<p>This Privacy Policy applies to current and former visitors to our website and to our online
users/customers. By visiting and/or using our website, you agree to this Privacy Policy.</p>
<h3>1. General</h3>
<p>This privacy statement is applicable to the website of Gayncapital.com. This Privacy Policy
applies regardless of whether you use a computer, mobile phone, tablet, or television or any
other media or computer resource to access our Services. It also applies to those who register
on our website in connection with use of our services, or whose data Gayncapital.com
otherwise receives in connection with its services. It is important that you read the Privacy
Policy carefully because whenever you use our website your Personal Data will be processed
(if at all) in accordance with this policy.</p>
<h3>2. Information Collected when you use our website</h3>
<h4>2.1. Contact information.</h4>
<p>We might collect your name, email, mobile number, phone number, street, city, state,
pin code, country, and IP address.</p>
<h4>2.2. Voluntary Information</h4>
<p>We collect information you post in a public space on our website or on a third-party social media
site belonging to Gayncapital.comWe may collect additional information at other times, including
but not limited to, when you provide feedback, comments, change your content or email
preferences, respond to a survey, or any communications with us.</p>
<h4>2.3. Demographic information.</h4>
<p>We may collect demographic information about you, events you like, events you intend to
participate in, or any other information provided by you during the use of our website. Any such
information collected might be used for surveys also.</p>
<h4>2.4. Other information.</h4>
<p>If you use our website, we may collect information about your IP address and the browser you
are using. We might look at what site you came from, duration of time spent on our website,
pages accessed or what site you visit when you leave us.</p>
<p>We might also collect the type of mobile device you are using, or the version of the operating
system your computer or device is running. We collect information in different ways.</p>
<p>We collect information directly from you when you participate on our website. We also collect
information if you post a comment on our websites or ask us a question through phone or email.
We collect information from you passively. We may use tracking tools like Google Analytics,
Google Webmaster, browser cookies, and web beacons for collecting information about your
usage of our website.</p>
<p>We get information about you from third parties. For example, if you use an integrated social
media feature on our websites, the third-party social media site will give us certain information
about you. This could include your name and email address.</p>

<h3>3. Use of Personal Data</h3>
<h4>3.1. Information used by the Company:</h4>
<p>3.1.1. We might use the information provided to contact you for confirmation of an interest, a
transaction on our website or for other promotional purposes. We use information to respond to
your requests or questions.</p>
<p>3.1.2. We might use your information to confirm your registration for an event or contest. We
use information to improve our products and services. We might use your information to
customize your experience with us. This could include displaying content based upon your</p>
<p>3.1.3. We may use your information to make our website and products better. We may
combine information we get from you with information about you we get from third parties.</p>
<p>3.1.4. We may use information for security purposes and to protect our company, our
customers, or our websites.</p>
<p>3.1.5. We use information for marketing and advertising purposes. we shall contact you from
time to time about any offers/products/ features that we believe may benefit / interest you.
These might be our own offers or products, or third-party offers or products we think you might
find interesting. We use information as otherwise permitted by law.</p>
<h4>3.2. Sharing of information with third parties</h4>
<p>3.2.1. You expressly acknowledge and agree that we may share or transfer your Personal
Information to third-party service providers in the following circumstances:</p>

<p>a{")"} We might share information with vendors who help us manage our online registration
process or payment processors or transactional message processors. Some vendors may be
located outside of India.</p>
<p>b{")"} We might share such Information to conduct its business and to share such Information
within its group companies for the purpose of processing personal information on its behalf. We
also ensure that these recipients of such Information agree to process such information based
on our instructions and in compliance with this Policy and any other appropriate confidentiality
and security measures.</p>
<p>c{")"} We may share your Information to enforce or protect our rights or any or all of its affiliates,
associates, employees, directors or officers or when we have reason to believe that disclosing
Information of User(s) is necessary to identify, contact or bring legal action against someone
who may be causing interference with our rights or our Sites, whether intentionally or otherwise,
or when anyone else could be harmed by such activities.</p>
<p>d{")"} We may share information if we think we must comply with the law or to protect ourselves.
We will share information to respond to a court order or subpoena. We may also share it if a
government agency or investigatory body requests. Or we might also share information when
we are investigating potential fraud. We may share information with any successor to all or part
of our business. For example, if part of our business is sold, we may give our customer list as
part of that transaction.</p>

<h3>4. Security</h3>
<p>We use commercially reasonable security measures to protect the loss, misuse, and alteration
of the information under our control. However, we cannot absolutely guarantee the protection of
any information shared with us.</p>

<h3>5. Accuracy and Confidentiality of Account Information</h3>
<p>Users are responsible for maintaining the secrecy and accuracy of password, email address,
and other account information at all times. Company is not responsible for any personal data
transmitted to a third party as a result of an incorrect account related information.</p>

<h3>6. Third-party sites</h3>
<p>If you click on one of the links to third party websites, you may be taken to websites we do not
control. This policy does not apply to the privacy practices of those websites. Read the privacy
policy of other websites carefully. We are not responsible for these third-party sites.</p>

<h3>7. Email Opt-Out</h3>
<p>You can opt out of receiving our marketing emails. To stop receiving our promotional emails,
please email us at social@gayncapital.com.</p>
<p>It may take about ten days to process your request.</p>

<h3>8. Disclaimer</h3>
<p>Gayncapital.com shall not be liable for any loss or damage sustained by reason of any
disclosure (inadvertent or otherwise) of any information concerning the user’s account and / or
information relating to or regarding online transactions using credit cards / debit cards and / or
their verification process and particulars nor for any error, omission or inaccuracy with respect to
any information so disclosed and used whether or not in pursuance of a legal process or
otherwise.</p>

<h3>9. No Contractual Obligation</h3>
<p>Please note that this Privacy Policy does not create any contractual or other legal rights in or on
behalf of any party, nor is it intended to do so.</p>

<h3>10. Updates to this policy</h3>
<p>This Privacy Policy was last updated on 14th July 2022. From time to time we may change our
privacy practices. We will notify you of any material changes to this policy as required by law.
We will also post an updated copy on our website. Please check our site periodically for
updates.</p>

<h3>11. Jurisdiction</h3>
<p>If you choose to visit the website, your visit and any dispute over privacy is subject to this Policy
and the website{"'"}s terms of use. In addition to the foregoing, any disputes arising under this
Policy shall be governed by the laws of India.</p>

<br />

<br />
<br />
<br />


</Container>
<Footer
        bgColor="secondary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        copyright={`Copyright ©  ${new Date().getFullYear()} GAYN Technologies Pvt Ltd`}
        logo="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096"
        logoInverted="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096"
        sticky={true}
      />
      </>
  )
}
