import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Sectionbks1 from "./Sectionbks1";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useDarkMode } from "./../util/theme";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 50,
    marginRight: theme.spacing(2),
  },
  drawerList: {
    width: 250,

    

  },
  spacer: {
    flexGrow: 1,
  },
  button: {
    backgroundColor: '#fff',
    color: '#404040',
    '&:hover': {
      backgroundColor: '#3DAEB6',
      color: '#ffffff',
  },
  
},
buttonc: {
  backgroundColor: '#fff',
  color: '#3DAEB6',
  '&:hover': {
    backgroundColor: '#3DAEB6',
    color: '#ffffff',
},

}
  
}));
const ITEM_HEIGHT = 48;
function Navbar(props) {
  const classes = useStyles();

  const auth = useAuth();
  
  const [userid, setUserid] = useState(null);
  const darkMode = useDarkMode();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuState, setMenuState] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setUserid(auth.user)
  }, [auth.user]);
  // Use inverted logo if specified
  // and we are in dark mode
  const logo =
    props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  const handleOpenMenu = (event, id) => {
    // Store clicked element (to anchor the menu to)
    // and the menu id so we can tell which menu is open.
    setMenuState({ anchor: event.currentTarget, id });
  };

  const handleCloseMenu = () => {
    setMenuState(null);
  };

  return (
    <Sectionbks1 bgColor={props.color} size="auto" style={{ height: "75px" }}>
      <AppBar
        position="static"
        color="transparent"
        elevation={0}
        style={{ marginTop: "8px" }}
      >
        <Container disableGutters={true}>
          <Toolbar>
            <Link to="/">
              <img src={logo} alt="Logo" className={classes.logo} />
            </Link>
            <div className={classes.spacer} />
            <Hidden smUp={true} implementation="css">
              <IconButton
                onClick={() => {
                  setDrawerOpen(true);
                }}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Hidden xsDown={true} implementation="css">

              {auth?.user?.emailVerified  && (
                <>

                  <Button
                    color="inherit"
                    aria-label="Account"
                    aria-controls="account-menu"
                    aria-haspopup="true"
                    onClick={(event) => {
                      handleOpenMenu(event, "account-menu");
                    }}
                    style={{marginRight:"1%", fontWeight:"bold", color:"primary"}}
                  >
                    
                    <AccountCircleIcon style={{height:'35px', width:'auto'}} color="primary"  />
                    <ExpandMoreIcon color="primary" className={classes.buttonIcon} />
                  </Button>
                  <Menu
                    id="account-menu"
                    open={
                      menuState && menuState.id === "account-menu"
                        ? true
                        : false
                    }
                    anchorEl={menuState && menuState.anchor}
                    getContentAnchorEl={undefined}
                    onClick={handleCloseMenu}
                    onClose={handleCloseMenu}
                    keepMounted={true}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    {auth?.user?.role === "user" && 
                    <MenuItem component={Link} to="/user/dashboard">
                     My Dashboard
                    </MenuItem>
                  }
                  {auth?.user?.role === "cp" && 
                    <MenuItem component={Link} to="/cp/dashboard">
                     My Dashboard
                    </MenuItem>
                  }
                  {auth?.user?.role === "admin" && 
                    <MenuItem component={Link} to="/admin/users">
                     My Dashboard
                    </MenuItem>
                  }
                    <Divider />
                    <MenuItem
                      onClick={(event) => {
                        auth.signout();
                      }}
                    >
                      Signout
                    </MenuItem>
                  </Menu>
                </>
              )}
                            { !userid  && (
                <>
                <div style={{ width: "160px"}}> </div>





                  <Button component={Link} to="/auth/signin" color="inherit"
                  style={{marginRight:"1%",  fontWeight:"bold"}}
                  className={classes.button}>
                    Sign in
                  </Button>
                  <Button
                    component={Link}
                    to="/auth/signup"
                    color="primary"
                    variant="outlined"
                    style={{marginRight:"1%",  fontWeight:"bold"}}
                    className={classes.buttonc}
                  >
                    Sign up
                  </Button>
                </>
              )}




            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <List
          className={classes.drawerList}
          
          onClick={() => setDrawerOpen(false)}
        >
          
    
     
          {!auth.user && (
            <>
            <ListItem style={{ display:'flex', justifyContent: 'center'  }}>
                              <Button href="/auth/signin"
                                color="primary"
                                variant="text"
                                size="large"
                                className={classes.button}
                                style={{marginRight:"1%", fontWeight:"bold", textTransform: 'capitalize'}}
                              >
                                Sign in
                              </Button>
                              <Button href="/auth/signup"
                                color="primary"
                                variant="outlined"
                                size="large"
                                className={classes.buttonc}
                                style={{marginRight:"1%", fontWeight:"bold", textTransform: 'capitalize'}}
                              >
                                Sign up
                              </Button></ListItem>
                              <hr style={{ backgroundColor:"primary"}}/>
                             
                            
 </>
          
          )}

          {auth.user && (
            <>
           
            <ListItem style={{ display:'flex', justifyContent: 'center' }}> 
            <AccountCircleIcon style={{height:'35px', width:'auto'}} color="primary"  /> </ListItem>

{auth?.user?.role === "user" && 
                  <ListItem style={{ display:'flex', justifyContent: 'center' }}> 

                  <Button href="/user/dashboard"
                    color="primary"
                    variant="text"
                    size="large"
                    className={classes.button}
                    style={{marginRight:"1%", fontWeight:"bold", textTransform: 'capitalize'}}
                  >
                    Dashboard
                  </Button></ListItem>
                  }
                  {auth?.user?.role === "cp" && 
                   <ListItem style={{ display:'flex', justifyContent: 'center' }}> 

                   <Button href="/cp/dashboard"
                     color="primary"
                     variant="text"
                     size="large"
                     className={classes.button}
                     style={{marginRight:"1%", fontWeight:"bold", textTransform: 'capitalize'}}
                   >
                     Dashboard
                   </Button></ListItem>
                  }
                  {auth?.user?.role === "admin" && 
                   <ListItem style={{ display:'flex', justifyContent: 'center' }}> 

                   <Button href="/admin/users"
                     color="primary"
                     variant="text"
                     size="large"
                     className={classes.button}
                     style={{marginRight:"1%", fontWeight:"bold", textTransform: 'capitalize'}}
                   >
                     Dashboard
                   </Button></ListItem>
                  }
<ListItem style={{ display:'flex', justifyContent: 'center' }}> 

<Button 
  color="primary"
  variant="text"
  size="large"
  onClick={(event) => {
    auth.signout();
  }}
  className={classes.button}
  style={{marginRight:"1%", fontWeight:"bold", textTransform: 'capitalize'}}
>
  Sign Out
</Button></ListItem>

               <hr style={{ backgroundColor:"primary"}}/>
            </>
          )}
        </List>
      </Drawer>
    </Sectionbks1>
  );
}

export default Navbar;
