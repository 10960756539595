import { Box, Container, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ChannelPartnerNavbar from "../../components/ChannelPartnerNavbar";
import { requireAuth ,  useAuth} from "../../util/auth";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Grid from "@material-ui/core/Grid";
import NewClientModal from "./new-client-modal";
import { getAllLeads,updateCpcreateuser,  } from "../../util/db";
import { history, useRouter } from "../../util/router";
import Navbr from '../../components/cpnav';
import { ToastContainer, toast } from 'react-toastify';
import { CreateUserAdmin, genericEmail } from '../../util/functions';
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const UseStyles = makeStyles((theme) => ({

    container: {
      paddingTop:"100px",
      marginLeft:"18%",
      marginRight:"0px",
    },
  }));

function MyClients(props) {
    const [clients, setClients] = useState([]);
    const auth = useAuth();
  const userId = auth?.user?.id;
    const route = useRouter();      
    const [pending1, setPending1] = useState(false);
    const [pending2, setPending2] = useState(false);
    const [showNewClientModal, setShowNewClientModal] = useState(false);
    const classes = UseStyles();
    const isSmall = useMediaQuery('(min-width:800px)');

    console.log('route', route.query.alert);
    useEffect(() => {
        fetchClients();
    }, []);

    const fetchClients = async () => {
        const temp = await getAllLeads();
        setClients(temp);
        console.table('my clients', temp);
        
    }

    const openAddClientModal = () => {
        // open modal
        setShowNewClientModal(true);
    }


    const cpCreateuser = async (a, b, c, d, e, f, g ) => {
        const formattedPhone = `+91${e.replace("+91", "")}`
        console.log("user", {a, b, c, d, e, f});
        const user = await CreateUserAdmin(a, b, c, d, formattedPhone, f ); 
        
        if (user?.data?.errorInfo) {
            toast.error("User Creation failed", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                setPending2(false);
        } else {
            setPending2(false);
          console.log("user", user.data.uid);
          updateCpcreateuser(user.data.uid, g);
          const data25 = {
            'email': a,
            'password': b,
            'name': c,
            'templateid' : 'd-8196b372b2e141a49cbf39c039a5b132'
        }
        const data26 = {
          'email': a,
          'password': b,
          'name': c,
          'templateid' : 'd-3ddf0bb447244aa59e29f4eabb94dc65'
      }
      const data27 = {
        'email': 'enquiry@gayncapital.com',
        'uemail': a,
        'phone': formattedPhone,
        'name': c,
        'templateid' : 'd-747db5af3ef7421089944e57f5340c6d'
    };
    genericEmail(data27);
          genericEmail(data25);
          genericEmail(data26);
          fetchClients();
          setPending1(true);
          toast.success("User created Successfully", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }
      };
    
    const handleCloseClientModal = (needRefresh) => {
        // open modal
        setShowNewClientModal(false);
        if (needRefresh) {
            fetchClients();
        }
    }

    const handleKYCStatusForUser = (userId) => {
        // alert("implement kyc status for user"); from git
        history.push(`/cp/investor/${userId}/details`);
    }

    return (
        <div>
           
             <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
<ToastContainer />
                <Navbr/>


            <section style={{ paddingTop:"100px",

marginLeft:isSmall? "300px":"10px",
marginRight:"5%",}}>
            <Box style={{ paddingLeft: '2rem', paddingRight: '2rem' }}>

                <Grid container spacing={3}>
                    <Grid item xs={6} md={6}>
                        <h1>My Clients</h1>
                    </Grid>
                    <Grid item xs={6} md={6} style={{ textAlign: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ margin: '1rem' }}
                            onClick={openAddClientModal}>
                            Add Client
                        </Button>
                        {showNewClientModal && <NewClientModal cpname={auth.user.first_name+ ' ' + auth.user.last_name} handleCloseModal={handleCloseClientModal} />}
                    </Grid>
                    <Grid item xs={12} >
                        <Paper sx={{ width: '100%' }}>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant={isSmall? "h6": "h8"}>Name</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant={isSmall? "h6": "h8"}>Mail ID</Typography>
                                            </TableCell>
                                           
                                            <TableCell >
                                                <Typography variant={isSmall? "h6": "h8"}>Mobile Number</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant={isSmall? "h6": "h8"}>Entry Method</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant={isSmall? "h6": "h8"}>Status</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant={isSmall? "h6": "h8"}>Action</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            clients.length > 0 && clients.map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.name}>
                                                        <TableCell >
                                                            {row.first_name} {row.last_name}
                                                        </TableCell>
                                                        <TableCell >
                                                            {row.email}
                                                        </TableCell>
                                                        <TableCell >
                                                            {row.phone_number}
                                                        </TableCell>
                                                        <TableCell >
                                                            {row.entry}
                                                        </TableCell>
                                                        <TableCell >
                                                            {row.status === "Completed" && <Typography
                                                                variant={"body1"}
                                                                style={{ color: "green" }}>
                                                                KYC Completed
                                                            </Typography>}
                                                            {row.status === "incomplete" && row.status !== "Pending" && row.user_id !== null && <Typography
                                                                variant={"body1"}
                                                                style={{ cursor: "pointer", color: "red" }}>
                                                                KYC Not Completed
                                                            </Typography>}
                                                            {row.status === "Pending" && row.user_id !== null && <Typography
                                                                variant={"body1"}
                                                                style={{ cursor: "pointer", color: "red" }}>
                                                                KYC Submitted
                                                            </Typography>}
                                                            {row.user_id == null && <><Typography
                                                                variant={"body1"}                                                       
                                                                style={{ cursor: "pointer", color: "red" }}>
                                                                User Not Created Yet
                                                            </Typography>  </>}
                                                        </TableCell>
                                                        <TableCell >
                                                            {row.status === "Complete" || row.status === "Pending" && <Typography
                                                                variant={"body1"}
                                                                style={{ color: "green" }}>
                                                                -
                                                            </Typography>}
                                                            {row.status === "incomplete" && row.user_id !== null && <Button
                            variant="contained"
                            color="primary"
                            style={{ margin: '1rem' }}
                            onClick={() => handleKYCStatusForUser(row.user_id)}
                           
                        >
                            Complete KYC
                        </Button>}
                                                            {row.user_id == null && <> <Button
                            variant="contained"
                            color="primary"
                            disabled={pending2}
                            style={{ margin: '1rem' }}
                            onClick={() => cpCreateuser(row.email, "Test@123", row.first_name, row.last_name, row.phone_number, "user", row.id)}
                        >
                            Create User {pending2 && <CircularProgress size={28} />}
                        </Button> </>}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {clients.length == 0 &&
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell colSpan={5} >
                                                    No Clients
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Grid>

            </Box>
            </section>
            {/* </Container> */}

        </div>
    );
}
export default requireAuth(MyClients, ["cp"]);
// export default MyClients;