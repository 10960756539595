import React, {useEffect, useState} from 'react';
import Navbr from '../../components/cpnav';
import { requireAuth,  useAuth  } from "../../util/auth";
import { useTable, useExpanded } from 'react-table';
import styled from 'styled-components';
import {getCpportfolio, getCpteamdata} from '../../util/db';
import makeData from './makeportdata'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;


    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    td {
      background-color: #DCDCDC;
      color: black;
    }
    th {
      background-color: #262632;
      color: white;
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`


function Myportfolio() {
  const [data, setData] = useState([]);
  const isSmall = useMediaQuery('(min-width:800px)');

  const columns = React.useMemo(
    () => [
      {
        // Build our expander column
        id: 'expander', // Make sure it has an ID
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? <ArrowBackIosIcon style={{ transform: 'rotate(270deg)' }}  size={12}/> : <ArrowBackIosIcon style={{ transform: 'rotate(180deg)' }} size={12}/>}
          </span>
        ),
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property
                  // and paddingLeft to indicate the depth
                  // of the row
                  paddingLeft: `${row.depth * 2}rem`,
                },
              })}
            >
              {row.isExpanded ? <ArrowBackIosIcon style={{ transform: 'rotate(270deg)' }} /> : <ArrowBackIosIcon style={{ transform: 'rotate(180deg)' }}/>}
            </span>
          ) : null,
      },
      {
        Header: 'Name',
        columns: [
          {
            Header: 'Team Member Name',
            accessor: 'cp_name',
          },
          {
            Header: 'CP Level',
            accessor: 'cp_level',
          },
        ],
      },
      {
        Header: 'Info',
        columns: [
          {
            Header: 'Investor Name',
            accessor: 'investor_name',
          },
          {
            Header: 'Property Name',
            accessor: 'property_name',
          },
          {
            Header: 'Status of the Investment',
            accessor: 'status',
          },
          {
            Header: 'Investment amount',
            accessor: 'invested_amount',
          },
          {
            Header: 'Commission Earned',
            accessor: 'returns',
          },
          {
            Header: 'Commission Status',
            accessor: 'payment_status', 
          },
        ],
      },
    ],
    []
  )
  const fetchPortfolio = async () => {
    const temp =  await getCpportfolio();
    setData(temp);
    console.table('my clients', temp);

}
  useEffect(() => { 
    fetchPortfolio();
  }, []);





  const data1 = React.useMemo(() => makeData(2, 2), [])

  console.log('data', data);
 console.log('data1', data1);
  return (
    <>
    <Navbr/>
     <section style={{ paddingTop:"100px",

marginLeft:isSmall? "300px":"20px",
marginRight:"5%",}}>
    <div style={{fontSize: 25, fontWeight:"bold",}}>My Portfolio</div>
    
    <Styles>
      { data?
      <Table columns={columns} data={data} /> : <p> please wait</p>}
    </Styles>
     </section>
    </>
  )
}
function Table({ columns: userColumns, data}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
  } = useTable(
    {
      columns: userColumns,
      data,
    },
    useExpanded // Use the useExpanded plugin hook
  )

  return (
    <>
      <table {...getTableProps()}>
        <thead >
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <br />
      <div>Showing the results {rows.length} of {rows.length} rows</div>
      {/* <pre>
        <code>{JSON.stringify({ expanded: expanded }, null, 2)}</code>
      </pre> */}
    </>
  )
}

export default requireAuth(Myportfolio, ["cp"]);