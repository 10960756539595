import React from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
// import ListItem from "@material-ui/core/ListItem";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "../util/router";
import { useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";

const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    backgroundColor:"white",

  },
  drawer: {
    flexShrink: 0,
    width: drawerWidth,
    color:"#ffffff"
   
  },
  drawerPaper: {
    width: drawerWidth, 
    backgroundColor: '#262632',
    color:"#ffffff"
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  content: {
    flexGrow: 1,
    backgroundColor:'#ffffff',
    padding: theme.spacing(3)
  },
  logo: {
    height: 40,
    marginRight: theme.spacing(2),
    justifyContent: "center",
    alignItems: "center",
  },
  active1:{
    backgroundColor: '#ffffff',  }
}));
const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#3DAEB6",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white"
      }
    },
    "&$selected:hover": {
      backgroundColor: "#3DAEB6",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white"
      }
    },
    "&:hover": {
      backgroundColor: "#3DAEB6",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white"
      }
    }
  },
  selected: {}
})(MuiListItem);

export default function CPnav(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const location = useLocation()
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar style={{zIndex:'999', display:'flex', justifyContent:"left"}}>
        <Hidden smUp={true} implementation="css">
          <IconButton
            color="white"
            aria-label="open drawer"
            edge="end"
            onClick={toggleDrawer}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          </Hidden>
          {!isMdUp &&
            <Link to="/">
              <img src={'https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-black.png?alt=media&token=4df2ee2a-8696-48b5-8a65-98171d8179d9'} alt="Logo" className={classes.logo} />
            </Link>}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant={isMdUp ? "permanent" : "temporary"}
        classes={{
          paper: classes.drawerPaper
        }}
        anchor="left"
        open={open}
        onClose={toggleDrawer}
      >
        <a href="/">
        <img src='https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096' style={{width:"250px", paddingLeft:"50px", paddingRight:"50px", paddingTop:"10px"}}/></a>
        <div className={classes.toolbar} />
        <Divider />
        <List>
          {[    {
      text: "Dashboard",
      icon: "https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/icon1.png?alt=media&token=e5b8c676-8ae4-4e71-ba07-210d1edb9590",
      to1: "/cp/dashboard"
    },
    {
      text: "My Profile",
      icon: "https://firebasestorage.googleapis.com/v0/b/gayn-82759.appspot.com/o/Group.png?alt=media&token=1bb53e09-013a-4042-9c64-7c2942c5bcf4",
      to1: "/cp/profile"
    },
    {
      text: "My Clients",
      icon: "https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/icon2.png?alt=media&token=01835664-bfcf-4be4-bbc8-28abda1fa00d",
      to1: "/cp/clients"
    },
    
    {
      text: "Documents",
      icon: 'https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/icon3.png?alt=media&token=4ca6c34e-069d-4072-8b16-b4179b25668f',
      to1: "/cp/documents"
    },
    {
      text: "My Portfolio",
      icon: 'https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/icon4.png?alt=media&token=788ad7c7-52ec-432c-9a4d-9f6e11489dba',
      to1: "/cp/portfolio"
    },
    {
      text: "My Team",
      icon: 'https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/icon4.png?alt=media&token=788ad7c7-52ec-432c-9a4d-9f6e11489dba',
      to1: "/cp/teams"
    },
    {
      text: "Sign out",
      icon: 'https://firebasestorage.googleapis.com/v0/b/gayn-82759.appspot.com/o/logout.png?alt=media&token=31ad9967-4805-4d8f-a261-3c4090738fbe',
      to1: "/signout"
    },
    ].map((item, index) => {const { text, icon, to1} = item; return (
            <ListItem button={true} key={text} component={Link} to= {to1}   selected={to1 === location.pathname} style={{height:'60px'}} >
              <ListItemIcon>
                {icon && <ListItemIcon style={{color:"#ffffff", height:"20px", marginBottom:"0px"}}><img style={{width: "22px"}} src={icon}/></ListItemIcon>}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          )})}
        </List>
      </Drawer>

      </div>
  );
}

