import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
const useStyles = makeStyles((theme) => ({
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    maxWidth: 700,
    fontSize: "11px",
    display: "inline-block",
    [theme.breakpoints.up("sm")]: {
      fontSize: "14px",
    },
    // So we can have max-width but still
    // have alignment controlled by text-align.

  },
}));

function Page1S22(props) {
  const classes = useStyles();
  const isSmall = useMediaQuery('(min-width:800px)');
  const { subtitle, subtitle1, title, size, className, ...otherProps } = props;

  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <>

      <Box
        component="header"
        className={
          classes.root + (props.className ? ` ${props.className}` : "")
        }
        {...otherProps}
      >
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <section
        style={{
          marginBottom: "-40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: isSmall?"40px": '30px',
          width: isSmall?"120px": '100px',
          backgroundColor: "#262632",
          color: "#ffffff",
          textAlign: "center",
        }}
      >
        <h4 style={{ fontSize: isSmall? '12px': '10px'}}>{props.property_type}</h4>
      </section></div>
        {title && (
          <>
            <Typography
              variant={isSmall?`h${size}`:`h${6}`}
              gutterBottom={props.subtitle ? true : false}
              style={{ fontWeight: "bold", marginBottom: "-5px" }}
            >
              {title}
            </Typography>

            {subtitle && (
              <Typography
                variant="subtitle1"
                className={classes.subtitle}
                style={{ color: "#3DAEB6", marginBottom: "10px" }}
              >
                {subtitle}
              </Typography>
            )}

            {subtitle1 && (
              <Typography variant="subtitle1" className={classes.subtitle}>
                {subtitle1}
              </Typography>
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default Page1S22;
