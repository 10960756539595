import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Sectionbks1 from "./Sectionbks1";
import SectionHeaderp2S4 from "./SectionHeaderp2S4";
import { Link } from "./../util/router";
import { FileText } from 'lucide-react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GoogleMapReact from 'google-map-react';
const useStyles = makeStyles((theme) => ({

  container: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  image: {
    margin: "0 auto",
    maxWidth: 570,
    display: "block",
    height: "auto",
    width: "100%",
  },
}));
const defaultProps = {
  center: {
    lat: 12.959045,
    lng: 77.744711
  },
  zoom: 18
};

// const AnyReactComponent = ({ text }) => <div>{text}</div>;
const AnyReactComponent = ({ text }) => <img src="https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/placeholder.png?alt=media&token=c903e9a4-83b7-4eb7-87e1-ba390b4e605d" style={{width: "50px", transform: "translate(-50%, -50%)"}}></img>;
function Page2S4(props) {
  const classes = useStyles();
  const isSmall = useMediaQuery('(min-width:800px)');

  return (
    <Sectionbks1
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container} style={{ marginTop: "-100px" }}>
        <h1
          style={{
            color: "#3DAEB6",
            paddingTop: "30px",
            marginBottom: "40px",
            fontSize: "20px",
          }}
        >
          Location
        </h1>
        <Grid container={true} alignItems="center" spacing={6}>
          <Grid item={true} xs={12} md={true}>
            <div style={{width: "100%", height: "500px"}}>
          <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCswVcR39VZai5xzlUDo80k7e-kJcJHMIY" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        >
        <AnyReactComponent
          lat={12.959045}
          lng={77.744711}
          text={<p style={{fontsize: '50px', fontWeight:'700'}}> Location</p>}
        />
      </GoogleMapReact>
      </div>
            {/* <figure>
              <img
                src={props.image}
                alt="illustration"
                className={classes.image}
                style={{ marginLeft: "-0px" }}
              />
            </figure> */}
          </Grid>
          <Grid container={true} item={true} direction="column" xs={12} md={6}>
            <Box textAlign={{ xs: "center", md: "left" }}>
              <SectionHeaderp2S4
                title={props.title}
                subtitle={props.subtitle}
                size={5}
              />
            </Box>
          </Grid>
        </Grid>
        <video controls
        src= {"https://firebasestorage.googleapis.com/v0/b/gayn-dev.appspot.com/o/GAYN%20Whitefield-AV.mp4?alt=media&token=7150c65b-db7d-427d-bb51-986551ef9ace#t=1.2"}
        style={{ width: "100%", marginTop: "100px", marginBottom: isSmall?"100px":'-10px'}}
      />
              {/* <Box textAlign="center" > */}
              <Grid container={true} alignItems="center" spacing={6}>
              <Grid item={true} xs={3} md={3}></Grid>
          <Grid item={true} xs={12} md={6}>
      <section style={ {backgroundColor:"#262632", textAlign:"center", display:'flex',alignItems: 'center', justifyContent: 'space-between', height:"80px"}}> 
     <FileText  style={{color:"white", marginRight:isSmall?"15%": "35%",  marginLeft:"4%"}}></FileText> 
     <h1 style={{color:"white", marginRight:"2%", fontSize: isSmall?'15px': '12px',  marginLeft:"-32%", fontWeight:'400'}}> Investment Memorandum</h1>
      <Button
          href="https://firebasestorage.googleapis.com/v0/b/gayn-dev.appspot.com/o/GAYN_Whitefield_InvMemo-HighRes.pdf?alt=media&token=042edee3-fa94-4c5a-880f-1dadb4944671"
          variant="contained"
          target="_blank"
          size={isSmall?"large": "small"}
          color={props.buttonColor}
          style={{ borderRadius: "0px",marginRight:"4%" }}
        >
          {props.buttonText}
        </Button>
     
        
        </section>
        </Grid>
        {/* <Grid item={true} xs={3} md={3}></Grid> */}
        </Grid>
        {/* </Box> */}
      </Container>

    </Sectionbks1>
  );
}

export default Page2S4;
