import React, { useEffect, useState } from "react";
import Meta from "./../components/Metalanding";
import Container from "@material-ui/core/Container";
import Navbar from "./../components/Navbarlanding";
import Page1S2 from "./../components/Page1S2landing";
import Section4A1 from "./../components1/Section4Alanding";
import { getaProducts} from '../util/db';
import Aos from 'aos';
import "aos/dist/aos.css";
import './forms.css';
import {Helmet} from "react-helmet";
import useMediaQuery from '@material-ui/core/useMediaQuery';

function LandingPage() {
    const isSmall = useMediaQuery('(min-width:800px)');
    useEffect(() => {
      Aos.init({ duration: 2000 });
  }, [])
    const [products, setProducts] = useState([]);
    const fetchProductsForFilter = async () => {
      const temp = await getaProducts('dWjFy1y1XVJ3Ue8EYFBx');
  
      setProducts(temp);
  }
  useEffect(() => {
    fetchProductsForFilter();
    
  }, []);
  

    return (
      <>
        <Meta />
        <Navbar
          color="default"
          logo="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-black.png?alt=media&token=4df2ee2a-8696-48b5-8a65-98171d8179d9"
          logoInverted="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096"
        />

  
<section id="section1" data-aos="fade-up" data-aos-duration="1200">
  
  <Section4A1
bgColor="default"
size="medium"
bgImage=""
bgImageOpacity={1}
title={
<h3>
GAYN Capital <br />
<a
style={{
  fontStyle: "italic",
  fontSize: "15px",
  color: "#000000",
}}
>
Passive Income for Life.
</a>
</h3>
}
subtitle={
<p style={{ fontSize: "15px" }}>
<a style={{ fontWeight: "600", fontSize: "15px" }}>
<a style={{ fontWeight: "700" }}>
  G<a style={{ color: "#3DAEB6" }}>A</a>YN
</a>{" "}
Capital is a FinTech Company that leverages technology to make
Premium CRE Investing Possible for Everyone.{" "}
</a>
<br /> <br />
The{" "}
<a style={{ fontWeight: "700" }}>
G<a style={{ color: "#3DAEB6" }}>A</a>YN Capital
</a>{" "}
team sources the best real estate opportunities in India’s thriving industries such as Retail, Logistics, Healthcare, and Entertainment. Our properties are carefully curated with a clear focus on high-quality tenants.
<br /> <br />
We unlock the potential of Collective Ownership, through Fractional Investing in institutional-grade Commercial Real Estate(CRE) assets. We make these investments a reality for you by democratizing CRE ownership; breaking it up into smaller affordable fragments.
</p>



}

image="https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/sec2img.webp?alt=media&token=632efcd1-c11b-4f8a-bd31-cfedfd6a1f0e"
buttonText="View Properties"
buttonColor="primary"
buttonPath="/pricing"
/> </section>

        <section id="sectionp">
  
        <Page1S2
          bgColor="secondary"
          size="medium"
          bgImage=""
          bgImageOpacity={1}
          text1="Property Size"
          ps="80000"
          psqft="8000"
          pc="317,229,880"
          tirr="16%"
          ey="9%"
          testext="22dda"
          ifr="Monthly"
          mi="Rs. 25,00,000"
          title= {products.property_name}
          subtitle={products.location}
          subtitle1="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nunc massa habitant amet. Suscipit gravida nisl, ipsum nulla neque ornare. Condimentum nisi, sed nunc amet enim. Dolor venenatis sed gravida sodales lobortis feugiat odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nunc massa habitant amet. Suscipit gravida nisl, ipsum nulla neque ornare. Condimentum nisi, sed nunc amet enim. Dolor venenatis sed gravida sodales lobortis feugiat odio."
          image="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/current-offer.png?alt=media&token=66841019-8150-4511-b576-97fe3cdc5af7"
        /> </section>



   <div>
   <Helmet>
   <script>
    {`!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '172553162362124');
    fbq('track', 'PageView');`}
    </script>
    <noscript>{`<img height="1" width="1" style="display:none"
    src="https://www.facebook.com/tr?id=172553162362124&ev=PageView&noscript=1"
    />`}
  </noscript>
   <script src="https://assets.kylas.io/lead-capture-forms/lcf.min.js" form-id="b8987d42-eb27-4fb0-93e2-646ac45cc9c2" api-key="0f1f2ec6-bc34-4d91-b449-f6ebea53e7b7:7710"></script>
   </Helmet>
   <Container>



   <div id="kl__form-container" style={{width: isSmall? "50%": "100%", fontFamily: "Montserrat !important", fontSize: "14px !important"}}></div>
   </Container>
   </div>

      </>
    );
  }
  

export default LandingPage