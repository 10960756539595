import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import VideogameAssetIcon from "@material-ui/icons/VideogameAsset";
import PetsIcon from "@material-ui/icons/Pets";
import { makeStyles } from "@material-ui/core/styles";
import Page1News31 from "./Page1News31";
import Page1Newss32 from "./Page1Newss32";
import '../pages/App.css';
import Aos from 'aos';
import "aos/dist/aos.css";
import Allyouhavecard from "./allyouhavecard";
const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
  },
  imageWrapper: {
    margin: "0 auto",
    maxWidth: 570,
    width: "100%",
    "& > img": {
      width: "100%",
    },
  },
  row: {
    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(2)}px`,
    },
  },
}));

function Page1Snew(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Play",
      description:
        "We embedded a bunch free flash games inside our app. What we lack in quality we make up in quantity.",
      icon: VideogameAssetIcon,
      iconColor: "secondary.main",
    },
    {
      title: "Cats",
      description:
        "Our market research told us people like cats so we put some cat icons on our website.",
      icon: PetsIcon,
      iconColor: "#00d1b2",
    },
  ];

  return (
    <Page1News31
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <Grid container={true} alignItems="center" spacing={8}>
          <Grid item={true} xs={12} md={6}>
            <Page1Newss32
              title={props.title}
              subtitle={props.subtitle}
              subtitle1={props.subtitle1}
              size={5}
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            
              {/* <img
                src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/side.png?alt=media&token=ddfc3353-37d1-40bd-9cff-424a32a9fc41"
                style={{ width: "80%" }}
              /> */}
              <Allyouhavecard/>
                                             
            
          </Grid>
        </Grid>
      </Container>
    </Page1News31>
  );
}

export default Page1Snew;
