import React, { useEffect, useState } from "react";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Grid from "@material-ui/core/Grid";
import { useForm } from "react-hook-form";
import { Alert } from '@material-ui/lab';
import { auth } from '../../util/auth';
import { addLeads } from '../../util/db';
import { requireAuth } from "../../util/auth";
import { InputAdornment } from "@material-ui/core";
import { genericEmail } from "../../util/functions";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function NewClientModal(props) {

    const { handleCloseModal } = props;
    const [open, setOpen] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [formAlert, setFormAlert] = useState(null);
    const handleClose = (needRefresh = false) => {
        setOpen(false);
        handleCloseModal(needRefresh);
    };

    useEffect(() => {
        if (open) {
            setFormAlert(null);
            setProcessing(false);
        }
    }, [open]);

    const { handleSubmit, register, errors, getValues } = useForm();

    const onSubmit = async (formData) => {
        try {
            console.log(formData);
            setProcessing(true);
            await addLeads(formData);
            const data27 = {
                'email': 'enquiry@gayncapital.com',
                'uemail': formData.email,
                'phone': formData.phone,
                'cpname': props.cpname,
                'name': formData.first_name + ' ' + formData.last_name,
                'templateid' : 'd-5904ba53c8064dd2ae9460b9f2b4f47d'
            };
            const data28 = {
                'email': 'distribution@gayncapital.com',
                'uemail': formData.email,
                'phone': formData.phone,
                'name': formData.first_name + ' ' + formData.last_name,
                'cpname': props.cpname,
                'templateid' : 'd-5904ba53c8064dd2ae9460b9f2b4f47d'
            };
            genericEmail(data27);
            genericEmail(data28);
            setFormAlert({
                type: "success",
                message: "Successfully added new client",
            });
            setTimeout(() => handleClose(true), 500);
        } catch (error) {
            setProcessing(false);
            setFormAlert({
                type: "error",
                message: error.message,
            });
        }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEscapeKeyDown={true}
                disableBackdropClick={true}>
                <Box sx={style}>
                    {formAlert && (
                        <Box mb={3}>
                            <Alert severity={formAlert.type}>{formAlert.message}</Alert>
                        </Box>
                    )}
                    <Typography id="modal-modal-title" variant="h4" component="h2" align='center' style={{ padding: '1rem' }}>
                        Enter New Client
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="First Name"
                                    name="first_name"
                                    fullWidth={true}
                                    onInput = {(e) =>{
                                        e.target.value = e.target.value.replace(/\s{2,}/g, ' ').replace(/[^A-Za-z ]/g, '');
                                    }}
                                    error={errors.name ? true : false}
                                    helperText={errors.name && errors.name.message}
                                    inputRef={register({
                                        required: "Please enter your client First Name",
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Last Name"
                                    name="last_name"
                                    fullWidth={true}
                                    onInput = {(e) =>{
                                        e.target.value = e.target.value.replace(/\s{2,}/g, ' ').replace(/[^A-Za-z ]/g, '');
                                    }}
                                    error={errors.name ? true : false}
                                    helperText={errors.name && errors.name.message}
                                    inputRef={register({
                                        required: "Please enter your client Last Name",
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Email"
                                    name="email"
                                    fullWidth={true}
                                    error={errors.email ? true : false}
                                    helperText={errors.email && errors.email.message}
                                    inputRef={register({
                                        required: "Please enter email",
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Phone Number"
                                    name="phone"
                                    fullWidth={true}
                                    error={errors.phone ? true : false}
                                    helperText={errors.phone && errors.phone.message}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">+91</InputAdornment>
                                      }}
                                    inputRef={register({
                                        required: "Please enter phone number",
                                    })}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'center' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    type="submit"
                                    disabled={processing}
                                >
                                    Submit
                                </Button>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'center' }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    type="button"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

// export default requireAuth(NewClientModal, ["cp"]);
export default NewClientModal;