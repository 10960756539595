import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Section from "./Section";
import SectionHeaderFAQ from "./sectionHeaderFAQ";
import { makeStyles } from "@material-ui/core/styles";

const point = [ '3. Choose a product that interests you... and you are well on your way.', '2. Create your account', '3. Choose a product that interests you... and you are well on your way.' ];
const listitems = point.map((points) => <li>{points}</li>);

const useStyles = makeStyles((theme) => ({
    root: {
      // Add bottom margin if element below
      "&:not(:last-child)": {
        marginBottom: "2rem",
      },
    },
    Muiexpanded: {
        "&:active" : {
          color:"#3DAEB6"}
    }
  }));

function FaqSection(props) {
    const classes = useStyles();

  const items = [
    {
      question: "What is Fractional Ownership?",
      answer:
        "Fractional Ownership is an extension of the “sharing economy”. Multiple owners can buy a percentage ownership in an asset such as a real estate property or an artwork. Together, the shared owners, proportionate to their ownership, can share income generated from that asset, and even realize profits on its eventual sale. ",
    },
    {
      question: "What is Fractional Investing?",
      answer:
        "Fractional Investing is an alternative investment approach that allows investors to invest smaller amounts in expensive but promising assets, which otherwise may be out of their reach. Fractional investing helps small investors diversify their portfolio even with limited capital.",
    },
    {
      question: "What is GAYN Capital?",
      answer:
        "GAYN Capital is a FinTech company headquartered in Chennai, India. Our primary product is an advanced digital ecosystem for Fractional Ownership in Commercial Indian Realty.",
    },
    {
      question: "What does GAYN Capital do?",
      answer:
        "At GAYN Capital, we curate the best Commercial Real Estate (CRE) investment opportunities in India and make them accessible and affordable to varying classes of investors via our proprietary technology platform. ",
    },
    {
      question: "Who can invest with GAYN Capital?",
      answer:
        "Indian citizens, above the age of 18 years, from any part of the world. ",
    },
    {
      question: "What does CRE stand for?",
      answer:
        "CRE stands for Commercial Real Estate. It can also be called Commercial Realty.",
    },
    {
      question: "What is Commercial Real Estate (CRE)",
      answer:
        "Any property that is used exclusively for business is commercial real estate or CRE. Examples include Offices, Stores, Malls, Hospitals, Hotels, Restaurants, and Warehouses. ",
    },

    {
      question: "How do I start investing?",
      answer:
        <a>Investing with GAYN Capital is as easy as 1..2..3..
      <ul>
      <li style={{listStyle:"none"}}>1. Log in to <a href="/">gayncapital.com</a></li>
      <li style={{listStyle:"none"}}> 2. Create your account</li>
        
      <li style={{listStyle:"none"}}> 3. Choose a product that interests you... and you are well on your way.</li></ul>
        
        </a>,
    },
    {
      question: "What does EOI stand for?",
      answer:
        "EOI stands for Expression of Interest, a formal document expressing serious interest by a potential investor in one of our curated assets. ",
    },
    {
      question: "What is the minimum investment expected from a GAYN Capital investor?",
      answer:
        "₹5 lakhs. ",
    },
    {
      question: "Why do I need to do an e-KYC?",
      answer:
        "e-KYC, also called Electronic KYC, is the fastest, most convenient way to complete our KYC process. As soon as your KYC documents are uploaded to our platform, they will be verified, and your account with us will be enabled to invest. ",
    },
    {
      question: "What documents do I need to sign up with GAYN Capital?",
      answer:
      <a>You need to submit just three items as KYC:
      <ul>
      <li style={{listStyle:"none"}}>1.	A copy of your PAN Card.</li>
      <li style={{listStyle:"none"}}>2.	Address Proof (Aadhar/Driver's License/Passport).</li>
        
      <li style={{listStyle:"none"}}>3.	Bank Account number or a cancelled cheque.</li></ul>
        
        </a>,
   
    },
    {
      question: "How can NRIs invest with GAYN Capital?",
      answer:
      <a>Our NRI investors can invest via: 
      <ul>
      <li>An NRO or NRE Account with an Indian bank.</li>
      <li>A normal savings bank account in India.</li>
        </ul>
        Please note that transfer of foreign currency by NRIs is regulated by current RBI and FEMA guidelines. 
        </a>,
    },
    {
      question: "What are the documents that NRIs need to submit for KYC?",
      answer:
      <a>If you are an NRI, you need to submit just two items as KYC:
      <ul>
      <li style={{listStyle:"none"}}>1.	A copy of your PAN Card.</li>
      <li style={{listStyle:"none"}}>2.	An NRO or NRE Savings Bank Account number or a cancelled cheque.</li>
        </ul> </a>,
    },
    {
      question: "What kind of tax deductions should I expect on my rental returns?",
      answer:
      <a>GAYN Capital will make tax deductions before remitting monthly returns:
      <ul>
      <li>10% TDS for Resident Indians.</li>
      <li>20.8% TDS for NRI Investors.</li>
        </ul>  For a reduction in TDS, Resident Indians can submit Form 15G/15H, and NRIs can explore benefits under Double Taxation Avoidance Agreement (“DTAA”) entered with the respective country, subject to availability of Tax Residency Certificate (“TRC”).</a>,
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeaderFAQ
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Box pt={2}>
          {items.map((item, index) => (
            <Accordion key={index}>
              <AccordionSummary 
              className={classes.Muiexpanded}
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`faq-panel-${index}`}
                // style={{ color: "#3DAEB6"}}
              >
                <Typography
                //  style={{color:"#3DAEB6",  }} 
                 variant="h6">{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails  id={`faq-panel-${index}`}>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
    </Section>
  );
}

export default FaqSection;
