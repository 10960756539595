import React, { useEffect, useState } from "react";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Grid from "@material-ui/core/Grid";
import { useForm } from "react-hook-form";
import { Alert } from '@material-ui/lab';
import { auth } from '../../util/auth';
import { addLeads } from '../../util/db';
import { requireAuth } from "../../util/auth";
import { InputAdornment } from "@material-ui/core";
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import {  approveEOI, getaProducts } from '../../util/db'
import { ToastContainer, toast } from 'react-toastify';
import {  genericEmail } from '../../util/functions';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


function ApproveEOI1(props) {
    const { handleCloseModal } = props;
    const [open, setOpen] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [formAlert, setFormAlert] = useState(null);
    const [investmenta, setInvestmenta] = useState(parseFloat(props?.rowinfos?.investment_amount));
   const [product, setProduct] = useState([]);
    const handleClose = (needRefresh = false) => {
        setOpen(false);
        handleCloseModal(needRefresh);
    };


    useEffect(() => {
        if (open) {
            setFormAlert(null);
            setProcessing(false);
            
        }
    }, [open]);

    const { handleSubmit, register, errors, getValues } = useForm();

    const onSubmit = async (formData) => {
        try {
            const otherRows = props.eoi_data.filter((row) => row.id !== props.rowinfos.id);
            const totalInvestment = await otherRows.reduce((acc, etf) => acc + Number(etf.investment_amount) , 0);
            if (Number(totalInvestment) + Number(investmenta) > Number(props.product_cost) ) {
                toast.error("Investment exceed the property cost", {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                return;
            }
            console.log(formData);
            setProcessing(true);
            const data25 = {
                'email': props.rowinfos.email,
                'name': props.rowinfos.name,
                'investment_amount': investmenta,
                'status': 'Approved',
                'templateid' : 'd-9b484f1fbb6741f8bfe8a533fbb10fc2',
                'property_name' : props.property_name,
            }
            await approveEOI(props.rowinfos.id, investmenta, props.rowinfos);

            genericEmail(data25);
            toast.success("Investment Updated Successfully and EOI has been approved", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            setTimeout(() => handleClose(true), 500);
        } catch (error) {
            setProcessing(false);
            toast.success("Investment updated unsuccessfully", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEscapeKeyDown={true}
                disableBackdropClick={true}>
                <Box sx={style}>
                    {formAlert && (
                        <Box mb={3}>
                            <Alert severity={formAlert.type}>{formAlert.message}</Alert>
                        </Box>
                    )}
                    <Typography id="modal-modal-title" variant="h4" component="h2" align='center' style={{ padding: '1rem' }}>
                        Approve Investment
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Client Name"
                                    InputLabelProps={{ shrink: true }}
                                    name="first_name"
                                    fullWidth={true}
                                    disabled={true}
                                    value={props?.rowinfos?.name}
                                    error={errors.name ? true : false}
                                    helperText={errors.name && errors.name.message}
                                    inputRef={register({
                                        required: "Please enter your client First Name",
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Client Email"
                                    InputLabelProps={{ shrink: true }}
                                    name="first_name"
                                    fullWidth={true}
                                    disabled={true}
                                    value={props?.rowinfos?.email}
                                    error={errors.name ? true : false}
                                    helperText={errors.name && errors.name.message}
                                    inputRef={register({
                                        required: "Please enter your client First Name",
                                    })}
                                />
                            </Grid>
                            <h2 style={{ fontWeight: "400", marginBottom: "20px" }}>  Investment Amount </h2>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={8} md={8}>
              <TextField
                variant="outlined"
                type="tel"
                placeholder="Investment Amount"
                name="investmentamount"
                value={investmenta}
                error={errors.name ? true : false}
                helperText={errors.name && errors.name.message}
                fullWidth={true}
                inputRef={register({
                  required: "Please enter your amount",
                })}
              />
            </Grid>
            <Grid item={true} xs={3} md={2}> <div style={{ display: "flex", flexDirection: "row", justifyContent: 'flex-start' }}>
              <Button variant="" onClick={() => setInvestmenta(investmenta + 250000)} style={{ color: "green", display: "flex", justifyContent: "flex-end" }} startIcon={<AddCircleOutlineRoundedIcon />}>
              </Button>
              <Button variant="" onClick={() => {if (Number(investmenta) <= 1250000){setInvestmenta(investmenta)}else{setInvestmenta( investmenta - 250000 )}}} style={{ color: "red", display: "flex", justifyContent: "flex-end", }} startIcon={<RemoveCircleOutlineRoundedIcon style={{ height: "40px" }} />}>

              </Button>
            </div></Grid>

            <Grid item={true} xs={12} md={12}>
              <TextField
                variant="outlined"
                type="number"
                label="No of Shares"
                name="shares"
                InputLabelProps={{ shrink: true }}
                error={errors.shares ? true : false}
                helperText={errors.shares && errors.shares.message}
                onChange={(e) => setInvestmenta(e.target.value*250000)}
                fullWidth={true}
                inputRef={register({
                  required: "Please enter your amount",
                })}
                onInput = {(e) =>{
                    // e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                    e.target.value =  e.target.value.replace(/[^0-9]/g, '').slice(0,3);
                }}
              />
            </Grid>
          </Grid>

          <br />
                            <Grid item xs={6} style={{ textAlign: 'center' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    type="submit"
                                    disabled={processing}
                                >
                                    Submit
                                </Button>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'center' }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    type="button"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}


export default ApproveEOI1