import React from "react";
import Container from "@material-ui/core/Container";
import Sectionbks1 from "./Sectionbks1";
import SectionHeader2 from "./SectionHeader2";
import Contact from "./Contact";
import Section3c from "./../components/Section3c";


function ContactSection(props) {
  console.log('1112', props.minimuminv)
  return (
    <Sectionbks1
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader2
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="Left"
        />

        <Contact
          showNameField={props.showNameField}
          buttonText={props.buttonText}
          buttonColor={props.buttonColor}
          minimumshares={props.minimumshares}
          minimuminv={props.minimuminv}
          propertycost={props.propertycost}
        />
      </Container>
    </Sectionbks1>
  );
}

export default ContactSection;
