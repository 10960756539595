import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Sectionbks1 from "./Sectionbks1";
import { Link } from "./../util/router";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useAuth } from "./../util/auth";

// import { useAuth } from "./../util/auth";
import { useDarkMode } from "./../util/theme";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 50,
    marginRight: theme.spacing(2),
  },
  drawerList: {
    width: 250,
  },
  spacer: {
    flexGrow: 1,
  },
  button: {
    backgroundColor: '#fff',
    color: '#404040',
    '&:hover': {
      backgroundColor: '#3DAEB6',
      color: '#ffffff',
  },
  
}}));

function Navbar(props) {
  const classes = useStyles();
  const auth = useAuth();
  // const auth = useAuth();
  
  const [userid, setUserid] = useState(null);
  const darkMode = useDarkMode();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuState, setMenuState] = useState(null);


  // Use inverted logo if specified
  // and we are in dark mode
  const logo =
    props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  const handleOpenMenu = (event, id) => {
    // Store clicked element (to anchor the menu to)
    // and the menu id so we can tell which menu is open.
    setMenuState({ anchor: event.currentTarget, id });
  };

  const handleCloseMenu = () => {
    setMenuState(null);
  };

  return (
    <Sectionbks1 bgColor={props.color} size="auto" style={{ height: "75px" }}>
      <AppBar
        position="static"
        color="transparent"
        elevation={0}
        style={{ marginTop: "8px" }}
      >
        <Container disableGutters={true} >
          <Toolbar >
            <Link to="/">
              <img src={logo} alt="Logo" className={classes.logo} />
            </Link>
            
                  
            <div className={classes.spacer}  />
            
            <Hidden smUp={true} implementation="css" > 
              <IconButton
                onClick={() => {
                  setDrawerOpen(true);
                }}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Hidden xsDown={true} implementation="css"  >


                        
                <>
                  <div style={{ width: "410px"}}> </div>
                  
                  

                  {userid && (
                <>
                <div style={{ width: "510px"}}> </div>
                <Button href="/"
                    color="primary"
                    variant="text"
                    size="large"
                    style={{marginRight:"1%",  fontWeight:"bold"}}
                    className={classes.button}
                  >
                    Home
                  </Button>
                  <Button href="/#section2"
                    color="primary"
                    variant="text"
                    size="large"
                    style={{marginRight:"1%",  fontWeight:"bold"}}
                    className={classes.button}
                  >
                    CRE
                  </Button>
                  <Button href="/#section3"
                    color="primary"
                    variant="text"
                    size="large"
                    style={{marginRight:"1%", fontWeight:"bold"}}
                    className={classes.button}
                  >
                    About Us
                  </Button>
                  <Button href="/#section4"
                    color="primary"
                    variant="text"
                    size="large"
                    className={classes.button}
                    style={{marginRight:"1%", fontWeight:"bold"}}
                  >
                    Our Team
                  </Button>
              
                  <Button
                    color="inherit"
                    aria-label="Account"
                    aria-controls="account-menu"
                    aria-haspopup="true"
                    onClick={(event) => {
                      handleOpenMenu(event, "account-menu");
                    }}
                    style={{marginRight:"1%", fontWeight:"bold", color:"primary"}}
                  >
                    
                    <AccountCircleIcon style={{height:'35px', width:'auto'}} color="primary"  />
                    <ExpandMoreIcon color="primary" className={classes.buttonIcon} />
                  </Button>
                  <Menu
                    id="account-menu"
                    open={
                      menuState && menuState.id === "account-menu"
                        ? true
                        : false
                    }
                    anchorEl={menuState && menuState.anchor}
                    getContentAnchorEl={undefined}
                    onClick={handleCloseMenu}
                    onClose={handleCloseMenu}
                    keepMounted={true}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem component={Link} to="/user/dashboard">
                     My Dashboard
                    </MenuItem>
                    <MenuItem component={Link} to="/user/details">
                      My Details
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={(event) => {
                        auth.signout();
                      }}
                    >
                      Signout
                    </MenuItem>
                  </Menu>
                </>
              )}
                            {!userid && (
                <>
                <div style={{ width: "610px"}}> </div>
                                  <Button href="/"
                    color="primary"
                    variant="text"
                    size="large"
                    style={{marginRight:"1%",  fontWeight:"bold"}}
                    className={classes.button}
                  >
                    Home
                  </Button>
                  <Button href="/#section2"
                    color="primary"
                    variant="text"
                    size="large"
                    style={{marginRight:"1%",  fontWeight:"bold"}}
                    className={classes.button}
                  >
                    CRE
                  </Button>
                  <Button href="/#section3"
                    color="primary"
                    variant="text"
                    size="large"
                    style={{marginRight:"1%", fontWeight:"bold"}}
                    className={classes.button}
                  >
                    About Us
                  </Button>
                  <Button href="/#section4"
                    color="primary"
                    variant="text"
                    size="large"
                    className={classes.button}
                    style={{marginRight:"1%", fontWeight:"bold"}}
                  >
                    Our Team
                  </Button>

                  <Button component={Link} to="/auth/signin" color="inherit"
                  style={{marginRight:"1%",  fontWeight:"bold"}}
                  className={classes.button}>
                    Sign in
                  </Button>
                  <Button
                    component={Link}
                    to="/auth/signup"
                    color="primary"
                    variant="outlined"
                    style={{marginRight:"1%",  fontWeight:"bold"}}
                    className={classes.buttonc}
                  >
                    Sign up
                  </Button>
                </>
              )}
                   
                </>
             




          
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <List
          className={classes.drawerList}
          onClick={() => setDrawerOpen(false)}
        >
          {/* {!auth.user && (
            <ListItem component={Link} to="/auth/signin" button={true}>
              <ListItemText>Sign in</ListItemText>
            </ListItem>
          )} */}



<ListItem>

<Button href="/"
  color="primary"
  variant="text"
  size="large"
  className={classes.button}
  style={{marginRight:"1%", fontWeight:"bold"}}
>
  Home
</Button></ListItem>
<ListItem>

<Button href="/#section2"
        color="primary"
        variant="text"
        size="large"
        style={{marginRight:"1%",  fontWeight:"bold"}}
        className={classes.button}
      >
        CRE
      </Button>
      </ListItem>
      <ListItem>

      <Button href="/#section3"
        color="primary"
        variant="text"
        size="large"
        style={{marginRight:"1%", fontWeight:"bold"}}
        className={classes.button}
      >
        About Us
      </Button></ListItem>
      <ListItem>

      <Button href="/#section4"
        color="primary"
        variant="text"
        size="large"
        className={classes.button}
        style={{marginRight:"1%", fontWeight:"bold"}}
      >
        Our Team
      </Button></ListItem>


        </List>
      </Drawer>
    </Sectionbks1>
  );
}

export default Navbar;
