import { Box, Container, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Navbr from '../../components/cpnav';
import { makeStyles } from "@material-ui/core/styles";
import { requireAuth ,  useAuth} from "../../util/auth";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {IconButton , InputAdornment} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {getUser,getCpd, getaProducts, getAllLeads} from "../../util/db";
import Page1S2 from "./../../components/Page1S2";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const UseStyles = makeStyles((theme) => ({

  container: {
    paddingTop:"100px",
    // paddingLeft:"280px",
    // paddingRight:"0px",
    marginLeft:"18%",
    marginRight:"0px",
    // width:"100%"
  },
}));
function Dashboard() {
  const classes = UseStyles();
  const auth = useAuth();
  const [clients, setClients] = useState([]);
  const userId = auth?.user?.id; 
  const copyText = 'https://gayncapital.com/auth/signup/'+userId;
  const [uid, setUid] = useState(userId);
  const [userd, setUserd] = React.useState([]);
  const [isCopied, setIsCopied] = React.useState(false);
  const [products, setProducts] = useState([]);
  const isSmall = useMediaQuery('(min-width:800px)');
  const fetchClients = async () => {
    const temp = await getAllLeads();
    setClients(temp);
    console.table('my clients', temp);
    
}
  const populateUser = async () => {
   
    if (userId !== null && userId !== undefined) {
      const user = await getCpd(uid);
      setUserd (user)
    }
  }
  const fetchProductsForFilter = async () => {
    const temp = await getaProducts('dWjFy1y1XVJ3Ue8EYFBx');

    setProducts(temp);
  }
  useEffect(() => {
    populateUser();
    fetchClients();
    fetchProductsForFilter();
  }, [])

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }


  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  return (<>
    <Navbr/>
    {/* <Container className={classes.container}> */}
    <section style={{ paddingTop:"100px",marginLeft:isSmall? "300px":"10px",marginRight:"5%",}}>
   <br />
   <br />
   <div style={{fontSize: 25, fontWeight:"bold",}}>Welcome <a style={{color:'#3DAEB6'}}>{userd.first_name} {userd.last_name}</a></div>

   <br />
   <br />
 <Grid container={true} alignItems="center" spacing={2} style={{fontVariant:'all-petite-caps'}}>
 <Grid container={true} item={true} direction="column"  style={{overflow:"hidden"}} xs={6} md={3}>
      <section style={ {backgroundColor:"#262632", paddingLeft:"8%",paddingTop:"8%", textAlign:"center", display:'flex', flexDirection:'column',alignContent:"left", justifyContent:"center", alignItems:"flex-start"}}> 
 <img style={{height:isSmall? "3vw": "50px", maxHeight:"75px", marginBottom:'10px'}} src='https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/clients.png?alt=media&token=2ebb7c31-851f-4a1d-94a1-9b84b16abc7a'/>
 <p style={{color:"#E5E5E5", marginRight:"2%", fontSize:isSmall? '15px': '12px', fontWeight:'400'}}>Total Clients</p>
  <p style={{color:"white", marginRight:"2%", fontSize:isSmall? '35px': '20px', fontWeight:'400', marginTop:"0px"}}>{clients?.length}</p>
    </section>
        </Grid>

        <Grid container={true} item={true} direction="column"  style={{overflow:"hidden"}} xs={6} md={3}>
      <section style={ { border:'2px solid #E5E5E5', paddingLeft:"8%",paddingTop:"8%", textAlign:"center", display:'flex', flexDirection:'column',alignContent:"left", justifyContent:"center", alignItems:"flex-start"}}> 
      <img style={{height:isSmall? "3vw": "50px", maxHeight:"75px", marginBottom:'10px'}} src='https://firebasestorage.googleapis.com/v0/b/gayn-82759.appspot.com/o/apartment%201.png?alt=media&token=a64900de-5960-40b0-871e-4d3310fc5ef3'/>
 <p style={{color:"#8E8E8E", marginRight:"2%", fontSize:isSmall? '15px': '12px', fontWeight:'400'}}>Total Properties</p>
  <p style={{color:"black", marginRight:"2%", fontSize:isSmall? '35px': '20px', fontWeight:'400', marginTop:"0px"}}>{userd.tp}</p>
    </section>
        </Grid>
        <Grid container={true} item={true} direction="column"  style={{overflow:"hidden"}} xs={6} md={3}>
        <section style={ {backgroundColor:"#262632", paddingLeft:"8%",paddingTop:"8%", textAlign:"center", display:'flex', flexDirection:'column',alignContent:"left", justifyContent:"center", alignItems:"flex-start"}}> 
 <img style={{height:isSmall? "3vw": "50px", maxHeight:"75px", marginBottom:'10px'}} src='https://firebasestorage.googleapis.com/v0/b/gayn-82759.appspot.com/o/investment%201.png?alt=media&token=ebec75d6-6fd9-441c-bea8-b2cb17bb1421'/>
 <p style={{color:"#E5E5E5", marginRight:"2%", fontSize:isSmall? '15px': '12px', fontWeight:'400'}}>Total Investments</p>
  <p style={{color:"white", marginRight:"2%", fontSize:isSmall? '35px': '20px', fontWeight:'400', marginTop:"0px"}}>{userd.ti}</p>
    </section>
        </Grid>
        <Grid container={true} item={true} direction="column"  style={{overflow:"hidden"}} xs={6} md={3}>
      <section style={ { border:'2px solid #E5E5E5', paddingLeft:"8%",paddingTop:"8%", textAlign:"center", display:'flex', flexDirection:'column',alignContent:"left", justifyContent:"center", alignItems:"flex-start"}}> 
      <img style={{height:isSmall? "3vw": "50px", maxHeight:"75px", marginBottom:'10px'}} src='https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/returns.png?alt=media&token=574aeecd-0c48-41b9-9339-a75fce55bfa6'/>
 <p style={{color:"#8E8E8E", marginRight:"2%", fontSize:isSmall? '15px': '12px', fontWeight:'400'}}>Total Commissions</p>
  <p style={{color:"black", marginRight:"2%", fontSize:isSmall? '35px': '20px', fontWeight:'400', marginTop:"0px"}}>{userd.tr}</p>
    </section>
        </Grid>

        
        </Grid>
        
    <Grid container={true} spacing={2}>
    <Grid item={true} xs={12}>
      <h1>Your Referral link</h1>
    </Grid>
                <Grid item={true} xs={12}>
      <TextField
      style={{fontWeight:'700', fontSize:'20px'}}
      variant="outlined"
      color="primary"
       type="text" 
       fullWidth
       InputProps={{ 
        style: {fontSize: 20, fontWeight:'800'},// <-- This is where the toggle button is added.
        endAdornment: (
          <InputAdornment position="end">
      <Button
      fullWidth
                          variant="f"
                          color="primary"
                          size="large"
       onClick={handleCopyClick}>
        <span>{isCopied ? 'Copied!' : 'Copy'}</span>
      </Button>
        
    </InputAdornment>
        )
        
      }}
       value={copyText}
       disabled></TextField></Grid>
       </Grid>


       <Page1S2
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        text1="Property Size"
        ps="80000"
        psqft="8000"
        pc="317,229,880"
        tirr="16%"
        ey="9%"
        testext="22dda"
        ifr="Monthly"
        mi="Rs. 25,00,000"
        title= {products.property_name}
        subtitle={products.location}
        subtitle1="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nunc massa habitant amet. Suscipit gravida nisl, ipsum nulla neque ornare. Condimentum nisi, sed nunc amet enim. Dolor venenatis sed gravida sodales lobortis feugiat odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nunc massa habitant amet. Suscipit gravida nisl, ipsum nulla neque ornare. Condimentum nisi, sed nunc amet enim. Dolor venenatis sed gravida sodales lobortis feugiat odio."
        image="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/current-offer.png?alt=media&token=66841019-8150-4511-b576-97fe3cdc5af7"
      />

       </section>
    {/* </Container> */}
    </>
  )
}
export default requireAuth(Dashboard, ["cp"]);
