import { Container } from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Navbr from '../../components/usernav';
import { requireAuth,  useAuth } from "../../util/auth";
import { makeStyles } from "@material-ui/core/styles";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import {getAllEOIProductsForUser} from '../../util/db';
import { Link } from "../../util/router";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Page1S21 from "../../components/Page1S21";
import Page1S22 from "../../components/Page1S22user";
import Page1S23user from "../../components/Page1S23user";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ToastContainer, toast } from 'react-toastify';
const UseStyles = makeStyles((theme) => ({

  container: {
    paddingTop:"100px",
    marginLeft:"18%",
    marginRight:"0px",
  },
}));
 function Myrealestate(props) {
  const auth = useAuth();
  const userid = auth?.user?.id;
  const [value, setValue] = useState('1');
  const [userproducts, setUserproducts] = useState([]);
  const isSmall = useMediaQuery('(min-width:800px)');

  const [userproducteoi, setUserproducteoi] = useState([]);
  const [specificp, setSpecificp] = useState();
  const [selectedproduct, setSlectedproduct] = useState();
  const [selectedUserId, setSelectedUserId] = React.useState(userid);
  const [userProductTransaction, setUserProductTransaction] = React.useState([]);
  const [requested, setRequested] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    fetcheoiForFilter();
}, []);
  const fetcheoiForFilter = async () => {
    const temp = await getAllEOIProductsForUser(auth?.user?.email);
    setUserproducts(temp.products);
    setUserproducteoi(temp.eoistat);
    console.log('seee', temp)
    
}
const getEOIStatus = (pid) => {
  const temp = userproducteoi.filter((eoi) => eoi.productid === pid);
  if(temp.length > 0) {
    return temp[0].status;
  }
  return "";
}
const getEOIinvs = (pid) => {
  const temp = userproducteoi.filter((eoi) => eoi.productid === pid);
  if(temp.length > 0) {
    return temp[0].investment_amount;
  }
  return "";
}

  const classes = UseStyles();
  return (
   <>
   <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>

    <Navbr/>
     {/* <Container className={classes.container}> */}
     <section style={{ paddingTop:"100px",

marginLeft:isSmall? "300px":"10px",
marginRight:"5%",}}>
      <h1>My <a style={{color:'#3DAEB6'}}>Investments</a></h1>
     {specificp == null && userproducts.map((product) => (<>
     <Card sx={{ maxWidth: 345 }}>
      <CardActionArea
      // component={Link}
      // to='/'
      onClick={() => {setSlectedproduct(product);
        setSpecificp(product.id);}}
      >

        <CardMedia
          component="img"
          height="140"
          image={product.images[0]}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {product.property_name}
          </Typography>

        </CardContent>
      </CardActionArea>
    </Card></>)) }

    {specificp != null && 
      <>
      <Container >
        <Grid container={true} alignItems="center" spacing={8}>
          <Grid>
            <figure className={classes.imageWrapper}>
              <img src={selectedproduct.images[0]} alt="" style={{ marginLeft: "15px", height:"350px" }} />
            </figure>
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <Page1S22
              title={<><a>{selectedproduct.property_name}</a> <a style={{color:'#FBA704', fontSize:"15px"}}>{getEOIStatus(selectedproduct.id)}</a></>}
              subtitle={selectedproduct.location}
              subtitle1={selectedproduct.description || props.subtitle1}
              size={5}
            />
            <Page1S23user
              title={selectedproduct.property_cost}
              text1={selectedproduct.property_cost}
              ps={getEOIinvs(selectedproduct.id)}
              psqft={selectedproduct.entry_yeild}
              pc={selectedproduct.property_cost}
              tirr={selectedproduct.target_irr}
              ey={selectedproduct.entry_yeild}
              ifr={selectedproduct.income_frequency}
              mi={selectedproduct.min_investment}
              size={6}
              size1={1}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
            </div>
          </Grid>
        </Grid>
        </Container>
        {/* </Container> */}
        </>
     }  

 </section>
      {/* </Container> */}
    </>
     
              
  )
}
// export default requireAuth(Myrealestate, "user"); from git
export default requireAuth(Myrealestate);