import { Container } from '@material-ui/core';
import Navbr from '../../components/cpnav';
import { requireAuth,  useAuth } from "../../util/auth";
import { makeStyles } from "@material-ui/core/styles";
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import React, { useState, useEffect } from 'react'
import AdminNavbar from './../../components/AdminNavbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
    getEOIForTransactionByProduct,
    getTransactionByUserAndProduct,
    getAllProducts,
    updateTransactionByProduct,
    deleteTransaction,
    markProductActive,
    getCpteamdata,
    getCpportfolio
} from '../../util/db';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from "@material-ui/core/Button";
import Grid from '@mui/material/Grid';
import { Checkbox } from '@material-ui/core';
import { Alert } from '@mui/material';
const UseStyles = makeStyles((theme) => ({

  container: {
    paddingTop:"100px",
    // paddingLeft:"280px",
    // paddingRight:"0px",
    marginLeft:"18%",
    marginRight:"0px",
    // width:"100%"
  },
}));
const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;


    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    td {
      background-color: #DCDCDC;
      color: black;
    }
    th {
      background-color: #262632;
      color: white;
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`

export default function Myteam() {
  const auth = useAuth();
  const userid = auth?.user?.id;
  const [teams, setTeams] = useState([]);
const [value, setValue] = React.useState('1');
//changes need to be done
const [selectedProduct, setSelectedProduct] = useState('dWjFy1y1XVJ3Ue8EYFBx');
const [selectedUserId, setSelectedUserId] = React.useState(userid);
const [userProductTransaction, setUserProductTransaction] = React.useState([]);
const isSmall = useMediaQuery('(min-width:800px)');

    const fetchTeams = async () => {
        const temp = await getCpteamdata();
        setTeams(temp);
        console.table('my clients', temp);

    }

useEffect(() => {
    fetchTeams();
}, []);

const classes = UseStyles();
return (
 <>
  <Navbr/>
   {/* <Container className={classes.container}> */}
   <section style={{ paddingTop:"100px",

marginLeft:isSmall? "300px":"20px",
marginRight:"5%",}}>  <div style={{fontSize: 25, fontWeight:"bold",}}>My Teams</div>
 <Box ><Styles>
                  <TableContainer sx={{ maxHeight: 440 }}>
                      <Table stickyHeader aria-label="sticky table">
                          <TableHead >
                              <TableRow > 
                                  <TableCell>
                                     Name
                                  </TableCell>
                                  <TableCell>
                                      Mail Id
                                  </TableCell>
                                  <TableCell>
                                  Number of client
                                  </TableCell>
                                  <TableCell>
                                        Total Investment
                                  </TableCell>
                                  <TableCell>
                                      Total Commission
                                  </TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {
                                  teams.map((row) => {
                                      return (
                                          <TableRow key={row.id} hover role="checkbox" tabIndex={-1}>
                                              <TableCell>
                                                  {row.first_name}{row.last_name}
                                              </TableCell>
                                              <TableCell>
                                                  {row.email}
                                              </TableCell>
                                              <TableCell>
                                                  {row.tc}
                                              </TableCell>
                                              <TableCell>
                                                  {row.ti}
                                              </TableCell>
                                              <TableCell>
                                                  {row.tr}
                                              </TableCell>

                                          </TableRow>
                                      );
                                  })}
                              {teams.length == 0 &&
                                  <TableRow hover role="checkbox" tabIndex={-1}>
                                      <TableCell colSpan={7} >
                                          You have no team
                                      </TableCell>
                                  </TableRow>
                              }
                          </TableBody>
                      </Table>
                  </TableContainer>
 </Styles>
              </Box>
              </section>
    {/* </Container> */}
  </>
)
}

