import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section23234 from "./Section23234";

const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    marginTop: 10,
  },
  row: {
    // Reverse every other row
    "&:nth-of-type(even)": {
      flexDirection: "row-reverse",
      justifyContent: "space-between",
    },

    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(2)}px`,
    },
  },
  figure: {
    maxWidth: 500,
    marginRight: "10px auto",
  },
  image: {
    height: "auto",
    maxWidth: "100%",
  },
}));

function Section621(props) {
  const classes = useStyles();

  const items = [
    {
      title: "",
      image: props.images[1],
      description: (
        <div style={{ textAlign: "left" }}>
          <p>
            {props.highlight_text[2]}
          </p>{" "}
          <p>
            {" "}
            {props.highlight_text[3]}
          </p>
        </div>
      ),
    },
    // {
    //   title: "",
    //   description: (
    //     <div style={{ textAlign: "left" }}>
    //       <p>
    //       {props.highlight_text[4]}
    //       </p>{" "}
    //       <p>
    //         {" "}
    //         {props.highlight_text[5]}
    //       </p>
    //     </div>
    //   ),
    //   image: props.images[1],
    // },
  ];

  return (
    <Section23234
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container style={{ marginTop: "-70px" }}>
        <Container disableGutters={true} className={classes.itemsContainer}>
          <h4 style={{ fontSize: "20px", color: "#3DAEB6", marginLeft: "5px" }}>
            Highlights
          </h4>

          {items.map((item, index) => (
            <Grid
              className={classes.row}
              container={true}
              item={true}
              alignItems="center"
              spacing={4}
              key={index}
            >
              <Grid item={true} xs={12} md={6} style={{ marginLeft: "-35px" }}>
                <figure className={classes.figure}>
                  <img
                    src={item.image}
                    alt={item.title}
                    className={classes.image}
                  />
                </figure>
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <Box
                  textAlign={{
                    xs: "center",
                    md: "left",
                  }}
                >
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ textAlign: "left" }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          ))}
        </Container>
      </Container>
    </Section23234>
  );
}

export default Section621;
