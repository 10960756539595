import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section23234 from "./Section23234";

const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    marginTop: 10,
  },
  row: {
    // Reverse every other row
    "&:nth-of-type(even)": {
      flexDirection: "row-reverse",
      justifyContent: "space-between",
    },

    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(2)}px`,
    },
  },
  figure: {
    maxWidth: 500,
    marginRight: "10px auto",
  },
  image: {
    height: "auto",
    maxWidth: "100%",
  },
}));

function Section621seller(props) {
  const classes = useStyles();

  const items = [
    {
      title: "",
      image: 'https://firebasestorage.googleapis.com/v0/b/gayn-dev.appspot.com/o/Properties%2FBIBA%2Fbhoomi.png?alt=media&token=dd627d5e-d9e4-472a-8262-4a5319ada5f3',
      description: (
        <div style={{ textAlign: "left" }}>
          <p>
          Bhoomi and Buildings believes in offering personalised service to each of
their customers. The dedicated team of well qualified executives,
professionals and consultants, leave no stone unturned to provide the best
solutions in design, construction and legal services. 
          </p>{" "}
          <p>
            {" "}
            Over the last decade, many home buyers have truly been satisfied with
Bhoomi and Buildings trustworthy and comprehensive services. Adopting
only the best practices, they have moved ahead by giving their clientele a
range of options to choose from, be it plots of land or luxurious
apartments in prime locations across the city. 
          </p>
        </div>
      ),
    },
    {
      title: "",
      description: (
        <div style={{ textAlign: "left" }}>
          <p>
          Bhoomi and Buildings effortlessly, striking new ground and making pathbreaking initiatives to expand the ever-growing network of happy families
bonded to Bhoomi and Buildings. 
          </p>{" "}
          <p>
            {" "}
            The new store in Padur, OMR, is one of those, estimated to see a huge response from the local community. Located in close proximity to the elevated road project, and with easy access to the upcoming metro, there is also a 3.5 lakh strong workforce in the near vicinity. With such a large audience, the potential for a large number of footfalls is extremely high.
          </p>
        </div>
      ),
      image: 'https://firebasestorage.googleapis.com/v0/b/gayn-dev.appspot.com/o/Properties%2FBIBA%2FElevations%20(1).jpeg?alt=media&token=195538df-3b8e-4fbf-bfa5-f12058d8b0d8',
    },
  ];

  return (
    <Section23234
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container style={{ marginTop: "-70px" }}>
        <Container disableGutters={true} className={classes.itemsContainer}>
          <h4 style={{ fontSize: "20px", color: "#3DAEB6", marginLeft: "5px" }}>
            Highlights
          </h4>

          {items.map((item, index) => (
            <Grid
              className={classes.row}
              container={true}
              item={true}
              alignItems="center"
              spacing={4}
              key={index}
            >
              <Grid item={true} xs={12} md={6} style={{ marginLeft: "-35px" }}>
                <figure className={classes.figure}>
                  <img
                    src={item.image}
                    alt={item.title}
                    className={classes.image}
                  />
                </figure>
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <Box
                  textAlign={{
                    xs: "center",
                    md: "left",
                  }}
                >
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ textAlign: "left" }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          ))}
        </Container>
      </Container>
    </Section23234>
  );
}

export default Section621seller;
