import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles((theme) => ({
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    maxWidth: 700,

    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: "inline-block",
  },
  titles:{
    fontSize: "10px",
    display: "inline-block",
    paddingRight: "15px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "12px",
    },
   
  },
  titles1:{
    fontSize: "10px",
    display: "inline-block",
    [theme.breakpoints.up("sm")]: {
      fontSize: "12px",
    },
   
  }
}));

function Page1S23(props) {
  const classes = useStyles();
  const isSmall = useMediaQuery('(min-width:800px)');
  const {
    subtitle,
    subtitle1,
    text1,
    text2,
    title,
    ps,
    psqft,
    pc,
    tirr,
    ey,
    ifr,
    mi,
    size,
    size1,
    className,
    ...otherProps
  } = props;

  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <>
      <Box
        component="header"
        className={
          classes.root + (props.className ? ` ${props.className}` : "")
        }
        {...otherProps}
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignContent: "space-between",
          }}
        >
          {text1 && (
            <Typography
              variant={'subtitle2'}
              gutterBottom={props.subtitle ? true : false}
              className={classes.titles}
            >
              Leased Area
              {ps && (
                <Typography
                  variant={isSmall? `h${size}` : 'body2'}
                  color="primary"
                  gutterBottom={props.subtitle ? true : false}
                  style={{ marginBottom: "25px", fontWeight: "600" }}
                >
                  {ps.toLocaleString("en-IN")} Sq. ft
                </Typography>
              )}
            </Typography>
          )}

          {text1 && (
            <Typography
              variant={'subtitle2'}
              gutterBottom={props.subtitle ? true : false}
              className={classes.titles}
            >
              Target IRR
              {tirr && (
                <Typography
                variant={isSmall? `h${size}` : 'body2'}
                style={{ fontWeight: "600" }}
                  color="primary"
                  gutterBottom={props.subtitle ? true : false}
                  
                >
                  {tirr} %
                </Typography>
              )}
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignContent: "space-between",
            // marginLeft: isSmall? "0px" : "5%",
          }}
        >
          {text1 && (
            <Typography
              variant={'subtitle2'}
              gutterBottom={props.subtitle ? true : false}
              className={classes.titles}
            >
              Investment Multiplier
              {ps && (
                <Typography
                variant={isSmall? `h${size}` : 'body2'}
                style={{ fontWeight: "600" }}
                  color="primary"
                  gutterBottom={props.subtitle ? true : false}
                >
                 1.7x 
                </Typography>
              )}
            </Typography>
          )}

          {text1 && (
            <Typography
              variant={'subtitle2'}
              gutterBottom={props.subtitle ? true : false}
              className={classes.titles}
            >
              Entry Yield
              {tirr && (
                <Typography
                variant={isSmall? `h${size}` : 'body2'}
                style={{ fontWeight: "600" }}
                  color="primary"
                  gutterBottom={props.subtitle ? true : false}
                >
                  {ey} %
                </Typography>
              )}
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignContent: "space-between",
          }}
        >
          {text1 && (
            <Typography
              variant={'subtitle2'}
              gutterBottom={props.subtitle ? true : false}
              className={classes.titles1}
            >
              Property Cost
              {psqft && (
                <Typography
                variant={isSmall? `h${size}` : 'body2'}
                style={{ fontWeight: "600" }}
                  color="primary"
                  gutterBottom={props.subtitle ? true : false}
                >
                  ₹ {pc.toLocaleString("en-IN")}
                </Typography>
              )}
            </Typography>
          )}

          {text1 && (
            <Typography
              variant={'subtitle2'}
              gutterBottom={props.subtitle ? true : false}
              className={classes.titles1}
            >
              Income Frequency
              {ey && (
                <Typography
                variant={isSmall? `h${size}` : 'body2'}
                style={{ fontWeight: "600" }}
                  color="primary"
                  gutterBottom={props.subtitle ? true : false}
                >
                  {ifr}
                </Typography>
              )}
            </Typography>
          )}
        </div>
      </Box>
      <Box
        component="header"
        className={
          classes.root + (props.className ? ` ${props.className}` : "")
        }
        {...otherProps}
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignContent: "space-between",
          }}
        >
          {text1 && (
            <Typography
              variant={'subtitle2'}
              gutterBottom={props.subtitle ? true : false}
              className={classes.titles}
            >
              Minimum Investment
              {ps && (
                <Typography
                variant={isSmall? `h${size}` : 'body2'}
                style={{ fontWeight: "600" }}
                  color="primary"
                  gutterBottom={props.subtitle ? true : false}
                >
                 ₹ {mi.toLocaleString("en-IN")}
                </Typography>
              )}
            </Typography>
          )}
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignContent: "space-between",
            marginTop: "-15px",
            paddingLeft: "10px",
          }}
        >
          <h4>{props.tp}% funded</h4>
          <LinearProgress variant="determinate" style={{ width: isSmall? "250px" : "160px" }} 
                      value={props.tp} />

        </div> */}
      </Box>
    </>
  );
}

export default Page1S23;
