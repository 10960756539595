import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm, Controller } from "react-hook-form";
import contact from "./../util/contact";
import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { Select } from "@material-ui/core";
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import { requireAuth, useAuth } from "../util/auth";
import { getUser, filteremailEOI } from "../util/db";
import { useRouter } from "../util/router";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { isEmpty } from "@firebase/util";
import { history } from "../util/router";

function Contact(props) {
  const auth = useAuth();
  const route = useRouter();
  const productId = route.query.productId;
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const { control, handleSubmit, register, errors, reset, setValue } = useForm();
  const [investmenta, setInvestmenta] = useState(props.minimuminv);
  const [user, setUser] = useState([]);
  const [name, setName] = useState(null);
  const [noofshares, setNoofshares] = useState(props.minimumshares);
  const [filte, setFilte] = useState();
  // const [filtp, setFiltp] = useState();
  console.log('sss', noofshares)
  const onSubmit = (data) => {
    // alert('submit');
    // Show pending indicator
    setPending(true);
    
    contact
      .submit({ ...data, productId })
      .then(() => {
        // Clear form
        reset();
        // Show success alert message
 
        setFormAlert({
          type: "success",
          message: "Your message has been sent!",
        });
        history.push('/user/offerings');
      })
      .catch((error) => {
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };
  // const filtep = async () => {
  //   const filt = filterpropEOI(productId);
  //   if ((await filt).length == 1) {
  //     setFiltp(false)
  //   }
  //   else
  //   {setFiltp(true)
  //   }
  //   console.log("2331", filtp)
  // }
  const filtem = async () => {
    const filt = filteremailEOI(auth?.user?.email, productId);
    if ((await filt).length == 1) {
      setFilte(false)
    }
    else
    {setFilte(true)
    }
    console.log("2322431", filte)
  }
  React.useEffect(() => {
   
    populateUser(); 
    // filtep();
    filtem();
    setInvestmenta(props.minimuminv)
  }, [auth, props]);

  const populateUser = async () => {
    const userid = auth?.user?.id;
    if (userid !== null) {
      const user = await getUser(userid);
      setUser(user);
      reset({
        name: user.first_name + " " + user.last_name,
        email: user.email,
        phone: user.phone_number?.trim().replace("+91", ""),
      })
    }
  }


  return (
    <>
      {formAlert && (
        <Box mb={3}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}
                { (user.aadhaar_online_verified != true || user.aadhaar_offline_verified != true ) &&
     <>      <br />  
    
     <Grid item={true} xs={12} direction="column">
       <section style={{display:'flex', direction:"row", alignItems:"center"}}>
     <ErrorOutlineIcon style={{color:"red", marginRight:"10px"}}> </ErrorOutlineIcon>
     <h3>This account is not KYC Compliant. Complete KYC <a style={{color:"red"}} href="/user/details">Click Here</a></h3>
     </section><br />  </Grid>
     
  </>
    } 
                    { filte == false &&
     <>      <br />  
    
     <Grid item={true} xs={12} direction="column">

     <h3>You have already sumbitted this form. Check out other properties <a style={{color:"#3DAEB6"}} href="/">Click Here</a></h3>
     <br />  </Grid>
   </>
    } 

      <form onSubmit={handleSubmit(onSubmit)}>
        <section>
          <h2 style={{ fontWeight: "400", marginBottom: "20px" }}> Your Personal Information </h2>
          <Grid container={true} spacing={2}>
            {props.showNameField && (

              <Grid item={true} xs={12} md={6}>

                <TextField
                  variant="outlined"
                  type="text"
                  label={"Name"}
                  name="name"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ readOnly: true, }}
                  value={name}
                  error={errors.name ? true : false}
                  helperText={errors.name && errors.name.message}
                  fullWidth={true}
                  inputRef={register({
                    required: "Please enter your name",
                  })}
                />
              </Grid>
            )}

            <Grid item={true} xs={12} md={props.showNameField ? 6 : 12}>
              <TextField
                variant="outlined"
                type="email"
                label="Email"
                InputLabelProps={{ shrink: true }}
                inputProps={{ readOnly: true, }}
                name="email"
                error={errors.email ? true : false}
                helperText={errors.email && errors.email.message}
                fullWidth={true}
                inputRef={register({
                  required: "Please enter your email",
                })}
              />
            </Grid>
            <Grid item={true} xs={12} md={6}>
              <TextField
                variant="outlined"
                type="number"
                label="Phone"
                name="phone"
                InputLabelProps={{ shrink: true }}
                inputProps={{ readOnly: true, }}
                error={errors.message ? true : false}
                helperText={errors.message && errors.message.message}
                fullWidth={true}
                inputRef={register({
                  required: "Please enter your phone number",
                })}
              />
            </Grid>
          </Grid>
        </section>
        <br />

        <section>
          <h2 style={{ fontWeight: "400", marginBottom: "20px" }}> Your Investment </h2>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={9} md={4}>
              <TextField
                variant="outlined"
                type="number"
                placeholder="Investment Amount"
                name="investmentamount"
                value={investmenta}
                InputProps={{ inputProps: { min: props.minimuminv, max: Number(props.propertycost) } }}
                error={errors.name ? true : false}
                helperText={errors.name && errors.name.message}
                fullWidth={true}
                inputRef={register({
                  required: "Please enter your amount",
                })}
              />
            </Grid>
            <Grid item={true} xs={3} md={2}> <div style={{ display: "flex", flexDirection: "row", justifyContent: 'flex-start' }}>
              <Button variant="" onClick={() => {setInvestmenta(investmenta + 250000); setNoofshares((Number(investmenta)+250000)/250000)}} style={{ color: "green", display: "flex", justifyContent: "flex-end" }} startIcon={<AddCircleOutlineRoundedIcon />}>
              </Button>
              <Button variant="" onClick={() => {if (investmenta <= props.minimuminv){setInvestmenta(investmenta); setNoofshares(Number(investmenta)/250000);}else{setInvestmenta( investmenta - 250000 ); setNoofshares((Number(investmenta)-250000)/250000);}}} style={{ color: "red", display: "flex", justifyContent: "flex-end", }} startIcon={<RemoveCircleOutlineRoundedIcon style={{ height: "40px" }} />}>
              </Button>
            </div></Grid>
            <Grid item={true} xs={12} md={12}>
              <TextField
                variant="outlined"
                type="number"
                label="No of Shares"
                name="shares"
                value={noofshares}
                InputLabelProps={{ shrink: true }}
                error={errors.shares ? true : false}
                helperText={errors.shares && errors.shares.message}
                onChange={(e) => {if(e.target.value > 4){setInvestmenta(e.target.value*250000);} setNoofshares(e.target.value);}}
                fullWidth={true}
                inputRef={register({
                  required: "Please enter your amount",

                })}
                onInput = {(e) =>{
                    // e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                    e.target.value =  e.target.value.replace(/[^0-9]/g, '').slice(0,3);
                    const value = parseInt(e.target.value);
                    if (value > 148) {
                      e.target.value = '148';
                    } else {
                      e.target.value = value.toString();
                    }
                }}
              />
            </Grid>
          </Grid>

          <br />
        </section>
        <Grid item={true} xs={12} md={props.showNameField ? 6 : 12}>
          <h2 style={{ fontWeight: "400", marginBottom: "20px" }}> Preferred Mode of Contact </h2>

          <FormControlLabel
            control={<Checkbox />}
            label="Mobile"
            name="mobileyes"
            value="yes"
            // inputRef={register({ required: "yes", })} 
              />
          <FormControlLabel
            control={<Checkbox />}
            label="Email"
            name="emailyes"
            value="yes"
            // inputRef={register({ required: "yes", })}   
            />

        </Grid>
        {(user.aadhaar_online_verified == true && user.aadhaar_offline_verified == true && filte == true ) &&
        <>
        <br />
        <Grid item={true} xs={12}>
          <Button
            style={{ borderRadius: "0px" }}
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={pending}
          >
            {!pending && <span>{props.buttonText}</span>}

            {pending && <CircularProgress size={28} />}
          </Button>
        </Grid>
        </>
         }
                { (user.aadhaar_online_verified != true || user.aadhaar_offline_verified != true ) &&
     <>      <br />  
    
     
     <Grid item={true} xs={12}>
     <Button
       style={{ borderRadius: "0px" }}
       variant="contained"
       color="primary"
       size="large"
       type="submit"
       disabled
     >
       {!pending && <span>{props.buttonText}</span>}

       {pending && <CircularProgress size={28} />}
     </Button>
   </Grid></>
    } 
                    { filte == false &&
     <>     
     <br />  
     <Grid item={true} xs={12}>
     <Button
       style={{ borderRadius: "0px" }}
       variant="contained"
       color="primary"
       size="large"
       type="submit"
       disabled
     >
       {!pending && <span>{props.buttonText}</span>}
       {pending && <CircularProgress size={28} />}
     </Button>
   </Grid></>
    } 
      </form>

    </>
  );
}

export default requireAuth(Contact);