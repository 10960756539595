import React, { useEffect, useState } from "react";
import Meta from "./../components/Meta";
import Navbar2 from "./../components/Navbar2";
import Sectionr101 from "./../components/Sectionr101";
import Section101 from "./../components/Section101";
import Section3 from "./../components/Section3";
import HeroSection from "./../components/HeroSection";
import Section621seller from "./../components/Section621seller";
import Page2S3 from "./../components/Page2S3";

import Page2S4 from "./../components/Page2S4";
import Page2S5 from "./../components/Page2S5";
import Sectionak4 from "./../components/Sectionak4";
import Sectionak5 from "./../components/Sectionak5";
import Footer2 from "./../components/Footer2";
import { requireAuth } from "./../util/auth";
import { getaProducts, getuserdocument } from '../util/db';
import Aos from 'aos';
import { useRouter } from "../util/router";
import {history} from "../util/router";
import "aos/dist/aos.css";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button, Container } from "@material-ui/core";
import { ColorLensOutlined } from "@material-ui/icons";
function Seller() {
    const route = useRouter();
    useEffect(() => {
      Aos.init({ duration: 2000 });
  }, [])
    //  console.log(props.testtext)
     const [products, setProducts] = useState([]);
     const [documents, setDocuments] = useState([]);
     const [productimages, setProductimages] = useState([]);
     const [producttext, setProducttext] = useState([]);


  
  //  console.log('sdssedeee', documents[0].documents['eoi_agreement'])
   
    return (
<>
        <Meta title="Product" />
        <Navbar2
          color="default"
          logo="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-black.png?alt=media&token=4df2ee2a-8696-48b5-8a65-98171d8179d9"
          logoInverted="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096"
        />
      <Button
      variant="text" href="/Product/dWjFy1y1XVJ3Ue8EYFBx" style={{color:"#3DAEB6", display:"flex", marginLeft:"20%", marginTop:"1%",  marginBottom:"-3%", zIndex:'999', justifyContent:"flex-start"}} startIcon={<ArrowBackIcon style={{fontSize:"30px",}}/>}> </Button> 
        <img data-aos="fade-up" data-aos-duration="1200"
          src={'https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/image2.png?alt=media&token=384d4c26-94c7-4ac6-b719-b37e2c3d1ba7'}
          style={{ height: "600px" }}
        ></img>
        <section style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection:'column', marginTop:"-30%", marginBottom:"11%"}}>
        <img data-aos="fade-up" data-aos-duration="1200"
          src={'https://firebasestorage.googleapis.com/v0/b/gayn-82759.appspot.com/o/Bhoomi%20logo.jpg?alt=media&token=0cc88abb-52ee-4aef-84ea-ecefc388ee18'}
          style={{ height: "200px", }}
        ></img>
        <h1 style={{zIndex:'999', fontSize:'25px', fontWeight:'bold', color:'white'}}>Bhoomi and Buildings</h1>
        <br/>
        <h2 style={{zIndex:'9999', color:'#3DAEB6'}}><a href="https://bhoomiandbuildings.com/" target="_blank"  style={{zIndex:'9999', color:'#3DAEB6'}}>Visit the Website</a></h2></section>
        
      

        <section id="Overview" data-aos="fade-up" data-aos-duration="1200">
        <HeroSection
          bgColor="default"
          size="normal"
          bgImage=""
          bgImageOpacity={1}
          title="Investment Overview"
          subtitle={
            <div style={{ textAlign: "left" }}>
              {" "}
              <p>
              Established in 2005, the company rose to heights by creating delightful experiences, and boasting client satisfaction, through high quality and extreme reliability. As the name itself suggests, the company deals with everything from plots of land to towering structures that reach for the sky. 
              </p>{" "}
              <p>
              The team of dedicated executives, and thoroughly qualified consultants, aim to provide best in class services in design, construction and legal services, to take you from an interested party to a registered owner, of your very own dream.
              </p>{" "}
            </div>
          }
         
        />
        </section>
        <section id="Highlights" data-aos="fade-up" data-aos-duration="1200">
      <Section621seller
       highlight_text={producttext}
       images={productimages}
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle="All the features you need to move faster"
      /></section>
        </>
    );
  
}
export default Seller