import { Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Navbr from '../../components/cpnav';
import { requireAuth, useAuth } from "../../util/auth";
import { makeStyles, styled } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LoadingScreen from '../../components/Loadingscreen';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { InputAdornment } from "@material-ui/core";
import MenuItem from '@mui/material/MenuItem';
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import { ToastContainer, toast } from 'react-toastify';
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { CreateUserAdmin, verifyKYCAadharAdmin, verifyKYCPAN, verifyKYCSPAN, verifyKYCBank, generateKYCAadharOTP,genericEmail, generateKYCSAadharOTP} from '../../util/functions';
import { uploadFileToBucket } from '../../util/firebase-storage';
import AadharOTPModal from "../modal";
import CloseIcon from '@material-ui/icons/Close';
import { getUser, updateInvestments, addOrUpdateAddress, addOrUpdateBankAccount, updateLeadstatus, getInvestments, getAddress, getBankAccount, updateUser, getuserdocument} from "../../util/db";
import { history, useRouter } from "../../util/router";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const Input1 = styled('input')({
  display: 'none',
});

const UseStyles = makeStyles((theme) => ({

  container: {
    paddingTop: "100px",

    marginRight: "0px",

  },
}));
function Investordetails(props) {
  const router = useRouter();
  const auth = useAuth();
  const userId = router?.query?.userId || auth?.user?.id;

  const [valuend, setValuend] = React.useState(null); 
  const [formAlert, setFormAlert] = useState(null);
  const [pending, setPending] = useState(false);
  const [pending1, setPending1] = useState(false);
  const [pending2, setPending2] = useState(false);
  const [pending3, setPending3] = useState(false);
  const [pending4, setPending4] = useState(false);
  const [pending5, setPending5] = useState(false);
  const [pending6, setPending6] = useState(false);
  const [pending7, setPending7] = useState(false);
  const [pending8, setPending8] = useState(false);
  const [pending9, setPending9] = useState(false);
  const [pending10, setPending10] = useState(false);
  const [pending11, setPending11] = useState(false);
  const [pending12, setPending12] = useState(false);
  const [pending13, setPending13] = useState(false);
  const [pending14, setPending14] = useState(false);
  const [pending15, setPending15] = useState(false);
  const [pending16, setPending16] = useState(false);
  const [pending17, setPending17] = useState(false);
  const [pending18, setPending18] = useState(false);
  const isSmall = useMediaQuery('(min-width:800px)');
  const [uid, setUid] = useState(userId);
  const [bankacname, setBankacname] = useState(null);
  const [bankname, setBankname] = useState(null);
  // const [uid, setUid] = useState("1HHrAnOQFnPHMprCph6gvdqlTBl1");
  const { handleSubmit, register, errors, getValues, reset } = useForm();
  const [aadharFile, setAadharFile] = useState(null);
  const [gstFile, setGstFile] = useState(null);
  const [boardresFile, setBoardresFile] = useState(null);
  const [cpanFile, setCpanFile] = useState(null);
  const [hufpanFile, setHufpanFile] = useState(null);
  const [spanFile, setSpanFile] = useState(null);
  const [saadharFile, setSAadharFile] = useState(null);
  const [panFile, setPanFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bankAccountFile, setBankAccountFile] = useState(null);
  const [aadhar, setAadhar] = useState(null);
  const [aadharVerified, setAadharVerified] = useState(false);
  const [certificateofincorp, setCertificateofincorp] = useState(null);
  const [saadharnum, setSAadharnum] = useState(null);
  const [secaadharVerified, setSECAadharVerified] = useState(false);
  const [secpanVerified, setSECPANVerified] = useState(false);
  const [saadharGeneratedOTP, setSAadharGeneratedOTP] = useState(false);
  const [saadharTransactionID, setSAadharTransactionID] = useState(null);
  const [saadharCode, setSAadharCode] = useState(null);
  const [aadharGeneratedOTP, setAadharGeneratedOTP] = useState(false);
  const [aadharTransactionID, setAadharTransactionID] = useState(null);
  const [aadharCode, setAadharCode] = useState(null);
  const [valued, setValued] = React.useState(null);
  const [valuesid, setValuesid] = React.useState(null);
  const [valuedoi, setValuedoi] = React.useState(null);
  const [span, setSPAN] = useState(null);
  const [spanfile, setSPANfile] = useState(null);

  const [saadhar, setSAadhar] = useState(null);
  const [pan, setPAN] = useState(null);
  const [panVerified, setPANVerified] = useState(false);
  const [panfVerified, setPANfVerified] = useState(false);
  const [aadharfVerified, setAadharfVerified] = useState(false);
  const [spanVerified, setSPANVerified] = useState(false);
  const [spanfVerified, setSPANfVerified] = useState(false);
  const [saadharVerified, setSAadharVerified] = useState(false);
  const [taboneVerified, setTaboneVerified] = useState(false);
  const [tabtwoVerified, setTabtwoVerified] = useState(false);
  const [tabthreeVerified, setTabthreeVerified] = useState(false);
  const [tabfourVerified, setTabfourVerified] = useState(false);
  const [coiVerified, setCoiVerified] = useState(false);
  const [cpanVerified, setCpanVerified] = useState(false);
  const [hufpanVerified, setHufpanVerified] = useState(false);
  const [gstVerified, setGstVerified] = useState(false);
  const [boardresVerified, setBoardresVerified] = useState(false);

  const [bankAccount, setBankAccount] = useState(null);
  const [bankAccountVerified, setBankAccountVerified] = useState(false);
  const [bankfVerified, setBankfVerified] = useState(false);

  const today = new Date();
  const minimumDate = new Date(today.setFullYear(today.getFullYear()-18));

  const [ifsc, setIFSC] = useState(null);
  const occupation = [
    {
      value: 'Self-Employed',
      label: 'Self-Employed',
    },
    {
      value: 'Salaried',
      label: 'Salaried',
    },
  ];
  const bactype = [
    {
      value: 'Savings Account',
      label: 'Savings Account',
    },
    {
      value: 'Current Account',
      label: 'Current Account',
    },
  ];
  const resitype = [
    {
      value: 'Indian Resident',
      label: 'Indian Resident',
    },
    {
      value: 'NRI',
      label: 'NRI',
    },
  ];
  const invstype = [
    {
      value: 'Joint Account',
      label: 'Joint Account',
    },
    {
      value: 'HUF',
      label: 'HUF',
    },
    {
      value: 'Partnership',
      label: 'Partnership',
    },
    {
      value: 'Individual',
      label: 'Individual',
    },
    {
      value: 'Private Limited',
      label: 'Private Limited',
    },

    {
      value: 'LLP',
      label: 'LLP',
    },


  ];
  const [occu, setOccu] = React.useState('');
  const [occuse, setOccuse] = React.useState('');
  const [userd, setUserd] = React.useState([]);
  const [investmentd, setInvestmentd] = React.useState([]);
  const [addressd, setAddressd] = React.useState([]);

  const [resit, setresit] = React.useState('');
  const [resuit, setresuit] = React.useState('');
  const [invst, setinvst] = React.useState('');
  const [value1, setValue1] = React.useState('1');
  const [bact, setbact] = React.useState('');
  const handlerChange = (event) => {
    setresit(event.target.value);
  };
  const handleoChange = (event) => {
    setOccu(event.target.value);
  };
  const handleosChange = (event) => {
    setOccuse(event.target.value);
  };
  const handleiChange = (event) => {

    setinvst(event.target.value);
  };
  const handlebChange = (event) => {

    setbact(event.target.value);

  };
  const handleChange = (event) => {

    setresuit(event.target.value);
  };
  const handleChanges = (event,newValue) => {
    if(userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed"){   
      setValue1(newValue);}
  
  };
  const submitHandlersByType = async ({ email, pass, first_name, last_name, phone }) => {
    const formattedPhone = `+91${phone.replace("+91", "")}`;
    const user = await CreateUserAdmin(email, pass, first_name, last_name, formattedPhone, "user");
    setFormAlert({
      type: "success",
      message: `User ${user.data.email} created successfully, verifying online KYC..`,
     
    });
    setPending(false);
    history.push('/admin/users');
  };
  useEffect(() => {
    populateUser();
    console.log('3223452daa', resit)
  }, [])

  const populateUser = async () => {
    if (userId !== null && userId !== undefined) {
      const user = await getUser(uid);
      const investment = await getInvestments(uid);
      console.log('investment_check', investment?.tab_two_verified)
      setInvestmentd(investment);
      const address = await getAddress(uid);
      setAddressd(address);
      const bank = await getBankAccount(uid);
      const userdocs = await getuserdocument(uid);
      console.log('aaa2224ef', investment?.investment_type )
      if(investment?.tab_one_verified === true && investment?.tab_two_verified === undefined && investment?.tab_three_verified  === undefined && investment?.tab_four_verified === undefined  ){
        setValue1("2");
      }else if(investment?.tab_one_verified === true && investment?.tab_two_verified === true && investment?.tab_three_verified  === undefined && investment?.tab_four_verified  === undefined ){
        setValue1("3");
      }else if(investment?.tab_one_verified === true && investment?.tab_two_verified === true && investment?.tab_three_verified === true && investment?.tab_four_verified  === undefined){
        setValue1("4");
      }
      const aadharfile = userdocs.find(obj => obj.name == 'Aadhar');
      if(aadharfile !== undefined){
        setAadharfVerified(true);
        
      }
      const panfile = userdocs.find(obj => obj.name == 'PAN');
      if(panfile !== undefined){
        setPANfVerified(true)
      }
      const coifile = userdocs.find(obj => obj.name == 'Certification Of Incorporation');
      if(coifile !== undefined){
        setCoiVerified(true)
      }
      const gstfile = userdocs.find(obj => obj.name == 'GST');
      if(gstfile !== undefined){
        setGstVerified(true)
      }
      const cpan = userdocs.find(obj => obj.name == 'Company PAN');
      if(cpan !== undefined){
        setCpanVerified(true)
      }
      const span = userdocs.find(obj => obj.name == 'PAN secondary');
      if(span !== undefined){
        setSPANVerified(true)
      }
      
      const saadhar = userdocs.find(obj => obj.name == 'Aadhar_secondary');
      if(saadhar !== undefined){
        setSAadharVerified(true)
      }
      const boardres = userdocs.find(obj => obj.name == 'Board Resolution');
      if(boardres !== undefined){
        setBoardresVerified(true)
      }
      const hufpan = userdocs.find(obj => obj.name == 'HUF PAN');
      if(hufpan !== undefined){
        setHufpanVerified(true)
      }
      const bankdoc = userdocs.find(obj => obj.name == 'Bank Document');
      if(bankdoc !== undefined){
        setBankfVerified(true)
      }
      console.log('223ddw', coiVerified)
      setAadhar(user?.aadhaar_number);
      setAadharVerified(user?.aadhaar_online_verified);
      setTaboneVerified(investment?.tab_one_verified);
      setTabtwoVerified(investment?.tab_two_verified);
      setTabthreeVerified(investment?.tab_three_verified);
      setTabfourVerified(investment?.tab_four_verified);
    
      setPAN(user.pan_number);
      setPANVerified(user.pan_online_verified);
      setBankAccount(bank?.account_number);
      setBankacname(bank?.bank?.account_name);
      setIFSC(bank?.ifsc);
      setBankname(bank?.bank_name);
      setBankAccountVerified(user?.bank_account_online_verified);
      setUserd (user)
      // from git instead investment put investment?
      setresit(investment?.residentialType);
      setresuit(investment?.secondaryInvestor?.residentialType);
      setOccu(investment?.occupation);
      setOccuse(investment?.secondaryInvestor?.occupation);
      // setValued(investment?.date_of_birth?.toDate());
      setValuesid(investment?.secondaryInvestor?.date_of_birth.toDate());

      setinvst(investment?.investment_type);
      console.log('from db', investment?.date_of_birth);
      
      setValuend(new Date(1970, 0, 1).setSeconds(investment?.nominee?.date_of_birth.seconds));

      setValued(new Date(1970, 0, 1).setSeconds(investment?.date_of_birth.seconds));
      setbact(bank?.account_type);

      let investFields = {};


      if (investment?.investment_type == "Joint Account") {
        setValuesid(new Date(1970, 0, 1).setSeconds(investment?.secondaryInvestor?.date_of_birth.seconds));
        setresit(investment?.secondaryInvestor?.residentialType)
        investFields = {
          sifirst_name: investment?.secondaryInvestor?.first_name,
          silast_name: investment?.secondaryInvestor?.last_name,
          siemail: investment?.secondaryInvestor?.email,
          siphone: investment?.secondaryInvestor?.phone,
          sifathername: investment?.secondaryInvestor?.fatherName,
          sioccupation: investment?.secondaryInvestor?.occupation,
          siPANnumber: investment?.secondaryInvestor?.pan_number,
          siaddress1: investment?.secondaryInvestor?.address1,
          siaddress2: investment?.secondaryInvestor?.address2,
          sicity: investment?.secondaryInvestor?.city,
          sipin: investment?.secondaryInvestor?.pin,
          sicountry: investment?.secondaryInvestor?.country,
          sistate: investment?.secondaryInvestor?.state,
          silandmark: investment?.secondaryInvestor?.landmark,
        };
      } else if (investment?.investment_type == "HUF") {
        investFields = {
          hufpan: investment?.hufPAN
        }
      } else if (investment?.investment_type === "LLP" || investment?.investment_type === "Partnership" || investment?.investment_type === "Private Limited") {
        setValuedoi(new Date(1970, 0, 1).setSeconds(investment?.company.date_of_incorporation.seconds));
        investFields = {
          companyname: investment?.company.company_name,
          companyPAN: investment?.company.company_pan,
        }
      }


      reset({
        first_name: investment?.first_name,
        last_name: investment?.last_name,
        email: investment?.email,
        phone: investment?.phone,
        fathername: investment?.fatherName,
        occupation: investment?.occupation,
        address1: address?.line1,
        address2: address?.line2,
        city: address?.city,
        country: address?.country,
        state: address?.state,
        pin: address?.pin,
        landmark: address?.landmark,
        bankAccount: bank?.account_number,
        bankname: bank?.bank_name,
        ifsc: bank?.ifsc,
        accountname: bank?.account_name,
        nfirst_name: investment?.nominee?.first_name,
        nlast_name: investment?.nominee?.last_name,
        nemail: investment?.nominee?.email,
        nphone: investment?.nominee?.phone,
        nrelationship: investment?.nominee?.relationship,
        ...investFields
      });
    }
  }

  const onotherSubmit = async (formData) => {
    if(panFile === null){
      toast.error("Please upload the PAN document", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

    }else if(aadharFile === null){
      toast.error("Please upload the Aadhaar document", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
    else{

    try { 
      uploadFileToBucket(uid, pancallbackFunction, panFile, `PAN`);
      uploadFileToBucket(uid,aadharcallbackFunction, aadharFile, `Aadhar`);

      const address = {
        uid: uid,
        line1: formData.address1,
        line2: formData.address2,
        city: formData.city,
        country: formData.country,
        state: formData.state,
        pin: formData.pin,
        landmark: formData.landmark
      }

      await addOrUpdateAddress(address);


      if (invst == "Joint Account") {
        const jointAccount = {
          address1: formData.siaddress1,
          address2: formData.siaddress2,
          city: formData.sicity,
          pin: formData.sipin,
          country: formData.sicountry,
          state: formData.sistate,
          landmark: formData.silandmark
        };
        await updateInvestments(uid, { investment_type: invst, secondaryInvestor: jointAccount });
      } else if (invst == "HUF") {
        await updateInvestments(uid, { investment_type: invst, hufPAN: formData.hufpan });
      } else if (invst === "LLP" || invst === "Partnership" || invst === "Private Limited") {
        const company = {
          company_name: formData.companyname,
          date_of_incorporation: valuedoi,
          company_pan: formData.companyPAN,
        }
        await updateInvestments(uid, { investment_type: invst, company: company });
        const caddress = {
          uid: uid,
          line1: formData.caddress1,
          line2: formData.caddress2,
          city: formData.ccity,
          country: formData.ccountry,
          state: formData.cstate,
          pin: formData.cpin,
          landmark: formData.clandmark,
          account_type: bact
        }
  
        await addOrUpdateAddress(caddress);
      }
      const personalDetails = {
        tab_two_verified: true,
        
      };
      await updateInvestments(uid, personalDetails);

      setFormAlert({
        type: "success",
        message: "Information Saved successfully!!", 
      });
    } catch (error) {
      setFormAlert({
        type: "error",
        message: error,
   
      });
    
    }
    setValue1('3');
    setPending(false);
    setTabtwoVerified(true);
  }
  }

  const onpersonalSubmit = async (formData) => {
    try {
      const personalDetails = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      phone: formData.phone,
      residentialType: resit,
      fatherName: formData.fathername,
      occupation: occu,
      investment_type: invst,
      date_of_birth: valued,
      tab_one_verified: true,
      
    };
    await updateInvestments(uid, personalDetails);
    if (invst == "Joint Account") {
      const jointAccount = {
        first_name: formData.sifirst_name,
        last_name: formData.silast_name,
        email: formData.siemail,
        phone: formData.siphone,
        residentialType: resuit,
        fatherName: formData.sifathername,
        occupation: occuse,
        date_of_birth: valuesid,
        pan_number: formData.siPANnumber,
      };
      await updateInvestments(uid, { investment_type: invst, secondaryInvestor: jointAccount });
    } else if (invst == "HUF") {
      await updateInvestments(uid, { investment_type: invst, hufPAN: formData.hufpan });
    } else if (invst === "LLP" || invst === "Partnership" || invst === "Private Limited") {
      const company = {
        company_name: formData.companyname,
        date_of_incorporation: valuedoi,
        // companyPAN: formData.companyPAN,
      }
      await updateInvestments(uid, { investment_type: invst, company: company });
    }
    setFormAlert({
      type: "success",
      message: "Information Saved successfully!!", 

    });
  }catch (error) {
    setFormAlert({
      type: "error",
      message: error,

    });
  } setValue1('2');
  setPending(false);
  setTaboneVerified(true);

} 

   const onnomieeSubmit = async (formData) => {
    try {
      const nominee = {
        first_name: formData.nfirst_name,
        last_name: formData.nlast_name,
        email: formData.nemail,
        phone: formData.nphone,
        relationship: formData.nrelationship,
        address: formData.naddress,
        date_of_birth: valuend
      }
     
      await updateUser(uid, {kyc_status: "submitted"});
      await updateLeadstatus(uid, "Pending");
      await updateInvestments(uid, { nominee: nominee });
      const personalDetails = {
        tab_four_verified: true,
        
      };
      await updateInvestments(uid, personalDetails);
      const data25 = {
        'email': userd.email,
        'name': userd.first_name + ' ' + userd.last_name,
        'templateid' : 'd-0722eea07a5c4784a4fe1babbdf4850d'
    }
    const data27 = {
      'email': 'support@gayncapital.com',
      'uemail': userd.email,
      'phone': userd.phone_number,
      'name': userd.first_name + ' ' + userd.last_name,
      'templateid' : 'd-152d16bde5cb4a77a89642ff557178d1'
  };
  genericEmail(data27);
  
        genericEmail(data25);
      setFormAlert({
        type: "success",
        message: "Your KYC is done successfully Please wait for the admin's approval. Once your KYC is approved by the admin you can see green tick box near the investor details instead of red one.",
      });
      history.push('/cp/clients');
    }catch (error) {
      setFormAlert({
        type: "error",
        message: error,
      });
    }
    setPending(false);
 setTabfourVerified(true);

  } 

  const onbankSubmit = async (formData) => {
     if(bankAccountFile === null){
      toast.error("Please upload the bank document", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
     }else{
    try {
      verifyBankAccount(formData);
     
    }catch (error) {
      toast.error("Bank verification failed", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      setFormAlert({
        type: "error",
        message: error,
        
      });
    }

  }
  } 
  
  

  // Handle form submission
  const onSubmit = ({ email, pass, first_name, last_name, phone }) => {
    // Show pending indicator
    setPending(true);

    // Call submit handler for auth type
    submitHandlersByType({
      email,
      pass,
      first_name,
      last_name,
      phone
    }).catch((error) => {
      setPending(false);
      setFormAlert({
        type: "error",
        message: error.message,
      });
    });
  };

  // const getFileExtension = (fileName) => String(fileName?.split('.').slice(-1)).toLowerCase();

  // aadhar upload with success notification start

  const aadharcallbackFunction = (fileName) => { 
    console.log("fileUploadCompleteCallback", fileName); 
    setPending2 (false);
    setAadharfVerified(true);
    toast.success("Aadhaar Uploaded Successfully", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
    
}
  
  const uploadAadhar = () => {
    setPending2(true);
    uploadFileToBucket(uid,aadharcallbackFunction, aadharFile, `Aadhar`);
    // setFormAlert({
    //   type: "success",
    //   message: "Aadhar document uploaded!!",
    // });
  }

  // aadhar upload with success notification end

  const saadharcallbackFunction = (fileName) => { 
    setPending3(false);
    setSAadharVerified(true);
    toast.success("Aadhaar Uploaded Successfully", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }

  const uploadsAadhar = () => {
    setPending3(true);
    uploadFileToBucket(uid, saadharcallbackFunction, saadhar, `Aadhar_secondary`);
    // setFormAlert({
    //   type: "success",
    //   message: "Aadhar document uploaded!!",
    // });
  }
  const coicallbackFunction = (fileName) => { 
    setPending4(false);
    setCoiVerified(true);
    toast.success("Certification of Incorporation Uploaded Successfully", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }
  const uploadcoi = () => {
    setPending4(true);
    uploadFileToBucket(uid, coicallbackFunction, certificateofincorp, `Certification Of Incorporation`);
    // setFormAlert({
    //   type: "success",
    //   message: "Aadhar document uploaded!!",
    // });
  }
  const cpancallbackFunction = (fileName) => { 
    setPending5(false);
    setCpanVerified(true);
    toast.success("Company PAN Uploaded Successfully", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }

  const uploadcpan = () => {
    setPending5(true);
    uploadFileToBucket(uid, cpancallbackFunction, cpanFile, `Company PAN`);
    // setFormAlert({
    //   type: "success",
    //   message: "Aadhar document uploaded!!",
    // });
  }
  const spancallbackFunction = (fileName) => { 
    setPending6(false);
    setSPANVerified(true);
    toast.success("PAN Uploaded Successfully", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }

  const uploadspan = () => {
    setPending6(true);
    uploadFileToBucket(uid, spancallbackFunction, spanFile, `PAN secondary`);
  }

  const hufpancallbackFunction = (fileName) => { 
    setPending7(false);
    setHufpanVerified(true);
    toast.success("HUF PAN Uploaded Successfully", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }


  const uploadhufpan = () => {
    setPending7(true);
    uploadFileToBucket(uid, hufpancallbackFunction, hufpanFile, `HUF PAN`);
  }

  const gstcallbackFunction = (fileName) => { 
    setPending8(false);
    setGstVerified(true);
    toast.success("GST Uploaded Successfully", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }


  const uploadgst = () => {
    setPending8(true);
    uploadFileToBucket(uid, gstcallbackFunction, gstFile, `GST`);
  }

  const pancallbackFunction = (fileName) => { 
    setPending9(false);
    setPANfVerified(true);
    toast.success("PAN Uploaded Successfully", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }


  const uploadPan = () => {
    setPending9(true);
    uploadFileToBucket(uid, pancallbackFunction, panFile, `PAN`);
  }

  const bankcallbackFunction = (fileName) => { 
    setPending10(false);
    setBankfVerified(true);
    toast.success("Bank Document Uploaded Successfully", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }


  const uploadBankAccount = () => {
    if(bankAccountFile === null){
      toast.error("Please upload the bank document", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
     }else{
    setPending10(true);
    uploadFileToBucket(uid, bankcallbackFunction, bankAccountFile, `Bank Document`);}
  }

  const boardrescallbackFunction = (fileName) => { 
    setPending11(false);
    setBoardresVerified(true);
    toast.success("Board Resolution Uploaded Successfully", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }


  const uploadboardres = () => {
    setPending11(true);
    uploadFileToBucket(uid, boardrescallbackFunction, boardresFile, `Board Resolution`);
  }



  const generateAadharOTP = async () => {
    setPending12(true);
    
    console.log("tesse", aadhar)
    if(aadhar === null || aadhar.length <= 11){      toast.error("Invalid Aadhar number", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      }); setPending12(false);}else{
        setPending12(false)
        setLoading(true)
    const info = await generateKYCAadharOTP(uid, 'user', aadhar);
    setLoading(false)
    setAadharTransactionID(info.data.data.transaction_id)
    setAadharCode(info.data.data.code);
    if(info.data.data.code === "1001"){
    
    setAadharGeneratedOTP(true);
    toast.success("OTP sent to your Mobile Successfully", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
    setFormAlert({
      type: "info",
      message: info.data.data.message,
    });
  } else {
    setLoading(false)
    toast.error(info.data.data.message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });

  }
  }
  }

  const handleAadharOTPModalClose = (info) => {
    setAadharGeneratedOTP(false);
    if (info != null && info != undefined) {
     
      setAadharVerified(info);
    }
    if (info == true) {
      setPending12(false);
      updateUser(uid, {aadhaar_number: aadhar, aadhaar_online_verified: true});
      toast.success("Aadhaar Verified Successfully", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

  }
}
  const generateSAadharOTP = async () => {
    setPending14(true);
    const info = await generateKYCSAadharOTP(uid, 'user', saadharnum);
    setSAadharTransactionID(info.data.data.transaction_id)
    setSAadharCode(info.data.data.code);
    setSAadharGeneratedOTP(true);
    setFormAlert({
      type: "info",
      message: info.data.data.message,
    });
  }

  const handleSAadharOTPModalClose = (info) => {
    setSAadharGeneratedOTP(false);
    if (info != null && info != undefined) {
      setSECAadharVerified(info.isSuccess);
    }
    if (info.isSuccess == true) {
      setPending14(false);
      toast.success("Aadhaar Verified Successfully", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
  }
}
  

  const verifyPAN = async () => {
    setPending13(true);
    if(pan === null || pan.length <= 9){ 
      toast.success("PAN Verified Successfully", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        setPending13(false);
         }else{
    const info = await verifyKYCPAN(uid, 'user', pan, '', "");
    setPANVerified(info.data.isSuccess);
   if(info.data.isSuccess == true){
     setPending13(false);
    
     toast.success("PAN Verified Successfully", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
}}
  }
  const verifysPAN = async () => {
    setPending15(true);
    const info = await verifyKYCSPAN(uid, 'user', pan, '', "");
    setSECPANVerified(info.data.isSuccess);
    

   if(info.data.isSuccess == true){setPending15(false);
    toast.success("PAN Verified Successfully", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });}
  }

  const verifyBankAccount = async (formData) => {
    try {
    const info = await verifyKYCBank(bankAccount, ifsc);
    setBankAccountVerified(info.data.isSuccess);
    setFormAlert({
      type: info.data.isSuccess ? "success" : "error",
      message: info.data.message,
    });
    if(info.data.isSuccess == true){
          uploadFileToBucket(uid, bankcallbackFunction, bankAccountFile, `Bank Document`);

      const backAccount = {
        uid: uid,
        account_number: formData.bankAccount,
        bank_name: formData.bankname,
        ifsc: formData.ifsc,
        account_name: formData.accountname,
        account_type: bact
      };
      await addOrUpdateBankAccount(backAccount);
      const personalDetails = {
        tab_three_verified: true,   
      };
      await updateInvestments(uid, personalDetails);
      setFormAlert({
        type: "success",
        message: "Information Saved successfully!!",
      
      }); 
setPending15(false);
      toast.success("Bank Verified Successfully", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        setValue1('4');
        setPending(false);} else{
          toast.error("Bank Verified failed", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }
      }catch (error) {      toast.error("Bank verification failed", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });}
        return false;
  }
  const classes = UseStyles();
  return (
   <>
 <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
<ToastContainer />

    <Navbr/>
 <> 
     <section style={{ paddingTop:"100px",

marginLeft:isSmall? "300px":"10px",
marginRight:"5%",}}>
     {formAlert && (
        <Box mb={3}>
          <Alert severity={formAlert.type}>{formAlert.message} </Alert>
        </Box>
      )}
      <h1>Welcome <a style={{color:"#3DAEB6"}}>{userd.first_name}</a></h1>
     <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext TabIndicatorProps={{style: {background:"#3DAEB6"}}} value={value1}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList variant="scrollable" TabIndicatorProps={{style: {background:"#3DAEB6"}}} onChange={handleChanges} aria-label="lab API tabs example">
            <Tab  label="Personal Information" value="1" />
            <Tab  label="Other Details" value="2" />
            <Tab  label="Bank Account Details" value="3" />
            <Tab   label="Nominee" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1">
        <><form onSubmit={handleSubmit(onpersonalSubmit)}>
              <Grid container={true} spacing={2} style={{marginLeft:"-32px"}}> 
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    type="first_name"
                    name="first_name"
                    onInput = {(e) =>{
                      e.target.value = e.target.value.replace(/\s{2,}/g, ' ').replace(/[^A-Za-z ]/g, '');
                  }}
                
                    placeholder="First Name"
                    value={investmentd?.first_name}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.first_name}
                    InputLabelProps={{ shrink: true }}  
                    required={true}
                    label="First Name"
                    error={errors.text ? true : false}
                    helperText={errors.text && errors.text.message}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter your first name",
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    type="last_name"
                    name="last_name"
                    onInput = {(e) =>{
                      e.target.value = e.target.value.replace(/\s{2,}/g, ' ').replace(/[^A-Za-z ]/g, '');
                  }}
                    value={investmentd?.last_name}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.last_name}
                    InputLabelProps={{ shrink: true }}  
                    required={true}
                    label="Last Name"
                    placeholder="Last Name"
                    error={errors.text ? true : false}
                    helperText={errors.text && errors.text.message}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter your last name",
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    type="email"
                    value={investmentd?.email}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.email}
                    InputLabelProps={{ shrink: true }}  
                    name="email"
                    label="Email"
                    required={true}
                    placeholder="user@example.com"
                    error={errors.email ? true : false}
                    helperText={errors.email && errors.email.message}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter your email",
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    type="phone"
                    label="Phone"
                    name="phone"
                    required={true}
                    value={investmentd?.phone}
                    onInput = {(e) =>{ e.target.value =  e.target.value.replace(/[^0-9]/g, '').slice(0,10); }}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.phone}
                    InputLabelProps={{ shrink: true }}
                    placeholder="99xxxxxx99"
                    error={errors.phone ? true : false}
                    helperText={errors.phone && errors.phone.message}
                    fullWidth={true}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+91</InputAdornment>
                    }}
                    inputRef={register({
                      required: "Please enter your Phone number",
                      validate: (value) => {
                        if (value.length >= 10) {
                          return true;
                        } else {
                          return "Please enter a valid phone number";
                        }
                      },
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    select
                    label="Residential Type"
                    type="residentialtype"
                    name="residentialtype"
                    required={true}
                    value={resit}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.residentialType}
                    fullWidth={true}
                    onChange={handlerChange}
                  >
                    {resitype.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    type="fathername"
                    label="Father's Name"
                    name="fathername"
                    required={true}
                    onInput = {(e) =>{
                      e.target.value =  e.target.value.replace(/[^A-Za-z ]/g, '')
                  }}
                    placeholder="Father's Name"
                    value={investmentd?.fatherName}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.fatherName}
                    InputLabelProps={{ shrink: true }}
                    error={errors.email ? true : false}
                    helperText={errors.email && errors.email.message}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter your Father's Name",
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    type="occupation"
                    value={occu}
                    required={true}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.occupation}
                    select
                    label="Occupation"
                    name="occupation"
                    placeholder="Occupation"
                    onChange={handleoChange}
                    fullWidth={true}
                    >
                      {occupation.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item={true} xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableFuture
                    maxDate={minimumDate}
                    label="Date Of Birth"
                    name="date_of_birth"
                    required={true}
                    openTo="year"
                    views={['year', 'month', 'day']}
                    value={valued}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.date_of_birth}
                    sx={{ width: '100%' }}
                    onChange={(newValue) => {
                      console.log('date_of_birth', newValue);
                      setValued(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    select
                    fullWidth={true}
                    label="Type"
                    type="investmenttype"
                    required={true}
                    name="investmenttype"
                    value={invst}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.investment_type}
                    onChange={handleiChange}
                    // required
                    // inputRef={register({
                    //   required: "Please select your Investment type",
                    // })}
                    helperText="Please select your Investment type"
                  >
                    {invstype.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item={true} xs={12} md={6}></Grid>
                { (invst === "LLP" || invst === "Partnership" || invst === "Private Limited") &&
                <>

                <Grid container={true} spacing={2} style={{marginLeft:"0px"}}>
                <Grid item={true} xs={12}> <h2>Company Details</h2></Grid>
                  <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    type="companyname"
                    label="Company Name"
                    name="companyname"
                    onInput = {(e) =>{
                      e.target.value =  e.target.value.replace(/[^A-Za-z ]/g, '')
                  }}
                    required={true}
                    value={investmentd?.company?.company_name}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.company?.company_name}
                    InputLabelProps={{ shrink: true }}
                    placeholder="Company Name"
                    error={errors.email ? true : false}
                    helperText={errors.email && errors.email.message}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter your company name",
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disableFuture
                        required={true}
                        label="Date Of Incorporation"
                        openTo="year"
                        name="date_of_incorporation"
                        views={['year', 'month', 'day']}
                        disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.company?.date_of_incorporation}
                        value={valuedoi}
                        sx={{ width: '100%' }}
                        onChange={(newValue) => {
                          setValuedoi(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    </Grid>
                </Grid>
              </>
                }
                              {invst === "HUF" &&
                <>
                  <Grid item={true} xs={12} md={6} >
                    <TextField
                      variant="outlined"
                      type="hufpan"
                      label="HUF PAN"
                      name="hufpan"
                      onInput = {(e) =>{
                        e.target.value =     e.target.value.replace(/[^A-Za-z0-9]/g, '');
                    }}
                      required={true}
                      value={investmentd?.hufPAN}
                      disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.hufPAN}
                      InputLabelProps={{ shrink: true }}  
                      placeholder="HUF PAN"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      inputRef={register({
                        required: "Please enter your HUF PAN",
                      })}
                    />
                  </Grid>
                </>
              }
                { invst == 'Joint Account' &&
                <>

                <Grid container={true} spacing={2}  style={{marginLeft:"0px"}}>
                <Grid item={true} xs={12}> <h2>Secondary Investor</h2> </Grid>
                  <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    type="first_name"
                    label="First Name"
                    onInput = {(e) =>{
                      e.target.value =  e.target.value.replace(/[^A-Za-z ]/g, '')
                  }}
                    required={true}
                    name="sifirst_name"
                    placeholder="First Name"
                    value={investmentd?.secondaryInvestor?.first_name}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.secondaryInvestor?.first_name}
                    InputLabelProps={{ shrink: true }}  
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter your first name",
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    type="last_name"
                    label="Last name"
                    onInput = {(e) =>{
                      e.target.value =  e.target.value.replace(/[^A-Za-z ]/g, '')
                  }}
                    name="silast_name"
                    required={true}
                    placeholder="Last Name"
                    value={investmentd?.secondaryInvestor?.last_name}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.secondaryInvestor?.last_name}
                    InputLabelProps={{ shrink: true }}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter your last name",
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    type="email"
                    required={true}
                    label="Email"
                    name="siemail"
                    value={investmentd?.secondaryInvestor?.email}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.secondaryInvestor?.email}
                    InputLabelProps={{ shrink: true }}
                    placeholder="user@example.com"
                    error={errors.email ? true : false}
                    helperText={errors.email && errors.email.message}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter your email",
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    required={true}
                    type="phone"
                    label="Phone"
                    name="siphone"
                    placeholder="99xxxxxx99"
                    onInput = {(e) =>{
                      e.target.value =  e.target.value.replace(/[^0-9]/g, '').slice(0,10);
                  }}
                    value={investmentd?.secondaryInvestor?.phone}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.secondaryInvestor?.phone}
                    InputLabelProps={{ shrink: true }}
                    error={errors.phone ? true : false}
                    helperText={errors.phone && errors.phone.message}
                    fullWidth={true}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+91</InputAdornment>
                    }}
                    inputRef={register({
                      required: "Please enter your Phone number",
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                <TextField
                    variant="outlined"
                    required={true} 
                    select
                    label="Residential Type"
                    type="residentialtype"
                    name="residentialtype"
                    value={resuit}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.secondaryInvestor?.residentialType}
                    fullWidth={true}
                    onChange={handleChange}
                  >
                    {resitype.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    required={true}
                    type="fathername"
                    label="Father's Name"
                    onInput = {(e) =>{
                      e.target.value =  e.target.value.replace(/[^A-Za-z ]/g, '')
                  }}
                    name="sifathername"
                    value={investmentd?.secondaryInvestor?.last_name}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.secondaryInvestor?.last_name}
                    InputLabelProps={{ shrink: true }}
                    placeholder="Father's Name"
                    error={errors.email ? true : false}
                    helperText={errors.email && errors.email.message}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter your Father's Name",
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    type="occupation"
                    required={true}
                    value={occuse}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.secondaryInvestor?.occupation}
                    select
                    label="Occupation"
                    name="occupation"
                    onChange={handleosChange}
                    fullWidth={true}
                    // inputRef={register({
                    //   required: "Please enter your Occupation",
                    // })}
                    
                    >
                      {occupation.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item={true} xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableFuture
                    required={true}
                    maxDate={minimumDate}
                    label="Date Of Birth"
                    name="date_of_birth"
                    openTo="year"
                    views={['year', 'month', 'day']}
                    value={valuesid}
                    sx={{ width: '100%' }}
                    onChange={(newValue) => {
                      console.log('date_of_birth', newValue);
                      setValuesid(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>

                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    type="PANnumber"
                    required={true}
                    label="PAN Number"
                    onInput = {(e) =>{
                      e.target.value =     e.target.value.replace(/[^A-Za-z0-9]/g, '');
                  }}
                    name="siPANnumber"
                    placeholder="PAN Number"
                    value={investmentd?.secondaryInvestor?.pan_number}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.secondaryInvestor?.pan_number}
                    InputLabelProps={{ shrink: true }}
                    error={errors.email ? true : false}
                    helperText={errors.email && errors.email.message}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter your PAN Number",
                    })}
                  />
                </Grid>
                </Grid>
                </>
                
                
                }
                <Grid item={true} xs={12}>
                  <Button
                    id="sign-in-button"
                    className="sign-in-button"
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={taboneVerified || pending || userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed"}
                  >
                    {!pending && <span>Save and continue</span>}

                    {pending && <CircularProgress size={28} />}
                  </Button>
                </Grid>
              </Grid>
            </form></></TabPanel>
        <TabPanel value="2">
               <>
                  <form onSubmit={handleSubmit(onotherSubmit)}>
                      <Grid container={true} spacing={2} style={{marginLeft:"-34px"}}>
                      <Grid item={true} xs={12} md={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Address Line 1"
                  name="address1"
                  required={true}
                  placeholder="Address Line 1"
                  value={addressd?.line1}
                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||addressd?.line1}
                  error={errors.address1 ? true : false}
                  helperText={errors.address1 && errors.address1.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your Address Line 1",
                  })}
                />
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Address Line 2"
                  name="address2"
                  placeholder="Address Line 2"
                  value={addressd?.line2}
                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||addressd?.line2}
                  error={errors.address2 ? true : false}
                  helperText={errors.address2 && errors.address2.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your Address Line 2",
                  })}
                />
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <TextField
                  variant="outlined"
                  type="city"
                  label="City"
                  name="city"
                  placeholder="City"
                  onInput = {(e) =>{
                    e.target.value =  e.target.value.replace(/[^A-Za-z]/g, '')
                }}
                  required={true}
                  error={errors.email ? true : false}
                  fullWidth={true}
                  value={addressd?.city}
                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||addressd?.city}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your City",
                  })}
                />
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <TextField
                  variant="outlined"
                  type="pin"
                  label="PIN"
                  name="pin"
                  placeholder="PIN"
                  onInput = {(e) =>{
                    e.target.value =  e.target.value.replace(/[^0-9]/g, '').slice(0,6);
                }}
                  required={true}
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  value={addressd?.pin}
                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||addressd?.pin}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your PIN Code",
                  })}
                />
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <TextField
                  variant="outlined"
                  type="country"
                  label="Country"
                  name="country"
                  placeholder="Country"
                  required={true}
                  onInput = {(e) =>{
                    e.target.value =  e.target.value.replace(/[^A-Za-z]/g, '')
                }}
                  value={addressd?.country}
                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||addressd?.country}
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your Country",
                  })}
                />
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <TextField
                  variant="outlined"
                  type="state"
                  label="State"
                  name="state"
                  onInput = {(e) =>{
                    e.target.value =  e.target.value.replace(/[^A-Za-z]/g, '')
                }}
                  placeholder="State"
                  required={true}
                  value={addressd?.state}
                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||addressd?.state}
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your State",
                  })}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <TextField
                  variant="outlined"
                  type="landmark"
                  label="Landmark"
                  name="landmark"
                  placeholder="Landmark"
                  required={true}
                  error={errors.email ? true : false}
                  helperText={errors.email && errors.email.message}
                  fullWidth={true}
                  value={addressd?.landmark}
                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||addressd?.landmark}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: "Please enter your Landmark",
                  })}
                />
              </Grid>
                       </Grid>
                       <br /><br />
                       <Grid container={true} spacing={8} style={{marginLeft: isSmall?"-30px": "-60px"}}>
                      
                       <Grid item={true} xs={12} style={{marginLeft:isSmall?"-24px": "0px", marginBottom:"-32px"}}> <h2>Personal Documents</h2></Grid>
                      
                       
                       <Grid item={true} xs={3} md={1}><h3>Aadhaar</h3></Grid>
                                <Grid item={true} xs={9} md={5}>
                                 
                                      <TextField
                                       variant="outlined"
                                       type="tel"
                                       label="Aadhar Number"
                                       name="aadhar"
                                       onInput = {(e) =>{
                                        e.target.value =  e.target.value.replace(/[^0-9]/g, '').slice(0,12);
                                    }}
                                       inputProps={{maxLength: 12,  minLength: 12}}
                                       required={true}
                                       value={aadhar}
                                       fullWidth={true}
                                       onChange={(e) => setAadhar(e.target.value)}
                                       onBlur={(e) => setAadhar(e.target.value)}
                                       disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||aadharVerified}
                                       inputRef={register({
                                         required: "Please enter your Aadhar number",
                                       })}
                                        />
                                  </Grid>
                                  {!isSmall && <>
                                    <Grid container={true} spacing={3} >
                                    <Grid item xs={6}>
                                <Button
                                  variant="outlined"
                                  component="label"
                                  color="primary"
                                  required={true}
                                  size={isSmall?"large": "small"}
                                  style={{overflow:'hidden', marginLeft:'18%'}}
                                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||aadharfVerified}
                                >
                                    <input
                                      type="file"
                                      style={{display:"none"}}
                                      onChange={(e) => { setAadharFile(e.target.files[0]) }} />
                                      <text style={{overflow:'hidden', width: '130px', height:'25px', display:'flex', alignItems:'center', justifyContent:'center' }}>{aadharFile?  aadharFile.name : "Choose File"}</text>
                                  </Button>

                                  </Grid>
                                    <Grid item={true} xs={6} >
                                      <Button
                                       style={{marginLeft:'18%'}}
                                        variant="contained"
                                        color="primary"
                                        size={isSmall?"large": "medium"}
                                        type="button"
                                        fullWidth={true}
                                        onClick={generateAadharOTP}
                                        disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||aadharVerified || pending12}>
                                        {/* {pending12 && <CircularProgress size={28} />} */}
                                        {aadharVerified ? 'Aadhar Verified' : 'Verify'}
                                      </Button>
                                        {aadharGeneratedOTP &&
                                          <AadharOTPModal
                                            uid={uid}
                                            aadhar_number={aadhar}
                                            transactionId={aadharTransactionID}
                                            handleModalClose={handleAadharOTPModalClose}
                                            code={aadharCode}
                                            userType={"user"} />}

                                     </Grid>

                                 </Grid></>}
                                  <Grid item={true} xs={3} md={1}><h3>PAN</h3></Grid>
                                   <Grid item={true} xs={9} md={5}>
                                      <TextField
                                       variant="outlined"
                                       type="text"
                                       required={true}
                                       label="Pan Number"
                                       name="pan"
                                       onInput = {(e) =>{
                                        e.target.value =     e.target.value.replace(/[^A-Za-z0-9]/g, '');
                                    }}
                                       value={pan}
                                       fullWidth={true}
                                       disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||panVerified}
                                       onChange={(e) => setPAN(e.target.value)}
                                       onBlur={(e) => setPAN(e.target.value)}
                                       inputRef={register({
                                         required: "Please enter your Pan number",
                                       })}
                                        />
                                  </Grid>
                                 {!isSmall && <>                   
                                  <Grid container={true} spacing={3} >   
                                  <Grid item xs={6}>
                                <Button
                                  variant="outlined"
                                  component="label"
                                  color="primary"
                                  size={isSmall?"large": "small"}
                                  style={{overflow:'hidden', marginLeft:'18%'}}
                                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||panfVerified || pending10}

                                >
                                    <input
                                      type="file"
                                      // hidden
                                     
                                      style={{display:"none"}}
                                      onChange={(e) => { setPanFile(e.target.files[0]) }} />
                                      <text style={{overflow:'hidden', width: '130px', height:'25px', display:'flex', alignItems:'center', justifyContent:'center' }}>{panFile?  panFile.name : "Choose File"}</text>
                                  </Button>
                                       </Grid>            
                                    <Grid item={true} xs={6} >
                                      <Button
                                       style={{marginLeft:'18%'}}
                                          variant="contained"
                                          color="primary"
                                          size={isSmall?"large": "medium"}
                                          type="button"
                                          required={true}
                                          fullWidth={true}
                                          onClick={verifyPAN}
                                          disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||panVerified || pending13}
                                        >
                                          {pending13 && <CircularProgress size={28} />}
                                          {panVerified ? 'Pan Verified' : 'Verify Pan'}
                                        </Button>

                                      </Grid>
                                     
                                       </Grid></>}
                                  <Grid container={true} spacing={3} >
                                  {isSmall && <>
                                  <Grid item={true} xs={1}></Grid>
                                  <Grid item xs={2}>
                                <Button
                                  variant="outlined"
                                  component="label"
                                  color="primary"
                                  required={true}
                                  size="large"
                                  style={{overflow:'hidden', marginLeft:'18%'}}
                                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||aadharfVerified}
                                >
                                    <input
                                      type="file"
                                      style={{display:"none"}}
                                      onChange={(e) => { setAadharFile(e.target.files[0]) }} />
                                      <text style={{overflow:'hidden', width: '130px', height:'25px', display:'flex', alignItems:'center', justifyContent:'center' }}>{aadharFile?  aadharFile.name : "Choose File"}</text>
                                  </Button>

                                  </Grid>
                                    <Grid item={true} xs={2} >
                                      <Button
                                       style={{marginLeft:'18%'}}
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        type="button"
                                        fullWidth={true}
                                        onClick={generateAadharOTP}
                                        disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||aadharVerified || pending12}>
                                        {/* {pending12 && <CircularProgress size={28} />} */}
                                        {aadharVerified ? 'Aadhar Verified' : 'Verify'}
                                      </Button>
                                        {aadharGeneratedOTP &&
                                          <AadharOTPModal
                                            uid={uid}
                                            aadhar_number={aadhar}
                                            transactionId={aadharTransactionID}
                                            handleModalClose={handleAadharOTPModalClose}
                                            code={aadharCode}
                                            userType={"user"} />}

                                     </Grid>
                                  
                                  <Grid item={true} xs={1}></Grid>

                                      <Grid item={true} xs={1}></Grid>
                                      <Grid item xs={2}>
                                <Button
                                  variant="outlined"
                                  component="label"
                                  color="primary"
                                  size="large"
                                  style={{overflow:'hidden', marginLeft:'10%'}}
                                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||panfVerified || pending10}

                                >
                                    <input
                                      type="file"
                                      // hidden
                                     
                                      style={{display:"none"}}
                                      onChange={(e) => { setPanFile(e.target.files[0]) }} />
                                      <text style={{overflow:'hidden', width: '130px', height:'25px', display:'flex', alignItems:'center', justifyContent:'center' }}>{panFile?  panFile.name : "Choose File"}</text>
                                  </Button>
                                       </Grid>
                                    <Grid item={true} xs={2} >
                                      <Button
                                       style={{marginLeft:'10%'}}
                                          variant="contained"
                                          color="primary"
                                          size="large"
                                          type="button"
                                          required={true}
                                          fullWidth={true}
                                          onClick={verifyPAN}
                                          disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||panVerified || pending13}
                                        >
                                          {pending13 && <CircularProgress size={28} />}
                                          {panVerified ? 'Pan Verified' : 'Verify Pan'}
                                        </Button>

                                      </Grid>
                                    
                                       </>}


                                               


                                      {invst == 'Joint Account' &&
                <>
                                      <br />
                                      <br />
                                      <Grid item={true} xs={12} style={{marginLeft:"0px"}}>     <h2>Secondary Investor</h2></Grid>
                       
                  <Grid item={true} xs={12} md={6} >
                    <TextField
                      variant="outlined"
                      type="siaddress1"
                      label="Address Line 1"
                      name="siaddress1"
                      placeholder="Address Line 1"
                      required={true}
                      value={investmentd?.secondaryInvestor?.address1}
                      disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.secondaryInvestor?.address1}
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your Address Line 1",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      type="siaddress2"
                      label="Address Line 2"
                      name="siaddress2"
                      placeholder="Address Line 2"
                      value={investmentd?.secondaryInvestor?.address2}
                      disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.secondaryInvestor?.address2}
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your Address Line 2",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      type="sicity"
                      label="City"
                      name="sicity"
                      onInput = {(e) =>{
                        e.target.value =  e.target.value.replace(/[^A-Za-z]/g, '')
                    }}
                      required={true}
                      value={investmentd?.secondaryInvestor?.city}
                      disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.secondaryInvestor?.city}
                      placeholder="City"
                      error={errors.email ? true : false}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your City",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      type="sipin"
                      label="PIN"
                      onInput = {(e) =>{
                        e.target.value =  e.target.value.replace(/[^0-9]/g, '').slice(0,6);
                    }}
                      required={true}
                      value={investmentd?.secondaryInvestor?.pin}
                      disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.secondaryInvestor?.pin}
                      name="sipin"
                      placeholder="PIN"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your PIN Code",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      type="sicountry"
                      label="Country"
                      name="sicountry"
                      onInput = {(e) =>{
                        e.target.value =  e.target.value.replace(/[^A-Za-z]/g, '')
                    }}
                      required={true}
                      value={investmentd?.secondaryInvestor?.country}
                      disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.secondaryInvestor?.country}
                     placeholder="Last Name"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your last name",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      type="sistate"
                      label="State"
                      name="sistate"
                      onInput = {(e) =>{
                        e.target.value =  e.target.value.replace(/[^A-Za-z]/g, '')
                    }}
                      required={true}
                      value={investmentd?.secondaryInvestor?.state}
                      disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.secondaryInvestor?.state}
                      placeholder="State"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your State",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      type="silandmark"
                      label="Landmark"
                      value={investmentd?.secondaryInvestor?.landmark}
                      disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.secondaryInvestor?.landmark}
                      name="silandmark"
                      placeholder="Landmark"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your Landmark",
                      })}
                    />
                  </Grid><Grid item={true} xs={12} md={6}></Grid>
                  <h2>Personal Documents</h2>
                       <br />
                       <Grid container={true} spacing={8}>
                       <Grid item={true} xs={1}><h3>Aadhaar</h3></Grid>
                                <Grid item={true} xs={5}>
                                 
                                      <TextField
                                       variant="outlined"
                                       type="text"
                                       label="Aadhar Number"
                                       onInput = {(e) =>{
                                        e.target.value =  e.target.value.replace(/[^0-9]/g, '').slice(0,12);
                                    }}
                                       name="aadhar"
                                       inputProps={{maxLength: 12,  minLength: 12}}
                                       required={true}
                                       value={saadharnum}
                                       fullWidth={true}
                                       onChange={(e) => setSAadharnum(e.target.value)}
                                       onBlur={(e) => setSAadharnum(e.target.value)}
                                       disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||secaadharVerified}
                                       inputRef={register({
                                         required: "Please enter your Aadhar number",
                                       })}
                                        />
                                  </Grid>
                                  <Grid item={true} xs={1}><h3>PAN</h3></Grid>
                                   <Grid item={true} xs={5}>
                                      <TextField
                                       variant="outlined"
                                       type="text"
                                       label="Pan Number"
                                       onInput = {(e) =>{
                                        e.target.value =     e.target.value.replace(/[^A-Za-z0-9]/g, '');
                                    }}
                  
                                       name="pan"
                                       value={span}
                                       fullWidth={true}
                                       disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||spanfVerified}
                                       onChange={(e) => setSPAN(e.target.value)}
                                       onBlur={(e) => setSPAN(e.target.value)}
                                       inputRef={register({
                                         required: "Please enter your Pan number",
                                       })}
                                        />
                                  </Grid>
                                  <Grid container={true} spacing={3} >
                                  <Grid item={true} xs={1}></Grid>
                                    <Grid item={true} xs={2} >
                                      <Button
                                       style={{marginLeft:'18%'}}
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        type="button"
                                        fullWidth={true}
                                        onClick={generateSAadharOTP}
                                        disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||secaadharVerified || pending14}>
                                        {pending14 && <CircularProgress size={28} />}
                                        {secaadharVerified ? 'Aadhar Verified' : 'Verify'}
                                      </Button>
                                        {saadharGeneratedOTP &&
                                          <AadharOTPModal
                                            uid={uid}
                                            aadhar_number={saadharnum}
                                            transactionId={saadharTransactionID}
                                            handleModalClose={handleSAadharOTPModalClose}
                                            code={saadharCode}
                                            userType={"user"} />}

                                     </Grid>
                                     <Grid item xs={2}>
                                <Button
                                  variant="outlined"
                                  component="label"
                                  color="primary"
                              
                                  size="large"
                                  style={{overflow:'hidden', marginLeft:'18%'}}
                                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||saadharVerified}
                                >
                                    <input
                                      type="file"
                                      
                                      style={{display:"none"}}
                                      onChange={(e) => { setSAadharFile(e.target.files[0]) }} />
                                      <text style={{overflow:'hidden', width: '130px', height:'25px', display:'flex', alignItems:'center', justifyContent:'center' }}>{saadharFile?  saadharFile.name : "Choose File"}</text>
                                  </Button>

                                  </Grid>
                                  <Grid item={true} xs={1}></Grid>

                                      <Grid item={true} xs={1}></Grid>
                                    <Grid item={true} xs={2} >
                                      <Button
                                       style={{marginLeft:'10%'}}
                                          variant="contained"
                                          color="primary"
                                          size="large"
                                          type="button"
                                          fullWidth={true}
                                          onClick={verifysPAN}
                                          disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||spanfVerified || pending15}
                                        >
                                          {pending15 && <CircularProgress size={28} />}
                                          {spanfVerified ? 'Pan Verified' : 'Verify Pan'}
                                        </Button>

                                      </Grid>
                                      <Grid item xs={2}>
                                <Button
                                  variant="outlined"
                                  component="label"
                                  color="primary"
                                  size="large"
                                  style={{overflow:'hidden', marginLeft:'10%'}}
                                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||spanfVerified || pending10}

                                >
                                    <input
                                      type="file"
                                      // hidden
                                      
                                      style={{display:"none"}}
                                      onChange={(e) => { setSPANfile(e.target.files[0]) }} />
                                      <text style={{overflow:'hidden', width: '130px', height:'25px', display:'flex', alignItems:'center', justifyContent:'center' }}>{spanfile?  spanfile.name : "Choose File"}</text>
                                  </Button>
                                       </Grid>
                                                    </Grid>
                                                    </Grid>
                                            </>
                                                        }  


{(invst === "LLP" || invst === "Partnership" || invst === "Private Limited") &&
                <>
                  <Grid item={true} xs={12}><h2>Company details</h2></Grid>
                  <Grid item={true} xs={12} md={6}>
                  <TextField
                      variant="outlined"
                      type="companyPAN"
                      label="Company PAN"
                      onInput = {(e) =>{
                        e.target.value =     e.target.value.replace(/[^A-Za-z0-9]/g, '');
                    }}
  
                      name="companyPAN"
                      placeholder="Company PAN"
                      required={true}
                      value={investmentd?.company?.company_pan}
                      disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.company?.company_pan}  
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your Company PAN",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={12}><h2>Company Address</h2></Grid>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      type="caddress1"
                      label="Address Line 1"
                      name="caddress1"
                      placeholder="Address Line 1"
                      required={true}
                      value={addressd?.line1}
                      disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||addressd?.line1}  
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your Address Line 1",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      type="caddress2"
                      label="Address Line 2"
                      name="caddress2"
                      placeholder="Address Line 2"
                      value={addressd?.line2}
                      disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||addressd?.line2}  
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your Address Line 2",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      type="ccity"
                      label="City"
                      name="ccity"
                      placeholder="City"
                      onInput = {(e) =>{
                        e.target.value =  e.target.value.replace(/[^A-Za-z]/g, '')
                    }}
                      required={true}
                      value={addressd?.city}
                      disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||addressd?.city}  
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your City",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      type="cpin"
                      label="PIN"
                      name="cpin"
                      onInput = {(e) =>{
                        e.target.value =  e.target.value.replace(/[^0-9]/g, '').slice(0,6);
                    }}
                      placeholder="PIN"
                      required={true}
                      value={addressd?.pin}
                      disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||addressd?.pin}  
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your PIN Code",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      type="ccountry"
                      label="Country"
                      name="ccountry"
                      onInput = {(e) =>{
                        e.target.value =  e.target.value.replace(/[^A-Za-z]/g, '')
                    }}
                      placeholder="Country"
                      required={true}
                      value={addressd?.country}
                      disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||addressd?.country}  
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your last name",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      type="cstate"
                      onInput = {(e) =>{
                        e.target.value =  e.target.value.replace(/[^A-Za-z]/g, '')
                    }}
                      required={true}
                      value={addressd?.state}
                      disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||addressd?.state} 
                      label="State"
                      name="cstate"
                      placeholder="State"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your State",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      type="clandmark"
                      required={true}
                      value={addressd?.landmark}
                      disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||addressd?.landmark} 
                      label="Landmark"
                      name="clandmark"
                      placeholder="Landmark"
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email.message}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register({
                        required: "Please enter your Landmark",
                      })}
                    />
                  </Grid>
                  <Grid item={true} xs={12}><h2>Company Documents</h2></Grid>
                  <Grid container={true} spacing={3} >
                                      <Grid item xs={12} md={6} >
                                      <Grid container={true} spacing={3} >
                                      <Grid item xs={12}>
                                <p style={{ marginBottom:'0px'
                                }}> Certificate of Incorporation  </p></Grid>
                                
                                <Grid item xs={12} md={6}>
                                <Button
                                  variant="outlined"
                                  component="label"
                                  color="primary"
                                  style={{overflow:'hidden'}}
                                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||coiVerified}
                                >
                                    <input
                                      type="file"
                                      // hidden
                                      style={{display:"none"}}
                                      onChange={(e) => { setCertificateofincorp(e.target.files[0]) }} />
                                      <text style={{overflow:'hidden', width: '130px', height:'25px', display:'flex', alignItems:'center', justifyContent:'center' }}>{certificateofincorp?  certificateofincorp.name : "Choose File"}</text>
                                  </Button>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                      <Button variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        type="button"
                                                        onClick={uploadcoi}
                                                        disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||coiVerified || pending4}
                                                        >{pending4 && <CircularProgress size={28} />}
                                                        {coiVerified ? 'Document Uploaded' : 'Upload Document'}
                                                        </Button>
                                                    </Grid>
                                                    </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} >
                                      <Grid container={true} spacing={3} >
                                      <Grid item xs={12}>
                                <p style={{ marginBottom:'0px'
                                }}>GST Certificate</p></Grid>
                                
                                <Grid item xs={12} md={6}>
                                <Button
                                  variant="outlined"
                                  component="label"
                                  color="primary"
                                  style={{overflow:'hidden'}}
                                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||gstVerified}
                                >
                                    <input
                                      type="file"
                                      // hidden
                                      style={{display:"none"}}
                                      onChange={(e) => { setGstFile(e.target.files[0]) }} />
                                      <text style={{overflow:'hidden', width: '130px', height:'25px', display:'flex', alignItems:'center', justifyContent:'center' }}>{gstFile?  gstFile.name : "Choose File"}</text>
                                  </Button>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                      <Button variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        type="button"
                                                        onClick={uploadgst}
                                                        disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||gstVerified || pending4}
                                                        >{pending8 && <CircularProgress size={28} />}
                                                        {gstVerified ? 'Document Uploaded' : 'Upload Document'}
                                                        </Button>
                                                    </Grid>
                                                    </Grid>
                                                    </Grid>
                                                    </Grid>
                                                    <Grid container={true} spacing={3} >
                                      <Grid item xs={12} md={6} >
                                      <Grid container={true} spacing={3} >
                                      <Grid item xs={12}>
                                <p style={{ marginBottom:'0px'
                                }}>Company PAN </p></Grid>
                                
                                <Grid item xs={12} md={6}>
                                <Button
                                  variant="outlined"
                                  component="label"
                                  color="primary"
                                  style={{overflow:'hidden'}}
                                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||cpanVerified}
                                >
                                    <input
                                      type="file"
                                      // hidden
                                      style={{display:"none"}}
                                      onChange={(e) => { setCpanFile(e.target.files[0]) }} />
                                      <text style={{overflow:'hidden', width: '130px', height:'25px', display:'flex', alignItems:'center', justifyContent:'center' }}>{cpanFile?  cpanFile.name : "Choose File"}</text>
                                  </Button>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                      <Button variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        type="button"
                                                        onClick={uploadcpan}
                                                        disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||cpanVerified || pending5}
                                                        >{pending5 && <CircularProgress size={28} />}
                                                        {cpanVerified ? 'Document Uploaded' : 'Upload Document'}
                                                        </Button>
                                                    </Grid>
                                                    </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} >
                                      <Grid container={true} spacing={3} >
                                      <Grid item xs={12}>
                                <p style={{ marginBottom:'0px'
                                }}>Board Resolution</p></Grid>
                                
                                <Grid item xs={12} md={6}>
                                <Button
                                  variant="outlined"
                                  component="label"
                                  color="primary"
                                  style={{overflow:'hidden'}}
                                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||cpanVerified}
                                >
                                    <input
                                      type="file"
                                      // hidden
                                      style={{display:"none"}}
                                      onChange={(e) => { setBoardresFile(e.target.files[0]) }} />
                                      <text style={{overflow:'hidden', width: '130px', height:'25px', display:'flex', alignItems:'center', justifyContent:'center' }}>{boardresFile?  boardresFile.name : "Choose File"}</text>
                                  </Button>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                      <Button variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        type="button"
                                                        onClick={uploadboardres}
                                                        disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||boardresVerified || pending11}
                                                        >{pending11 && <CircularProgress size={28} />}
                                                        {boardresVerified ? 'Document Uploaded' : 'Upload Document'}
                                                        </Button>
                                                    </Grid>
                                                    </Grid>
                                                    </Grid>
                                                    </Grid>

                 </>}
                 {invst === "HUF" && <>
                 
                 <Grid container={true} spacing={3} style={{marginLeft:"22px"}}>
                                      <Grid item xs={12}>
                                <p style={{ marginBottom:'0px'
                                }}>HUF PAN </p></Grid>
                                
                                <Grid item xs={12} md={6}>
                                <Button
                                  variant="outlined"
                                  component="label"
                                  color="primary"

                                  style={{overflow:'hidden'}}
                                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||hufpanVerified}
                                >
                                    <input
                                      type="file"
                                     
                                      // hidden
                                      style={{display:"none"}}
                                      onChange={(e) => { setHufpanFile(e.target.files[0]) }} />
                                      <text style={{overflow:'hidden', width: '130px', height:'25px', display:'flex', alignItems:'center', justifyContent:'center' }}>{hufpanFile?  hufpanFile.name : "Choose File"}</text>
                                  </Button>
                                                    </Grid>
                                                    </Grid>

                 </>}

                  </Grid>
       
                  <Grid item={true} xs={12} style={{marginLeft:isSmall?"-26px": "0px",  marginTop:"50px"}}>
                  <Button
                    id="save-button"
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||tabtwoVerified || pending16}
               
                  >
                    {!pending16 && <span>Save and continue</span>}
                    {pending16 && <CircularProgress size={28} />}
                  </Button>
                </Grid>
                       </Grid>
                  </form>
                </>
          </TabPanel>
        <TabPanel value="3"> <form onSubmit={handleSubmit(onbankSubmit)}>         
         <Grid container={true} spacing={2} style={{marginLeft:"-32px"}}>
            <Grid item={true} xs={12}><h2>Bank Details</h2></Grid>

            <Grid item={true} xs={12} md={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  onInput = {(e) =>{
                    e.target.value =     e.target.value.replace(/[^A-Za-z0-9]/g, '');
                }}
                  placeholder="Bank Account Number"
                  label="Bank Account Number"
                  name="bankAccount"
                  required={true}
                  InputLabelProps={{ shrink: true }}
                  value={bankAccount}
                  fullWidth={true}
                  onChange={(e) => setBankAccount(e.target.value)}
                  onBlur={(e) => setBankAccount(e.target.value)}
                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||bankAccountVerified}
                  inputRef={register({
                    required: "Please enter your Bank Account number",
                  })}
                />
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  placeholder="Account Name"
                  label="Account Name"
                  onInput = {(e) =>{
                    e.target.value =  e.target.value.replace(/[^A-Za-z ]/g, '')
                }}
                  InputLabelProps={{ shrink: true }}
                  required={true}
                  name="accountname"
                  value={bankacname}
                  fullWidth={true}
                  onChange={(e) => setBankacname(e.target.value)}
                  onBlur={(e) => setBankacname(e.target.value)}
                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||bankAccountVerified}
                  inputRef={register({
                    required: "Please enter your Bank Account Name",
                  })}
                />
              </Grid>



              <Grid item={true} xs={12} md={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  placeholder="IFSC"
                  onInput = {(e) =>{
                    e.target.value =     e.target.value.replace(/[^A-Za-z0-9]/g, '');
                }}
                  required={true}
                  InputLabelProps={{ shrink: true }}
                  label="IFSC"
                  name="ifsc"
                  value={ifsc}
                  fullWidth={true}
                  onChange={(e) => setIFSC(e.target.value)}
                  onBlur={(e) => setIFSC(e.target.value)}
                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||bankAccountVerified}
                  inputRef={register({
                    required: "Please enter your IFSC",
                  })}
                />
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  placeholder="Bank Name"
                  onInput = {(e) =>{
                    e.target.value =  e.target.value.replace(/[^A-Za-z ]/g, '')
                }}
                  label="Bank Name"
                  InputLabelProps={{ shrink: true }}
                  name="bankname"
                  value={bankname}
                  fullWidth={true}
                  onChange={(e) => setBankname(e.target.value)}
                  onBlur={(e) => setBankname(e.target.value)}
                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||bankAccountVerified}
                  inputRef={register({
                    required: "Please enter your Bank Account number",
                  })}
                />
              </Grid>

              <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    select
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||bankAccountVerified}
                    required={true}
                    label="Account Type"
                    type="accounttype"
                    name="accounttype"
                    value={bact}
                    fullWidth={true}
                    onChange={handlebChange}
                  >
                    {bactype.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              <Grid container={true} spacing={3} >
                                      <Grid item xs={12}>
                                <p style={{ marginBottom:'0px'
                                }}>Upload Cheque Leaf </p></Grid>
                                
                                <Grid item xs={6} md={3}>
                                <Button
                                  variant="outlined"
                                  component="label"
                                  size={isSmall?"large": "small"}
                                  fullWidth={true}
                                  color="primary"
                                  style={{overflow:'hidden'}}
                                  disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||bankfVerified}
                                >
                                    <input
                                      type="file"
                                      style={{display:"none"}}
                                      onChange={(e) => { setBankAccountFile(e.target.files[0]) }} />
                                      <text style={{overflow:'hidden', width: '130px', height:'25px', display:'flex', alignItems:'center', justifyContent:'center' }}>{bankAccountFile?  bankAccountFile.name : "Choose File"}</text>
                                  </Button>
                                                    </Grid>
                                                    {/* <Grid item xs={6} md={3}>
                                                      <Button variant="contained"
                                                        color="primary"
                                                        size={isSmall?"large": "medium"}
                                                        type="button"
                                                        fullWidth={true}
                                                        onClick={uploadBankAccount}
                                                        disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||bankfVerified || pending2}
                                                        >{pending2 && <CircularProgress size={28} />}
                                                        {bankfVerified ? 'Document Uploaded' : 'Upload Cheque Leaf'}
                                                        </Button>
                                                    </Grid> */}
                                                    </Grid>


              <Grid item xs={3} />
              <Grid item xs={12} md={6} />
              <br/>
              <br/>
              <br/>
              <Grid item xs={9} md={4}>
                <Grid item={true} xs={12}>
                  <Button
                    id="save-button"
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||tabthreeVerified || pending}
                    fullWidth={true}
                  >
                    {!pending17 && <span>Verify and continue</span>}
                    {pending17 && <CircularProgress size={28} />}
                  </Button>
                </Grid>
              </Grid>
          </Grid></form> 
</TabPanel>
        <TabPanel value="4">  <form onSubmit={handleSubmit(onnomieeSubmit)}> 
        <Grid container={true} spacing={2} style={{marginLeft:"-32px"}}>
                       <Grid item={true} xs={12}><h2>Nominee</h2></Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    type="nfirst_name"
                    label="First Name"
                    name="nfirst_name"
                    onInput = {(e) =>{
                      e.target.value =  e.target.value.replace(/[^A-Za-z ]/g, '')
                  }}
                    required={true}
                    value={investmentd?.nominee?.first_name}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.nominee?.first_name}
                    InputLabelProps={{ shrink: true }}  
                    placeholder="First Name"
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter your first name",
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    type="nlast_name"
                    label="Last name"
                    onInput = {(e) =>{
                      e.target.value =  e.target.value.replace(/[^A-Za-z ]/g, '')
                  }}
                    required={true}
                    value={investmentd?.nominee?.last_name}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.nominee?.last_name}
                    InputLabelProps={{ shrink: true }}  
                    name="nlast_name"
                    placeholder="Last Name"
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter your last name",
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    type="nemail"
                    label="Email"
                    required={true}
                    value={investmentd?.nominee?.email}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" || investmentd?.nominee?.email }
                    InputLabelProps={{ shrink: true }}  
                    name="nemail"
                    placeholder="user@example.com"
                    error={errors.email ? true : false}
                    helperText={errors.email && errors.email.message}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter your email",
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    type="nphone"
                    required={true}
                    onInput = {(e) =>{
                      e.target.value =  e.target.value.replace(/[^0-9]/g, '').slice(0,10);
                  }}
                    value={investmentd?.nominee?.phone}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" || investmentd?.nominee?.phone }
                    InputLabelProps={{ shrink: true }}  
                    label="Phone"
                    name="nphone"
                    placeholder="99xxxxxx99"
                    error={errors.phone ? true : false}
                    helperText={errors.phone && errors.phone.message}
                    fullWidth={true}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+91</InputAdornment>
                    }}
                    inputRef={register({
                      required: "Please enter your Phone number",
                    })}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    required={true}
                    value={investmentd?.nominee?.relationship}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.nominee?.relationship}
                    InputLabelProps={{ shrink: true }}  
                    onInput = {(e) =>{
                      e.target.value =  e.target.value.replace(/[^A-Za-z]/g, '')
                  }}    
                    type="nrelationship"
                    label="Relationship"
                    name="nrelationship"
                    placeholder="Relationship"
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter your Relationship",
                    })}
                  />
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                  
                  
                      disableFuture
                      label="Date Of Birth"
                      name="ndate_of_birth"
                      openTo="year"
                      views={['year', 'month', 'day']}
                      value={valuend}
                      disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.nominee?.date_of_birth}
                      sx={{width: '100%'}}
                      onChange={(newValue) => {
                        setValuend(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    </LocalizationProvider>
                <Grid item={true} xs={12}>
                  <TextField
                    variant="outlined"
                    type="naddress"
                    label="Address"
                    required={true}
                    value={investmentd?.nominee?.address}
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||investmentd?.nominee?.address}
                    InputLabelProps={{ shrink: true }}  
                    name="naddress"
                    placeholder="Address"
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter nominee address",
                    })}
                  />
                </Grid>
                <Grid item xs={9} md={3}>
                <Grid item={true} xs={12}>
                  <Button
                    id="save-button"
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={ userd?.kyc_status === "submitted" || userd?.kyc_status === "Completed" ||tabfourVerified || pending18}
                    fullWidth={true}
                  >
                    {!pending18 && <span>Save and finish</span>}
                    {pending18 && <CircularProgress size={28} />}
                  </Button>
                </Grid>
              </Grid>
                </Grid>              
                </form>
                </TabPanel>
      </TabContext>
    </Box>
 </section> </>

    </>
  )
}
export default requireAuth(Investordetails, ["cp"]);