import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    maxWidth: 700,
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: "inline-block",
  },
}));

function SectionHeader23(props) {
  const classes = useStyles();

  const {
    subtitle,
    subtitle1,
    text1,
    text2,
    title,
    ps,
    psqft,
    pc,
    tirr,
    ey,
    ifr,
    aay,
    mi,
    size,
    size1,
    className,
    ...otherProps
  } = props;

  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <Box
      component="header"
      className={classes.root + (props.className ? ` ${props.className}` : "")}
      {...otherProps}
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          alignContent: "space-between",
          color: "#8E8E8E",
        }}
      >
        {text1 && (
          <Typography
            variant={size1}
            gutterBottom={props.subtitle ? true : false}
          >
            Leased Area
            {ps && (
              <Typography
                variant={`h${size}`}
                color="primary"
                gutterBottom={props.subtitle ? true : false}
                style={{ marginBottom: "30px" }}
              >
                {ps.toLocaleString("en-IN")} Sq.ft
              </Typography>
            )}
          </Typography>
        )}

        {text1 && (
          <Typography
            variant={size1}
            gutterBottom={props.subtitle ? true : false}
          >
            *Entry Yield
            {tirr && (
              <Typography
                variant={`h${size}`}
                color="primary"
                gutterBottom={props.subtitle ? true : false}
              >
                {ey} %
              </Typography>
            )}
          </Typography>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          alignContent: "space-between",
          color: "#8E8E8E",
        }}
      >
        {text1 && (
          <Typography
            variant={size1}
            gutterBottom={props.subtitle ? true : false}
          >
            **Investment Multiplier
            {ps && (
              <Typography
                variant={`h${size}`}
                color="primary"
                gutterBottom={props.subtitle ? true : false}
              >
              1.7x
              </Typography>
            )}
          </Typography>
        )}

        {text1 && (
          <Typography
            variant={size1}
            gutterBottom={props.subtitle ? true : false}
          >
            Income Frequency
            {tirr && (
              <Typography
                variant={`h${size}`}
                color="primary"
                gutterBottom={props.subtitle ? true : false}
              >
                {ifr}
              </Typography>
            )}
          </Typography>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          alignContent: "space-between",
          color: "#8E8E8E",
        }}
      >
        {text1 && (
          <Typography
            variant={size1}
            gutterBottom={props.subtitle ? true : false}
          >
            Property Cost
            {ps && (
              <Typography
                variant={`h${size}`}
                color="primary"
                gutterBottom={props.subtitle ? true : false}
              >
               ₹ {pc.toLocaleString("en-IN")}
              </Typography>
            )}
          </Typography>
        )}

        {text1 && (
          <Typography
            variant={size1}
            gutterBottom={props.subtitle ? true : false}
          >
            Min Investment
            {tirr && (
              <Typography
                variant={`h${size}`}
                color="primary"
                gutterBottom={props.subtitle ? true : false}
              >
               ₹ {mi.toLocaleString("en-IN")}
              </Typography>
            )}
          </Typography>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          alignContent: "space-between",
          color: "#8E8E8E",
        }}
      >
        {text1 && (
          <Typography
            variant={size1}
            gutterBottom={props.subtitle ? true : false}
          >
            **Target IRR
            {psqft && (
              <Typography
                variant={`h${size}`}
                color="primary"
                gutterBottom={props.subtitle ? true : false}
              >
                {tirr} %
              </Typography>
            )}
          </Typography>
        )}

        {text1 && (
          <Typography
            variant={size1}
            gutterBottom={props.subtitle ? true : false}
          >
            Avg Rental Yield
            {ey && (
              <Typography
                variant={`h${size}`}
                color="primary"
                gutterBottom={props.subtitle ? true : false}
              >
                {aay} %
              </Typography>
            )}
          </Typography>
        )}
      </div>
    </Box>
  );
}

export default SectionHeader23;
