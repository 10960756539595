import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ChatIcon from "@material-ui/icons/Chat";
import VideogameAssetIcon from "@material-ui/icons/VideogameAsset";
import { makeStyles } from "@material-ui/core/styles";
import Section145 from "./Section145";
import SectionHeader from "./SectionHeader";
import SectionHeader23 from "./SectionHeader23";
import ProductPage from "../pages/product";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
  },
  imageWrapper: {
    margin: "0 auto",
    maxWidth: 570,
    width: "100%",
    "& > img": {
      width: "100%",
    },
  },
  progressbar: {
    background: '#000000',
  
    '& .MuiLinearProgress-bar': {
      backgroundColor: 'primary',
    },
  },
  row: {
    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(2)}px`,
    },
  },
}));

function Section101(props) {
  const classes = useStyles();
  console.log('aw2', props.minimuminv)
  const items = [
    {
      title: "Chat",
      description:
        "Imagine a world where you can chat with your friends without having to see their dumb faces.",
      icon: ChatIcon,
      iconColor: "primary.main",
    },
    {
      title: "Play",
      description:
        "We embedded a bunch free flash games inside our app. What we lack in quality we make up in quantity.",
      icon: VideogameAssetIcon,
      iconColor: "secondary.main",
    },
  ];

  return (
    <Section145
    sx={{display:{sm: "none", xs: "none", lg: "flex" }}}
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      style={{
        backgroundColor: "#262632",
      }}
    >
      <Container className={classes.container}>
        <Grid container={true} alignItems="center" spacing={8}>
          <Grid
            item={true}
            xs={12}
            md={12}
            style={{
              marginTop: "-15%",
              backgroundColor: "#262632",
              color: "#ffffff",
            }}
          >
            <SectionHeader
              title={props.title}
              type={props.type}
              subtitle={props.subtitle}
              subtitle1={props.subtitle1}
              size={4}
            />
            <SectionHeader23
              title={props.title}
              text1={props.text1}
              ps={props.ps}
              psqft={props.psqft}
              pc={props.pc}
              tirr={props.tirr}
              aay={props.aay}
              ey={props.ey}
              ifr={props.ifr}
              mi={props.mi}
              size={6}
              size1={1}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <section
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  alignContent: "space-between",
                }}
              >
                <h2>{props.tp}% funded</h2>
          <LinearProgress variant="determinate" style={{ width: "450px" }}   className={classes.progressbar}
                      value={props.tp} />
                {/* <h2>0% Funded</h2>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/bar.png?alt=media&token=76d614da-24dd-4c40-a190-5d441556d359"
                  style={{ width: "400px", height: "12px" }}
                /> */}
              </section>
              
              <Button
                variant="contained"
                color="primary"
                size="large"
                href={"/enquiry/"+props.path}
                minimuminv={props.minimuminv}
                style={{
                  width: "40%",
                  height: "50px",
                  marginTop: "3%",
                  borderRadius: "0px",
                }}
              >
                Invest Now
              </Button>
            </div>
          </Grid>
        </Grid>
        <br />
        <p style={{color: 'gray'}}>*Gross Entry Yield includes 1% Rental Top-up</p>
        <p style={{color: 'gray'}}>**IRR & Target Multiplier are calculated with an asset value appreciation of 5% CAGR</p>
      </Container>
    </Section145>
  );
}

export default Section101;
