import { doc } from "firebase/firestore";
import { createEOI } from "./db";
import { auth } from "./auth";
const endpoint = `https://v1.nocodeapi.com/srinath56/google_sheets/MsAKvbyLbkULBNnu?tabId=Sheet1`;

function submit(data) {
  fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify([[data.name, data.email, data.phone, data.investmentamount, data.mobileyes, data.emailyes, data.investmenta]]),
  }).then((r) => r.json());



  // eoi update in firestore
  return createEOI({
    name: data.name,
    email: data.email,
    phone: data.phone,
    investment_amount: data.investmentamount,
    mobile_yes: "yes",
    email_yes: "yes",
    investmenta: data.investmenta ?? "",
    //Changes need to be done
    productid: data.productId,
    type: "A",
    status: 'Requested',
    uid: data.email,
    user_id: auth.currentUser.uid,
  });

}

const contact = { submit };

export default contact;
