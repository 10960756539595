import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import LinkMui from "@material-ui/core/Link";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import { makeStyles } from "@material-ui/core/styles";
import Sectionbks1 from "./Sectionbks1";
import { Link } from "./../util/router";
import { useDarkMode } from "./../util/theme";
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles((theme) => ({
  sticky: {
    marginTop: "auto",
  },
  wrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  item: {
    display: "flex",
    flex: "none",
    justifyContent: "center",
    width: "100%",
    marginBottom: 24,
    [theme.breakpoints.up("sm")]: {
      flex: "50%",
    },
  },
  brand: {
    display: "block",
    height: 32,
  },
  social: {
    alignItems: "flex-end",
  },
  link: {
    color: "inherit",
    lineHeight: 1,
    "&:not(:last-of-type)": {
      marginRight: "1.2rem",
    },
  },
  left: {
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  right: {
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
    },
  },
  // Move links to end (bottom right)
  // Swaps position with social
  smallLinks: {
    [theme.breakpoints.up("sm")]: {
      order: 1,
    },
  },
  legal: {
    opacity: 0.6,
    fontSize: "0.875rem",
    "& a": {
      color: "inherit",
      marginLeft: "0.8rem",
    },
  },
}));

function Footer2(props) {
  const classes = useStyles();

  const darkMode = useDarkMode();
  // Use inverted logo if specified
  // and we are in dark mode
  const logo =
    props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  return (
    <Sectionbks1
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className={props.sticky && classes.sticky}
    >
      <Container>
        <div className={classes.wrapper}>
          <div className={`${classes.item} ${classes.left}`}>
            <Link to="/">
              <img src={logo} alt="Logo" className={classes.brand} />
            </Link>
          </div>
          <div
            className={`${classes.item} ${classes.right} ${classes.smallLinks}`}
          >
            <Typography>
              <LinkMui href='/#section2' className={classes.link}>
                About CRE
              </LinkMui>
              <LinkMui href='/#section1' className={classes.link}>
                About Us
              </LinkMui>
              <LinkMui href='/#section3'  className={classes.link}>
                Our Team
              </LinkMui>
              <LinkMui href='/faq'  className={classes.link}>
                FAQs
              </LinkMui>
              <LinkMui href='/privacy'  className={classes.link}>
                Privacy Policy
              </LinkMui>
            </Typography>
          </div>
          <div className={`${classes.item} ${classes.right} ${classes.social}`}>
            <a
              href=" https://twitter.com/GaynCapital"
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              <TwitterIcon fontSize="small" />
            </a>
            <a
              href="https://www.facebook.com/gayncapital/"
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              <FacebookIcon fontSize="small" />
            </a>
            <a
              href="https://instagram.com/gayncapital?igshid=YmMyMTA2M2Y="
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              <InstagramIcon fontSize="small" />
            </a>
            <a
              href="https://www.linkedin.com/company/gayncapital/"
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
            <LinkedInIcon fontSize="small" /></a>
          </div>
          <span className={`${classes.item} ${classes.legal} ${classes.left} ${classes.leftalign}`}>
            {props.copyright} <br/>5-2, Maharaja Surya Rao Rd, Dutch Village, Vannia Teynampet, Venus Colony, Alwarpet, Chennai, Tamil Nadu 600018 </span>
            <div
            className={`${classes.item} ${classes.left} ${classes.smallLinks}`}
          >
            <Typography>
              <LinkMui  className={classes.link}>
              info@gayncapital.com
              </LinkMui>

            </Typography>
          </div>
        </div>
      </Container>
    </Sectionbks1>
  );
}

export default Footer2;
