import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Aos from 'aos';
import "aos/dist/aos.css";
import useMediaQuery from '@material-ui/core/useMediaQuery';
const useStyles = makeStyles((theme) => ({
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    maxWidth: 700,
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: "inline-block",
  },
}));

function Page1S32(props) {
  const isSmall = useMediaQuery('(min-width:800px)');
  const classes = useStyles();
  React.useEffect(() => {
    Aos.init({ duration: 2000 });
}, [])
  const { subtitle, subtitle1, title, size, className, ...otherProps } = props;

  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <Box
      component="header"
      className={classes.root + (props.className ? ` ${props.className}` : "")}
      {...otherProps}
      textAlign="left"
      color="#ffffff"
    >
      {title && (
        <>
          <Typography
            // variant={`h${size}`}
            // gutterBottom={props.subtitle ? true : false}
            style={{ fontWeight: "500", marginBottom: "15px", fontSize: "20px"}}
          >
            {title}
          </Typography>

          {subtitle1 && (
            <Typography variant="subtitle1" className={classes.subtitle}>
              {subtitle1}
            </Typography>
          )}
        </>
      )}
     <section data-aos="fade-up" data-aos-duration="1200" style={{fontSize: isSmall?"15px": '11px' }}>
      <div data-aos="fade-up" data-aos-duration="3200"
        style={{
          backgroundColor: "#616161",
          textAlign: "center",
          height: "90px",
          marginBottom: "20px",
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex"
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/02%201.png?alt=media&token=a9027c92-5341-4587-86ef-6bd4c30269f7"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
        <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "left" , paddingRight: "25px" }}>
          Unprecedented leasing growth thanks to the pent-up demand finally
          being fulfilled. 
        </a>
      </div>
      <div data-aos="fade-up" data-aos-duration="3200"
        style={{
          backgroundColor: "#262632",
          textAlign: "center",
          height: "90px",
          marginBottom: "20px",
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/02%201.png?alt=media&token=a9027c92-5341-4587-86ef-6bd4c30269f7"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
        <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "left" , paddingRight: "25px" }}>
          The CRE market in India is estimated to grow by a CAGR of 13-16% in
          the next five years.
        </a>
      </div>
      <div data-aos="fade-up" data-aos-duration="3200"
        style={{
          backgroundColor: "#22CFDB",
          textAlign: "center",
          height: "40px",
          height: "90px",
          marginBottom: "20px",
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/02%201.png?alt=media&token=a9027c92-5341-4587-86ef-6bd4c30269f7"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
        <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "left" , paddingRight: "25px"}}>
          Close to 30 percent of NRIs are interested in fractional ownership of
          CRE in India.
        </a>
      </div>
      <div data-aos="fade-up" data-aos-duration="3200"
        style={{
          backgroundColor: "#3DAEB6",
          textAlign: "center",
          height: "40px",
          height: "90px",
          marginBottom: "20px",
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/02%201.png?alt=media&token=a9027c92-5341-4587-86ef-6bd4c30269f7"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
        <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "left" , paddingRight: "25px"}}>
          Tipped to become a $5 billion industry in the next three years.
        </a>
      </div>
      </section> 
    </Box>
  );
}

export default Page1S32;
