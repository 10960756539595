import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    maxWidth: 700,
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: "inline-block",
  },
}));

function Page1Newss32(props) {
  const classes = useStyles();

  const { subtitle, subtitle1, title, size, className, ...otherProps } = props;

  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <>
      {title && (
        <>
          <Typography
            variant={`h${size}`}
            gutterBottom={props.subtitle ? true : false}
            style={{ fontWeight: "bold", marginBottom: "15px" }}
          >
            {title}
          </Typography>

          {subtitle1 && (
            <Typography variant="subtitle1" className={classes.subtitle}>
              {subtitle1}
            </Typography>
          )}
        </>
      )}

      <Box
        component="header"
        className={
          classes.root + (props.className ? ` ${props.className}` : "")
        }
        {...otherProps}
        textAlign="left"
        color="#ffffff"
      >
        <p style={{ fontSize: "15px" }}>
          Explore the GAYN Capital FinTech platform for your CRE Evaluations,
          Investments, and Exits.
          <br />
          <br />
          <br />
          Start your investment journey with <a style={{color:'#3DAEB6'}}>JUST ONE</a> CLICK.
          <br />
          <br />
          <br />
          At GAYN Capital, we are aggressively data-driven, and rely heavily on
          machine learning, predictive analytics, and data science to deliver all
          the relevant information necessary for you to make smart CRE
          investments.{" "}
        </p>
        <br />
        {/* <div style={{ textAlign: "left", fontSize: "15px" }}>
          <ul>
            <li>Our processing is 100% digital. </li>
            <li>e-KYC</li>
            <li>Online Transfers & Pay-outs </li>
          </ul>
        </div> */}
      </Box>
      <Box
        component="header"
        className={
          classes.root + (props.className ? ` ${props.className}` : "")
        }
        {...otherProps}
        textAlign="left"
        color="#ffffff"
        fontSize="15px"
      >
        <hr
          style={{
            width: "100%",
            backgroundColor: "#3DAEB6",
            height: "2px",
            borderWidth: "0px",
          }}
        />
        Digital Signatures
        <hr
          style={{
            width: "100%",
            backgroundColor: "#3DAEB6",
            height: "2px",
            borderWidth: "0px",
          }}
        />
        e-KYC
        <hr
          style={{
            width: "100%",
            backgroundColor: "#3DAEB6",
            height: "2px",
            borderWidth: "0px",
          }}
        />
        Online Transfers & Pay-outs
        <hr
          style={{
            width: "100%",
            backgroundColor: "#3DAEB6",
            height: "2px",
            borderWidth: "0px",
          }}
        />
      </Box>
    </>
  );
}

export default Page1Newss32;
