import { Container } from '@material-ui/core';
import  React, { useEffect, useState } from 'react';
import Navbr from '../../components/usernav';
import { requireAuth, useAuth } from "../../util/auth";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { Link } from "../../util/router";
import { FileText } from 'lucide-react';
import Button from "@material-ui/core/Button";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import faker from 'faker';
import { getUser, getAllemailEOI} from "../../util/db";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: 'Chart.js Bar Chart - Stacked',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      backgroundColor: 'rgb(255, 99, 132)',
    },
    {
      label: 'Dataset 2',
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      backgroundColor: 'rgb(75, 192, 192)',
    },
    {
      label: 'Dataset 3',
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      backgroundColor: 'rgb(53, 162, 235)',
    },
  ],
};

const UseStyles = makeStyles((theme) => ({

  container: {
    paddingTop:"100px",
    // paddingLeft:"280px",
    // paddingRight:"0px",
    marginLeft:"20%",
    marginRight:"0px",
    width:"100%"
  },
}));
function Dashboard(props) {
  const [value, setValue] = React.useState('1');
  // const router = useRouter();
  const isSmall = useMediaQuery('(min-width:800px)');
  const auth = useAuth();
  const userId = auth?.user?.id; 
  const [uid, setUid] = useState(userId);
  const [userd, setUserd] = React.useState([]);
  const [eoi, setEoi] = React.useState([]);
  const [fields, setFields] = React.useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const populateUser = async () => {
    if (userId !== null && userId !== undefined) {
      const user = await getUser(uid);
      const dataeoi = await getAllemailEOI(user.email);
      const approvedeoi = dataeoi.filter((eoi) => eoi.status !== "Requested");
      const totalinvestment = approvedeoi.reduce((acc, row) => Number(acc) + Number(row.investmenta), 0);

      setEoi(approvedeoi);
      setFields(totalinvestment);

      setUserd (user)
    }
  }
  useEffect(() => {
    populateUser();
    
  }, [])
  const classes = UseStyles();
  return (
   <>
    <Navbr/>
     {/* <Container className={classes.container}> */}
     <section style={{ paddingTop:"100px",

    marginLeft: isSmall? "300px":"10px",
    marginRight:"5%",}}>
       <br />
       <br />
      
       <h1>Welcome <a style={{color:"#3DAEB6"}}>{userd.first_name} {userd.last_name}</a></h1>
       <br />
       <br />
     <Grid container={true} alignItems="center" spacing={2} style={{fontVariant:'all-petite-caps'}}>
          <Grid container={true} item={true} direction="column"  style={{overflow:"hidden"}} xs={6} md={3}>
          <section style={ {backgroundColor:"#262632", paddingLeft:"8%",paddingTop:"8%", textAlign:"center", display:'flex', flexDirection:'column',alignContent:"left", justifyContent:"center", alignItems:"flex-start"}}> 
     <img style={{height:isSmall? "75px": "50px", maxHeight:"75px", marginBottom:'10px'}} src='https://firebasestorage.googleapis.com/v0/b/gayn-82759.appspot.com/o/apartment%201.png?alt=media&token=a64900de-5960-40b0-871e-4d3310fc5ef3'/>
     <p style={{color:"#E5E5E5", marginRight:"2%", fontSize:isSmall? '15px': '12px',fontWeight:'400'}}> Total Properites</p>
      <p style={{color:"white", marginRight:"2%",fontSize:isSmall? '25px': '20px', fontWeight:'400', marginTop:"0px"}}>{eoi.length} <br /></p>
        </section>
            </Grid>
            <Grid container={true} item={true} direction="column"  style={{overflow:"hidden"}} xs={6} md={3}>
          <section style={ { border:'1px solid rgba(0,0,0,0.2)', backgroundColor:"#E5E5E5", paddingLeft:"8%", paddingTop:"8%", textAlign:"center", display:'flex', flexDirection:'column',alignContent:"left", justifyContent:"center", alignItems:"flex-start"}}> 
     <img style={{height:isSmall? "75px": "50px",maxHeight:"75px",  marginBottom:'10px'}} src='https://firebasestorage.googleapis.com/v0/b/gayn-82759.appspot.com/o/investment%201.png?alt=media&token=ebec75d6-6fd9-441c-bea8-b2cb17bb1421'/>
     <p style={{color:"#8E8E8E", marginRight:"2%", fontSize:isSmall? '15px': '12px', fontWeight:'400'}}> Total Investments</p>
      <p style={{color:"black", marginRight:"2%", fontSize:isSmall? '25px': '20px', fontWeight:'400', marginTop:"0px"}}>{fields} <br /></p>
        </section>
            </Grid>
            <Grid container={true} item={true} direction="column"  style={{overflow:"hidden"}} xs={6} md={3}>
          <section style={ {backgroundColor:"#3DAEB6", paddingLeft:"8%",paddingTop:"8%", textAlign:"center", display:'flex', flexDirection:'column',alignContent:"left", justifyContent:"center", alignItems:"flex-start"}}> 
     <img style={{height:isSmall? "75px": "50px", maxHeight:"75px", marginBottom:'10px'}} src='https://firebasestorage.googleapis.com/v0/b/gayn-test-ee28f.appspot.com/o/returnsw.png?alt=media&token=3e7d326f-f4fb-4369-8678-9f72c1aa51ff'/>
     <p style={{color:"#E5E5E5", marginRight:"2%", fontSize:isSmall? '15px': '12px', fontWeight:'400'}}> Total Returns</p>
      <p style={{color:"white", marginRight:"2%", fontSize:isSmall? '25px': '20px',  fontWeight:'400', marginTop:"0px"}}>{userd.tr}</p>
        </section>
            </Grid>
            <Grid container={true} item={true} direction="column"  style={{overflow:"hidden"}} xs={6} md={3}>
          <section style={ { border:'1px solid #A9A9A9',backgroundColor:"#E5E5E5",paddingLeft:"8%" , paddingTop:"8%", textAlign:"center", display:'flex', flexDirection:'column',alignContent:"left", justifyContent:"center", alignItems:"flex-start"}}> 
     <img style={{height:isSmall? "75px": "50px", maxHeight:"75px", marginBottom:'10px'}} src='https://firebasestorage.googleapis.com/v0/b/gayn-82759.appspot.com/o/Factral.png?alt=media&token=93fd331b-8cdf-4734-8a74-abbd4378ed21'/>
     <p style={{color:"#8E8E8E", marginRight:"2%",fontSize:isSmall? '15px': '12px', fontWeight:'400', }}>Factral Ownership</p>
      <p style={{color:"black", marginRight:"2%", fontSize:isSmall? '25px': '20px',  fontWeight:'400', marginTop:"0px"}}>{userd.fo}</p>
        </section>
            </Grid>
            {/* <Grid container={true} item={true} direction="column"  xs={12} md={12}><h2 style={{color:"#3DAEB6"}}>Total Returns</h2></Grid> */}
            {/* <Bar options={options} data={data} /> */}
            <Grid container={true} item={true} direction="column"  style={{overflow:"hidden", height:"30px"}} xs={12} md={12}></Grid>
            {/* <Grid container={true} item={true} direction="column"  style={{overflow:"hidden", height:"200px"}} xs={4} md={4}>
              
          <section style={ {backgroundColor:"#3DAEB6", paddingLeft:"1%",paddingTop:"1%", textAlign:"center", display:'flex', flexDirection:'column',alignContent:"left", justifyContent:"center", alignItems:"flex-start"}}> 
     <p style={{color:"#E5E5E5", marginRight:"2%", fontSize:'15px', fontWeight:'400'}}> Next payment date</p>
      <p style={{color:"white", marginRight:"2%", fontSize:'25px', fontWeight:'700', marginTop:"0px"}}>{userd.npd}</p>
        </section>

        <section style={ {backgroundColor:"#3DAEB6", paddingLeft:"1%",paddingTop:"1%", textAlign:"center", display:'flex', flexDirection:'column',alignContent:"left", justifyContent:"center", alignItems:"flex-start"}}> 
     <p style={{color:"#E5E5E5", marginRight:"2%", fontSize:'15px', fontWeight:'400'}}> Next payment</p>
      <p style={{color:"white", marginRight:"2%", fontSize:'25px', fontWeight:'700', marginTop:"0px"}}>{userd.np}</p>
        </section>
            </Grid> */}
            </Grid>


            </section>
      {/* </Container> */}
    </>
  )
}
// export default requireAuth(Dashboard, "user"); from git
export default requireAuth(Dashboard);