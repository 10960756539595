import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import VideogameAssetIcon from "@material-ui/icons/VideogameAsset";
import PetsIcon from "@material-ui/icons/Pets";
import { makeStyles } from "@material-ui/core/styles";
import Page2S51 from "./Page2S51";
import Page2S52 from "./Page2S52";
import useMediaQuery from '@material-ui/core/useMediaQuery';
const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(-8)}px`,
  },
  imageWrapper: {
    margin: "0 auto",
    maxWidth: 570,
    width: "100%",
    "& > img": {
      width: "100%",
    },
  },
  row: {
    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(2)}px`,
    },
  },
}));

function Page2S5(props) {
  const classes = useStyles();
  const isSmall = useMediaQuery('(min-width:800px)');
  const items = [
    {
      title: "Play",
      description:
        "We embedded a bunch free flash games inside our app. What we lack in quality we make up in quantity.",
      icon: VideogameAssetIcon,
      iconColor: "secondary.main",
    },
    {
      title: "Cats",
      description:
        "Our market research told us people like cats so we put some cat icons on our website.",
      icon: PetsIcon,
      iconColor: "#00d1b2",
    },
  ];

  return (
    <Page2S51
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <h1
          style={{
            color: "#3DAEB6",
            paddingTop: "30px",
            marginBottom: "40px",
            fontSize: "20px",
          }}
        >
          Tenants
        </h1>
        <Grid container={true} alignItems="center" spacing={isSmall? 8: 0}>
          <Grid container={true} item={true} direction="column" xs={12} md={5}>
            <figure className={classes.imageWrapper}>
              <img src={props.image} alt="" />
            </figure>
          </Grid>
          <Grid item={true} xs={12} md={7}>
            <Page2S52
              title={props.title}
              subtitle={props.subtitle}
              subtitle1={props.subtitle1}
              size={5}
            />
          </Grid>
        </Grid>
      </Container>
      <Container className={classes.container}>
        
        <h1
          style={{
            color: "#3DAEB6",
            paddingTop: "30px",
            marginBottom: "40px",
            fontSize: "20px",
          }}
        >
          Lease Highlights
        </h1>
        <Grid container={true} alignItems="center" spacing={isSmall? 8: 0}>
          <Grid container={true} item={true} direction="column" xs={10} md={10}>
            {
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "space-between",
                  flexDirection: "column",
                  marginTop: "-1%",
                  textAlign: "left",
                  color: "#ffffff",
                }}
              >
               { isSmall? <> <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    flexDirection: "row",
                    fontSize: "13px",
                    textAlign: "left",
                  }}
                >
                  <h1 style={{ marginBottom: "-15px", marginRight: "15%", fontSize: isSmall?"25px": "15px" }}>
                  37,000{" "}
                    <p style={{ marginTop: "-5px", fontSize: isSmall? "15px": "12px" }}>Total SqFt</p>
                  </h1>
                  <h1 style={{ marginBottom: "-15px", marginRight: "15%", fontSize: isSmall?"25px": "15px"  }}>
                  9 Years{" "}
                    <p style={{ marginTop: "-5px", fontSize: isSmall? "15px": "12px" }}>
                      {" "}
                      Lease Period
                    </p>
                  </h1>
                  <h1 style={{ marginBottom: "-15px", marginRight: "15%", fontSize: isSmall?"25px": "15px" }}>
                    3 Years{" "}
                    <p style={{ marginTop: "-5px", fontSize: isSmall? "15px": "12px" }}>
                      {" "}
                      Tenant lock-in Period
                    </p>
                  </h1>
                  <h1 style={{ marginBottom: "-15px", fontSize: isSmall?"25px": "15px" }}>
                    5%{" "}
                    <p style={{ marginTop: "-5px", fontSize: isSmall? "15px": "12px" }}>
                      {" "}
                      *Escalation Rate/Year 
                    </p>
                  </h1>
                </div> </>: <><div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    flexDirection: "row",
                    fontSize: "13px",
                    textAlign: "left",
                  }}
                >
                  <h1 style={{ marginBottom: "-15px", marginRight: "15%", fontSize: isSmall?"25px": "15px" }}>
                  37,000{" "}
                    <p style={{ marginTop: "-5px", fontSize: isSmall? "15px": "12px" }}>Total SqFt</p>
                  </h1>
                  <h1 style={{ marginBottom: "-15px", marginRight: "15%",  fontSize: isSmall?"25px": "15px"  }}>
                  9 Years{" "}
                    <p style={{ marginTop: "-5px", fontSize: isSmall? "15px": "12px" }}>
                      {" "}
                      Lease Period
                    </p>
                  </h1>

                  

                </div> 
                <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  flexDirection: "row",
                  fontSize: "13px",
                  textAlign: "left",
                }}
              >

                <h1 style={{ marginBottom: "-15px", marginRight:"15%", fontSize: isSmall?"25px": "15px" }}>
                  3 Years{" "}
                  <p style={{ marginTop: "-5px", fontSize: isSmall? "15px": "12px" }}>
                    {" "}
                    Tenant lock-in Period
                  </p>
                </h1>
                <h1 style={{ marginBottom: "-15px",fontSize: isSmall?"25px": "15px" }}>
                  5%{" "}
                  <p style={{ marginTop: "-5px", fontSize: isSmall? "15px": "12px" }}>
                    {" "}
                    *Escalation Rate/Year 
                  </p>
                </h1>
              </div> </>}

              </div>
            }
          </Grid>
          <Grid item={true} xs={12} md={2}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              href="/enquiry/dWjFy1y1XVJ3Ue8EYFBx"
              style={{
                width: "100%",
                marginTop: isSmall? "-39px": "20px",
                borderRadius: "0px",
              }}
            >
              Invest Now
            </Button>
          </Grid>
        </Grid>
        <p style={{color: 'gray'}}>*Applicable from second year</p>

      </Container>
    </Page2S51>
  );
}

export default Page2S5;
