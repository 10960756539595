import React from "react";
import Meta from "./../components/Meta";
import AuthSection from "./../components/AuthSection";
import { useRouter } from "./../util/router";
import Navbar3 from "./../components/Navbar3";
import FeaturesSection from "./../components/FeaturesSection";

function AuthPage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="Auth" />
      <Navbar3
        color="default"
        logo="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-black.png?alt=media&token=4df2ee2a-8696-48b5-8a65-98171d8179d9"
        logoInverted="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096"
      />
      <FeaturesSection
        
        bgColor="default"
        size="medium"
        bgImage="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/image17.png?alt=media&token=9f01a9c5-6acb-40be-9166-8ad94a13d6bb"
        bgImageOpacity={1}
        image="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/image17.png?alt=media&token=9f01a9c5-6acb-40be-9166-8ad94a13d6bb"
      />
    </>
  );
}

export default AuthPage;
