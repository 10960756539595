import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import VideogameAssetIcon from "@material-ui/icons/VideogameAsset";
import PetsIcon from "@material-ui/icons/Pets";
import { makeStyles } from "@material-ui/core/styles";
import Page1News1S31 from "./Page1News1S31";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
  },
  imageWrapper: {
    margin: "0 auto",
    maxWidth: 570,
    width: "100%",
    "& > img": {
      width: "100%",
    },
  },
  row: {
    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(2)}px`,
    },
  },
}));

function Page1S2New(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Play",
      description:
        "We embedded a bunch free flash games inside our app. What we lack in quality we make up in quantity.",
      icon: VideogameAssetIcon,
      iconColor: "secondary.main",
    },
    {
      title: "Cats",
      description:
        "Our market research told us people like cats so we put some cat icons on our website.",
      icon: PetsIcon,
      iconColor: "#00d1b2",
    },
  ];

  return (
    <Page1News1S31
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <p style={{ color: "#ffffff", fontSize: "15px",  }}>
          <a style={{ color: "#3DAEB6", fontSize: "18px", fontWeight: "600" }}>
            Do something fulfilling, meaningful, and enjoyable with your life.
          </a>
          <br /> <br />
          Do you have the financial freedom, today, to go back to school, become
          an entrepreneur, travel the world, or take a holiday for life?
          <br />
          <br />
          If that’s not the case yet, learning how to generate PASSIVE INCOME
          from your earned income is just the thing for you.
          <br />
          <br />
          Passive income is income that requires little to no effort to achieve
          or keep. It doesn’t need to be actively managed, and it is, therefore,
          much more scalable.
          <br />
          <br />
          <br />
          <a style={{ color: "#3DAEB6", fontSize: "18px", fontWeight: "600" }}>
            Let’s Put Your Savings to Work.{" "}
          </a>
          <br />
          <br />
          We, at GAYN Capital, put together our intellectual and technological
          resources to find proven and profitable CRE investments for you, to
          help you make money while you sleep.
          <br />
          <br />
          The due diligence is done  <a style={{letterSpacing:'5px', fontSize:"0px"}}>    . </a> <a style={{letterSpacing:'5px',fontSize:"18px", fontWeight:"bolder"}}>    | </a>  The paperwork is done <a style={{letterSpacing:'5px', fontSize:"0px"}}>    . </a> <a style={{letterSpacing:'5px',fontSize:"18px", fontWeight:"bolder"}}>    | </a>   All you need to
          do is INVEST and EARN.
        </p>
        <br />
        <p
          style={{
            color: "#ffffff",
            textAlign: "center",
            fontSize: "20px",
            letterSpacing: "5px",
          }}
        >
          YOU G<a style={{ color: "#3DAEB6" }}>A</a>YN from US
        </p>
        <br />
        <Grid container={true} alignItems="center" spacing={0}>
          <Grid item={true} xs={12} md={2} />
          <Grid item={true} xs={12} md={8}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/fixed.png?alt=media&token=1f545672-4a39-48b9-ace8-d68344630d52"
              style={{ width: "50%" }}
            />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/capital.png?alt=media&token=4f06e696-b745-473c-9ca5-6b46347c21c1"
              style={{ width: "50%" }}
            />
          </Grid>
          <Grid item={true} xs={12} md={2} />
        </Grid>
      </Container>
    </Page1News1S31>
  );
}

export default Page1S2New;
