import * as React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Grid from "@material-ui/core/Grid";
import { useAuth } from "../util/auth";
import { Alert } from '@material-ui/lab';
import { InputAdornment } from '@material-ui/core';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function PhoneVerificationModal(props) {
    const auth = useAuth();
    const { user, phone_number, handleModalClose } = props;
    const [open, setOpen] = React.useState(true);
    const [otp, setOTP] = React.useState("");
    const [formAlert, setFormAlert] = React.useState(null);
    const [processing, setProcessing] = React.useState(false);
    const [phone, setPhone] = React.useState(phone_number?.replace("+91", ""));
    const [generatedOTP, setGeneratedOTP] = React.useState(false);
    const [show, setShow]= React.useState(false);
    const handleClose = (data) => {
        if (data == true) {
            setOpen(false);
            handleModalClose(data);
        } else {
            setFormAlert({
                type: "error",
                message: "Phone Number verification is mandatory!!",
            });
        }
    };


    React.useEffect(() => {
        if (phone !== null && phone?.length == 10 && user !== null) {
            GenerateOTP();
        }
        setTimeout(() => setShow(true), 10000);
    }, [])

    const GenerateOTP = async () => {
        try {
            if (phone?.length == 10) {
                setGeneratedOTP(true);
                // setFormAlert(null);
                const formattedPhone = `+91${phone.replace("+91", "")}`;
                await auth.linkPhoneNumberGenerateOTP(user, formattedPhone);
                setFormAlert({
                    type: "success",
                    message: "OTP Sent to your phone number!!",
                });
            } else {
                setFormAlert({
                    type: "error",
                    message: "Invalid Phone Number!!",
                });
                setGeneratedOTP(false);
            }
        } catch (error) {
            setFormAlert({
                type: "error",
                message: "Error Generating OTP!!",
            });
        }
    }

    const submitOTP = async () => {
        try {
           
            
            setProcessing(true);
            await auth.confirmPhoneNumberOTP(otp);
            setFormAlert({
                type: "success",
                message: "Successfully verified OTP",
            });
            setTimeout(() => handleClose(true), 500);
        } catch (error) {
            setProcessing(false);
            const msg = error.message?.indexOf("invalid-verification-code") > -1 ? "Invalid OTP" : "Unable to verify OTP please try again by resending OTP";
            setFormAlert({
                type: "error",
                message: msg,
            });
        }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={() => handleClose(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h4" component="h2" align="center" style={{ paddingBottom: '2rem' }}>
                        Link Mobile Number
                    </Typography>
                    {formAlert && (
                        <Box mb={3}>
                            <Alert severity={formAlert.type}>{formAlert.message}</Alert>
                        </Box>
                    )}
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                type="text"
                                label="Phone Number"
                                name="phone_number"
                                value={phone}
                                disabled={phone_number}
                                readOnly={true}
                                fullWidth={true}
                                onChange={(e) => setPhone(e.target.value)}
                                onBlur={(e) => setPhone(e.target.value)}
                                InputProps={{maxLength: 10,
                                    startAdornment: <InputAdornment position="start">+91</InputAdornment>
                                  }}
                                  onInput = {(e) =>{
                                    // e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                    e.target.value =  e.target.value.replace(/[^0-9]/g, '').slice(0,10);
                                }}

                            />
                        </Grid>
                        {!generatedOTP && phone != phone_number && <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                type="button"
                                onClick={() => GenerateOTP()}
                            >
                                Generate OTP
                            </Button>
                        </Grid>}
                       {generatedOTP && <><Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                type="text"
                                label="OTP"
                                name="otp"
                                value={otp}
                                fullWidth={true}
                                InputProps={{maxLength: 6
                                  }}
                                  onInput = {(e) =>{
                                    // e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                    e.target.value =  e.target.value.replace(/[^0-9]/g, '').slice(0,6);
                                }}
                                onChange={(e) => {setOTP(e.target.value); console.log(e.target.value)}}
                                // onBlur={(e) => setOTP(e.target.value)}
                            />
                           {show && <Button
                             style={{marginTop:"10px"}}
                                variant="contained"
                                color="primary"
                                size="small"
                                type="button" onClick={() => GenerateOTP()}> Resend OTP</Button>}
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                type="button"
                                onClick={() => submitOTP()}
                            >
                                Submit OTP
                            </Button>
                        </Grid></>}
                        {/* <Grid item xs={6} style={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                type="button"
                                onClick={() => handleClose(false)}
                            >
                                Cancel
                            </Button>
                        </Grid> */}
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}