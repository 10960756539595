import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Flippy, { FrontSide, BackSide } from 'react-flippy';
const useStyles = makeStyles((theme) => ({
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    maxWidth: 700,
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: "inline-block",
  },
}));

function Page1News322(props) {
  const classes = useStyles();
  const [isFlipped, setIsFlipped] = React.useState(false)
  const [count, setCount] = React.useState(1)

  React.useEffect(() => {
    console.log(`initializing interval`);
    const interval = setInterval(() => {
      setIsFlipped(!isFlipped);
    }, 4000);

    return () => {
      console.log(`clearing interval`);
      clearInterval(interval);
    };
  }, [isFlipped]);
  React.useEffect(() => {
    const interva1 = setInterval(() => {
      setCount(count+1)
    }, 9000);
    
  
    return () => {
      console.log(`clearing interval`);
      clearInterval(interva1);
    };
  }, []);
  const { subtitle, subtitle1, title, size, className, ...otherProps } = props;

  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <Box
      component="header"
      className={classes.root + (props.className ? ` ${props.className}` : "")}
      {...otherProps}
      textAlign="left"
      color="#ffffff"
    >
      {title && (
        <>
          <Typography
            variant={`h${size}`}
            gutterBottom={props.subtitle ? true : false}
            style={{ fontWeight: "bold", marginBottom: "15px" }}
          />

          {subtitle1 && (
            <Typography variant="subtitle1" className={classes.subtitle}>
              {subtitle1}
            </Typography>
          )}
        </>
      )}
              <Flippy 
          isFlipped={isFlipped}
    flipDirection="vertical"
  >
     <FrontSide
      style={{
        backgroundColor: "#3DAEB6",
        textAlign: "center",
        height: "90px",
        marginBottom: "20px",
        justifyContent: "flex-start",
        alignItems: "center",
        display: "flex",
      }}
    >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/trending-up.png?alt=media&token=e10848ed-ba88-40c1-8aa5-6ccf8ed93bec"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
        <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
          Land is an appreciating & secure asset
        </a>
    </FrontSide>
    <BackSide
      style={{
        backgroundColor: "#262632",
        textAlign: "center",
        height: "90px",
        marginBottom: "20px",
        justifyContent: "flex-start",
        alignItems: "center",
        display: "flex",
      }}>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/banknote.png?alt=media&token=50d84f43-7776-420b-931d-b35a0694cd76"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
        <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
          Capital appreciation due to land appreciation
        </a>
    </BackSide>

  </Flippy>
  <Flippy 
          isFlipped={isFlipped}
    flipDirection="vertical"
  >
     <FrontSide
      style={{
        backgroundColor: "#616161",
        textAlign: "center",
        height: "40px",
        height: "90px",
        marginBottom: "20px",
        justifyContent: "flex-start",
        alignItems: "center",
        display: "flex",
      }}
    >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/trending-down.png?alt=media&token=4d7f245e-3cde-4ff2-bbf8-ec94aecc911b"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
        <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
          Reduces the financial burden of a Single Investor
        </a>
    </FrontSide>
    { count% 2 != 0 &&   <BackSide
      style={{
        backgroundColor: "#3DAEB6",
        textAlign: "center",
        height: "40px",
        height: "90px",
        marginBottom: "20px",
        justifyContent: "flex-start",
        alignItems: "center",
        display: "flex",
      }}>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/frame.png?alt=media&token=77ab8a13-d70e-4720-b06b-a631a259ab03"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
        <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
          A tool for Portfolio Diversification and Risk Mitigation
        </a>
    </BackSide>}

   { count% 2 == 0 && <BackSide
        style={{
          backgroundColor: "#3DAEB6",
          textAlign: "center",
          height: "40px",
          height: "90px",
          marginBottom: "20px",
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/crown.png?alt=media&token=a5ff57ec-3861-411f-b83f-26d8d1024c2e"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
        <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
          Beats Savings Rates across all Indian Banks
        </a>
      </BackSide>}

  </Flippy>
      {/* <div
        style={{
          backgroundColor: "#3DAEB6",
          textAlign: "center",
          height: "90px",
          marginBottom: "20px",
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/trending-up.png?alt=media&token=e10848ed-ba88-40c1-8aa5-6ccf8ed93bec"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
        <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
          Land is an appreciating & secure asset
        </a>
      </div>
      <div
        style={{
          backgroundColor: "#262632",
          textAlign: "center",
          height: "90px",
          marginBottom: "20px",
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/banknote.png?alt=media&token=50d84f43-7776-420b-931d-b35a0694cd76"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
        <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
          Capital appreciation due to land appreciation
        </a>
      </div> */}
      {/* <div
        style={{
          backgroundColor: "#616161",
          textAlign: "center",
          height: "40px",
          height: "90px",
          marginBottom: "20px",
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/trending-down.png?alt=media&token=4d7f245e-3cde-4ff2-bbf8-ec94aecc911b"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
        <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
          Reduces the financial burden of a Single Investor
        </a>
      </div>
      <div
        style={{
          backgroundColor: "#3DAEB6",
          textAlign: "center",
          height: "40px",
          height: "90px",
          marginBottom: "20px",
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/frame.png?alt=media&token=77ab8a13-d70e-4720-b06b-a631a259ab03"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
        <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
          A tool for Portfolio Diversification and Risk Mitigation
        </a>
      </div> */}
      {/* <div
        style={{
          backgroundColor: "#3DAEB6",
          textAlign: "center",
          height: "40px",
          height: "90px",
          marginBottom: "20px",
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/crown.png?alt=media&token=a5ff57ec-3861-411f-b83f-26d8d1024c2e"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        />
        <a style={{ fontWeight: "400", color: "#ffffff", textAlign: "Left" }}>
          Beats Savings Rates across all Indian Banks
        </a>
      </div> */}
    </Box>
  );
}

export default Page1News322;
