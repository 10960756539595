import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    maxWidth: 700,
    // So we can have max-width but still
    // have alignment controlled by text-align.
    //display: "inline-block",
  },
}));

function Evers2(props) {
  const classes = useStyles();

  const { subtitle, title, size, className, ...otherProps } = props;

  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <Box
      component="header"
      className={classes.root + (props.className ? ` ${props.className}` : "")}
      {...otherProps}
    >
      <section>
        {title && (
          <Typography
            variant={`h${size}`}
            gutterBottom={props.subtitle ? true : false}
            style={{ fontWeight: "bold", color: "#3DAEB6" }}
          >
            {subtitle && (
              <Typography variant="title" className={classes.subtitle}>
                {subtitle}
              </Typography>
            )}
          </Typography>
        )}
      </section>
    </Box>
  );
}

export default Evers2;
