import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {IconButton , InputAdornment} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { auth as myAuth, useAuth } from "./../util/auth";
import { RecaptchaVerifier } from 'firebase/auth';
import 'react-phone-number-input/style.css'
// from git
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import PhoneVerificationModal from "../pages/phoneVerificationModal";
import { getUser } from "../util/db";
import { history } from "../util/router";
import { async } from "analytics/lib/analytics.cjs";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useRouter } from "./../util/router";

function AuthForm(props) {
  const auth = useAuth();
  // from git
  const router = useRouter();

  const [values, setValues] = React.useState({
    password: '',
    cpassword: '',
    showPassword: false,
    showcPassword: false,
  });
  const [showMobileVerificationModal, setShowMobileVerificationModal] = useState(false);
  const [user, setUser] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  // const [showPassword, setShowPassword] = useState(false);
// const handleClickShowPassword = () => setShowPassword(!showPassword);
// const handleMouseDownPassword = () => setShowPassword(!showPassword);
const [txt, setTxt] = useState('');
const [txtl, setTxtl] = useState('');


const onfnInputChange = e => {
  const { value } = e.target;
  console.log('Input value: ', value);
  

  const re = /^[A-Za-z]+$/;
  if (value === "" || re.test(value)) {
    setTxt(value);
  }
}
const onlnInputChange = e => {
  const { value } = e.target;
  console.log('Input value: ', value);
  

  const re = /^[A-Za-z]+$/;
  if (value === "" || re.test(value)) {
    setTxtl(value);
  }
}
  useEffect(() => {
    // myAuth.settings.appVerificationDisabledForTesting = true;
    // from git
    // appSignOut();
    if (user?.phoneNumber == null) {
      // appSignOut();
    }
    window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        //onSignInSubmit();
        console.log('reCAPTCHA solved, allow signInWithPhoneNumber.');
      }
    }, myAuth);
  }, []);

  const [pending, setPending] = useState(false);
  const { handleSubmit, register, errors, getValues } = useForm();
  const [value, setValue] = useState();
  const submitHandlersByType = {
    signin: ({ email, pass }) => {
      // return auth.signin(email, pass).then((user) => { from git
        return auth.signinad(email, pass).then(async (user) => {
        // Call auth complete handler
        // props.onAuth(user); change from git
        // props.onAuth(user, auth.userRole); from git
        // if (auth.userRole == "user"){
        //   appSignOut();
        //   router.push("/auth/signin");


        // }
        // if (auth.userRole == "cp"){
        //   appSignOut();
        //   router.push("/cp/auth/signin");
        // }
        
        if (user?.phoneNumber == null) {
          setUser(user);
          const userInfo = await getUser(user.uid);
          setPhoneNumber(userInfo?.phone_number);
          setShowMobileVerificationModal(true);
        } else {
          // props.onAuth(user); from git
          props.onAuth(user, auth.userRole)
        }
      });
    },
    // signup: ({ email, pass, first_name, last_name, phone}) => { from git
      signup: ({ email, pass, first_name, last_name, phone }) => {
      const formattedPhone = `+91${phone.replace("+91", "")}`;
      // return auth.signup(email, pass, first_name, last_name, formattedPhone).then((user) => { from git
        // Call auth complete handler
        // props.onAuth(user); change from git
        return auth.signup(email, pass, first_name, last_name, formattedPhone).then(async (user) => {
          if (props.referralCode != null && props.referralCode != undefined) {
            const userInfo = await getUser(user.uid);
            await auth.addReferral({ id: user.uid, ...userInfo }, props.referralCode);
          }
          setPhoneNumber(formattedPhone);
          setUser(user);
          setShowMobileVerificationModal(true);
        // props.onAuth(user, auth.userRole); from git
      });
    },
    forgotpass: ({ email }) => {
      return auth.sendPasswordResetEmail(email).then(() => {
        setPending(false);
        // Show success alert message
        props.onFormAlert({
          type: "success",
          message: "Password reset email sent",
        });
      });
    },
    changepass: ({ pass }) => {
      return auth.confirmPasswordReset(pass).then(() => {
        setPending(false);
        // Show success alert message
        props.onFormAlert({
          type: "success",
          message: "Your password has been changed",
        });
      });
    },
  };
  const onCloseMobileVerification = async (data) => {
    setShowMobileVerificationModal(false);
    console.log('onCloseMobileVerification', data);
    if (data === true) {
      console.log('Authorized')
      props.onAuth(user);
    } else {
      console.log('Need mobile verification')
      await auth.signout();
      setPending(false);
      window.location.reload();
    }
  }
   // SIGN OUT FUNCTION
  const appSignOut = async () => {
    await auth.signout();
  }
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    
  };

  const handlecChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowcPassword = () => {
    setValues({
      ...values,
      showcPassword: !values.showcPassword,
    });
  };

  const handleMouseDowncPassword = (event) => {
    event.preventDefault();
  };


  // Handle form submission
  const onSubmit = ({ email, pass, first_name, last_name, phone }) => {
    // Show pending indicator
    setPending(true);
    
    // Call submit handler for auth type
    submitHandlersByType[props.type]({
      email,
      pass,
      first_name,
      last_name,
      phone
    }).catch((error) => {
      setPending(false);
      // Show error alert message from git
      if (error.code === "auth/user-not-found") {
        props.onFormAlert({
          type: "error",
          message: "Email not found, Please sign Up",
        });
      } else if (error.code === "auth/wrong-password") {
        props.onFormAlert({
          type: "error",
          message: "You entered wrong password, Please try again or Reset your password",
        });
      }else if (error.code === "auth/too-many-requests") {
        props.onFormAlert({
          type: "error",
          message: "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. ",
        });
      }
      else if (error.code === "auth/popup-closed-by-user") {
        props.onFormAlert({
          type: "error",
          message: "You closed the popup before completing the Google sign in. ",
        });
      }
      // const msg = error.message?.indexOf("email-already-in-use") > -1 ? "Email already in registered, Please sign In" : error.message;
      // props.onFormAlert({
      //   type: "error",
      //   message: msg,
      // });
    });
  };

  return (
    // <form onSubmit={handleSubmit(onSubmit)}>
    //   <Grid container={true} spacing={2}>
    //     {["signup"].includes(props.type) && (
    //       <Grid item={true} xs={12}>
    //         <TextField
    //         style={{backgroundColor: "#F2F2F2", borderRadius:"0px"}}
    //         variant="outlined"
    //         color="secondary"
    //           type="first_name"
    //           label="First Name"
    //           name="first_name"
    //           placeholder="First Name"
    //           error={errors.email ? true : false}
    //           helperText={errors.email && errors.email.message}
    //           fullWidth={true}
    //           inputRef={register({
    //             required: "Please enter your first name",
    //           })}
    //         />
    //       </Grid>
    //     )}
    //     {["signup"].includes(props.type) && (
    //       <Grid item={true} xs={12}>
    //         <TextField
    //           variant="outlined"
    //           type="last_name"
    //           label="Last name"
    //           name="last_name"
    //           placeholder="Last Name"
    //           error={errors.email ? true : false}
    //           helperText={errors.email && errors.email.message}
    //           fullWidth={true}
    //           inputRef={register({
    //             required: "Please enter your last name",
    //           })}
    //         />
    //       </Grid>
    //     )}


    //     {["signup", "signin", "forgotpass"].includes(props.type) && (
    //       <Grid item={true} xs={12}>
    //         <TextField
    //           variant="outlined"
    //           type="email"
    //           label="Email"
    //           name="email"
    //           placeholder="user@example.com"
    //           error={errors.email ? true : false}
    //           helperText={errors.email && errors.email.message}
    //           fullWidth={true}
    //           inputRef={register({
    //             required: "Please enter your email",
    //           })}
    //         />
    //       </Grid>
    //     )}

    //     {["signup", "signin", "changepass"].includes(props.type) && (
    //       <Grid item={true} xs={12}>
    //         <TextField
    //           variant="outlined"
    //           type="password"
    //           label="Password"
    //           name="pass"
    //           error={errors.pass ? true : false}
    //           helperText={errors.pass && errors.pass.message}
    //           fullWidth={true}
    //           inputRef={register({
    //             required: "Please enter a password",
    //           })}
    //         />
    //       </Grid>
    //     )}

    //     {["signup", "changepass"].includes(props.type) && (
    //       <Grid item={true} xs={12}>
    //         <TextField
    //           variant="outlined"
    //           type="password"
    //           label="Confirm Password"
    //           name="confirmPass"
    //           error={errors.confirmPass ? true : false}
    //           helperText={errors.confirmPass && errors.confirmPass.message}
    //           fullWidth={true}
    //           inputRef={register({
    //             required: "Please enter your password again",
    //             validate: (value) => {
    //               if (value === getValues().pass) {
    //                 return true;
    //               } else {
    //                 return "This doesn't match your password";
    //               }
    //             },
    //           })}
    //         />
    //       </Grid>
    //     )}

    //     {["signup"].includes(props.type) && (
    //       <Grid item={true} xs={12}>
    //                           <TextField
    //                 variant="outlined"
    //                 type="phone"
    //                 label="Phone"
    //                 name="phone"
    //                 placeholder="99xxxxxx99"
    //                 error={errors.phone ? true : false}
    //                 helperText={errors.phone && errors.phone.message}
    //                 fullWidth={true}
    //                 InputProps={{
    //                   startAdornment: <InputAdornment position="start">+91</InputAdornment>
    //                 }}
    //                 inputRef={register({
    //                   required: "Please enter your Phone number",
    //                 })}
    //               />
    //       </Grid>
          
    //     )}
    //     {["signup", "signin", "changepass", "forgotpass"].includes(props.type) && (
    //     <Grid item={true} xs={12}>
    //       <Button
    //         id="sign-in-button"
    //         className="sign-in-button"
    //         variant="contained"
    //         color="primary"
    //         size="large"
    //         type="submit"
    //         disabled={pending}
    //         fullWidth={true}
    //       >
    //         {!pending && <span>{props.buttonAction}</span>}

    //         {pending && <CircularProgress size={28} />}
    //       </Button>
    //     </Grid>
    //     )}
    //     {["verify"].includes(props.type) && (
    //     <Grid item={true} xs={12}>
    //       <Button
    //         id="sign-in-button"
    //         className="sign-in-button"
    //         variant="contained"
    //         color="primary"
    //         size="large"
    //         type="submit"
    //         disabled={pending}
    //         fullWidth={true}
    //         href="/"
    //       >
    //         {!pending && <span>{props.buttonAction}</span>}

    //         {pending && <CircularProgress size={28} />}
    //       </Button>
    // from git
    <>
     <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
<ToastContainer /> <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container={true} spacing={2}>
          {["signup"].includes(props.type) && (
            <Grid item={true} xs={12}>
              <TextField
                // style={{ backgroundColor: "#F2F2F2", borderRadius: "0px" }}
                variant="outlined"
                color="secondary"
                type="text"
                label="First Name"
                name="first_name"
                value={txt}
                onChange={onfnInputChange}
                placeholder="First Name"
                error={errors.email ? true : false}
                helperText={errors.email && errors.email.message}
                fullWidth={true}
                inputRef={register({
                  required: "Please enter your first name",
                })}
              />
            </Grid>
          )}
          {["signup"].includes(props.type) && (
            <Grid item={true} xs={12}>
              <TextField
                variant="outlined"
                type="text"
                label="Last name"
                name="last_name"
                value={txtl}
                onChange={onlnInputChange}
                placeholder="Last Name"
                error={errors.email ? true : false}
                helperText={errors.email && errors.email.message}
                fullWidth={true}
                inputRef={register({
                  required: "Please enter your last name",
                })}
              />
            </Grid>
          )}


          {["signup", "signin", "forgotpass"].includes(props.type) && (
            <Grid item={true} xs={12}>
              <TextField
                variant="outlined"
                type="email"
                label="Email"
                name="email"
                placeholder="user@example.com"
                error={errors.email ? true : false}
                helperText={errors.email && errors.email.message}
                fullWidth={true}
                inputRef={register({
                  required: "Please enter your email",
                })}
              />
            </Grid>
          )}

          {[ "signin", "changepass"].includes(props.type) && (
            <Grid item={true} xs={12}>
              <TextField
                variant="outlined"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                label="Password"
                name="pass"
                error={errors.pass ? true : false}
                helperText={errors.pass && errors.pass.message}
                fullWidth={true}
              
                inputRef={register({
                  required: "Please enter a password",
                })}
                InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
                  )
                  
                }}
              />
            </Grid>
          )}

          {[ "changepass"].includes(props.type) && (
            <Grid item={true} xs={12}>
              <TextField
                variant="outlined"
                // type="password"
                label="Confirm Password"
                name="confirmPass"
                type={values.showcPassword ? 'text' : 'password'}
              value={values.cpassword}
              onChange={handlecChange('cpassword')}
                error={errors.confirmPass ? true : false}
                helperText={errors.confirmPass && errors.confirmPass.message}
                fullWidth={true}
                inputRef={register({
                  required: "Please enter your password again",
                  validate: (value) => {
                    if (value === getValues().pass) {
                      return true;
                    } else {
                      return "This doesn't match your password";
                    }
                  },
                })}
                InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowcPassword}
                  onMouseDown={handleMouseDowncPassword}
                >
                  {values.showcPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
                  )
                }}
              />
            </Grid>
          )}

          {["signin", "changepass", "forgotpass"].includes(props.type) && (
            <Grid item={true} xs={12}>
              <Button
                id="sign-in-button"
                className="sign-in-button"
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={pending}
                fullWidth={true}
              >
                {!pending && <span>{props.buttonAction}</span>}

                {pending && <CircularProgress size={28} />}
              </Button>
            </Grid>
          )}
          {["verify"].includes(props.type) && (
            <Grid item={true} xs={12}>
              <Button
                id="sign-in-button"
                className="sign-in-button"
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={pending}
                fullWidth={true}
                href="/auth/signin"
              >
                {!pending && <span>{props.buttonAction}</span>}

                {pending && <CircularProgress size={28} />}
              </Button>
            </Grid>
          )}
        </Grid>
        </form>
      {showMobileVerificationModal && <PhoneVerificationModal
        user={user}
        phone_number={phoneNumber}
        handleModalClose={onCloseMobileVerification}
      />}
      {/* <button type="button" onClick={() => setShowMobileVerificationModal(true)}>Open verifciation modal</button> */}
    </>
  );
}

export default AuthForm;
