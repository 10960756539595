import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import ChatIcon from "@material-ui/icons/Chat";
import VideogameAssetIcon from "@material-ui/icons/VideogameAsset";
import PetsIcon from "@material-ui/icons/Pets";
import { makeStyles } from "@material-ui/core/styles";
import Sectionbks1 from "./Sectionbks1";
import AuthSection from "./AuthSectionadmin";
import { useRouter } from "./../util/router";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
    marginTop:"3%"
  },
  imageWrapper: {
    margin: "0 auto",
    maxWidth: 570,
    width: "100%",
    "& > img": {
      width: "100%",
    },
  },
  row: {
    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(2)}px`,
    },
  },
}));

function FeaturesSection(props) {
  const classes = useStyles();

  const router = useRouter();

  const items = [
    {
      title: "Chat",
      description:
        "Imagine a world where you can chat with your friends without having to see their dumb faces.",
      icon: ChatIcon,
      iconColor: "primary.main",
    },
    {
      title: "Play",
      description:
        "We embedded a bunch free flash games inside our app. What we lack in quality we make up in quantity.",
      icon: VideogameAssetIcon,
      iconColor: "secondary.main",
    },
    {
      title: "Cats",
      description:
        "Our market research told us people like cats so we put some cat icons on our website.",
      icon: PetsIcon,
      iconColor: "#00d1b2",
    },
  ];

  return (
    <Sectionbks1
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      // style={{height: "100vh"}}
    >
      <Container className={classes.container}>
        <Grid container={true} alignItems="center" spacing={8}>
          <Grid container={true} item={true} direction="column" xs={12} md={6} sx={{ display: { sm: "flex", xs: "flex", lg: "none" }}}>
            <figure className={classes.imageWrapper}>
              <h1
                style={{
                  fontSize: "40px",
                  color: "#ffffff",
                  textAlign: "right",
                }}
              >
                <a style={{ color: "#3DAEB6" }}>GAYN</a> CAPITAL
              </h1>{" "}
              <p style={{ color: "#ffffff", textAlign: "right" , fontSize:"20px"}}>
              Welcome to the future of Retail <a style={{color:"#3DAEB6"}}>CRE Investing</a>
              <br/>
              
                {" "}
                An Alternative Investment
Platform defined by <a style={{color:"#3DAEB6"}}>Scalability,
Simplicity, and Speed</a> 
              </p>
            </figure>
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <AuthSection
              bgColor="default"
              size="medium"
              bgImage=""
              bgImageOpacity={1}
              type={router.query.type}
              referralCode={router.query.referralCode}
              providers={["google"]}
              afterAuthPath="/"
            />
          </Grid>
        </Grid>
      </Container>
    </Sectionbks1>
  );
}

export default FeaturesSection;
