import * as React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Grid from "@material-ui/core/Grid";
import { submitKYCAadharOTP, submitKYCAadharOTPNew } from '../util/functions';
import CircularProgress from "@material-ui/core/CircularProgress";
import { ToastContainer, toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function AadharOTPModal(props) {
    const { uid, aadhar_number, transactionId, code, userType, handleModalClose } = props;
    const [open, setOpen] = React.useState(true);
    const [otp, setOTP] = React.useState("");
    const [pending18, setPending18] = React.useState(false);
    const handleClose = (data) => {
        setOpen(false);
        handleModalClose(data);
    };

    const submitOTP = async () => {
        setPending18(true);
        const data = await submitKYCAadharOTPNew(uid, aadhar_number, userType, transactionId, code, otp);
       console.log("data", data.isSuccess)

        handleClose(data.isSuccess);

    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Please Enter Generated OTP to complete KYC for Aadhaar
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                type="text"
                                label="OTP"
                                name="otp"
                                value={otp}
                                fullWidth={true}
                                onChange={(e) => setOTP(e.target.value)}
                                onBlur={(e) => setOTP(e.target.value)}
                                InputProps={{maxLength: 6,
                                }}
                                onInput = {(e) =>{
                                  e.target.value =  e.target.value.replace(/[^0-9]/g, '').slice(0,6);
                              }}
                            />
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                type="button"
                                disabled={pending18}
                                onClick={submitOTP}
                            >
                                
                                {!pending18 && <span>Submit OTP</span>}
                                {pending18 && <CircularProgress size={28} />}
                            </Button>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                type="button"
                                disabled={pending18}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
