import React, {useState, useEffect} from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import { requireAuth, useAuth } from "../util/auth";
import Divider from "@material-ui/core/Divider";
import LogoutIcon from '@material-ui/icons/ExitToApp';
// import ListItem from "@material-ui/core/ListItem";
import {getUser} from "../util/db";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "../util/router";
import { useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Container } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BusinessIcon from '@material-ui/icons/Business';
const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    backgroundColor:"white",

  },
  logo: {
    height: 40,
    marginRight: theme.spacing(2),
    justifyContent: "center",
    alignItems: "center",
  },
  drawer: {
    flexShrink: 0,
    width: drawerWidth,
    color:"#ffffff"
   
  },
  drawerPaper: {
    width: drawerWidth, 
    backgroundColor: '#262632',
    color:"#ffffff"
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  content: {
    flexGrow: 1,
    backgroundColor:'#ffffff',
    padding: theme.spacing(3)
  },
  active1:{
    backgroundColor: '#ffffff',  }
}));
const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#3DAEB6",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white"
      }
    },
    "&$selected:hover": {
      backgroundColor: "#3DAEB6",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white"
      }
    },
    "&:hover": {
      backgroundColor: "#3DAEB6",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white"
      }
    }
  },
  selected: {}
})(MuiListItem);
export default function Usernav(props) {
  const auth = useAuth();
  const userId = auth?.user?.id;
  const [uid, setUid] = useState(userId);
  const [userd, setUserd] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const location = useLocation()
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    populateUser();
  }, [])
  
  const populateUser = async () => {
    const user = await getUser(uid);
    if (user.aadhaar_offline_verified == true){
      setUserd(true);
    } else{
      setUserd(false);
    }
    console.log('w2222',user.aadhaar_offline_verified)
  }

  const toggleDrawer = event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
     
      <AppBar position="fixed" className={classes.appBar}>
    
        <Toolbar style={{zIndex:'99', display:'flex', justifyContent:"left"}}>

                      <Hidden smUp={true} implementation="css">
              <IconButton
             
                onClick={() => {
                  setDrawerOpen(true);
                }}
                color="black"
              >
                <MenuIcon />
              </IconButton>

            </Hidden>
            {!isMdUp &&
            <Link to="/">
              <img src={'https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-black.png?alt=media&token=4df2ee2a-8696-48b5-8a65-98171d8179d9'} alt="Logo" className={classes.logo} />
            </Link>}
        </Toolbar>
 
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant={isMdUp ? "permanent" : "temporary"}
        classes={{
          paper: classes.drawerPaper
        }}
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <div style={{height:'80px'}}>
        <a href="/">
        <img src='https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/logo-white.png?alt=media&token=f01e01de-ca6d-4359-a864-66b184579096' style={{width:"210px", paddingLeft:"70px", paddingRight:"40px", paddingTop:"15px"}}/></a></div>
        <Divider />
        <List>
          {[    
    {
      text: "Dashboard",
      icon: "https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/icon1.png?alt=media&token=e5b8c676-8ae4-4e71-ba07-210d1edb9590",
      to1: "/user/dashboard",
      icon2: false
    },
    {
      text: "Current Offering",
      icon: "https://firebasestorage.googleapis.com/v0/b/gayn-82759.appspot.com/o/ic_baseline-business.png?alt=media&token=1f126692-99d0-46bc-8437-9c07a3f87885",
      to1: "/user/offerings",
      icon2: false
    },
    {
      text: "My Real Estate",
      icon: "https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/icon2.png?alt=media&token=01835664-bfcf-4be4-bbc8-28abda1fa00d",
      to1: "/user/properties",
      icon2: false
    },
    {
      text: "Documents",
      icon: "https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/icon3.png?alt=media&token=4ca6c34e-069d-4072-8b16-b4179b25668f",
      to1: "/user/documents",
      icon2: false
    },
    {
      text: "Investor Details",
      icon: "https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/icon4.png?alt=media&token=788ad7c7-52ec-432c-9a4d-9f6e11489dba",
      to1: "/user/details",
      icon2: true
    },
    {
      text: "Transactions",
      icon: "https://firebasestorage.googleapis.com/v0/b/gyan-test-30dd3.appspot.com/o/icon5.png?alt=media&token=36478adb-7268-4356-b9fd-8663f8433ca9",
      to1: "/user/transactions",
      icon2: false
    },
    {
      text: "Sign out",
      icon: "https://firebasestorage.googleapis.com/v0/b/gayn-82759.appspot.com/o/logout.png?alt=media&token=31ad9967-4805-4d8f-a261-3c4090738fbe",
      to1: "/signout",
      icon2: false
    },
    ].map((item, index) => {const { text, icon, to1, icon2} = item; return (
      <>
            <ListItem button={true} key={text} component={Link} to= {to1}   selected={to1 === location.pathname} style={{height:'60px'}} >
    <ListItemIcon style={{color:"#ffffff", height:"20px", marginBottom:"0px"}}><img style={{width: "22px"}} src={icon}/></ListItemIcon>

              <span style={{color:"#ffffff", zIndex:'999', fontSize:isMdUp?"12px":"10px" , fontWeight:"500", marginRight:"10px"}}>{text}</span>
              
              {(icon2 == true && userd == true) && <CheckCircleOutlineIcon style={{color:'green'}}/>}
              {(icon2 == true && userd == false) && <ErrorOutlineIcon style={{color:'red'}}/>}
            </ListItem>
            </> )})}
        </List>

      </Drawer>

      </div>
  );
}

