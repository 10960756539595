import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from "./firebase";
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
const functions = getFunctions(firebaseApp);

export const verifyKYCAadharAdmin = (uid, role, aadhar_number) => {
    try {
        const callVerifyAadhar = httpsCallable(functions, 'verifyAadharAdmin');
        const data = {
            'aadharNumber': aadhar_number,
            'uid': uid,
            'role': role
        }
        return callVerifyAadhar(data);
    }
    catch (error) {
        console.log(`error: ${JSON.stringify(error)}`);
        return null;
    }
}

export const verifyKYCPAN = (uid, role, panNumber, name, date_of_birth) => {
    try {
        const callVerifyPAN = httpsCallable(functions, 'verifyPAN');
        const data = {
            'uid': uid,
            'role': role,
            'panNumber': panNumber,
            'name': name,
            'dateOfBirth': date_of_birth,
        }
        console.log("pan", data);
        return callVerifyPAN(data);
     
    }
    catch (error) {
        console.log(`er32323ror: ${JSON.stringify(error)}`);
        return null;
    }
}
export const verifyKYCSPAN = (uid, role, panNumber, name, date_of_birth) => {
    try {
        const callVerifyPAN = httpsCallable(functions, 'verifyPAN');
        const data = {
            'uid': uid,
            'role': role,
            'spanNumber': panNumber,
            'name': name,
            'dateOfBirth': date_of_birth,
        }
        return callVerifyPAN(data);
    }
    catch (error) {
        console.log(`error: ${JSON.stringify(error)}`);
        return null;
    }
}
  

// from git
export const setCPnumber = (uid) => {
    try {
        const callSetUserClaims = httpsCallable(functions, 'setCPnumber');
        const data = {
            'uid': uid,            
        }
        return callSetUserClaims(data);
    }
    catch (error) {
        console.log(`error: ${JSON.stringify(error)}`);
        return null;
    }
}

export const setAddTotal = (uid, funds) => {
    try {
        const callSetUserClaims = httpsCallable(functions, 'setAddTotalFunds');
        const data = {
            'uid': uid,
            'funds': Number(funds)           
        }
        return callSetUserClaims(data);
    }
    catch (error) {
        console.log(`error: ${JSON.stringify(error)}`);
        return JSON.stringify(error);
    }
}

export const setAddUserTotal = (uid, funds) => {
    try {
        const callSetUserClaims = httpsCallable(functions, 'setAddUserFunds');
        const data = {
            'uid': uid,
            'funds': Number(funds)           
        }
        return callSetUserClaims(data);
    }
    catch (error) {
        console.log(`error: ${JSON.stringify(error)}`);
        return JSON.stringify(error);
    }
}
export const setUserClaims = (uid) => {
    try {
        const callSetUserClaims = httpsCallable(functions, 'setUserClaims');
        const data = {
            'uid': uid,            
        }
        return callSetUserClaims(data);
    }
    catch (error) {
        console.log(`error: ${JSON.stringify(error)}`);
        return null;
    }
}



export const verifyKYCBank = (acno, code) => {
    try {
        const callVerifyBankAccount = httpsCallable(functions, 'verifyBank');
        const data = {
            'acno': acno,
            'code': code,
        }
        return callVerifyBankAccount(data);
    }
    catch (error) {
        console.log(`error: ${JSON.stringify(error)}`);
        return null;
    }
}
export const genericEmail = (data) => {
    try {
        const genericEmail = httpsCallable(functions, 'genericEmail');
        // const data = {
        //     'email': email,
        //     'subject': subject,
        //     'name': name,
        //     'text': text
        // }
        // console.log('erruuuor' , data );

        return genericEmail(data);
    }
    catch (error) {
        console.log(`error: ${JSON.stringify(error)}`);
        return null;
    }
}

export const CreateUserAdmin = async (email, password, first_name, last_name, phone, role, level, parentcp, type, cp_code) => {
    try {
        const callCreateUser = httpsCallable(functions, 'createUser');
        const data = {
            'email': email,
            'password': password,
            'firstname': first_name,
            'lastname': last_name,
            'phone': phone,
            'role': role,
            'level': level,
            'parentcp': parentcp,
            "type" : type,
            "cp_code" : cp_code,
        }
        console.log("322322", data)
        return await callCreateUser(data);

    }
    catch (error) {
        throw error;
    }
    

}

export const generateKYCAadharOTP = (uid, role, aadhar_number) => {
    try {
        console.log("locfun", aadhar_number)
        const callVerifyAadhar = httpsCallable(functions, 'verifyAadharGenerateOTP');
        const data = {
            'aadharNumber': aadhar_number,
            'uid': uid,
            'role': role
        }
        return callVerifyAadhar(data);
    }
    catch (error) {
        console.log(`error: ${JSON.stringify(error)}`);
        return null;
    }
}
export const generateKYCSAadharOTP = (uid, role, aadhar_number) => {
    try {
        
        const callVerifyAadhar = httpsCallable(functions, 'verifyAadharGenerateOTP');
        const data = {
            'saadharNumber': aadhar_number,
            'uid': uid,
            'role': role
        }
        return callVerifyAadhar(data);
    }
    catch (error) {
        console.log(`error: ${JSON.stringify(error)}`);
        return null;
    }
}


export const submitKYCAadharOTP = (uid, aadhar_number, role, transactionId, shareCode, otp) => {
    try {
       
        const callVerifyAadhar = httpsCallable(functions, 'verifyAadharWithOTP');
        const data = {
            'aadharNumber': aadhar_number,
            'uid': uid,
            'role': role,
            'shareCode': shareCode,
            'otp': otp,
            'transactionId': transactionId,
        }
        return callVerifyAadhar(data);
    }
    catch (error) {
        console.log(`error: ${JSON.stringify(error)}`);
        return null;
    }
}

export const generateKYCAadharOTPNew = async (uid, role, aadhar_number) => {
    try {
       
        const options = {
            method: 'POST',
            url: 'https://api.gridlines.io/aadhaar-api/boson/generate-otp',
            headers: {
              'Content-Type': 'application/json',
              'X-Auth-Type': 'API-Key',
              'X-API-Key': '1UCu2vMrrieX05Fx6xL7Pe6qNWAz7NXz'
            },
            data: {aadhaar_number: aadhar_number, consent: 'Y'}
          };
      
        const data =  await axios.request(options).then(function (response) {
            return response.data;
          }).catch(function (error) {
            return error.response.data;
          });
            console.log("data", data);
        return data;
      
    }
    catch (error) {
        console.log(`error: ${JSON.stringify(error)}`);
        return null;
    }
}

export const submitKYCAadharOTPNew = async (uid, aadhar_number, role, transactionId, shareCode, otp) => {
    try {
       
        const options = {
            method: 'POST',
            url: 'https://api.gridlines.io/aadhaar-api/boson/submit-otp',
            headers: {
              'Content-Type': 'application/json',
              'X-Auth-Type': 'API-Key',
              'X-Transaction-ID': transactionId,
              'X-API-Key': '1UCu2vMrrieX05Fx6xL7Pe6qNWAz7NXz'
            },
            data: {otp: otp, include_xml: true, share_code: shareCode}
          };
        
      const data =   await axios.request(options).then(function (response) {
            console.log( 'response' ,response.data);
            toast.success(response.data, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });

                return {
                    isSuccess: true,
                    message: "Aadhaar KYC Verified",
                  };
            
          }).catch(function (error) {
            console.error('error:' ,error.response.data.error.message);
            toast.error(error.response.data.error.message, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                return {
                    isSuccess: false,
                    message: "Aadhaar KYC Not Verified",
                  };
          });

      return data;
    }
    catch (error) {
        console.log(`error: ${JSON.stringify(error)}`);
        return null;
    }
}
export const submitKYCSAadharOTP = (uid, aadhar_number, role, transactionId, shareCode, otp) => {
    try {
        const callVerifyAadhar = httpsCallable(functions, 'verifyAadharWithOTP');
        const data = {
            'saadharNumber': aadhar_number,
            'uid': uid,
            'role': role,
            'shareCode': shareCode,
            'otp': otp,
            'transactionId': transactionId,
        }
        return callVerifyAadhar(data);
    }
    catch (error) {
        console.log(`error: ${JSON.stringify(error)}`);
        return null;
    }
}